import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom'

import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { useQuery } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { DetectionEventChartDataSource } from '~/graphql-codegen/graphql'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    validity: true,
  },
  event: {
    datasets: true,
    detectedBy: true,
    detectedByArray: true,
    detectedOn: true,
    validity: true,
  },
}

export function StudyDetectionDatasetsStatistics() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getStudyNodeActivity = useQuery(api.DETECTION_EVENT_CHART, {
    variables: {
      studyId,
      datasource: DetectionEventChartDataSource.StudyNodeActivity,
      query,
    },
  })

  const getAntennaArrayActivity = useQuery(api.DETECTION_EVENT_CHART, {
    variables: {
      studyId,
      datasource: DetectionEventChartDataSource.AntennaArrayActivity,
      query,
    },
  })

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../datasets/organisms" className="hover:text-wa21-100">
                Detection events
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Event analysis</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
      </header>

      <main className="rounded-lg bg-white py-6 shadow">
        <div className="border-b border-gray-200 px-5 sm:px-6 pb-5 mb-10">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} />
        </div>
        <div className="px-5 sm:px-6 space-y-10">
          <div>
            <div className="border-b border-gray-200 pb-5 mb-5">
              <h2 className="text-base font-semibold leading-6 text-gray-900">Detections by pathway</h2>
            </div>
            <Components.BarChart chart={getStudyNodeActivity.data?.detectionEventChart} filename={`events-${study?.name}-pathways`} />
          </div>
          <div>
            <div className="border-b border-gray-200 pb-5 mb-5">
              <h2 className="text-base font-semibold leading-6 text-gray-900">Detections by antenna array</h2>
            </div>
            <Components.BarChart chart={getAntennaArrayActivity.data?.detectionEventChart} filename={`events-${study?.name}-arrays`} />
          </div>
        </div>
      </main>

      <Outlet />
    </>
  )
}
