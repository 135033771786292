import { Fragment, useEffect, useState } from 'react'

import { Transition, Portal } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

import _ from 'lodash'
import classNames from 'classnames'

import { AppAction, useAppDispatch, useAppState } from '~/state'
import { Spinner } from './spin'

export function NotificationContainer() {
  const dispatch = useAppDispatch()
  const [expiry] = useState<{ [k: string]: number }>({})
  const [visibility, setVisibility] = useState<{ [k: string]: boolean }>({})
  const { notifications } = useAppState()
  const items = notifications || []

  function onHide(id: string) {
    let newVisibility = { ...visibility }

    newVisibility[id] = false
    setVisibility(newVisibility)
  }

  useEffect(() => {
    const task = setInterval(() => {
      for (let id in expiry) {
        if (expiry[id] < Date.now()) {
          onHide(id)
        }
      }
    }, 1000)

    return () => clearInterval(task)
  })

  useEffect(() => {
    let newVisibility = { ..._.pickBy(visibility, (_, id) => notifications.find((item) => item.id === id)) }
    let visibilityChanged = _.size(newVisibility) < _.size(visibility)

    for (let item of items) {
      if (expiry[item.id] === undefined) {
        newVisibility[item.id] = true
        visibilityChanged = true
        if (item.timeout > 0) {
          expiry[item.id] = Date.now() + item.timeout * 1000
        }
      }
    }
    for (let id in expiry) {
      if (newVisibility[id] === undefined) {
        delete expiry[id]
      }
    }
    if (visibilityChanged) {
      setVisibility(newVisibility)
    }
  }, [notifications])

  return (
    <Portal>
      <Transition.Root show={items.length > 0} as={Fragment}>
        <div className="absolute z-50">
          {items.some((item) => item.forced) && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-wa21-100 bg-opacity-75 transition-opacity" />
            </Transition.Child>
          )}

          <div className="fixed top-0 right-0 flex flex-col items-end space-y-2 sm:items-start pt-20 pr-8">
            {items.map((notification) => {
              let color = 'text-wa21-400'

              if (notification.level === 'error') {
                color = 'text-wa21-danger-400'
              } else if (notification.level === 'warn') {
                color = 'text-wa21-warning-400'
              }
              return (
                <div key={notification.id} className="flex w-96 flex-col items-center space-y-4 sm:items-end">
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition
                    show={visibility[notification.id] === true}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => dispatch({ action: AppAction.DismissNotification, notification })}>
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="p-4">
                        <div className="flex items-start">
                          {!notification.spinner && (
                            <div className={classNames(color, 'flex-shrink-0')}>
                              {notification.level === 'error' && <XCircleIcon className="h-6 w-6" aria-hidden="true" />}
                              {notification.level === 'warn' && <ExclamationCircleIcon className="h-6 w-6" aria-hidden="true" />}
                              {notification.level === 'info' && <CheckCircleIcon className="h-6 w-6" aria-hidden="true" />}
                            </div>
                          )}
                          {notification.spinner && (
                            <div className={classNames(color, 'flex-shrink-0')}>
                              <Spinner className="h-6 w-6" />
                            </div>
                          )}
                          <div className="ml-3 w-0 flex-1">
                            {notification.title !== '' && <p className={classNames(color, 'text-medium font-medium')}>{notification.title}</p>}
                            {notification.message !== '' && <p className="text-sm text-gray-700 leading-6">{notification.message}</p>}
                          </div>
                          {!notification.forced && (
                            <div className="ml-4 flex flex-shrink-0">
                              <button
                                type="button"
                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={(ev) => {
                                  ev.stopPropagation()
                                  onHide(notification.id)
                                }}>
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              )
            })}
          </div>
        </div>
      </Transition.Root>
    </Portal>
  )
}
