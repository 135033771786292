import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { StudyState } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyAntennaArrayUpsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const studyNodeId = params.studyNodeId || '0'
  const antennaArrayId = params.antennaArrayId || 'new'
  const [searchParams] = useSearchParams()

  const apollo = useApolloClient()
  const [insertAntennaArray] = useMutation(api.INSERT_ANTENNA_ARRAY)
  const [updateAntennaArray] = useMutation(api.UPDATE_ANTENNA_ARRAY)
  const [busy, setBusy] = useState(false)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      const description = data.get('description')?.toString()
      const location = data.get('location')?.toString() || ''
      const antennaNames = data.get('antennaNames')?.toString() || ''

      if (antennaArrayId === 'new') {
        await insertAntennaArray({
          variables: {
            studyNodeId,
            name: data.get('name')?.toString() || '',
            fields: {
              description,
              location: location.length > 0 ? JSON.parse(location) : undefined,
              antennaNames: antennaNames.length > 0 ? JSON.parse(antennaNames) : undefined,
            },
          },
        })
      } else {
        await updateAntennaArray({
          variables: {
            id: antennaArrayId,
            name: data.get('name')?.toString() || '',
            fields: {
              description,
              location: location.length > 0 ? JSON.parse(location) : undefined,
              antennaNames: antennaNames.length > 0 ? JSON.parse(antennaNames) : undefined,
            },
          },
        })
      }
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: antennaArrayId === 'new' ? 'Failed to create antenna array.' : 'Failed to update antenna array.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getAntennaArray = useQuery(api.GET_ANTENNA_ARRAY, { variables: { id: antennaArrayId }, skip: antennaArrayId === 'new' })
  const antennaArray = getAntennaArray.data?.antennaArray

  return (
    <Components.SlideOver
      title={antennaArrayId !== 'new' ? 'Edit antenna array' : 'Add antenna array'}
      busy={busy}
      disabled={study?.state !== StudyState.Active || study?.deletedAt}
      primaryAction={antennaArrayId !== 'new' ? 'Update' : 'Create'}
      onPrimaryAction={onUpsert}>
      {(antennaArrayId === 'new' || antennaArray) && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              required
              type="text"
              name="name"
              label="Name"
              defaultValue={antennaArray?.name}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              placeholder="Array name"
            />
            <Components.TagsField
              name="antennaNames"
              label="Antennas"
              defaultValue={antennaArray?.antennaNames as any}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
            />
            <Components.TextAreaField
              name="description"
              label="Description"
              defaultValue={antennaArray?.description || undefined}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              placeholder="Array description/notes"></Components.TextAreaField>
            <Components.CoordinatesField
              name="location"
              label="Coordinates"
              defaultValue={antennaArray?.location ? JSON.stringify(antennaArray?.location) : undefined}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
            />
          </div>
        </div>
      )}
    </Components.SlideOver>
  )
}
