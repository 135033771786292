import React from 'react'
import { Link, useNavigate, To } from 'react-router-dom'

import classNames from 'classnames'

export function HeaderPrimaryButton({ children, ...props }: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className="block rounded-md bg-white py-2 px-3 text-center text-sm font-semibold text-wa21-800 shadow-sm hover:bg-wa21-100 disabled:text-gray-400 disabled:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      {...props}>
      {children}
    </button>
  )
}

export function HeaderSecondaryButton({ children, ...props }: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className="block rounded-md border border-wa21-50 py-2 px-3 text-center text-sm font-semibold text-wa21-50 shadow-sm hover:text-wa21-800 hover:bg-wa21-50 disabled:text-gray-100 disabled:border-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      {...props}>
      {children}
    </button>
  )
}

export type HeaderTab = {
  to: To
  value: string
  label?: React.ReactNode
  pill?: React.ReactNode
}

export function HeaderTabs({ tabs = [], currentTab, ...props }: { tabs?: HeaderTab[]; currentTab?: string } & React.HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate()

  function isDefined(value: React.ReactNode) {
    return value !== undefined && value !== null && value !== false
  }

  function onChange(ev: React.ChangeEvent<HTMLSelectElement>) {
    if (ev.currentTarget instanceof HTMLSelectElement) {
      const tab = tabs.find((tab) => tab.value === ev.currentTarget.value)

      if (tab) {
        navigate(tab.to)
      }
    }
  }

  return (
    <div {...props}>
      <div className="sm:hidden">
        <label htmlFor="filter" className="sr-only">
          Select a tab
        </label>
        <select
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-wa21-600"
          defaultValue={currentTab}
          onChange={onChange}>
          {tabs.map((tab) => (
            <option key={tab.value} value={tab.value}>
              {tab.label || tab.value}
              {isDefined(tab.pill) && `(${tab.pill})`}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-white">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.value}
                className={classNames(
                  tab.value === currentTab ? 'border-white text-white font-semibold' : 'border-transparent text-white hover:border-white',
                  'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium text-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
                )}
                to={tab.to}>
                {tab.label || tab.value}
                {isDefined(tab.pill) && (
                  <span
                    className={classNames(
                      tab.value === currentTab ? 'bg-white text-wa21-800' : 'border-white text-white',
                      'ml-3 hidden rounded-full border border-transparent py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}>
                    {tab.pill}
                  </span>
                )}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
