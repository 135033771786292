import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { DateTime, Duration } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import { DetectionEventSort, ResultType, WellKnownCategories } from '~/graphql-codegen/graphql'

export function StudyResultsOrganismEvents() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const organismId = params.organismId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getOrganism = useQuery(api.GET_ORGANISM, { variables: { id: organismId } })
  const organism = getOrganism.data?.organism

  const listResults = useQuery(api.LIST_RESULT_EVENTS, { variables: { studyId, request: { page: 0, limit: 0, organismId } } })
  const resultsColumns: Components.TableColumn[] = [
    {
      name: 'type',
      label: 'Type',
      className: 'w-16 text-center',
    },
    {
      name: 'pathway',
      label: 'Pathway',
      className: 'w-16 text-center',
    },
    {
      name: 'array',
      label: 'Array',
      className: 'w-32 text-center',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-24 text-right',
    },
    {
      name: 'duration',
      label: 'Duration',
      className: 'w-16 text-right',
    },
    // {
    //   name: 'delay',
    //   label: 'Delay',
    //   className: 'w-16 text-right',
    // },
  ]
  const resultsRows: Components.TableRow[] = (listResults.data?.resultEvents.items || []).map((event) => ({
    id: event.id,
    type: event.resultType,
    pathway: <Components.StudyNodeDisplay studyNodeId={event.studyNodeId} />,
    array:
      event.resultType === ResultType.Passage ? (
        <span className="space-x-1">
          <Components.AntennaNameDisplay antennaArrayId={event.fromId} />
          <span>→</span>
          <Components.AntennaNameDisplay antennaArrayId={event.toId} />
        </span>
      ) : (
        <Components.AntennaNameDisplay antennaArrayId={event.fromId} />
      ),
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    duration: event.duration && Duration.fromMillis(parseInt(event.duration) * 1000).toFormat('h:mm:ss'),
    delay: event.prefixDelay && event.suffixDelay && (
      <span>
        -{Duration.fromMillis(parseInt(event.prefixDelay) * 1000).toFormat('mm:ss')}+{Duration.fromMillis(parseInt(event.suffixDelay) * 1000).toFormat('mm:ss')}
      </span>
    ),
  }))

  const listDetectionEvents = useQuery(api.LIST_DETECTION_EVENTS, {
    variables: { studyId, request: { page, limit: 100, valid: true, triggeredBy: [organismId], sortBy: DetectionEventSort.DetectedOn } },
  })
  const eventColumns: Components.TableColumn[] = [
    {
      name: 'id',
      label: 'ID',
      className: 'w-32',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-48',
    },
    {
      name: 'detectedFor',
      label: 'Duration (s)',
      className: 'w-32 text-right',
    },
    {
      name: 'antenna',
      label: 'Antenna',
      className: '',
    },
  ]
  const eventRows: Components.TableRow[] = (listDetectionEvents.data?.detectionEvents.items || []).map((event) => ({
    id: event.id,
    dataset: (
      <Link to={`../datasets/detections/${event.datasetId}`} className="block w-full text-wa21-600 hover:text-wa21-900">
        {event.datasetId}
      </Link>
    ),
    line: event.line,
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    detectedFor: event.detectedFor ? numeral(event.detectedFor).format('0,0.0') : undefined,
    antenna: <Components.AntennaNameDisplay antennaArrayId={event.detectedByArrayId} antenna={event.detectedByAntenna} />,
  }))

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../analysis/summary" className="hover:text-wa21-100">
                Passage events
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>{organism?.tag}</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 space-y-10 shadow sm:px-6">
        <div>
          <div className="border-b border-gray-200 pb-5 mb-5">
            <h2 className="text-base font-semibold leading-6 text-gray-900">Individual</h2>
          </div>
          <Components.ReadonlyProperty
            condensed
            label="Species"
            name="species"
            currentValue={<Components.CategoryValueDisplay category={WellKnownCategories.OrganismSpecies} value={organism?.speciesId as any} />}
          />
          {organism?.label && <Components.ReadonlyProperty condensed label="Label" name="label" currentValue={organism.label} />}
          {organism?.description && <Components.ReadonlyProperty condensed label="Description/notes" name="description" currentValue={organism.description} />}
          {organism && <Components.ReadonlyProperty condensed label="Size (mm)" name="size" currentValue={Components.formatOrganismSize(organism) || '-'} />}
          {organism?.weight && <Components.ReadonlyProperty condensed label="Weight (g)" name="weight" currentValue={numeral(organism.weight).format('0,0')} />}
          {organism?.taggedOn && (
            <Components.ReadonlyProperty
              condensed
              label="Tagged on"
              name="tagged_on"
              currentValue={
                <span title={DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATETIME_SHORT)}>
                  {DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATE_SHORT)}
                </span>
              }
            />
          )}
          {organism?.capturedAtId && (
            <Components.ReadonlyProperty
              condensed
              label="Captured at"
              name="captured_at"
              currentValue={<Components.StudyNodeDisplay studyNodeId={organism.capturedAtId} />}
            />
          )}
          {organism?.releasedAtId && (
            <Components.ReadonlyProperty
              condensed
              label="Released at"
              name="released_at"
              currentValue={<Components.StudyNodeDisplay studyNodeId={organism.releasedAtId} />}
            />
          )}
        </div>
        <div>
          <div className="border-b border-gray-200 pb-5 mb-5">
            <h2 className="text-base font-semibold leading-6 text-gray-900">Aggregation</h2>
          </div>
          <Components.Table columns={resultsColumns} rows={resultsRows} condensed collapsed />
        </div>
        <div>
          <div className="border-b border-gray-200 pb-5 mb-5">
            <h2 className="text-base font-semibold leading-6 text-gray-900">Raw detection events</h2>
          </div>
          <Components.Table
            columns={eventColumns}
            rows={eventRows}
            condensed
            collapsed
            page={page}
            pages={listDetectionEvents.data?.detectionEvents.pages}
            total={listDetectionEvents.data?.detectionEvents.total}
            onPage={onPage}
          />
        </div>
      </main>
    </>
  )
}
