import { useState } from 'react'
import { useNavigate, useSearchParams, Outlet } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import { DateTime } from 'luxon'

import * as api from '~/api'
import * as Components from '~/components'
import { Study, StudyState } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export * from './study'

export function StudiesListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')
  const currentTab = searchParams.get('filter') || 'active'
  const deleted = currentTab === 'deleted'
  const state = deleted ? undefined : (currentTab.toUpperCase() as StudyState)

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsert() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const apollo = useApolloClient()
  const [cloneStudy] = useMutation(api.CLONE_STUDY)
  const [deleteStudy] = useMutation(api.DELETE_STUDY)
  const [busy, setBusy] = useState(false)

  async function onDelete(study: Partial<Study>) {
    const id = study.id

    if (!id) {
      return
    }

    const confirm =
      !study.deletedAt ||
      (await new Promise<boolean>((resolve) => {
        dispatch({
          action: AppAction.RequestConfirmation,
          confirmation: {
            title: 'Confirmation',
            message: (
              <span>
                Are you sure you want to delete <span className="font-medium">{study.name}</span>?
              </span>
            ),
            resolve,
          },
        })
      }))

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteStudy({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: [api.LIST_STUDIES] })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onClone(study: Partial<Study>) {
    const id = study.id

    if (!id) {
      return
    }
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudiesListing.onClone',
        title: 'Cloning study',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await cloneStudy({
        variables: {
          id,
          name: `${study.name} (copied on ${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)})`,
          includeOrganisms: true,
          includeEvents: true,
        },
      })
      try {
        await apollo.refetchQueries({ include: [api.LIST_STUDIES] })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to clone study.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudiesListing.onClone' } })
      setBusy(false)
    }
  }

  async function onRestore(study: Partial<Study>) {
    const id = study.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteStudy({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: [api.LIST_STUDIES] })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const listStudies = useQuery(api.LIST_STUDIES, { variables: { request: { page, limit: 25, deleted, state } } })
  const studyColumns: Components.TableColumn[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'organisms',
      label: 'Invididuals',
      className: 'w-32 text-right',
    },
    {
      name: 'events',
      label: 'Events',
      className: 'w-32 text-right',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-36 text-right',
      interactive: true,
    },
  ]
  const studyRows: Components.TableRow[] = (listStudies.data?.currentOrganization.studies.items || []).map((study) => ({
    id: study.id,
    name: study.name,
    organisms: study.validOrganismCount,
    events: study.validDetectionCount,
    actions: (
      <div className="space-x-2">
        {!study.deletedAt && (
          <button onClick={() => onClone(study)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            Clone
          </button>
        )}
        {study.deletedAt && (
          <button onClick={() => onRestore(study)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            Restore
          </button>
        )}
        {study.state !== StudyState.Completed && (
          <button onClick={() => onDelete(study)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            {!study.deletedAt ? 'Trash' : 'Delete'}
          </button>
        )}
      </div>
    ),
  }))

  const tabs: Components.HeaderTab[] = [
    {
      to: { search: '' },
      value: 'active',
      label: 'Active',
      // pill: '42',
    },
    // {
    //   to: { search: 'filter=completed' },
    //   value: 'completed',
    //   label: 'Completed',
    //   // pill: '5',
    // },
    {
      to: { search: 'filter=archived' },
      value: 'archived',
      label: 'Archived',
      // pill: '3',
    },
    {
      to: { search: 'filter=deleted' },
      value: 'deleted',
      label: 'Trash bin',
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Studies</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Components.HeaderPrimaryButton onClick={() => onInsert()} disabled={busy}>
              Add study
            </Components.HeaderPrimaryButton>
          </div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Components.Table
          columns={studyColumns}
          rows={studyRows}
          stickyHeader
          page={page}
          pages={listStudies.data?.currentOrganization.studies.pages}
          total={listStudies.data?.currentOrganization.studies.total}
          onClick={onUpdate}
          onPage={onPage}
          loading={listStudies.loading}
        />
      </main>

      <Outlet />
    </div>
  )
}
