import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { StudyNodeType, StudyState, WellKnownCategories } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyOrganismUpsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const datasetId = params.datasetId || '0'
  const organismId = params.organismId || 'new'
  const [searchParams] = useSearchParams()

  const apollo = useApolloClient()
  const [insertOrganism] = useMutation(api.INSERT_ORGANISM)
  const [updateOrganism] = useMutation(api.UPDATE_ORGANISM)
  const [deleteOrganism] = useMutation(api.DELETE_ORGANISM)
  const [busy, setBusy] = useState(false)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      const fields = {
        valid: Components.maybeBool(data, 'valid'),
        tagType: Components.maybeString(data, 'tagType'),
        taggedOn: Components.maybeDateTime(data, 'taggedOn'),
        taggedBy: Components.maybeId(data, 'taggedBy'),
        speciesId: Components.maybeId(data, 'speciesId'),
        length: Components.maybeDecimal(data, 'length'),
        width: Components.maybeDecimal(data, 'width'),
        height: Components.maybeDecimal(data, 'height'),
        weight: Components.maybeDecimal(data, 'weight'),
        age: Components.maybeInteger(data, 'age'),
        capturedOn: Components.maybeDateTime(data, 'capturedOn'),
        capturedAt: Components.maybeId(data, 'capturedAt'),
        capturedBy: Components.maybeId(data, 'capturedBy'),
        capturedLocation: Components.maybeLocation(data, 'capturedLocation'),
        capturedWithMethodId: Components.maybeId(data, 'capturedWithMethodId'),
        label: Components.maybeString(data, 'label'),
        description: Components.maybeString(data, 'description'),
      }

      if (organismId === 'new') {
        await insertOrganism({
          variables: {
            datasetId,
            tag: Components.requireData(Components.maybeString(data, 'tag')),
            fields,
          },
        })
      } else {
        await updateOrganism({
          variables: {
            id: organismId,
            fields,
          },
        })
      }
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: organismId === 'new' ? 'Failed to create individual.' : 'Failed to update individual.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onDelete() {
    const confirm = await new Promise<boolean>((resolve) => {
      dispatch({
        action: AppAction.RequestConfirmation,
        confirmation: {
          title: 'Confirmation',
          message: <span>Are you sure you want to delete this individual?</span>,
          resolve,
        },
      })
    })

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteOrganism({ variables: { id: organismId } })
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete individual.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getOrganism = useQuery(api.GET_ORGANISM, { variables: { id: organismId }, skip: organismId === 'new' })
  const organism = getOrganism.data?.organism

  const getOrganismDataset = useQuery(api.GET_ORGANISM_DATASET, { variables: { id: organism?.datasetId || '' }, skip: !organism?.datasetId })
  const organismDataset = getOrganismDataset.data?.organismDataset
  const displayUndefined = true // !organismDataset?.validated

  const listSpecies = useQuery(api.FIND_CATEGORY, { variables: { name: WellKnownCategories.OrganismSpecies } })
  const species = (listSpecies.data?.findCategory.categoryValues.items || []).map((value) => ({
    value: value.id,
    label: Components.localizedText(value.name, value.localizedName),
  }))

  const listCaptureMethods = useQuery(api.FIND_CATEGORY, { variables: { name: WellKnownCategories.OrganismCaptureWithMethod } })
  const captureMethods = (listCaptureMethods.data?.findCategory.categoryValues.items || []).map((value) => ({
    value: value.id,
    label: Components.localizedText(value.name, value.localizedName),
  }))

  const listStudyNodes = useQuery(api.LIST_STUDY_NODES, { variables: { studyId, request: { page: 0, limit: 0 } } })
  const pointOfInterests = (listStudyNodes.data?.studyNodes.items || [])
    .filter((value) => value.nodeType === StudyNodeType.PointOfInterest)
    .map((value) => ({
      value: value.id,
      label: value.name,
    }))

  const listUsers = useQuery(api.LIST_USERS, { variables: { request: { page: 0, limit: 0 } } })
  const users = (listUsers.data?.currentOrganization.users.items || []).map((user) => {
    return {
      value: user.id,
      label: user.fullname || user.email,
    }
  })

  return (
    <Components.SlideOver
      title={organismId !== 'new' ? 'Edit individual' : 'Add individual'}
      busy={busy}
      disabled={study?.state !== StudyState.Active || study?.deletedAt}
      primaryAction={organismId !== 'new' ? 'Update' : 'Create'}
      onPrimaryAction={onUpsert}
      secondaryAction={organismId !== 'new' && !organismDataset?.store ? 'Delete' : null}
      onSecondaryAction={onDelete}>
      {(organismId === 'new' || organism) && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.CheckboxField
              name="valid"
              label="Included"
              defaultChecked={organism ? organism.valid : true}
              description="Mark this individual as valid in the dataset."
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
            />
            <Components.TextField
              type="text"
              name="label"
              label="Label"
              defaultValue={organism?.label || undefined}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              maxLength={32}
            />
            <Components.TextAreaField
              name="description"
              label="Description/notes"
              defaultValue={organism?.description || undefined}
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
            />
            {(organism?.warning || []).length > 0 && (
              <Components.TextAreaField
                name="warning"
                label="Warnings"
                defaultValue={(organism?.warning || []).join('\n')}
                disabled={true}
                rows={(organism?.warning || []).length}
              />
            )}
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Individual description</h2>
            {(displayUndefined || organism?.speciesId) && (
              <Components.EnumField
                name="speciesId"
                label="Species"
                items={species}
                defaultValue={organism?.speciesId || ''}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.length) && (
              <Components.TextField
                type="number"
                name="length"
                label="Length (mm)"
                defaultValue={organism?.length?.toString()}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {displayUndefined && organism?.width && (
              <Components.TextField
                type="number"
                name="width"
                label="Width (mm)"
                defaultValue={organism?.width?.toString()}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {displayUndefined && organism?.height && (
              <Components.TextField
                type="number"
                name="height"
                label="Height (mm)"
                defaultValue={organism?.height?.toString()}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.weight) && (
              <Components.TextField
                type="number"
                name="weight"
                label="Weight (g)"
                defaultValue={organism?.weight?.toString()}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.age) && (
              <Components.TextField
                type="number"
                name="age"
                label="Age (y+)"
                defaultValue={organism?.age?.toString()}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Tagging information</h2>
            <Components.TextField
              required
              type="text"
              name="tag"
              label="Tag"
              defaultValue={organism?.tag}
              disabled={busy || !!organism || study?.state !== StudyState.Active || study?.deletedAt}
              placeholder="Tag ID/code"
            />
            {(displayUndefined || organism?.tagType) && (
              <Components.TextField
                type="text"
                name="tagType"
                label="Tag type"
                defaultValue={organism?.tagType || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.taggedOn) && (
              <Components.TextField
                type="datetime-local"
                name="taggedOn"
                label="Tagged on"
                defaultValue={organism?.taggedOn}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.taggedById) && (
              <Components.EnumField
                name="taggedBy"
                label="Tagged by"
                items={users}
                defaultValue={organism?.taggedById || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Capture information</h2>
            {(displayUndefined || organism?.capturedOn) && (
              <Components.TextField
                type="datetime-local"
                name="capturedOn"
                label="Captured on"
                defaultValue={organism?.capturedOn}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.capturedAtId) && (
              <Components.EnumField
                name="capturedAt"
                label="Captured at"
                items={pointOfInterests}
                defaultValue={organism?.capturedAtId || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.capturedById) && (
              <Components.EnumField
                name="capturedBy"
                label="Captured by"
                items={users}
                defaultValue={organism?.capturedById || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.capturedWithMethodId) && (
              <Components.EnumField
                name="capturedWithMethodId"
                label="Captured with method"
                items={captureMethods}
                defaultValue={organism?.capturedWithMethodId || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.capturedLocation) && (
              <Components.CoordinatesField
                name="capturedLocation"
                label="Captured at"
                defaultValue={organism?.capturedLocation ? JSON.stringify(organism.capturedLocation) : undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Release information</h2>
            {(displayUndefined || organism?.releasedOn) && (
              <Components.TextField
                type="datetime-local"
                name="releasedOn"
                label="Released on"
                defaultValue={organism?.releasedOn}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
            {(displayUndefined || organism?.releasedAtId) && (
              <Components.EnumField
                name="releasedAt"
                label="Released at"
                items={pointOfInterests}
                defaultValue={organism?.releasedAtId || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.releasedById) && (
              <Components.EnumField
                name="releasedBy"
                label="Released by"
                items={users}
                defaultValue={organism?.releasedById || undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
            {(displayUndefined || organism?.releasedLocation) && (
              <Components.CoordinatesField
                name="releasedLocation"
                label="Released at"
                defaultValue={organism?.releasedLocation ? JSON.stringify(organism.releasedLocation) : undefined}
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
              />
            )}
          </div>
        </div>
      )}
    </Components.SlideOver>
  )
}
