import { Fragment, useState, useRef, useEffect, useMemo, TargetedEvent } from 'react'

import { useQuery } from '@apollo/client'
import { Listbox, Transition, Switch } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon, PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'

import _ from 'lodash'
import classNames from 'classnames'
import { Decimal } from 'decimal.js'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import { PrimaryCoordinate, Coordinates, CsvDialect } from '~/graphql-codegen/graphql'

import { CoordinatesDisplay } from './coordinates'
import { renderCSVHref } from './helpers'

type PropertyAttributes<T> = {
  name: string
  label: React.ReactNode
  condensed?: boolean
  currentValue: T | null | undefined
  onUpdate?: (value?: T | null) => Promise<void>
}

const NOP_UPDATE = () => Promise.resolve()

export function ReadonlyProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  ...props
}: React.HTMLAttributes<HTMLElement> & PropertyAttributes<React.ReactNode>) {
  const isUndefined = value === undefined || value === null

  return (
    <div className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className={classNames('flex-grow', props.className as string)}>{isUndefined ? '-' : value}</div>
        <span className="ml-4 flex-shrink-0 space-x-2"></span>
      </dd>
    </div>
  )
}

type DownloadableListAttributes = {
  filename: string
  limit?: number
}

export function ReadonlyDownloadableListProperty({
  name,
  label,
  condensed,
  filename,
  limit = 10,
  currentValue: value = undefined,
  ...props
}: React.HTMLAttributes<HTMLElement> & PropertyAttributes<string[]> & DownloadableListAttributes) {
  const isUndefined = value === undefined || value === null || value.length === 0
  const currentUser = useQuery(api.CURRENT_USER)

  function onDownload(ev: TargetedEvent<HTMLAnchorElement>) {
    const href = renderCSVHref(currentUser.data?.currentUser.csvDialect || CsvDialect.Standard, [[name], ...(value || []).map((item) => [item])])

    if (href) {
      ev.currentTarget.href = href
    } else {
      ev.preventDefault()
    }
  }

  return (
    <div className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className={classNames('flex-grow', props.className as string)}>
          {isUndefined && '-'}
          {!isUndefined && (
            <>
              {value.slice(0, limit).join(', ')}
              {value.length > limit && <span className="ml-2 text-xs text-gray-500">({value.length - limit} more...)</span>}
              {value.length > limit && (
                <div className="mt-2">
                  <a
                    href="about:blank"
                    download={filename}
                    className="rounded bg-white px-2 py-1 font-medium text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={onDownload}>
                    Download as CSV
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2"></span>
      </dd>
    </div>
  )
}

type NumberAttributes = {
  numberFormat?: string
}

export function TextProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  numberFormat = '0',
  ...props
}: React.HTMLAttributes<HTMLInputElement> & PropertyAttributes<string> & NumberAttributes) {
  const isUndefined = value === undefined || value === null
  const href = (() => {
    switch (props.type) {
      case 'email':
        return `mailto:${value}`
      case 'phone':
        return `tel:${value}`
      case 'url':
        return value?.toString()
    }
  })()
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => inputRef.current?.focus(), [inputRef])

  async function onUpdateInternal(ev?: Event) {
    const value = inputRef.current?.value

    ev?.preventDefault()
    if (!inputRef.current?.checkValidity()) {
      return
    }
    switch (props.type) {
      case 'date':
        await onUpdate(value ? DateTime.fromFormat(value, 'yyyy-MM-dd').toSeconds().toString() : null)
        break
      case 'datetime-local':
        await onUpdate(value ? DateTime.fromISO(value).toSeconds().toString() : null)
        break
      case 'number':
        await onUpdate(value ? numeral(value).format(numberFormat) : null)
        break
      default:
        await onUpdate(value)
        break
    }
    setEdit(false)
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Escape') {
      setEdit(false)
    }
  }

  function dateValue(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value).toISODate() : null

    return isoValue || undefined
  }

  function dateText(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value) : null

    return isoValue ? isoValue.toLocaleString(DateTime.DATE_SHORT) : undefined
  }

  function dateTimeValue(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value).toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true }) : null

    return isoValue || undefined
  }

  function dateTimeText(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value) : null

    return isoValue ? isoValue.toLocaleString(DateTime.DATETIME_SHORT) : undefined
  }

  function numberText(value: any): string | undefined {
    return value ? numeral(value).format(numberFormat) : undefined
  }

  function renderValue(value: any): string | undefined {
    switch (props.type) {
      case 'date':
        return dateValue(value)
      case 'datetime-local':
        return dateTimeValue(value)
      case 'number':
        return numberText(value)
      default:
        return value
    }
  }

  function renderText(value: any): string | undefined {
    switch (props.type) {
      case 'date':
        return dateText(value)
      case 'datetime-local':
        return dateTimeText(value)
      case 'number':
        return numberText(value)
      default:
        return value
    }
  }

  return (
    <form ref={formRef} className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit &&
            (href ? (
              <a href={href} target="_blank" rel="noreferrer">
                {renderText(value) || '-'}
              </a>
            ) : (
              renderText(value) || '-'
            ))}
          {edit && (
            <input
              {...props}
              id={name}
              name={name}
              defaultValue={renderValue(value)}
              className="block w-full pb-1 border-b border-gray-300 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
              ref={inputRef}
              onKeyUp={onKey}
            />
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

export function TextAreaProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  children,
  ...props
}: React.HTMLAttributes<HTMLTextAreaElement> & PropertyAttributes<string>) {
  const isUndefined = value === undefined || value === null
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => inputRef.current?.focus(), [inputRef])

  async function onUpdateInternal(ev?: Event) {
    const value = inputRef.current?.value

    ev?.preventDefault()
    if (!inputRef.current?.checkValidity()) {
      return
    }
    await onUpdate(value)
    setEdit(false)
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Escape') {
      setEdit(false)
    } else if (ev.code === 'Enter' && ev.ctrlKey) {
      onUpdateInternal()
    }
  }

  return (
    <form ref={formRef} className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit && <span className="whitespace-pre-line">{value || '-'}</span>}
          {edit && (
            <textarea
              {...props}
              id={name}
              name={name}
              defaultValue={value || undefined}
              className="block w-full border-0 border-b border-gray-300 p-0 pb-1 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
              ref={inputRef}
              onKeyUp={onKey}>
              {children}
            </textarea>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

export function ToggleProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  children,
  ...props
}: React.HTMLAttributes<HTMLInputElement> & PropertyAttributes<boolean>) {
  async function onUpdateInternal(value: boolean) {
    await onUpdate(value)
  }

  return (
    <div className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <Switch
          checked={!!value}
          disabled={props.disabled}
          onChange={onUpdateInternal}
          className={classNames(
            value ? 'bg-wa21-500' : 'bg-gray-200',
            'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2'
          )}>
          <span className="sr-only">{label}</span>
          <span
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white border border-gray-200 shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </dd>
    </div>
  )
}

export type EnumPropertyItem = {
  value: string
  label: React.ReactNode
}

type EnumPropertyAttributes = {
  readOnly?: boolean
  items: EnumPropertyItem[]
}

export function EnumProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  items,
  ...props
}: React.HTMLAttributes<HTMLSelectElement> & PropertyAttributes<string> & EnumPropertyAttributes) {
  const isUndefined = value === undefined || value === null
  const currentLabel = _.find(items, (item) => item.value === value)?.label
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLSelectElement>(null)

  useEffect(() => inputRef.current?.focus(), [inputRef])

  async function onUpdateInternal(ev?: Event) {
    const value = inputRef.current?.value

    ev?.preventDefault()
    if (!inputRef.current?.checkValidity()) {
      return
    }
    await onUpdate(value)
    setEdit(false)
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Escape') {
      setEdit(false)
    } else if (ev.code === 'Enter' && ev.ctrlKey) {
      onUpdateInternal()
    }
  }

  return (
    <form ref={formRef} className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit && <span className="whitespace-pre-line">{currentLabel || '-'}</span>}
          {edit && (
            <select
              {...props}
              id={name}
              name={name}
              defaultValue={value || undefined}
              className="block w-full bg-transparent border-0 border-b border-gray-300 p-0 pb-1 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
              ref={inputRef}
              onKeyUp={onKey}>
              {items.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

export function MultiEnumProperty({
  name,
  label,
  condensed,
  currentValue: value = [],
  onUpdate = NOP_UPDATE,
  items,
  ...props
}: React.HTMLAttributes<HTMLSelectElement> & PropertyAttributes<string[]> & EnumPropertyAttributes) {
  const isUndefined = value === undefined || value === null
  const [currentValue, setCurrentValue] = useState<string[]>(items.filter((item) => (value || []).indexOf(item.value) >= 0).map((item) => item.value))
  const currentSelection = useMemo(() => items.filter((item) => currentValue.indexOf(item.value) >= 0), [currentValue])
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const formRef = useRef<HTMLFormElement>(null)

  async function onUpdateInternal(ev?: Event) {
    ev?.preventDefault()
    await onUpdate(currentValue)
    setEdit(false)
  }

  return (
    <form ref={formRef} className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit && (
            <span className="whitespace-pre-line">
              {currentSelection.length > 0
                ? currentSelection.map((item, i) => {
                    if (i === 0) {
                      return <span key={i}>{item.label || item.value}</span>
                    }
                    return <span key={i}>, {item.label || item.value}</span>
                  })
                : '-'}
            </span>
          )}
          {edit && (
            <Listbox defaultValue={value} disabled={props.disabled as boolean} onChange={setCurrentValue} multiple>
              {({ open }) => (
                <>
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default border-b bg-white pr-10 text-left text-gray-900 focus:outline-none focus:ring-0 focus:border-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100">
                      <span className="block truncate">
                        {currentSelection.length > 0
                          ? currentSelection.map((item, i) => {
                              if (i === 0) {
                                return <span key={i}>{item.label || item.value}</span>
                              }
                              return <span key={i}>, {item.label || item.value}</span>
                            })
                          : '-'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <ChevronDownIcon className="h-4 w-4 text-gray-800" aria-hidden="true" />
                      </span>
                    </Listbox.Button>

                    <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {items.map((item, i) => (
                          <Listbox.Option
                            key={i}
                            className={({ active }) =>
                              classNames(active ? 'bg-wa21-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4')
                            }
                            value={item.value}>
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{item.label || item.value}</span>

                                {selected ? (
                                  <span className={classNames(active ? 'text-white' : 'text-wa21-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5')}>
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

type TagsPropertyAttributes = {
  sorted?: boolean
  allowDuplicates?: boolean
  readOnly?: boolean
}

export function TagsProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  sorted,
  allowDuplicates,
  ...props
}: React.HTMLAttributes<HTMLInputElement> & PropertyAttributes<string[]> & TagsPropertyAttributes) {
  const isUndefined = value === undefined || value === null
  const [currentValue, setCurrentValue] = useState(value || [])
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const formRef = useRef<HTMLFormElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => inputRef.current?.focus(), [inputRef])

  async function onUpdateInternal(ev?: Event) {
    const value = (inputRef.current?.value || '').trim()

    ev?.preventDefault()
    if (value.length > 0) {
      let values = _.concat(currentValue, [value])

      if (!allowDuplicates) {
        values = _.uniq(values)
      }
      if (sorted) {
        values.sort()
      }
      setCurrentValue(values)
      if (inputRef.current) {
        inputRef.current.value = ''
      }
      return
    }
    if (!inputRef.current?.checkValidity()) {
      return
    }
    await onUpdate(currentValue)
    setEdit(false)
  }

  function onRemove(value: string) {
    setCurrentValue(currentValue.filter((other) => other !== value))
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Escape') {
      setEdit(false)
    } else if (ev.code === 'Enter' && ev.ctrlKey) {
      onUpdateInternal()
    }
  }

  return (
    <form ref={formRef} className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit && (
            <div className="-mb-2">
              {currentValue.length === 0 && '-'}
              {currentValue.map((value, i) => (
                <span
                  key={i}
                  className="inline-flex items-center mr-2 mb-2 gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                  {value}
                </span>
              ))}
            </div>
          )}
          {edit && (
            <label
              htmlFor={name}
              className="flex items-start w-full pb-1 border-b border-gray-300 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100">
              <div className="flex flex-initial flex-wrap -mb-2">
                {currentValue.map((value, i) => (
                  <span
                    key={i}
                    className="inline-flex whitespace-nowrap items-center gap-x-0.5 rounded-md bg-gray-50 mr-2 mb-2 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    {value}
                    <button type="button" className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20" onClick={() => onRemove(value)}>
                      <span className="sr-only">Remove</span>
                      <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75">
                        <path d="M4 4l6 6m0-6l-6 6" />
                      </svg>
                      <span className="absolute -inset-1" />
                    </button>
                  </span>
                ))}
              </div>
              <input
                {...props}
                id={name}
                name={name}
                className="flex flex-auto basis-0 grow min-w-[25%] placeholder:text-gray-400 outline-none focus:ring-0"
                ref={inputRef}
                onKeyUp={onKey}
              />
            </label>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

export function CoordinatesProperty({
  name,
  label,
  condensed,
  currentValue: value = undefined,
  onUpdate = NOP_UPDATE,
  ...props
}: React.HTMLAttributes<HTMLInputElement> & PropertyAttributes<Coordinates>) {
  const { primary, lv03, lv95, wgs84 } = value || {}
  const isUndefined = !primary
  const [edit, setEdit] = useState(isUndefined && !props.disabled)
  const [mode, setMode] = useState<PrimaryCoordinate>(primary || PrimaryCoordinate.Lv95)
  const [a, setA] = useState<string>('')
  const [b, setB] = useState<string>('')
  const [c, setC] = useState<string>('')
  const selectRef = useRef<HTMLSelectElement>(null)

  useEffect(() => selectRef.current?.focus(), [selectRef])
  useEffect(() => {
    function fixed(value: string | null | undefined, dp: number = 0): string {
      try {
        return value ? new Decimal(value).toDP(dp).toString() : ''
      } catch (e) {
        return ''
      }
    }

    switch (mode) {
      case PrimaryCoordinate.None:
        setA('')
        setB('')
        setC('')
        break
      case PrimaryCoordinate.Lv03:
        setA(fixed(lv03?.x))
        setB(fixed(lv03?.y))
        setC(fixed(lv03?.altitude, 2))
        break
      case PrimaryCoordinate.Lv95:
        setA(fixed(lv95?.east))
        setB(fixed(lv95?.north))
        setC(fixed(lv95?.altitude, 2))
        break
      case PrimaryCoordinate.Wgs84:
        setA(fixed(wgs84?.latitude, 6))
        setB(fixed(wgs84?.longitude, 6))
        setC(fixed(wgs84?.altitude, 2))
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  async function onUpdateInternal(ev: Event) {
    let altitude = numeral(c).value()

    ev.preventDefault()
    switch (mode) {
      case PrimaryCoordinate.Lv03:
        let x = numeral(a).value()
        let y = numeral(b).value()

        if (!x || !y) {
          break
        }
        await onUpdate({
          primary: mode,
          lv03: {
            x: x.toString(),
            y: y.toString(),
            altitude: altitude?.toString(),
          },
        })
        setEdit(false)
        return
      case PrimaryCoordinate.Lv95:
        let east = numeral(a).value()
        let north = numeral(b).value()

        if (!north || !east) {
          break
        }
        await onUpdate({
          primary: mode,
          lv95: {
            east: east.toString(),
            north: north.toString(),
            altitude: altitude?.toString(),
          },
        })
        setEdit(false)
        return
      case PrimaryCoordinate.Wgs84:
        let latitude = numeral(a).value()
        let longitude = numeral(b).value()

        if (!latitude || !longitude) {
          break
        }
        await onUpdate({
          primary: mode,
          wgs84: {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            altitude: altitude?.toString(),
          },
        })
        setEdit(false)
        return
    }
    await onUpdate({
      primary: PrimaryCoordinate.None,
    })
    setEdit(false)
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Escape') {
      setEdit(false)
    }
  }

  function onChange(field: 'a' | 'b' | 'c', value: string) {
    value = value.replace(',', '.').replace("'", '')
    switch (field) {
      case 'a':
        setA(value)
        break
      case 'b':
        setB(value)
        break
      case 'c':
        setC(value)
        break
    }
  }

  return (
    <form className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')} onSubmit={onUpdateInternal}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {!edit && <CoordinatesDisplay value={value as any} />}
          {edit && (
            <div className="grid grid-cols-4 gap-3">
              <select
                ref={selectRef}
                value={mode}
                disabled={props.disabled}
                className="block w-full border-b border-gray-300 text-gray-900 bg-white placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
                onKeyUp={onKey}
                onChange={(ev) => setMode(ev.currentTarget.value as PrimaryCoordinate)}>
                <option value={PrimaryCoordinate.Lv95}>LV95</option>
                <option value={PrimaryCoordinate.Lv03}>LV03</option>
                <option value={PrimaryCoordinate.Wgs84}>WGS84</option>
                <option value={PrimaryCoordinate.None}>None</option>
              </select>
              <div className="relative">
                <input
                  {...props}
                  value={a}
                  disabled={mode === PrimaryCoordinate.None || props.disabled}
                  className="block w-full pb-1 pr-12 border-b border-gray-300 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
                  onKeyUp={onKey}
                  onChange={(ev) => onChange('a', ev.currentTarget.value)}
                />
                <span className="pointer-events-none absolute inset-y-0 right-0 pb-1 flex items-center pr-3 text-gray-400">
                  {mode === PrimaryCoordinate.Lv03 && 'X'}
                  {mode === PrimaryCoordinate.Lv95 && 'E'}
                  {mode === PrimaryCoordinate.Wgs84 && 'LAT'}
                </span>
              </div>
              <div className="relative">
                <input
                  {...props}
                  value={b}
                  disabled={mode === PrimaryCoordinate.None || props.disabled}
                  className="block w-full pb-1 pr-12 border-b border-gray-300 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
                  onKeyUp={onKey}
                  onChange={(ev) => onChange('b', ev.currentTarget.value)}
                />
                <span className="pointer-events-none absolute inset-y-0 right-0 pb-1 flex items-center pr-3 text-gray-400">
                  {mode === PrimaryCoordinate.Lv03 && 'Y'}
                  {mode === PrimaryCoordinate.Lv95 && 'N'}
                  {mode === PrimaryCoordinate.Wgs84 && 'LON'}
                </span>
              </div>
              <div className="relative">
                <input
                  {...props}
                  value={c}
                  disabled={mode === PrimaryCoordinate.None || props.disabled}
                  className="block w-full pb-1 pr-12 border-b border-gray-300 text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
                  onKeyUp={onKey}
                  onChange={(ev) => onChange('c', ev.currentTarget.value)}
                />
                <span className="pointer-events-none absolute inset-y-0 right-0 pb-1 flex items-center pr-3 text-gray-400">H</span>
              </div>
            </div>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2">
          {!edit && !props.disabled && !props.readOnly && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-wa21-600 hover:text-wa21-500 outline-none focus:ring-0"
              onClick={() => setEdit(true)}>
              <PencilSquareIcon className="w-5 h-4" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && !isUndefined && (
            <button
              type="button"
              className="rounded-md bg-white font-medium text-gray-400 hover:text-wa21-danger-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2"
              onClick={() => setEdit(false)}>
              <XMarkIcon className="w-5 h-5" />
            </button>
          )}
          {edit && !props.disabled && !props.readOnly && (
            <button
              type="submit"
              className="rounded-md bg-white font-medium text-wa21-500 hover:text-wa21-500 focus:outline-none focus:ring-2 focus:ring-wa21-500 focus:ring-offset-2">
              <CheckIcon className="w-5 h-5" />
            </button>
          )}
        </span>
      </dd>
    </form>
  )
}

type MapPropertyAttributes = {
  zoom?: number
}

export function MapProperty({
  name,
  label,
  condensed,
  zoom = 8,
  currentValue: value = undefined,
  ...props
}: React.HTMLAttributes<HTMLIFrameElement> & PropertyAttributes<Coordinates> & MapPropertyAttributes) {
  const { primary, lv03, lv95, wgs84 } = value || {}
  const isUndefined = !primary
  const params = new URLSearchParams({
    lang: 'en',
    topic: 'ech',
    zoom: Math.max(0, Math.min(13, zoom)).toFixed(0),
    bgLayer: 'ch.swisstopo.pixelkarte-farbe',
    crosshair: 'marker',
    // layers: 'KML||https:%2F%2Fpublic.geo.admin.ch%2Fapi%2Fkml%2Ffiles%2FRzSE1HyQTKm2M7J5fBScEg',
  })

  if (lv95) {
    params.set('E', lv95.east)
    params.set('N', lv95.north)
  } else if (lv03) {
    params.set('X', lv03.x)
    params.set('Y', lv03.y)
  } else if (wgs84) {
    // TODO: google maps
    return null
  }

  return (
    <div className={classNames('sm:grid sm:grid-cols-3 sm:gap-4', condensed ? 'py-1 sm:py-2' : 'py-4  sm:py-5')}>
      <dt className="text-sm font-medium text-gray-500">
        <label htmlFor={name}>{label}</label>
      </dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="flex-grow">
          {(isUndefined || primary === PrimaryCoordinate.None) && '-'}
          {lv95 && (
            <iframe
              {...props}
              className="w-full h-96 border border-wa21-400 shadow-sm"
              title={`Map`}
              src={`https://map.geo.admin.ch/embed.html?${params}`}
              allow="geolocation"></iframe>
          )}
        </div>
        <span className="ml-4 flex-shrink-0 space-x-2"></span>
      </dd>
    </div>
  )
}
