import { render } from 'preact'
import { RouterProvider } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-luxon'

import numeral from 'numeral'

import apolloClient from './apollo'
import router from './router'
import { AuthController, AuthProvider } from './session'

import './index.css'
import 'reactflow/dist/style.css'
import 'katex/dist/katex.min.css'
import 'jsoneditor-react18/es/editor.min.css'

Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  Title,
  Tooltip
)

numeral.register('locale', 'ch', {
  delimiters: {
    thousands: "'",
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'st' : 'th'
  },
  currency: {
    symbol: 'CHF',
  },
})
numeral.locale('ch')

const Main = () => (
  <AuthProvider value={AuthController.instance}>
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </AuthProvider>
)

render(<Main />, document.getElementById('app')!)
