import { Fragment, useRef, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Dialog, Transition } from '@headlessui/react'

import { Spinner } from './spin'

export type DialogProps = {
  title: React.ReactNode
  icon: React.ReactNode
  children: React.ReactNode
  busy?: boolean
  showBusy?: boolean
  manual?: boolean
  primaryAction?: React.ReactNode
  primaryActionDisabled?: boolean
  onPrimaryAction?: () => void
  secondaryAction?: React.ReactNode
  secondaryActionDisabled?: boolean
  onSecondaryAction?: () => void
}

export function Modal({
  title,
  icon,
  children,
  busy,
  showBusy,
  manual,
  primaryAction,
  primaryActionDisabled,
  onPrimaryAction,
  secondaryAction,
  secondaryActionDisabled,
  onSecondaryAction,
}: Partial<DialogProps>) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)
  const primaryActionRef = useRef(null)
  const secondaryActionRef = useRef(null)

  useEffect(() => {
    if (show) {
      return
    }
    const task = setTimeout(() => setShow(true), 10)
    return () => clearTimeout(task)
  }, [show])

  useEffect(() => {
    if (!hide || manual) {
      return
    }
    const task = setTimeout(() => navigate({ pathname: '..', search: searchParams.toString() }), 250)
    return () => clearTimeout(task)
  }, [navigate, searchParams, hide, manual])

  function onClose() {
    if (!busy) {
      setHide(true)
    }
  }

  return (
    <Transition show={show && !hide} as={Fragment}>
      <Dialog initialFocus={secondaryActionRef || primaryActionRef} onClose={manual ? onSecondaryAction || onClose : onClose}>
        <div className="relative z-30">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="w-full sm:flex sm:items-start">
                      {icon && (
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          {icon}
                        </div>
                      )}
                      <div className="flex-1 mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">{title}</Dialog.Title>
                        <div className="mt-2">{children}</div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:items-center sm:px-6">
                    {primaryAction && (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-wa21-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-wa21-500 sm:ml-3 sm:w-auto disabled:bg-wa21-200"
                        disabled={primaryActionDisabled || busy}
                        onClick={onPrimaryAction}
                        ref={primaryActionRef}>
                        {primaryAction}
                      </button>
                    )}
                    {secondaryAction && (
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto disabled:bg-gray-50 disabled:text-gray-300"
                        disabled={secondaryActionDisabled || busy}
                        onClick={onSecondaryAction || onClose}
                        ref={secondaryActionRef}>
                        {secondaryAction}
                      </button>
                    )}
                    {busy && showBusy && (
                      <div className="flex-1 mt-3 text-center sm:mt-0 sm:text-left">
                        <Spinner className="inline w-8 h-8 text-gray-200" />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
