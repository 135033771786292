import Decimal from 'decimal.js'
import { DateTime, Duration } from 'luxon'
import numeral from 'numeral'
import Papa from 'papaparse'
import { Base64 } from 'js-base64'
import _ from 'lodash'

import { CoordinatesInput, CsvDialect, DataFormat, Organism } from '~/graphql-codegen/graphql'

export function requireData<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) {
    throw new Error('missing required data')
  }
  return value
}

export function maybeString(data: FormData, key: string): string | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? text : null
}

export function maybeId(data: FormData, key: string): string | null | undefined {
  return maybeString(data, key)
}

export function maybeEnum<T>(data: FormData, key: string): T | null | undefined {
  return maybeString(data, key) as T
}

export function maybeBool(data: FormData, key: string): boolean | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? text === 'true' : null
}

export function maybeInteger(data: FormData, key: string): number | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? new Decimal(text).round().toNumber() : null
}

export function maybeDecimal(data: FormData, key: string): string | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? new Decimal(text).toString() : null
}

export function maybeDateTime(data: FormData, key: string): number | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? DateTime.fromISO(text).toSeconds() : null
}

export function maybeLocation(data: FormData, key: string): CoordinatesInput | null | undefined {
  let value = data.get(key)
  let text = value?.toString().trim() || ''

  if (value === null || value === undefined) {
    return value
  }
  return text.length > 0 ? JSON.parse(text) : null
}

export function gradeColor(value: number): string {
  if (value >= 0.8) {
    return '#7395d3'
  } else if (value >= 0.6) {
    return '#94c275'
  } else if (value >= 0.4) {
    return '#FFDF40'
  } else if (value >= 0.2) {
    return '#ff9633'
  }
  return '#FF6F7F'
}

export function gradeCategory(value: number): string {
  if (value >= 0.8) {
    return 'Very good'
  } else if (value >= 0.6) {
    return 'Good'
  } else if (value >= 0.4) {
    return 'Moderate'
  } else if (value >= 0.2) {
    return 'Unsatisfactory'
  }
  return 'Bad'
}

export function formatData(
  format: DataFormat,
  value: string | undefined | null,
  precision: number | null | undefined,
  placeholder?: React.ReactNode
): React.ReactNode {
  let precisionSuffix = _.range(0, precision || 0)
    .map(() => '0')
    .join('')

  if (value === undefined || value === null) {
    return placeholder
  }
  switch (format) {
    case DataFormat.Integer:
      return numeral(value).format('0,0')
    case DataFormat.Decimal:
      return numeral(value).format(`0,0.${precisionSuffix}`)
    case DataFormat.Percentage:
      return numeral(value).format(`0.${precisionSuffix}%`)
    case DataFormat.Duration:
      return formatDelayMillis(value)
    case DataFormat.Grading:
      return <div className="text-gray-500">{numeral(value).format(`0,0.${precisionSuffix}`)}</div>
    case DataFormat.GradingWithColor:
      let grade = parseFloat(value)
      let styles: React.CSSProperties = {
        backgroundColor: gradeColor(grade),
      }

      return (
        <div className="relative flex justify-center py-1 px-2" title={gradeCategory(grade)}>
          <div className="absolute inset-0 opacity-40 rounded" style={styles}>
            {' '}
          </div>
          <div className="flex text-center">{numeral(value).format(`0,0.${precisionSuffix}`)}</div>
        </div>
      )
  }
  return value
}

export function formatOrganismSize(organism: Partial<Organism> | null | undefined): React.ReactNode {
  let items = []

  if (organism?.length) {
    items.push(numeral(organism.length).format('0,0'))
  }
  if (organism?.width) {
    items.push(numeral(organism.width).format('0,0'))
  }
  if (organism?.height) {
    items.push(numeral(organism.height).format('0,0'))
  }
  return items.length > 0 ? items.join(' x ') : null
}

export function formatDelayMillis(value: string): string {
  let duration = Duration.fromMillis(new Decimal(value).mul(1000).toDP(0).toNumber()).shiftTo('days', 'hours', 'minutes', 'seconds')

  if (duration.days >= 1) {
    return duration.toFormat("d'd' hh:mm:ss'")
  } else if (duration.hours >= 1) {
    return duration.toFormat('hh:mm:ss')
  }
  return duration.toFormat('mm:ss')
}

export function stripFilenameExtension(filename: string): string {
  let i = filename.lastIndexOf('.')

  return i > 0 ? filename.substring(0, i) : filename
}

export function renderCSV(dialect: CsvDialect, rows: any[]): string {
  switch (dialect) {
    case CsvDialect.Standard:
      return Papa.unparse(rows, { delimiter: ',', escapeChar: '"', quoteChar: '"', newline: '\n' })
    case CsvDialect.Excel:
      return Papa.unparse(rows, { delimiter: ';', escapeChar: '"', quoteChar: '"', newline: '\n' })
    default:
      throw new Error(`unsupported dialect: ${dialect}`)
  }
}

export function renderCSVHref(dialect: CsvDialect, rows: any[]): string {
  return 'data:text/csv;base64,' + Base64.encode(renderCSV(dialect, rows))
}

export const TEXT_CLIPBOARD_AVAILABLE = !!navigator.clipboard && !!navigator.clipboard.writeText
export const BINARY_CLIPBOARD_AVAILABLE = !!navigator.clipboard && !!navigator.clipboard.write && ClipboardItem

export async function textToClipboard(text: string) {
  await navigator.clipboard.writeText(text)
}

export async function dataUrlToClipboard(dataUrl: string) {
  const blob = await (await fetch(dataUrl)).blob()

  await navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })])
}

export function downloadDataUrl(dataUrl: string, filename: string) {
  const anchor = document.createElement('a')

  anchor.href = dataUrl
  anchor.download = filename
  anchor.click()
}
