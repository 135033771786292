import { TargetedEvent, useState } from 'react'

import { useQuery, useMutation } from '@apollo/client'

import * as api from '~/api'
import { AppAction, useAppDispatch } from '~/state'

export function ChangePassword() {
  const dispatch = useAppDispatch()
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [busy, setBusy] = useState(false)

  const currentUser = useQuery(api.CURRENT_USER)
  const [updateUserPassword] = useMutation(api.UPDATE_USER_PASSWORD)

  const user = currentUser.data?.currentUser

  async function onSubmit(ev: TargetedEvent<HTMLFormElement>) {
    ev.preventDefault()

    if (!user || !newPassword || newPassword.trim().length === 0) {
      return
    }

    setBusy(true)
    setPassword('')
    setNewPassword('')
    try {
      await updateUserPassword({ variables: { id: user.id, oldPassword: password, newPassword: newPassword } })

      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'info', title: 'Action successful', message: 'Your password was changed.', timeout: 5 },
      })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: 'Failed to update password.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Change password</h1>
          </div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <form className="space-y-4" onSubmit={onSubmit}>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label htmlFor="email-address" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="off"
                  readOnly
                  tabIndex={-1}
                  className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                  placeholder="Enter your current password"
                  value={user?.email || ''}
                />
              </div>
            </div>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Current password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  tabIndex={0}
                  className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                  placeholder="Enter your current password"
                  value={password}
                  onChange={(ev) => setPassword(ev.currentTarget.value)}
                  disabled={busy}
                />
              </div>
            </div>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-4">
              <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-gray-900">
                New password
              </label>
              <div className="mt-2">
                <input
                  id="new-password"
                  name="new-password"
                  type="password"
                  autoComplete="new-password"
                  required
                  minLength={6}
                  tabIndex={1}
                  className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                  placeholder="Choose a new password"
                  value={newPassword}
                  onChange={(ev) => setNewPassword(ev.currentTarget.value)}
                  disabled={busy}
                />
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-wa21-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-wa21-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-600 disabled:bg-gray-400"
              disabled={busy}>
              Change password
            </button>
          </div>
        </form>
      </main>
    </div>
  )
}
