import { useMemo } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import * as api from '~/api'
import * as Components from '~/components'
import { OrganismChartDataSource, OrganismTableDataSource } from '~/graphql-codegen/graphql'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    validity: true,
    weight: true,
  },
  event: {},
}

export function StudyOrganismDatasetsStatistics() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('panel') || 'tagging'
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getLengthDistribution = useQuery(api.ORGANISM_CHART, {
    variables: {
      studyId,
      datasource: OrganismChartDataSource.LengthDistribution,
      query,
    },
    skip: currentTab !== 'length',
  })

  const getLengthWeightRelationship = useQuery(api.ORGANISM_CHART, {
    variables: {
      studyId,
      datasource: OrganismChartDataSource.LengthWeightRelationship,
      query,
    },
    skip: currentTab !== 'length-weight',
  })

  const getTaggingHistory = useQuery(api.ORGANISM_CHART, {
    variables: {
      studyId,
      datasource: OrganismChartDataSource.TaggingHistory,
      query,
    },
    skip: currentTab !== 'tagging',
  })

  const getSpecies = useQuery(api.ORGANISM_TABLE, {
    variables: {
      studyId,
      datasource: OrganismTableDataSource.Species,
      query,
    },
    skip: currentTab !== 'tagging',
  })
  const getCapturedAt = useQuery(api.ORGANISM_TABLE, {
    variables: {
      studyId,
      datasource: OrganismTableDataSource.CaptureLocation,
      query,
    },
    skip: currentTab !== 'tagging',
  })
  const getReleasedAt = useQuery(api.ORGANISM_TABLE, {
    variables: {
      studyId,
      datasource: OrganismTableDataSource.ReleaseLocation,
      query,
    },
    skip: currentTab !== 'tagging',
  })
  const aggregatedCountTable = useMemo(
    () => Components.mergeDataTables([getSpecies.data?.organismTable, getCapturedAt.data?.organismTable, getReleasedAt.data?.organismTable]),
    [getSpecies, getCapturedAt, getReleasedAt]
  )

  // const getLabels = useQuery(api.ORGANISM_TABLE, {
  //   variables: {
  //     studyId,
  //     datasource: OrganismTableDataSource.Label,
  //     query,
  //   },
  //   skip: currentTab !== 'tagging',
  // })

  const queryParams = Components.encodeStudyFilters(filters)

  function withSearchParams(name: string, value: string): string {
    const newSearchParams = new URLSearchParams(queryParams)

    newSearchParams.set(name, value)
    return newSearchParams.toString()
  }

  const tabs: Components.HeaderTab[] = [
    {
      to: { search: withSearchParams('panel', '') },
      value: 'tagging',
      label: 'Tagging',
    },
    {
      to: { search: withSearchParams('panel', 'length') },
      value: 'length',
      label: 'Length distribution',
    },
    {
      to: { search: withSearchParams('panel', 'length-weight') },
      value: 'length-weight',
      label: 'Length/weight relationship',
    },
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../datasets/organisms" className="hover:text-wa21-100">
                Individuals
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Demographic analysis</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white py-6 shadow">
        <div className="border-b border-gray-200 px-5 sm:px-6 pb-5 mb-10">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} />
        </div>
        <div className="px-5 sm:px-6 space-y-10">
          {currentTab === 'tagging' && (
            <>
              <div>
                <div className="border-b border-gray-200 pb-5 mb-5">
                  <h2 className="text-base font-semibold leading-6 text-gray-900">Population by capture/release locations</h2>
                </div>
                <div className="px-5">
                  <Components.DataTable table={aggregatedCountTable} condensed collapsed filename={`population-${study?.name}`} />
                </div>
              </div>
              <div>
                <div className="border-b border-gray-200 pb-5 mb-5">
                  <h2 className="text-base font-semibold leading-6 text-gray-900">Tagging activity (count/day)</h2>
                </div>
                <Components.BarChart chart={getTaggingHistory.data?.organismChart} filename={`tagging-${study?.name}`} />
              </div>
            </>
          )}
          {currentTab === 'length' && (
            <>
              <div>
                <div className="border-b border-gray-200 pb-5 mb-5">
                  <h2 className="text-base font-semibold leading-6 text-gray-900">Length distribution by species (mm)</h2>
                </div>
                <Components.BarChart chart={getLengthDistribution.data?.organismChart} filename={`length-${study?.name}`} />
              </div>
              <div>
                <div className="px-5">
                  <Components.DataTableFromChart chart={getLengthDistribution.data?.organismChart} filename={`length-${study?.name}`} />
                </div>
              </div>
            </>
          )}
          {currentTab === 'length-weight' && (
            <div>
              <div className="border-b border-gray-200 pb-5 mb-5">
                <h2 className="text-base font-semibold leading-6 text-gray-900">Length/weight relationship (mm/g)</h2>
              </div>
              <Components.ScatterChart chart={getLengthWeightRelationship.data?.organismChart} filename={`length-weight-${study?.name}`} />
            </div>
          )}
        </div>
      </main>
    </>
  )
}
