import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useApolloClient, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { AppAction, useAppDispatch } from '~/state'

export function StudyOrganismDatasetInsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'

  const apollo = useApolloClient()
  const [insertDataset] = useMutation(api.INSERT_ORGANISM_DATASET)
  const [busy, setBusy] = useState(false)

  async function onInsert(data: FormData) {
    setBusy(true)
    try {
      const response = await insertDataset({
        variables: {
          studyId,
          name: data.get('name')?.toString() || '',
          fields: { validated: true },
        },
      })

      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: `../${response.data?.insertOrganismDataset.id}` })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to create dataset.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  return (
    <Components.SlideOver title="Add dataset" busy={busy} primaryAction="Create" onPrimaryAction={onInsert}>
      <div className="divide-y divide-gray-200 px-4 sm:px-6">
        <div className="space-y-6 pb-5 pt-6">
          <Components.TextField required type="text" name="name" label="Name" placeholder="Dataset name" disabled={busy} />
        </div>
      </div>
    </Components.SlideOver>
  )
}
