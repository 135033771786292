import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure, Transition } from '@headlessui/react'
import { BlockMath } from 'react-katex'

import classNames from 'classnames'

import * as api from '~/api'
import * as Components from '~/components'
import { EvaluationIndicatorsDataSource, EvaluationTableDataSource } from '~/graphql-codegen/graphql'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
  },
}

export function StudyEvaluationPassability() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const currentTab = searchParams.get('panel') || 'ee'
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)
  const queryParams = Components.encodeStudyFilters(filters)

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getPassabilityEE = useQuery(api.EVALUATION_TABLE, {
    variables: {
      studyId,
      datasource: EvaluationTableDataSource.PassabilityEe,
      query,
    },
    skip: currentTab !== 'ee',
  })
  const getIndicatorsEE = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.ClassPassabilityEe,
      query,
    },
    skip: currentTab !== 'ee',
  })

  const getPassabilityPE = useQuery(api.EVALUATION_TABLE, {
    variables: {
      studyId,
      datasource: EvaluationTableDataSource.PassabilityPe,
      query,
    },
    skip: currentTab !== 'pe',
  })
  const getIndicatorsPE = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.ClassPassabilityPe,
      query,
    },
    skip: currentTab !== 'pe',
  })

  const getPassabilityPD = useQuery(api.EVALUATION_TABLE, {
    variables: {
      studyId,
      datasource: EvaluationTableDataSource.PassabilityPd,
      query,
    },
    skip: currentTab !== 'pd',
  })
  const getIndicatorsPD = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.ClassPassabilityPd,
      query,
    },
    skip: currentTab !== 'pd',
  })

  const withParam = (key: string, value?: string): string => {
    const result = new URLSearchParams(queryParams)

    if (value !== undefined) {
      result.set(key, value)
    } else {
      result.delete(key)
    }
    return result.toString()
  }
  const tabs: Components.HeaderTab[] = [
    {
      to: { search: withParam('panel') },
      value: 'ee',
      label: 'Entry Efficiency (EE)',
    },
    {
      to: { search: withParam('panel', 'pe') },
      value: 'pe',
      label: 'Passage Efficiency (PE)',
    },
    {
      to: { search: withParam('panel', 'pd') },
      value: 'pd',
      label: 'Passage Duration (PD)',
    },
  ]

  const equation1aee = `
    y = \\rho_c(\\frac{n_{out}}{n_{in}})
  `
  const equation1bee = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\rho_c(x) & \\text{species grading function (class specific)} \\\\
    n_{out} & \\text{all entered individuals} \\\\
    n_{in} & \\text{all detected individuals} \\\\
    \\end{array}
  `
  const equation1ape = `
    y = \\rho_c(\\frac{n_{out}}{n_{in}})
  `
  const equation1bpe = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\rho_c(x) & \\text{species grading function (class specific)} \\\\
    n_{out} & \\text{all passed individuals} \\\\
    n_{in} & \\text{all entered individuals} \\\\
    \\end{array}
  `
  const equation1apd = `
    y = \\rho_c(\\frac{t_{\\text{median}}}{h})
  `
  const equation1bpd = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\rho_c(x) & \\text{species grading function (class specific)} \\\\
    t_{\\text{median}} & \\text{median of passage duration in seconds} \\\\
    h & \\text{pathway denivelation in meter} \\\\
    \\end{array}
  `
  const equation1a = currentTab === 'ee' ? equation1aee : currentTab === 'pe' ? equation1ape : equation1apd
  const equation1b = currentTab === 'ee' ? equation1bee : currentTab === 'pe' ? equation1bpe : equation1bpd
  const equation1c = `
    \\text{with constraint } 0 \\le \\rho_c(x) \\le 1
  `
  const equation2a = `
    Y_{\\Set{\\text{strong}, \\text{weak}, \\text{bottom}}} = \\frac{\\sum_i \\gamma(n_i) y_i}{\\sum_i \\gamma(n_i)}
  `
  const equation2b = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\gamma(n) & \\text{species weighting function} \\\\
    n_i & \\text{number of individuals of species } i \\\\
    y_i & \\text{grading of species } i \\\\
    \\end{array}
  `

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../evaluation/summary" className="hover:text-wa21-100">
                Evaluation
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Passability</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="relative z-20 pb-5 mb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actions={[]} />
        </div>

        <Disclosure>
          {({ open }) => (
            <div className="mt-10 border-b border-gray-200">
              <div className="border-b border-gray-200 pb-5">
                <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                  Passability by class
                  {currentTab === 'ee' && ' (entry efficiency)'}
                  {currentTab === 'pe' && ' (passage efficiency)'}
                  {currentTab === 'pd' && ' (passage duration)'}
                  <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                    tell me more
                    <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                  </Disclosure.Button>
                </h2>
              </div>
              {currentTab === 'ee' && (
                <Components.IndicatorCards
                  indicators={getIndicatorsEE.data?.evaluationIndicators}
                  columns={getIndicatorsEE.data?.evaluationIndicators.items.length as any}
                />
              )}
              {currentTab === 'pe' && (
                <Components.IndicatorCards
                  indicators={getIndicatorsPE.data?.evaluationIndicators}
                  columns={getIndicatorsPE.data?.evaluationIndicators.items.length as any}
                />
              )}
              {currentTab === 'pd' && (
                <Components.IndicatorCards
                  indicators={getIndicatorsPD.data?.evaluationIndicators}
                  columns={getIndicatorsPD.data?.evaluationIndicators.items.length as any}
                />
              )}
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel>
                  <article className="px-5 pt-5 prose prose-sm max-w-none border-t border-gray-200">
                    <p>Species are grouped in classes and graded using this formula:</p>
                    <BlockMath>{equation2a}</BlockMath>
                    <BlockMath>{equation2b}</BlockMath>
                  </article>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="mt-10">
              <div className="border-b border-gray-200 pb-5 mb-5">
                <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                  Passability by species
                  {currentTab === 'ee' && ' (entry efficiency)'}
                  {currentTab === 'pe' && ' (passage efficiency)'}
                  {currentTab === 'pd' && ' (passage duration)'}
                  <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                    tell me more
                    <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                  </Disclosure.Button>
                </h2>
              </div>
              <div className="px-5">
                {currentTab === 'ee' && (
                  <Components.DataTable table={getPassabilityEE.data?.evaluationTable} condensed collapsed filename={`passability-ee-${study?.name}`} />
                )}
                {currentTab === 'pe' && (
                  <Components.DataTable table={getPassabilityPE.data?.evaluationTable} condensed collapsed filename={`passability-pe-${study?.name}`} />
                )}
                {currentTab === 'pd' && (
                  <Components.DataTable table={getPassabilityPD.data?.evaluationTable} condensed collapsed filename={`passability-pd-${study?.name}`} />
                )}
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel>
                  <article className="px-5 pt-5 prose prose-sm max-w-none">
                    <p>Each species is graded for each pathway/criteria using this formula:</p>
                    <BlockMath>{equation1a}</BlockMath>
                    <BlockMath>{equation1b}</BlockMath>
                    <BlockMath>{equation1c}</BlockMath>
                  </article>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </main>
    </>
  )
}
