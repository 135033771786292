import { ChangeEvent, Fragment, TargetedEvent, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { Listbox, Transition, Menu } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'

import _ from 'lodash'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import Decimal from 'decimal.js'

import * as api from '~/api'
import { DataSourceQuery, DataSourceFilter, DataSourceFilterOperator, StudyNodeType } from '~/graphql-codegen/graphql'

import { localizedText } from './localized'

export type StudyDecimalFilterRange = {
  from?: string
  to?: string
}

export type StudyDateFilterRange = {
  from?: number
  to?: number
}

export type StudyFilterOrganismValue = {
  datasets: string[]
  validity: boolean
  species: string[]
  length: StudyDecimalFilterRange
  width: StudyDecimalFilterRange
  height: StudyDecimalFilterRange
  weight: StudyDecimalFilterRange
  age: StudyDecimalFilterRange
  tags: string[]
  tagQuery: string
  tagTypes: string[]
  taggedOn: StudyDateFilterRange
  capturedOn: StudyDateFilterRange
  capturedAt: string[]
  releasedOn: StudyDateFilterRange
  releasedAt: string[]
  labels: string[]
}

export type StudyFilterEventValue = {
  datasets: string[]
  validity: boolean
  detectedOn: StudyDateFilterRange
  detectedBy: string[]
  detectedByArray: string[]
}

export type StudyFilterValue = {
  organism: Partial<StudyFilterOrganismValue>
  event: Partial<StudyFilterEventValue>
}

export type StudyFilterOrganismFlags = { [k in keyof StudyFilterOrganismValue]: boolean }
export type StudyFilterEventFlags = { [k in keyof StudyFilterEventValue]: boolean }
export type StudyFilterFlags = {
  organism: Partial<StudyFilterOrganismFlags>
  event: Partial<StudyFilterEventFlags>
}

const ORGANISM_PREFIX = 'o'
const EVENT_PREFIX = 'e'

const DATASET_KEY = 'ds'
const VALIDITY_KEY = 'va'
const SPECIES_KEY = 'sp'
const LENGTH_KEY = 'le'
const WIDTH_KEY = 'wi'
const HEIGHT_KEY = 'he'
const WEIGHT_KEY = 'we'
const AGE_KEY = 'ag'
const TAG_KEY = 'ta'
const TAG_QUERY_KEY = 'taq'
const TAG_TYPE_KEY = 'tt'
const TAGGED_ON_KEY = 'to'
const CAPTURED_ON_KEY = 'co'
const CAPTURED_AT_KEY = 'ca'
const RELEASED_ON_KEY = 'ro'
const RELEASED_AT_KEY = 'ra'
const LABEL_KEY = 'la'
const DETECTED_ON_KEY = 'do'
const DETECTED_BY_KEY = 'db'
const DETECTED_BY_ARRAY_KEY = 'da'
const ALL_ORGANISM_KEYS: { [k in keyof StudyFilterOrganismValue]: string } = {
  datasets: DATASET_KEY,
  validity: VALIDITY_KEY,
  species: SPECIES_KEY,
  length: LENGTH_KEY,
  width: WIDTH_KEY,
  height: HEIGHT_KEY,
  weight: WEIGHT_KEY,
  age: AGE_KEY,
  tags: TAG_KEY,
  tagQuery: TAG_QUERY_KEY,
  tagTypes: TAG_TYPE_KEY,
  taggedOn: TAGGED_ON_KEY,
  capturedOn: CAPTURED_ON_KEY,
  capturedAt: CAPTURED_AT_KEY,
  releasedOn: RELEASED_ON_KEY,
  releasedAt: RELEASED_AT_KEY,
  labels: LABEL_KEY,
}
const ALL_EVENT_KEYS: { [k in keyof StudyFilterEventValue]: string } = {
  datasets: DATASET_KEY,
  validity: VALIDITY_KEY,
  detectedOn: DETECTED_ON_KEY,
  detectedBy: DETECTED_BY_KEY,
  detectedByArray: DETECTED_BY_ARRAY_KEY,
}

export function encodeLikeQuery(value: string | null | undefined): string | undefined {
  if (value !== null && value !== undefined) {
    return `%${value.replace('%', '\\%').replace('_', '\\_')}%`
  }
  return undefined
}

export function parseStudyFilters(searchParams: URLSearchParams): StudyFilterValue {
  let result: StudyFilterValue = {
    organism: {},
    event: {},
  }

  for (let name in ALL_ORGANISM_KEYS) {
    let key = name as keyof StudyFilterOrganismValue
    const values = searchParams.getAll(ORGANISM_PREFIX + ALL_ORGANISM_KEYS[key])

    switch (key) {
      case 'length':
      case 'width':
      case 'height':
      case 'weight':
      case 'age':
        if (values.length === 1) {
          let parts = values[0].split(':')

          result.organism[key] = {
            from: parts[0].length > 0 ? new Decimal(parts[0]).toString() : undefined,
            to: parts[1].length > 0 ? new Decimal(parts[1]).toString() : undefined,
          }
        }
        break
      case 'taggedOn':
      case 'capturedOn':
      case 'releasedOn':
        if (values.length === 1) {
          let parts = values[0].split(':')

          result.organism[key] = {
            from: parts[0].length > 0 ? parseInt(parts[0]) : undefined,
            to: parts[1].length > 0 ? parseInt(parts[1]) : undefined,
          }
        }
        break
      case 'validity':
        if (values.length === 1) {
          result.organism[key] = values[0] === 'true'
        }
        break
      case 'tagQuery':
        if (values.length === 1) {
          result.organism[key] = values[0]
        }
        break
      default:
        if (values.length > 0) {
          result.organism[key] = values
        }
        break
    }
  }

  for (let name in ALL_EVENT_KEYS) {
    let key = name as keyof StudyFilterEventValue
    const values = searchParams.getAll(EVENT_PREFIX + ALL_EVENT_KEYS[key])

    switch (key) {
      case 'detectedOn':
        if (values.length === 1) {
          let parts = values[0].split(':')

          result.event[key] = {
            from: parts[0].length > 0 ? parseInt(parts[0]) : undefined,
            to: parts[1].length > 0 ? parseInt(parts[1]) : undefined,
          }
        }
        break
      case 'validity':
        if (values.length === 1) {
          result.event[key] = values[0] === 'true'
        }
        break
      default:
        if (values.length > 0) {
          result.event[key] = values
        }
        break
    }
  }
  return result
}

export function encodeStudyFilters(
  filters: StudyFilterValue,
  backend: boolean = false,
  extraParams: { [k: string]: string | number | boolean | undefined } = {}
): URLSearchParams {
  const result = new URLSearchParams()

  function single(prefix: string, key: string, value: string | undefined) {
    if (value !== undefined) {
      result.set(prefix + key, value)
    }
  }

  function multiple(prefix: string, key: string, values: string[] | undefined) {
    if (backend) {
      ;(values || []).forEach((value, i) => result.append(`${prefix}${key}[${i}]`, value))
    } else {
      ;(values || []).forEach((value) => result.append(prefix + key, value))
    }
  }

  function range(prefix: string, key: string, value: StudyDecimalFilterRange | StudyDateFilterRange | undefined) {
    let data = [value?.from, value?.to].map((value) => (value ? value.toString() : '')).join(':')

    if (value) {
      result.set(prefix + key, data)
    }
  }

  multiple(ORGANISM_PREFIX, DATASET_KEY, filters.organism.datasets)
  single(ORGANISM_PREFIX, VALIDITY_KEY, filters.organism.validity?.toString())
  multiple(ORGANISM_PREFIX, SPECIES_KEY, filters.organism.species)
  range(ORGANISM_PREFIX, LENGTH_KEY, filters.organism.length)
  range(ORGANISM_PREFIX, WIDTH_KEY, filters.organism.width)
  range(ORGANISM_PREFIX, HEIGHT_KEY, filters.organism.height)
  range(ORGANISM_PREFIX, WEIGHT_KEY, filters.organism.weight)
  range(ORGANISM_PREFIX, AGE_KEY, filters.organism.age)
  multiple(ORGANISM_PREFIX, TAG_KEY, filters.organism.tags)
  single(ORGANISM_PREFIX, TAG_QUERY_KEY, backend ? encodeLikeQuery(filters.organism.tagQuery) : filters.organism.tagQuery)
  multiple(ORGANISM_PREFIX, TAG_TYPE_KEY, filters.organism.tagTypes)
  range(ORGANISM_PREFIX, TAGGED_ON_KEY, filters.organism.taggedOn)
  range(ORGANISM_PREFIX, CAPTURED_ON_KEY, filters.organism.capturedOn)
  multiple(ORGANISM_PREFIX, CAPTURED_AT_KEY, filters.organism.capturedAt)
  range(ORGANISM_PREFIX, RELEASED_ON_KEY, filters.organism.releasedOn)
  multiple(ORGANISM_PREFIX, RELEASED_AT_KEY, filters.organism.releasedAt)
  multiple(ORGANISM_PREFIX, LABEL_KEY, filters.organism.labels)
  multiple(EVENT_PREFIX, DATASET_KEY, filters.event.datasets)
  single(EVENT_PREFIX, VALIDITY_KEY, filters.event.validity?.toString())
  range(EVENT_PREFIX, DETECTED_ON_KEY, filters.event.detectedOn)
  multiple(EVENT_PREFIX, DETECTED_BY_KEY, filters.event.detectedBy)
  multiple(EVENT_PREFIX, DETECTED_BY_ARRAY_KEY, filters.event.detectedByArray)
  for (let key in extraParams) {
    const value = extraParams[key]

    if (value !== undefined) {
      result.set(key, value.toString())
    } else {
      result.delete(key)
    }
  }
  return result
}

type StudyGenericFilterRange<T> = {
  from?: T
  to?: T
}

export function buildDataSourceQuery(filters: StudyFilterValue): DataSourceQuery {
  const query = {
    filters: [] as DataSourceFilter[],
  }

  function buildDataSourceRangeFilter<T>(key: string, range: StudyGenericFilterRange<T>): DataSourceFilter {
    if (range.from) {
      if (range.to) {
        return { key, operator: DataSourceFilterOperator.Between, params: [range.from.toString(), range.to.toString()] }
      }
      return { key, operator: DataSourceFilterOperator.GreaterOrEqual, params: [range.from.toString()] }
    } else if (range.to) {
      return { key, operator: DataSourceFilterOperator.Less, params: [range.to.toString()] }
    }
    throw Error('invalid range filter')
  }

  if (filters.organism.datasets) {
    query.filters.push({ key: 'organism.datasetId', operator: DataSourceFilterOperator.In, params: filters.organism.datasets })
  }
  if (filters.organism.validity !== undefined) {
    query.filters.push({ key: 'organism.validity', operator: DataSourceFilterOperator.Equal, params: [filters.organism.validity.toString()] })
  }
  if (filters.organism.species) {
    query.filters.push({ key: 'organism.speciesId', operator: DataSourceFilterOperator.In, params: filters.organism.species })
  }
  if (filters.organism.length) {
    query.filters.push(buildDataSourceRangeFilter('organism.length', filters.organism.length))
  }
  if (filters.organism.width) {
    query.filters.push(buildDataSourceRangeFilter('organism.width', filters.organism.width))
  }
  if (filters.organism.height) {
    query.filters.push(buildDataSourceRangeFilter('organism.height', filters.organism.height))
  }
  if (filters.organism.weight) {
    query.filters.push(buildDataSourceRangeFilter('organism.weight', filters.organism.weight))
  }
  if (filters.organism.age) {
    query.filters.push(buildDataSourceRangeFilter('organism.age', filters.organism.age))
  }
  if (filters.organism.tags) {
    query.filters.push({ key: 'organism.tag', operator: DataSourceFilterOperator.In, params: filters.organism.tags })
  }
  if (filters.organism.tagQuery) {
    query.filters.push({ key: 'organism.tagQuery', operator: DataSourceFilterOperator.Like, params: [encodeLikeQuery(filters.organism.tagQuery) as string] })
  }
  if (filters.organism.tagTypes) {
    query.filters.push({ key: 'organism.tagType', operator: DataSourceFilterOperator.In, params: filters.organism.tagTypes })
  }
  if (filters.organism.taggedOn) {
    query.filters.push(buildDataSourceRangeFilter('organism.taggedOn', filters.organism.taggedOn))
  }
  if (filters.organism.capturedOn) {
    query.filters.push(buildDataSourceRangeFilter('organism.capturedOn', filters.organism.capturedOn))
  }
  if (filters.organism.capturedAt) {
    query.filters.push({ key: 'organism.capturedAt', operator: DataSourceFilterOperator.In, params: filters.organism.capturedAt })
  }
  if (filters.organism.releasedOn) {
    query.filters.push(buildDataSourceRangeFilter('organism.releasedOn', filters.organism.releasedOn))
  }
  if (filters.organism.releasedAt) {
    query.filters.push({ key: 'organism.releasedAt', operator: DataSourceFilterOperator.In, params: filters.organism.releasedAt })
  }
  if (filters.organism.labels) {
    query.filters.push({ key: 'organism.label', operator: DataSourceFilterOperator.In, params: filters.organism.labels })
  }
  if (filters.event.datasets) {
    query.filters.push({ key: 'event.datasetId', operator: DataSourceFilterOperator.In, params: filters.event.datasets })
  }
  if (filters.event.validity !== undefined) {
    query.filters.push({ key: 'event.validity', operator: DataSourceFilterOperator.Equal, params: [filters.event.validity.toString()] })
  }
  if (filters.event.detectedOn) {
    query.filters.push(buildDataSourceRangeFilter('event.detectedOn', filters.event.detectedOn))
  }
  if (filters.event.detectedBy) {
    query.filters.push({ key: 'event.detectedBy', operator: DataSourceFilterOperator.In, params: filters.event.detectedBy })
  }
  if (filters.event.detectedByArray) {
    query.filters.push({ key: 'event.detectedByArray', operator: DataSourceFilterOperator.In, params: filters.event.detectedByArray })
  }
  return query
}

export type StudyEnumItem = {
  value: string
  label?: React.ReactNode
}

type StudyEnumFilterProps = {
  items: StudyEnumItem[]
  selection: string | string[]
  multiple: boolean
  onFilter: (selection: string | string[]) => void
}

export function StudyEnumFilter({
  items = [],
  selection = [],
  multiple,
  onFilter = () => {},
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & Partial<StudyEnumFilterProps>) {
  let selectedItems = items.filter((item) => selection.indexOf(item.value) >= 0)

  return (
    <Listbox value={multiple ? selection : selection[0]} onChange={onFilter} multiple={multiple}>
      {({ open }) => (
        <>
          <div {...props} className={classNames(className as string, 'relative')}>
            <Listbox.Button className="relative w-full cursor-default rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-wa21-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {selectedItems.length > 0
                  ? selectedItems.map((item, i) => {
                      if (i === 0) {
                        return <span key={i}>{item.label || item.value}</span>
                      }
                      return <span key={i}>, {item.label || item.value}</span>
                    })
                  : 'Any'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {!multiple && (
                  <Listbox.Option
                    className={({ active }) =>
                      classNames(active ? 'bg-wa21-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4')
                    }
                    value="">
                    <span className="block truncate">Any</span>
                  </Listbox.Option>
                )}
                {items.map((item, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      classNames(active ? 'bg-wa21-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4')
                    }
                    value={item.value}>
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{item.label || item.value}</span>

                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-wa21-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

type StudyTextFilterProps = {
  value: string
  onFilter: (value: string) => void
}

export function StudyTextFilter({ value, onFilter = () => {}, className, ...props }: React.HTMLAttributes<HTMLInputElement> & Partial<StudyTextFilterProps>) {
  let [data, setData] = useState(value?.toString() || '')
  let inputRef = useRef<HTMLInputElement>(null)
  let [focused, setFocused] = useState(false)

  useEffect(() => {
    if (!focused && inputRef.current) {
      inputRef.current.value = value?.toString() || ''
      if (data !== inputRef.current.value) {
        setData(inputRef.current.value)
      }
    }
  }, [focused, value])

  function onNotify() {
    onFilter(data)
    setFocused(false)
  }

  function onChange(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.currentTarget instanceof HTMLInputElement) {
      setData(ev.currentTarget.value)
    }
  }

  return (
    <input
      {...props}
      ref={inputRef}
      type="text"
      value={data}
      className="form-input w-full py-1.5 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm placeholder:text-gray-400 sm:leading-6"
      placeholder="any"
      onChange={onChange}
      onFocus={() => setFocused(true)}
      onBlur={onNotify}
      step="any"
    />
  )
}

type StudyRangeFilterProps<T> = {
  range: StudyGenericFilterRange<T>
  onFilter: (value: StudyGenericFilterRange<T>) => void
}

export function StudyDateRangeFilter({
  range = {},
  onFilter = () => {},
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & Partial<StudyRangeFilterProps<number>>) {
  let [data, setData] = useState<{ from: string; to: string }>({
    from: range.from ? DateTime.fromSeconds(range.from).toISODate() || '' : '',
    to: range.to ? DateTime.fromSeconds(range.to).toISODate() || '' : '',
  })
  let fromRef = useRef<HTMLInputElement>(null)
  let [fromFocused, setFromFocused] = useState(false)
  let toRef = useRef<HTMLInputElement>(null)
  let [toFocused, setToFocused] = useState(false)

  useEffect(() => {
    let updatedData = data

    if (!fromFocused && fromRef.current) {
      fromRef.current.value = range.from ? DateTime.fromSeconds(range.from).startOf('day').toISODate() || '' : ''
      if (data.from !== fromRef.current.value) {
        updatedData = { ...updatedData, from: fromRef.current.value }
      }
    }
    if (!toFocused && toRef.current) {
      toRef.current.value = range.to ? DateTime.fromSeconds(range.to).endOf('day').toISODate() || '' : ''
      if (data.to !== toRef.current.value) {
        updatedData = { ...updatedData, to: toRef.current.value }
      }
    }
    if (updatedData !== data) {
      setData(updatedData)
    }
  }, [range])

  function onNotify() {
    onFilter({
      from: data.from.length > 0 ? DateTime.fromISO(data.from).startOf('day').toSeconds() : undefined,
      to: data.to.length > 0 ? DateTime.fromISO(data.to).endOf('day').toSeconds() : undefined,
    })
    setFromFocused(false)
    setToFocused(false)
  }

  function onChangeFrom(ev: TargetedEvent<HTMLInputElement>) {
    let updated = { ...data, from: ev.currentTarget.value }

    setData(updated)
  }

  function onChangeTo(ev: TargetedEvent<HTMLInputElement>) {
    let updated = { ...data, to: ev.currentTarget.value }

    setData(updated)
  }

  return (
    <div
      {...props}
      className="form-input flex flex-row items-center py-0 space-x-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm">
      <input
        ref={fromRef}
        type="date"
        value={data.from}
        className="w-full flex flex-auto py-1.5 bg-transparent text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 sm:leading-6"
        onChange={onChangeFrom}
        onFocus={() => setFromFocused(true)}
        onBlur={onNotify}
      />
      <ArrowLongRightIcon className="flex flex-initial w-8 h-8" />
      <input
        ref={toRef}
        type="date"
        value={data.to}
        className="w-full flex flex-auto py-1.5 bg-transparent text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 sm:leading-6"
        onChange={onChangeTo}
        onFocus={() => setToFocused(true)}
        onBlur={onNotify}
      />
    </div>
  )
}

export function StudyNumberRangeFilter({
  range = {},
  onFilter = () => {},
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & Partial<StudyRangeFilterProps<string>>) {
  let [data, setData] = useState<{ from: string | undefined; to: string | undefined }>({
    from: range.from ? new Decimal(range.from).toString() : undefined,
    to: range.to ? new Decimal(range.to).toString() : undefined,
  })
  let fromRef = useRef<HTMLInputElement>(null)
  let [fromFocused, setFromFocused] = useState(false)
  let toRef = useRef<HTMLInputElement>(null)
  let [toFocused, setToFocused] = useState(false)

  useEffect(() => {
    if (!fromFocused && !toFocused && fromRef.current && toRef.current) {
      fromRef.current.value = range.from ? range.from.toString() : ''
      toRef.current.value = range.to ? range.to.toString() : ''
      if (data.from !== fromRef.current.value || data.to !== toRef.current.value) {
        setData({ from: fromRef.current.value, to: toRef.current.value })
      }
    }
  }, [fromFocused, toFocused, range])

  function onNotify() {
    onFilter({
      from: (data.from || '').length > 0 ? new Decimal(data.from || '').toString() : undefined,
      to: (data.to || '').length > 0 ? new Decimal(data.to || '').toString() : undefined,
    })
    setFromFocused(false)
    setToFocused(false)
  }

  function onChangeFrom(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.currentTarget instanceof HTMLInputElement) {
      let updated = { ...data, from: ev.currentTarget.value }

      setData(updated)
    }
  }

  function onChangeTo(ev: ChangeEvent<HTMLInputElement>) {
    if (ev.currentTarget instanceof HTMLInputElement) {
      let updated = { ...data, to: ev.currentTarget.value }

      setData(updated)
    }
  }

  return (
    <div
      {...props}
      className="form-input flex flex-row items-center py-0 space-x-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm">
      <input
        ref={fromRef}
        type="number"
        value={data.from}
        className="w-full flex flex-auto py-1.5 bg-transparent text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 sm:leading-6"
        placeholder="from"
        onChange={onChangeFrom}
        onFocus={() => setFromFocused(true)}
        onBlur={onNotify}
        step="any"
      />
      <ArrowLongRightIcon className="flex flex-initial w-8 h-8" />
      <input
        ref={toRef}
        type="number"
        value={data.to}
        className="w-full flex flex-auto py-1.5 bg-transparent text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 sm:leading-6"
        placeholder="to"
        onChange={onChangeTo}
        onFocus={() => setToFocused(true)}
        onBlur={onNotify}
        step="any"
      />
    </div>
  )
}

export type StudyFilterAction = {
  name: React.ReactNode
  url?: string
  onClick?: () => void
  disabled?: boolean
}

type StudyFiltersProps = {
  studyId: string
  flags?: StudyFilterFlags
  actions?: StudyFilterAction[]
  actionGroups?: StudyFilterAction[][]
  pageKey?: string
}

export function StudyFilters({ studyId, flags = { organism: {}, event: {} }, ...props }: StudyFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const current = parseStudyFilters(searchParams)
  const active =
    _.toPairs(current.organism).filter(([key]) => flags.organism[key as keyof StudyFilterOrganismFlags]).length > 0 ||
    _.toPairs(current.event).filter(([key]) => flags.event[key as keyof StudyFilterEventFlags]).length > 0

  const validityItems = [
    {
      value: 'true',
      label: 'Included',
    },
    {
      value: 'false',
      label: 'Excluded',
    },
  ]

  const listOrganismDatasets = useQuery(api.LIST_ORGANISM_DATASETS, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false } },
    skip: !flags.organism.datasets,
  })
  const organismDatasetsItems = (listOrganismDatasets.data?.study.organismDatasets.items || []).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const findStudySpecies = useQuery(api.FIND_STUDY_SPECIES, { variables: { studyId }, skip: !flags.organism.species })
  const studySpecies = (findStudySpecies.data?.studySpecies || []).map((item) => {
    return {
      value: item.id,
      label: localizedText(item.name, item.localizedDisplay ? item.localizedName : []),
    }
  })

  studySpecies.sort((a, b) => (a.label || '').localeCompare(b.label || ''))

  const findStudyCaptureLocations = useQuery(api.FIND_STUDY_CAPTURE_LOCATIONS, { variables: { studyId }, skip: !flags.organism.capturedAt })
  const studyCaptureLocations = (findStudyCaptureLocations.data?.studyCaptureLocations || []).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const findStudyReleaseLocations = useQuery(api.FIND_STUDY_RELEASE_LOCATIONS, { variables: { studyId }, skip: !flags.organism.releasedAt })
  const studyReleaseLocations = (findStudyReleaseLocations.data?.studyReleaseLocations || []).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const listLabels = useQuery(api.FIND_STUDY_LABELS, {
    variables: { studyId },
    skip: !flags.organism.labels,
  })
  const labelsItems = (listLabels.data?.studyLabels || []).map((label) => {
    return {
      value: label,
    }
  })

  const listDetectionDatasets = useQuery(api.LIST_DETECTION_DATASETS, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false } },
    skip: !flags.event.datasets,
  })
  const detectionDatasetsItems = (listDetectionDatasets.data?.study.detectionDatasets.items || []).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const listStudyNodes = useQuery(api.LIST_STUDY_NODES, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false } },
    skip: !flags.event.detectedBy,
  })
  const studyPathways = (listStudyNodes.data?.studyNodes.items || [])
    .filter((item) => item.nodeType !== StudyNodeType.PointOfInterest)
    .map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })

  const listAntennaArrays = useQuery(api.LIST_ANTENNA_ARRAYS, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false, studyNodeIds: current.event.detectedBy } },
    skip: !flags.event.detectedByArray,
  })
  const studyArrays = (listAntennaArrays.data?.antennaArrays.items || []).map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  function onChange(prefix: string, key: string, value: string) {
    setSearchParams((prev) => {
      if (value.length > 0) {
        prev.set(prefix + key, value)
      } else {
        prev.delete(prefix + key)
      }
      if (props.pageKey) {
        prev.delete(props.pageKey)
      }
      return prev
    })
  }

  function onChangeMultiple(prefix: string, key: string, values: string[]) {
    setSearchParams((prev) => {
      prev.delete(prefix + key)
      values.forEach((value) => prev.append(prefix + key, value))
      if (props.pageKey) {
        prev.delete(props.pageKey)
      }
      return prev
    })
  }

  function onChangeRange<T>(prefix: string, key: string, value: StudyGenericFilterRange<T>) {
    setSearchParams((prev) => {
      let data = [value.from, value.to].map((value) => (value ? value.toString() : '')).join(':')

      if (data !== ':') {
        prev.set(prefix + key, data)
      } else {
        prev.delete(prefix + key)
      }
      if (props.pageKey) {
        prev.delete(props.pageKey)
      }
      return prev
    })
  }

  function onClear() {
    setSearchParams((prev) => {
      for (let name in ALL_ORGANISM_KEYS) {
        let key = name as keyof StudyFilterOrganismValue

        if (flags.organism[key]) {
          prev.delete(ORGANISM_PREFIX + ALL_ORGANISM_KEYS[key])
        }
      }
      for (let name in ALL_EVENT_KEYS) {
        let key = name as keyof StudyFilterEventValue

        if (flags.event[key]) {
          prev.delete(EVENT_PREFIX + ALL_EVENT_KEYS[key])
        }
      }
      if (props.pageKey) {
        prev.delete(props.pageKey)
      }
      return prev
    })
  }

  return (
    <div className="flex flex-row w-full space-x-4">
      <div className="flex-auto grid grid-cols-4 gap-x-2">
        {flags.organism.datasets && (
          <div>
            <label className="text-xs text-gray-700">Individual dataset</label>
            <StudyEnumFilter
              items={organismDatasetsItems}
              selection={current.organism.datasets}
              multiple
              onFilter={(values) => onChangeMultiple(ORGANISM_PREFIX, DATASET_KEY, values as string[])}
            />
          </div>
        )}
        {flags.organism.validity && (
          <div>
            <label className="text-xs text-gray-700">Individual inclusion</label>
            <StudyEnumFilter
              items={validityItems}
              selection={current.organism.validity !== undefined ? [current.organism.validity.toString()] : undefined}
              onFilter={(value) => onChange(ORGANISM_PREFIX, VALIDITY_KEY, value as string)}
            />
          </div>
        )}
        {flags.event.datasets && (
          <div>
            <label className="text-xs text-gray-700">Event dataset</label>
            <StudyEnumFilter
              items={detectionDatasetsItems}
              selection={current.event.datasets}
              multiple
              onFilter={(values) => onChangeMultiple(EVENT_PREFIX, DATASET_KEY, values as string[])}
            />
          </div>
        )}
        {flags.event.validity && (
          <div>
            <label className="text-xs text-gray-700">Event inclusion</label>
            <StudyEnumFilter
              items={validityItems}
              selection={current.event.validity !== undefined ? [current.event.validity.toString()] : undefined}
              onFilter={(value) => onChange(EVENT_PREFIX, VALIDITY_KEY, value as string)}
            />
          </div>
        )}
        {flags.organism.labels && (
          <div>
            <label className="text-xs text-gray-700">Label</label>
            <StudyEnumFilter
              items={labelsItems}
              selection={current.organism.labels}
              multiple
              onFilter={(values) => onChangeMultiple(ORGANISM_PREFIX, LABEL_KEY, values as string[])}
            />
          </div>
        )}
        {flags.organism.species && (
          <div>
            <label className="text-xs text-gray-700">Species</label>
            <StudyEnumFilter
              items={studySpecies}
              selection={current.organism.species}
              multiple
              onFilter={(values) => onChangeMultiple(ORGANISM_PREFIX, SPECIES_KEY, values as string[])}
            />
          </div>
        )}
        {flags.organism.length && (
          <div>
            <label className="text-xs text-gray-700">Length (mm)</label>
            <StudyNumberRangeFilter range={current.organism.length} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, LENGTH_KEY, value)} />
          </div>
        )}
        {flags.organism.weight && (
          <div>
            <label className="text-xs text-gray-700">Weight (g)</label>
            <StudyNumberRangeFilter range={current.organism.weight} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, WEIGHT_KEY, value)} />
          </div>
        )}
        {flags.organism.age && (
          <div>
            <label className="text-xs text-gray-700">Age (y)</label>
            <StudyNumberRangeFilter range={current.organism.age} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, AGE_KEY, value)} />
          </div>
        )}
        {flags.organism.tagQuery && (
          <div>
            <label className="text-xs text-gray-700">Tag search</label>
            <StudyTextFilter value={current.organism.tagQuery} onFilter={(value) => onChange(ORGANISM_PREFIX, TAG_QUERY_KEY, value)} />
          </div>
        )}
        {flags.organism.tagTypes && (
          <div>
            <label className="text-xs text-gray-700">Tag type</label>
            <StudyTextFilter
              value={current.organism.tagTypes ? current.organism.tagTypes[0] : undefined}
              onFilter={(value) => onChange(ORGANISM_PREFIX, TAG_TYPE_KEY, value)}
            />
          </div>
        )}
        {flags.organism.taggedOn && (
          <div>
            <label className="text-xs text-gray-700">Tagged on</label>
            <StudyDateRangeFilter range={current.organism.taggedOn} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, TAGGED_ON_KEY, value)} />
          </div>
        )}
        {flags.organism.capturedOn && (
          <div>
            <label className="text-xs text-gray-700">Captured on</label>
            <StudyDateRangeFilter range={current.organism.capturedOn} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, CAPTURED_ON_KEY, value)} />
          </div>
        )}
        {flags.organism.capturedAt && (
          <div>
            <label className="text-xs text-gray-700">Captured at</label>
            <StudyEnumFilter
              items={studyCaptureLocations}
              selection={current.organism.capturedAt}
              multiple
              onFilter={(values) => onChangeMultiple(ORGANISM_PREFIX, CAPTURED_AT_KEY, values as string[])}
            />
          </div>
        )}
        {flags.organism.releasedOn && (
          <div>
            <label className="text-xs text-gray-700">Released on</label>
            <StudyDateRangeFilter range={current.organism.releasedOn} onFilter={(value) => onChangeRange(ORGANISM_PREFIX, RELEASED_ON_KEY, value)} />
          </div>
        )}
        {flags.organism.releasedAt && (
          <div>
            <label className="text-xs text-gray-700">Released at</label>
            <StudyEnumFilter
              items={studyReleaseLocations}
              selection={current.organism.releasedAt}
              multiple
              onFilter={(values) => onChangeMultiple(ORGANISM_PREFIX, RELEASED_AT_KEY, values as string[])}
            />
          </div>
        )}
        {flags.event.detectedBy && (
          <div>
            <label className="text-xs text-gray-700">Detected by</label>
            <StudyEnumFilter
              items={studyPathways}
              selection={current.event.detectedBy}
              multiple
              onFilter={(values) => onChangeMultiple(EVENT_PREFIX, DETECTED_BY_KEY, values as string[])}
            />
          </div>
        )}
        {flags.event.detectedByArray && (
          <div>
            <label className="text-xs text-gray-700">Detected by array</label>
            <StudyEnumFilter
              items={studyArrays}
              selection={current.event.detectedByArray}
              multiple
              onFilter={(values) => onChangeMultiple(EVENT_PREFIX, DETECTED_BY_ARRAY_KEY, values as string[])}
            />
          </div>
        )}
        {flags.event.detectedOn && (
          <div>
            <label className="text-xs text-gray-700">Detected on</label>
            <StudyDateRangeFilter range={current.event.detectedOn} onFilter={(value) => onChangeRange(EVENT_PREFIX, DETECTED_ON_KEY, value)} />
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between space-y-2 pt-6">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:text-gray-500 disabled:bg-gray-100 disabled:ring-gray-300"
          onClick={onClear}
          disabled={!active}>
          Clear
        </button>
        {((props.actions || []).length > 0 || (props.actionGroups || []).length > 0) && (
          <div className="relative inline-block text-left">
            <Menu>
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:text-gray-500 disabled:bg-gray-100 disabled:ring-gray-300">
                  Actions
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                  {(props.actions || []).length > 0 && (
                    <div className="py-1">
                      {(props.actions || []).map((action, i) => (
                        <Menu.Item key={i}>
                          {({ active }) => {
                            if (action.url) {
                              return (
                                <a
                                  href={action.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                  {action.name}
                                </a>
                              )
                            }
                            if (action.onClick) {
                              return (
                                <button
                                  onClick={action.onClick}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block w-full text-left px-4 py-2 text-sm disabled:text-gray-400'
                                  )}
                                  disabled={action.disabled}>
                                  {action.name}
                                </button>
                              )
                            }
                            return <span className="block w-full text-left px-4 py-2 text-sm text-gray-500">{action.name}</span>
                          }}
                        </Menu.Item>
                      ))}
                    </div>
                  )}
                  {(props.actionGroups || []).map((group, i) => (
                    <div key={i}>
                      {group.map((action, j) => (
                        <Menu.Item key={j}>
                          {({ active }) => {
                            if (action.url) {
                              return (
                                <a
                                  href={action.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                  {action.name}
                                </a>
                              )
                            }
                            if (action.onClick) {
                              return (
                                <button
                                  onClick={action.onClick}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block w-full text-left px-4 py-2 text-sm disabled:text-gray-400'
                                  )}
                                  disabled={action.disabled}>
                                  {action.name}
                                </button>
                              )
                            }
                            return <span className="block w-full text-left px-4 py-2 text-sm text-gray-500">{action.name}</span>
                          }}
                        </Menu.Item>
                      ))}
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>
    </div>
  )
}
