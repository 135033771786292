import { useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { DateTime } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import {
  Organism,
  OrganismSort,
  StudyState,
  UpdateOrganismMutationVariables,
  UpdateOrganismScope,
  UpdateOrganismsMutationVariables,
  UpsertOrganismFields,
  WellKnownCategories,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    validity: true,
    weight: true,
  },
  event: {},
}

export function StudyOrganismsListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const page = parseInt(searchParams.get('page') || '0')
  const sort = searchParams.get('sort') || 'tag'
  const sortBy = (sort.startsWith('-') ? sort.substring(1) : sort).toUpperCase() as OrganismSort
  const sortByReverse = sort.startsWith('-')

  const apollo = useApolloClient()
  const [updateOrganism] = useMutation(api.UPDATE_ORGANISM)
  const [updateOrganisms] = useMutation(api.UPDATE_ORGANISMS)
  const [busy, setBusy] = useState(false)

  async function onUpdateOrganism<K extends keyof Organism>(id: string, key: K, value: Organism[K]) {
    setBusy(true)
    try {
      const variables: UpdateOrganismMutationVariables = {
        id,
        fields: {},
      }

      if (key === 'tag') {
        variables.tag = value
      } else {
        variables.fields[key as keyof UpsertOrganismFields] = value
      }
      await updateOrganism({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individual.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateOrganisms<K extends keyof UpsertOrganismFields>(key: K, value: UpsertOrganismFields[K]) {
    setBusy(true)
    try {
      const variables: UpdateOrganismsMutationVariables = {
        studyId,
        filters: Components.buildDataSourceQuery(filters).filters,
        fields: {},
        scope: UpdateOrganismScope.Organisms,
      }

      variables.fields[key] = value
      await updateOrganisms({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individuals.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateLabels() {
    const label = await new Promise<string | number | null>((resolve) => {
      dispatch({
        action: AppAction.RequestPrompt,
        prompt: {
          title: 'New label',
          placeholder: 'Enter label...',
          resolve,
        },
      })
    })

    if (label !== null) {
      await onUpdateOrganisms('label', label as string)
    }
  }

  async function onClearLabels() {
    await onUpdateOrganisms('label', null)
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onSort(sort: string) {
    setSearchParams((prev) => {
      prev.delete('page')
      if (sort !== 'tag') {
        prev.set('sort', sort)
      } else {
        prev.delete('sort')
      }
      return prev
    })
  }

  function onView(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const listOrganismsFilters = Components.parseStudyFilters(searchParams)
  const listOrganisms = useQuery(api.LIST_ORGANISMS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        sortBy,
        sortByReverse,
        datasetIds: listOrganismsFilters.organism.datasets,
        valid: listOrganismsFilters.organism.validity,
        tags: listOrganismsFilters.organism.tags,
        tagQuery: Components.encodeLikeQuery(listOrganismsFilters.organism.tagQuery),
        labels: listOrganismsFilters.organism.labels,
        speciesIds: listOrganismsFilters.organism.species,
        length: listOrganismsFilters.organism.length,
        width: listOrganismsFilters.organism.width,
        height: listOrganismsFilters.organism.height,
        weight: listOrganismsFilters.organism.weight,
        taggedOn: listOrganismsFilters.organism.taggedOn,
        capturedAt: listOrganismsFilters.organism.capturedAt,
        releasedAt: listOrganismsFilters.organism.releasedAt,
      },
    },
  })
  const organismColumns: Components.TableColumn[] = [
    {
      name: 'tag',
      label: 'Tag',
      sortable: true,
    },
    {
      name: 'label',
      label: 'Label',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'species',
      label: 'Species',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'size',
      label: 'Size (mm)',
      align: 'right',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'weight',
      label: 'Weight (g)',
      align: 'right',
      className: 'w-24',
      sortable: true,
    },
    {
      name: 'tagged_on',
      label: 'Tagged on',
      align: 'center',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'valid',
      label: 'Included',
      align: 'center',
      className: 'w-16',
    },
    {
      name: 'actions',
      label: 'Actions',
      align: 'right',
      className: 'w-24',
      interactive: true,
    },
  ]
  const organismRows: Components.TableRow[] = (listOrganisms.data?.organisms.items || []).map((organism) => ({
    id: organism.id,
    tag: (
      <>
        {organism.tag}
        {organism.description && <span className="block italic text-gray-600">({organism.description})</span>}
      </>
    ),
    label: organism.label,
    species: <Components.CategoryValueDisplay category={WellKnownCategories.OrganismSpecies} value={organism.speciesId as any} />,
    size: Components.formatOrganismSize(organism as Organism),
    weight: organism.weight && numeral(organism.weight).format('0,0'),
    tagged_on: organism.taggedOn && (
      <span title={DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATETIME_SHORT)}>
        {DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATE_SHORT)}
      </span>
    ),
    valid: organism.valid ? <CheckCircleIcon className="inline w-5 h-5 text-wa21-500" /> : <XCircleIcon className="inline w-5 h-5 text-wa21-danger-500" />,
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {!organism.valid && (
          <button
            onClick={() => onUpdateOrganism(organism.id, 'valid', true)}
            className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900"
            disabled={busy}>
            Include
          </button>
        )}
        {organism.valid && (
          <button
            onClick={() => onUpdateOrganism(organism.id, 'valid', false)}
            className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900"
            disabled={busy}>
            Exclude
          </button>
        )}
        {/*<button onClick={() => onDeleteOrganism(organism as Organism)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
          Delete
        </button>*/}
      </div>
    ),
  }))

  const exportUrlParams = Components.encodeStudyFilters(filters, true)
  const exportUrl = `/api/csv/organisms/${studyId}?${exportUrlParams}`
  const actionGroups: Components.StudyFilterAction[][] = [
    [
      { name: 'Include all', onClick: () => onUpdateOrganisms('valid', true), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Exclude all', onClick: () => onUpdateOrganisms('valid', false), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [
      { name: 'Set labels', onClick: () => onUpdateLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Clear labels', onClick: () => onClearLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [{ name: 'Export CSV', url: exportUrl }],
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>All individuals</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/*<Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>Add individual</Components.HeaderPrimaryButton>*/}
          </div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="relative z-20 pb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actionGroups={actionGroups} pageKey="page" />
        </div>

        <Components.Table
          columns={organismColumns}
          rows={organismRows}
          condensed
          stickyHeader
          onClick={onView}
          page={page}
          pages={listOrganisms.data?.organisms.pages}
          total={listOrganisms.data?.organisms.total}
          loading={listOrganisms.loading}
          onPage={onPage}
          sort={sort}
          onSort={onSort}
        />
      </main>

      <Outlet />
    </>
  )
}
