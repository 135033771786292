import { useRef, useEffect } from 'react'

import FooterBafuData from './footer-bafu.svg'
import FooterWa21Data from './footer-wa21.svg'
import LogoData from './logo.svg'
import SpinData from './spin.svg?raw'

export const FooterBafu = FooterBafuData
export const FooterWa21 = FooterWa21Data
export const Logo = LogoData
export const Spin = (props: React.SVGProps<SVGSVGElement>) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (container.current) {
      container.current.innerHTML = SpinData
    }
  }, [container])

  return <div ref={container} {...(props as any)}></div>
}
