import { useEffect, useState } from 'react'

import _ from 'lodash'

import { AppAction, AppConfirmation, useAppDispatch, useAppState } from '~/state'

import { Modal } from './modal'

export function ConfirmationContainer() {
  const dispatch = useAppDispatch()
  const [expiry] = useState<{ [k: string]: number }>({})
  const [visibility, setVisibility] = useState<{ [k: string]: boolean }>({})
  const { confirmations } = useAppState()
  const items = confirmations || []

  useEffect(() => {
    let newVisibility = { ..._.pickBy(visibility, (_, id) => confirmations.find((item) => item.id === id)) }
    let visibilityChanged = _.size(newVisibility) < _.size(visibility)

    for (let item of items) {
      if (expiry[item.id] === undefined) {
        newVisibility[item.id] = true
        visibilityChanged = true
      }
    }
    if (visibilityChanged) {
      setVisibility(newVisibility)
    }
  }, [confirmations])

  function onPrimaryAction(item: AppConfirmation) {
    item.resolve(true)
    dispatch({ action: AppAction.DismissConfirmation, confirmation: item })
  }

  function onSecondaryAction(item: AppConfirmation) {
    item.resolve(false)
    dispatch({ action: AppAction.DismissConfirmation, confirmation: item })
  }

  return (
    <>
      {items.map((item) => {
        return (
          <Modal
            key={item.id}
            title={item.title}
            manual
            primaryAction={item.choices === 'okcancel' ? 'OK' : 'Yes'}
            onPrimaryAction={() => onPrimaryAction(item)}
            secondaryAction={item.choices === 'okcancel' ? 'Cancel' : 'No'}
            onSecondaryAction={() => onSecondaryAction(item)}>
            {item.message}
          </Modal>
        )
      })}
    </>
  )
}
