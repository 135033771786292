import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useApolloClient, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { AppAction, useAppDispatch } from '~/state'

export function StudyInsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const apollo = useApolloClient()
  const [insertStudy] = useMutation(api.INSERT_STUDY)
  const [busy, setBusy] = useState(false)

  async function onInsert(data: FormData) {
    setBusy(true)
    try {
      const response = await insertStudy({
        variables: {
          name: data.get('name')?.toString() || '',
          fields: {},
        },
      })

      try {
        await apollo.refetchQueries({ include: [api.LIST_STUDIES] })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: `../${response.data?.insertStudy.id}`, search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to create study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  return (
    <Components.SlideOver title="Add study" busy={busy} primaryAction="Add" onPrimaryAction={onInsert}>
      <div className="divide-y divide-gray-200 px-4 sm:px-6">
        <div className="space-y-6 pb-5 pt-6">
          <Components.TextField type="text" name="name" label="Name" placeholder="Study name" required disabled={busy} />
        </div>
      </div>
    </Components.SlideOver>
  )
}
