import { useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { DateTime, Duration } from 'luxon'

import * as api from '~/api'
import * as Components from '~/components'
import {
  DataSourceFilterOperator,
  ResultChartDataSource,
  ResultType,
  StudyState,
  UpdateOrganismScope,
  UpdateOrganismsMutationVariables,
  UpsertOrganismFields,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch, useAppState } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
    detectedOn: true,
  },
}

const ACTIVE_FILTERS_EXT = {
  organism: {
    ...ACTIVE_FILTERS.organism,
    tagQuery: true,
  },
  event: {
    ...ACTIVE_FILTERS.event,
    detectedOn: true,
  },
}

export function StudyResultsSummary() {
  const { settings } = useAppState()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const currentTab = searchParams.get('panel') || 'overview'
  const page = parseInt(searchParams.get('page') || '0')
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)
  const queryParams = Components.encodeStudyFilters(filters)

  const apollo = useApolloClient()
  const [aggregateStudy] = useMutation(api.AGGREGATE_STUDY)
  const [updateOrganisms] = useMutation(api.UPDATE_ORGANISMS)
  const [busy, setBusy] = useState(false)

  async function onAggregate() {
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyResultsPassageEventListing.onAggregate',
        title: 'Processing study datasets',
        message: 'This can take up to a few minutes, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await aggregateStudy({ variables: { id: studyId } })
      try {
        await apollo.refetchQueries({ include: api.ALL_RESULT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Processing failed', message: 'Check your study setup and retry later.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyResultsPassageEventListing.onAggregate' } })
      setBusy(false)
    }
  }

  async function onUpdateOrganisms<K extends keyof UpsertOrganismFields>(key: K, value: UpsertOrganismFields[K]) {
    setBusy(true)
    try {
      let resultType: string

      switch (currentTab) {
        case 'redetections':
          resultType = ResultType.Redetection.toLowerCase()
          break
        case 'entries':
          resultType = ResultType.Entry.toLowerCase()
          break
        case 'passages':
          resultType = ResultType.Passage.toLowerCase()
          break
        case 'exits':
          resultType = ResultType.Exit.toLowerCase()
          break
        default:
          throw Error('unsupported tab')
      }

      const mergedFilters = [
        { key: 'result.resultType', operator: DataSourceFilterOperator.Equal, params: [resultType] },
        ...(Components.buildDataSourceQuery(filters).filters || []),
      ]

      const variables: UpdateOrganismsMutationVariables = {
        studyId,
        filters: mergedFilters,
        fields: {},
        scope: UpdateOrganismScope.Results,
      }

      variables.fields[key] = value
      await updateOrganisms({ variables })
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individuals.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateLabels() {
    const label = await new Promise<string | number | null>((resolve) => {
      dispatch({
        action: AppAction.RequestPrompt,
        prompt: {
          title: 'New label',
          placeholder: 'Enter label...',
          resolve,
        },
      })
    })

    if (label !== null) {
      await onUpdateOrganisms('label', label as string)
    }
  }

  async function onClearLabels() {
    await onUpdateOrganisms('label', null)
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onViewOrganism(row: Components.TableRow) {
    navigate({ pathname: row.organismId as string })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getSpider = useQuery(api.RESULT_CHART, {
    variables: {
      studyId,
      datasource: ResultChartDataSource.Spider,
      query,
    },
  })

  const listRedetections = useQuery(api.LIST_RESULT_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        resultType: ResultType.Redetection,
        datasetIds: filters.organism.datasets,
        tags: filters.organism.tags,
        tagQuery: Components.encodeLikeQuery(filters.organism.tagQuery),
        labels: filters.organism.labels,
        speciesIds: filters.organism.species,
        length: filters.organism.length,
        width: filters.organism.width,
        height: filters.organism.height,
        weight: filters.organism.weight,
        taggedOn: filters.organism.taggedOn,
        capturedAt: filters.organism.capturedAt,
        releasedAt: filters.organism.releasedAt,
        detectedBy: filters.event.detectedBy,
      },
    },
    skip: !study || study?.dirty || currentTab !== 'redetections',
  })
  const redetectionsColumns: Components.TableColumn[] = [
    {
      name: 'pathway',
      label: 'Pathway',
      className: 'w-16 text-center',
    },
    {
      name: 'array',
      label: 'Array',
      className: 'w-32 text-center',
    },
    {
      name: 'organism',
      label: 'Individual',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-24 text-right',
    },
  ]
  const redetectionsRows: Components.TableRow[] = (listRedetections.data?.resultEvents.items || []).map((event) => ({
    id: event.id,
    organismId: event.organismId,
    pathway: <Components.StudyNodeDisplay studyNodeId={event.studyNodeId} />,
    array: <Components.AntennaNameDisplay antennaArrayId={event.fromId} />,
    organism: <Components.OrganismTagDisplay organismId={event.organismId} displayLabel={true} />,
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
  }))

  const listEntries = useQuery(api.LIST_RESULT_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        resultType: ResultType.Entry,
        datasetIds: filters.organism.datasets,
        tags: filters.organism.tags,
        tagQuery: Components.encodeLikeQuery(filters.organism.tagQuery),
        labels: filters.organism.labels,
        speciesIds: filters.organism.species,
        length: filters.organism.length,
        width: filters.organism.width,
        height: filters.organism.height,
        weight: filters.organism.weight,
        taggedOn: filters.organism.taggedOn,
        capturedAt: filters.organism.capturedAt,
        releasedAt: filters.organism.releasedAt,
        detectedBy: filters.event.detectedBy,
      },
    },
    skip: !study || study?.dirty || currentTab !== 'entries',
  })
  const entriesColumns: Components.TableColumn[] = [
    {
      name: 'pathway',
      label: 'Pathway',
      className: 'w-16 text-center',
    },
    {
      name: 'array',
      label: 'Array',
      className: 'w-32 text-center',
    },
    {
      name: 'organism',
      label: 'Individual',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-24 text-right',
    },
    // {
    //   name: 'duration',
    //   label: 'Duration',
    //   className: 'w-16 text-right',
    // },
    // {
    //   name: 'delay',
    //   label: 'Noise delay',
    //   className: 'w-16 text-right',
    // },
  ]
  const entriesRows: Components.TableRow[] = (listEntries.data?.resultEvents.items || []).map((event) => ({
    id: event.id,
    organismId: event.organismId,
    pathway: <Components.StudyNodeDisplay studyNodeId={event.studyNodeId} />,
    array: <Components.AntennaNameDisplay antennaArrayId={event.fromId} />,
    organism: <Components.OrganismTagDisplay organismId={event.organismId} displayLabel={true} />,
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    duration: event.duration && Duration.fromMillis(parseInt(event.duration) * 1000).toFormat('h:mm:ss'),
    delay: event.prefixDelay && event.suffixDelay && (
      <span>
        -{Duration.fromMillis(parseInt(event.prefixDelay) * 1000).toFormat('mm:ss')}+{Duration.fromMillis(parseInt(event.suffixDelay) * 1000).toFormat('mm:ss')}
      </span>
    ),
  }))

  const listPassages = useQuery(api.LIST_RESULT_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        resultType: ResultType.Passage,
        datasetIds: filters.organism.datasets,
        tags: filters.organism.tags,
        tagQuery: Components.encodeLikeQuery(filters.organism.tagQuery),
        labels: filters.organism.labels,
        speciesIds: filters.organism.species,
        length: filters.organism.length,
        width: filters.organism.width,
        height: filters.organism.height,
        weight: filters.organism.weight,
        taggedOn: filters.organism.taggedOn,
        capturedAt: filters.organism.capturedAt,
        releasedAt: filters.organism.releasedAt,
        detectedBy: filters.event.detectedBy,
      },
    },
    skip: !study || study?.dirty || currentTab !== 'passages',
  })
  const passageColumns: Components.TableColumn[] = [
    {
      name: 'pathway',
      label: 'Pathway',
      className: 'w-16 text-center',
    },
    {
      name: 'array',
      label: 'Array',
      className: 'w-32 text-center',
    },
    {
      name: 'organism',
      label: 'Individual',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-48 text-right',
    },
    {
      name: 'duration',
      label: 'Duration',
      className: 'w-32 text-right',
    },
    // {
    //   name: 'delay',
    //   label: 'Noise delay',
    //   className: 'w-32 text-right',
    // },
    {
      name: 'redetectionDelay',
      label: 'Re-detection delay',
      className: 'w-32 text-right',
    },
  ]
  const passageRows: Components.TableRow[] = (listPassages.data?.resultEvents.items || []).map((event) => ({
    id: event.id,
    organismId: event.organismId,
    pathway: <Components.StudyNodeDisplay studyNodeId={event.studyNodeId} />,
    array: (
      <span className="space-x-1">
        <Components.AntennaNameDisplay antennaArrayId={event.fromId} />
        <span>→</span>
        <Components.AntennaNameDisplay antennaArrayId={event.toId} />
      </span>
    ),
    organism: <Components.OrganismTagDisplay organismId={event.organismId} displayLabel={true} />,
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    duration: event.duration && Components.formatDelayMillis(event.duration),
    delay: event.prefixDelay && event.suffixDelay && (
      <span>
        -{Components.formatDelayMillis(event.prefixDelay)}+{Components.formatDelayMillis(event.suffixDelay)}
      </span>
    ),
    redetectionDelay: event.redetectionDelay && Components.formatDelayMillis(event.redetectionDelay),
  }))

  const listExits = useQuery(api.LIST_RESULT_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        resultType: ResultType.Exit,
        datasetIds: filters.organism.datasets,
        tags: filters.organism.tags,
        tagQuery: Components.encodeLikeQuery(filters.organism.tagQuery),
        labels: filters.organism.labels,
        speciesIds: filters.organism.species,
        length: filters.organism.length,
        width: filters.organism.width,
        height: filters.organism.height,
        weight: filters.organism.weight,
        taggedOn: filters.organism.taggedOn,
        capturedAt: filters.organism.capturedAt,
        releasedAt: filters.organism.releasedAt,
        detectedBy: filters.event.detectedBy,
      },
    },
    skip: !study || study?.dirty || currentTab !== 'exits',
  })
  const exitsColumns: Components.TableColumn[] = [
    {
      name: 'pathway',
      label: 'Pathway',
      className: 'w-16 text-center',
    },
    {
      name: 'array',
      label: 'Array',
      className: 'w-32 text-center',
    },
    {
      name: 'organism',
      label: 'Individual',
    },
    {
      name: 'time',
      label: 'Time',
      className: 'w-24 text-right',
    },
    // {
    //   name: 'duration',
    //   label: 'Duration',
    //   className: 'w-16 text-right',
    // },
    // {
    //   name: 'delay',
    //   label: 'Noise delay',
    //   className: 'w-16 text-right',
    // },
  ]
  const exitsRows: Components.TableRow[] = (listExits.data?.resultEvents.items || []).map((event) => ({
    id: event.id,
    organismId: event.organismId,
    pathway: <Components.StudyNodeDisplay studyNodeId={event.studyNodeId} />,
    array: <Components.AntennaNameDisplay antennaArrayId={event.fromId} />,
    organism: <Components.OrganismTagDisplay organismId={event.organismId} displayLabel={true} />,
    time: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    duration: event.duration && Duration.fromMillis(parseInt(event.duration) * 1000).toFormat('h:mm:ss'),
    delay: event.prefixDelay && event.suffixDelay && (
      <span>
        -{Duration.fromMillis(parseInt(event.prefixDelay) * 1000).toFormat('mm:ss')}+{Duration.fromMillis(parseInt(event.suffixDelay) * 1000).toFormat('mm:ss')}
      </span>
    ),
  }))

  const withParam = (key: string, value?: string): string => {
    const result = new URLSearchParams(queryParams)

    if (value !== undefined) {
      result.set(key, value)
    } else {
      result.delete(key)
    }
    return result.toString()
  }
  const tabs: Components.HeaderTab[] = [
    {
      to: { search: withParam('panel') },
      value: 'overview',
      label: 'Overview',
    },
  ]

  if (!study?.dirty) {
    if (study?.enableRedetectionRate) {
      tabs.push({
        to: { search: withParam('panel', 'redetections') },
        value: 'redetections',
        label: 'Re-detections',
      })
    }
    if (study?.enableEntryEfficiency) {
      tabs.push({
        to: { search: withParam('panel', 'entries') },
        value: 'entries',
        label: 'Entries',
      })
    }
    if (study?.enablePassageEfficiency) {
      tabs.push({
        to: { search: withParam('panel', 'passages') },
        value: 'passages',
        label: 'Passages',
      })
    }
    if (study?.enableExitEfficiency) {
      tabs.push({
        to: { search: withParam('panel', 'exits') },
        value: 'exits',
        label: 'Exits',
      })
    }
  }

  const activeFilters = currentTab !== 'overview' ? ACTIVE_FILTERS_EXT : ACTIVE_FILTERS

  const exportResultType = () => {
    switch (currentTab) {
      case 'redetections':
        return 'Redetection'
      case 'entries':
        return 'Entry'
      case 'passages':
        return 'Passage'
      case 'exits':
        return 'Exit'
    }
    return undefined
  }
  const exportUrlParams = Components.encodeStudyFilters(filters, true, { rty: exportResultType() })
  const exportUrl = `/api/csv/results/${studyId}?${exportUrlParams}`
  const actionGroups: Components.StudyFilterAction[][] = []

  if (currentTab !== 'overview') {
    actionGroups.push([
      { name: 'Set labels', onClick: () => onUpdateLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Clear labels', onClick: () => onClearLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ])
    actionGroups.push([{ name: 'Export CSV', url: exportUrl }])
  }

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Results</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {(study?.dirty || settings.allowForcedAggregation) && (
                <Components.HeaderPrimaryButton onClick={onAggregate} disabled={busy}>
                  Aggregate datasets
                </Components.HeaderPrimaryButton>
              )}
            </div>
          )}
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      {study?.dirty && (
        <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {(study?.state !== StudyState.Active || study?.deletedAt) && <p>Note: this study is archived and no result was computed prior to archival.</p>}
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <p>
              Note: study parameters, topology and/or datasets changed since last aggregation. Please click 'aggregate' button to compute latest statistics.
            </p>
          )}
        </main>
      )}
      {study && !study.dirty && (
        <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <div className="relative z-20 pb-5 mb-5 border-b border-gray-200">
            <Components.StudyFilters studyId={studyId} flags={activeFilters} pageKey="page" actionGroups={actionGroups} />
          </div>
          {currentTab === 'overview' && <Components.RadarChart chart={getSpider.data?.resultChart} filename={`results-${study?.name}`} />}
          {currentTab === 'redetections' && (
            <Components.Table
              columns={redetectionsColumns}
              rows={redetectionsRows}
              condensed
              collapsed
              page={page}
              pages={listRedetections.data?.resultEvents.pages}
              total={listRedetections.data?.resultEvents.total}
              loading={listRedetections.loading}
              onClick={onViewOrganism}
              onPage={onPage}
            />
          )}
          {currentTab === 'entries' && (
            <Components.Table
              columns={entriesColumns}
              rows={entriesRows}
              condensed
              collapsed
              page={page}
              pages={listEntries.data?.resultEvents.pages}
              total={listEntries.data?.resultEvents.total}
              loading={listEntries.loading}
              onClick={onViewOrganism}
              onPage={onPage}
            />
          )}
          {currentTab === 'passages' && (
            <Components.Table
              columns={passageColumns}
              rows={passageRows}
              condensed
              collapsed
              page={page}
              pages={listPassages.data?.resultEvents.pages}
              total={listPassages.data?.resultEvents.total}
              loading={listPassages.loading}
              onClick={onViewOrganism}
              onPage={onPage}
            />
          )}
          {currentTab === 'exits' && (
            <Components.Table
              columns={exitsColumns}
              rows={exitsRows}
              condensed
              collapsed
              page={page}
              pages={listExits.data?.resultEvents.pages}
              total={listExits.data?.resultEvents.total}
              loading={listExits.loading}
              onClick={onViewOrganism}
              onPage={onPage}
            />
          )}
        </main>
      )}
    </>
  )
}
