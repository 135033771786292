import { useState, useMemo, useEffect, useCallback, TargetedEvent, useRef } from 'react'

import { useQuery } from '@apollo/client'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'
import * as _ from 'lodash'
import { toSvg } from 'html-to-image'

import * as api from '~/api'
import { DataTable as DataTableModel, DataTableRow, DataTableColumn, Chart, ChartSerie, DataFormat, CsvDialect } from '~/graphql-codegen/graphql'

import {
  renderCSV,
  renderCSVHref,
  formatData,
  dataUrlToClipboard,
  textToClipboard,
  downloadDataUrl,
  BINARY_CLIPBOARD_AVAILABLE,
  TEXT_CLIPBOARD_AVAILABLE,
} from './helpers'
import { Spinner } from './spin'

export type TableRow = { id: any } & { [k: string]: React.ReactNode }

export type TableColumnAlign = 'left' | 'center' | 'right'

export type TableColumnRenderer = (value: any, row: TableRow) => React.ReactNode

export type TableRowStyler = (row: TableRow) => React.CSSProperties

export type TableCellStyler = (value: any, row: TableRow) => React.CSSProperties

export type TableColumn = {
  name: string
  label?: React.ReactNode
  labelText?: string
  align?: TableColumnAlign
  sortable?: boolean
  interactive?: boolean
  className?: string
  span?: number
  headerStyle?: React.CSSProperties
  rowStyle?: React.CSSProperties
  footerStyle?: React.CSSProperties
  renderer?: TableColumnRenderer
  cellStyler?: TableCellStyler
}

export type TableProps = {
  headers: TableColumn[]
  columns: TableColumn[]
  rows: TableRow[]
  footerRows: TableRow[]
  placeholder: React.ReactNode
  condensed: boolean
  collapsed: boolean
  stickyHeader: boolean
  hideFooter: boolean
  customStyle: boolean
  loading: boolean
  onClick: (row: TableRow) => void
  page: number
  pages: number
  pageSize: number
  total: number
  onPage: (page: number) => void
  sort: string
  onSort: (sort: string) => void
  filename: string
}

type LastTableProps = {
  rows: TableRow[]
  pages: number
  pageSize: number
  total: number
}

export function Table({
  headers = [],
  columns = [],
  rows = [],
  footerRows = [],
  placeholder = '-',
  condensed,
  collapsed,
  stickyHeader,
  hideFooter,
  customStyle,
  loading,
  onClick,
  page = 0,
  pages = 1,
  pageSize = rows.length,
  total = rows.length,
  onPage = () => {},
  sort,
  onSort = () => {},
  filename,
}: Partial<TableProps>) {
  const currentUser = useQuery(api.CURRENT_USER)
  const [last, setLast] = useState<LastTableProps>({ rows, pages, pageSize, total })
  const cachedPages = useMemo(() => (loading ? last.pages : pages), [loading, last, pages])
  const cachedPageSize = useMemo(() => (loading ? last.pageSize : pageSize), [loading, last, pageSize])
  const cachedTotal = useMemo(() => (loading ? last.total : total), [loading, last, total])
  const cachedRows = useMemo(
    () => (rows.length === 0 && (loading || (cachedTotal > 0 && page < cachedPages)) ? last.rows : rows),
    [loading, rows, last, page, cachedPages, cachedTotal]
  )
  const pagesBefore = _.range(0, page < 5 ? 5 : 1).filter((p) => p < cachedPages)
  const pagesBeforeLast = _.last(pagesBefore) || 0
  const pagesBetween = page >= 4 && page <= cachedPages - 5 ? _.range(page - 2, page + 3).filter((p) => p > pagesBeforeLast && p < cachedPages) : []
  const pagesBetweenLast = _.last(pagesBetween) || pagesBeforeLast
  const pagesAfter = _.range(page > cachedPages - 5 ? cachedPages - 5 : cachedPages - 1, cachedPages).filter((p) => p > pagesBetweenLast)
  const tableRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (!loading) {
      setLast({ rows, pages, pageSize, total })
    }
  }, [loading, rows, pages, pageSize, total])

  function headerClasses(col: TableColumn, i: number) {
    const items = []

    items.push(`text-${col.align || 'left'}`)
    if (condensed) {
      if (i > 0 && i < columnClasses.length - 1) {
        items.push('px-2')
      } else {
        if (i === 0) {
          items.push('pl-4')
        } else {
          items.push('pl-3')
        }
        if (i === columns.length - 1) {
          items.push('pr-4')
        } else {
          items.push('pr-3')
        }
      }
      items.push('py-3.5')
    } else {
      items.push('px-4 py-3.5')
    }
    if (!onClick) {
      if (i === 0) {
        items.push('sm:pl-0')
      }
      if (i === columns.length - 1) {
        items.push('sm:pr-0')
      }
    }
    if (col.className) {
      items.push(col.className)
    }
    return items.join(' ')
  }

  function columnClasses(col: TableColumn, i: number) {
    const items = [`text-${col.align || 'left'}`]

    if (col.interactive) {
      items.push('cursor-default')
    }
    if (condensed) {
      if (i > 0 && i < columnClasses.length - 1) {
        items.push('px-2')
      } else {
        if (i === 0) {
          items.push('pl-4')
        } else {
          items.push('pl-3')
        }
        if (i === columns.length - 1) {
          items.push('pr-4')
        } else {
          items.push('pr-3')
        }
      }
      items.push('py-2')
    } else {
      items.push('px-4 py-3.5')
    }
    if (!onClick) {
      if (i === 0) {
        items.push('sm:pl-0')
      }
      if (i === columns.length - 1) {
        items.push('sm:pr-0')
      }
    }
    if (col.className) {
      items.push(col.className)
    }
    return items.join(' ')
  }

  function footerClasses(col: TableColumn, _i: number, j: number) {
    return columnClasses(col, j)
  }

  function isDefined(value: any) {
    return value !== undefined && value !== null
  }

  const onClickInteractive = useMemo(() => {
    if (!!onClick) {
      return (ev: Event) => ev.stopPropagation()
    }
    return undefined
  }, [onClick])

  const onSortInternal = useMemo(() => {
    return (col: TableColumn) => {
      if (!!onSort) {
        if (sort === col.name) {
          onSort(`-${col.name}`)
        } else {
          onSort(col.name)
        }
      }
    }
  }, [onSort, sort])

  const toCSV = (): any[] => {
    const content = []

    if (headers.length > 0) {
      content.push(_.flatMap(headers, (header) => [header.labelText || header.label || ''].concat(_.fill(Array((header.span || 1) - 1), ''))))
    }
    content.push(columns.map((col) => col.label || ''))
    content.push(...cachedRows.map((row) => columns.map((col) => (isDefined(row[col.name]) ? row[col.name] : ''))))
    return content
  }
  const copyCSV = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()

    const csv = renderCSV(currentUser.data?.currentUser.csvDialect || CsvDialect.Standard, toCSV())

    if (csv) {
      textToClipboard(csv)
    }
  }
  const downloadCSV = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()

    const href = renderCSVHref(currentUser.data?.currentUser.csvDialect || CsvDialect.Standard, toCSV())

    if (href) {
      downloadDataUrl(href, `${filename || 'table'}.csv`)
    }
  }
  const copySVG = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (tableRef.current) {
      toSvg(tableRef.current, { fontEmbedCSS: '', backgroundColor: 'transparent', filter: (node) => !node.classList?.contains('render-hidden') }).then(
        (url) => {
          dataUrlToClipboard(url)
        }
      )
    }
  }
  const downloadSVG = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    if (tableRef.current) {
      toSvg(tableRef.current, { fontEmbedCSS: '', backgroundColor: 'transparent', filter: (node) => !node.classList?.contains('render-hidden') }).then(
        (url) => {
          downloadDataUrl(url, `${filename || 'table'}.svg`)
        }
      )
    }
  }

  function renderExports() {
    return filename ? (
      <div className="space-x-4 font-normal render-hidden">
        {TEXT_CLIPBOARD_AVAILABLE && (
          <a className="text-xs text-wa21-500" href="about:blank" onClick={copyCSV}>
            Copy CSV
          </a>
        )}
        {BINARY_CLIPBOARD_AVAILABLE && (
          <a className="text-xs text-wa21-500" href="about:blank" onClick={copySVG}>
            Copy SVG
          </a>
        )}
        <a className="text-xs text-wa21-500" href="about:blank" onClick={downloadCSV} download={filename}>
          Export CSV
        </a>
        <a className="text-xs text-wa21-500" href="about:blank" onClick={downloadSVG} download={filename}>
          Export SVG
        </a>
      </div>
    ) : null
  }

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6">
        <div className={classNames(collapsed ? (hideFooter ? '' : 'min-w-[50rem]') : 'min-w-full', 'relative inline-block py-2 align-middle sm:px-6')}>
          <table ref={tableRef} className="min-w-full border-separate border-spacing-0">
            <thead className={classNames({ 'sticky top-0 z-10 bg-white': stickyHeader })}>
              {headers.length > 0 && (
                <tr className={classNames(!customStyle && 'divide-x divide-gray-200')}>
                  {headers.map((col, i) => {
                    return (
                      <th
                        key={col.name}
                        scope="col"
                        className={classNames('border-b border-gray-200 text-sm font-semibold text-gray-900 align-top', headerClasses(col, i))}
                        style={col.headerStyle}
                        colSpan={col.span}>
                        {col.label}
                        {i === 0 && cachedTotal > 0 && !loading && renderExports()}
                      </th>
                    )
                  })}
                </tr>
              )}
              <tr className={classNames(!customStyle && 'divide-x divide-gray-200')}>
                {columns.map((col, i) => {
                  if (col.sortable) {
                    return (
                      <th
                        key={col.name}
                        scope="col"
                        className={classNames('border-b border-gray-200 text-sm font-semibold text-gray-900 align-top', headerClasses(col, i))}
                        style={col.headerStyle}>
                        <button className="group inline-flex" onClick={() => onSortInternal(col)}>
                          {col.label}
                          {(sort === `${col.name}` || sort === `-${col.name}`) && (
                            <span className="text-gray-600 ml-1 mt-1 flex-none rounded group-hover:visible group-focus:visible">
                              {sort !== `-${col.name}` && <ChevronUpIcon className="w-3 h-3" />}
                              {sort === `-${col.name}` && <ChevronDownIcon className="w-3 h-3 mt-1" />}
                            </span>
                          )}
                        </button>
                        {headers.length === 0 && i === 0 && cachedTotal > 0 && !loading && renderExports()}
                      </th>
                    )
                  }
                  return (
                    <th
                      key={col.name}
                      scope="col"
                      className={classNames('border-b border-gray-200 text-sm font-semibold text-gray-900 align-top', headerClasses(col, i))}
                      style={col.headerStyle}>
                      {col.label}
                      {headers.length === 0 && i === 0 && cachedTotal > 0 && !loading && renderExports()}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="bg-white">
              {cachedTotal === 0 && (
                <tr className={classNames(!customStyle && 'divide-x divide-gray-200')}>
                  <td
                    colSpan={columns.length}
                    className={classNames('whitespace-nowrap text-sm italic text-gray-500', condensed ? 'px-4 py-2' : 'px-4 py-3.5', onClick ? '' : 'sm:px-0')}>
                    {loading ? 'Loading...' : 'No result'}
                  </td>
                </tr>
              )}
              {cachedRows.map((row, i) => (
                <tr
                  key={i}
                  className={classNames(
                    'text-gray-900',
                    !customStyle && 'divide-x divide-gray-200',
                    !!onClick && 'cursor-pointer hover:bg-gray-50 hover:text-wa21-800'
                  )}
                  onClick={!!onClick ? () => onClick(row) : undefined}>
                  {columns.map((col, j) => (
                    <td
                      key={col.name}
                      className={classNames('border-b border-gray-200 whitespace-nowrap text-sm', columnClasses(col, j))}
                      style={col.cellStyler ? col.cellStyler(row[col.name], row) : col.rowStyle}
                      onClick={col.interactive ? onClickInteractive : undefined}>
                      {isDefined(row[col.name]) ? (col.renderer ? col.renderer(row[col.name], row) : row[col.name]) : placeholder}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {footerRows.map((row, i) => (
                <tr key={i} className={classNames('text-gray-900', !customStyle && 'divide-x divide-gray-200')}>
                  {columns.map((col, j) => (
                    <td
                      key={col.name}
                      className={classNames('whitespace-nowrap text-sm font-semibold text-gray-900', footerClasses(col, i, j))}
                      style={col.footerStyle}>
                      {isDefined(row[col.name]) ? (col.renderer ? col.renderer(row[col.name], row) : row[col.name]) : placeholder}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
          {loading && cachedTotal > 0 && (
            <div className="absolute inset-y-2 bg-gray-50 flex items-center justify-center opacity-50 sm:inset-x-6 lg:inset-x-8">
              <Spinner />
            </div>
          )}
          {!hideFooter && cachedTotal > 0 && (
            <nav className={classNames(!!onClick ? '' : 'sm:px-0', 'flex items-center justify-between px-4')}>
              <div className="-mt-px flex w-0 flex-1">
                {cachedPages === 1 && (
                  <div className="pt-3 text-gray-700 text-sm">
                    Showing <span className="font-medium">{cachedTotal}</span> items
                  </div>
                )}
                {cachedPages > 1 && (
                  <div className="pt-4 text-gray-700 text-sm">
                    Showing <span className="font-medium">{page * cachedPageSize + 1}</span> to{' '}
                    <span className="font-medium">{Math.min(cachedTotal, (page + 1) * cachedPageSize)}</span> of{' '}
                    <span className="font-medium">{cachedTotal}</span> items
                  </div>
                )}
              </div>
              {cachedPages > 1 && (
                <>
                  <div className="hidden md:-mt-px md:flex">
                    {pagesBefore.map((i) => (
                      <button
                        key={i}
                        onClick={() => onPage(i)}
                        className={classNames(
                          i === page ? 'border-wa21-500 text-wa21-600' : 'border-transparent text-gray-500',
                          'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700'
                        )}>
                        {i + 1}
                      </button>
                    ))}
                    {pagesBetween.length > 0 && pagesBetween[0] > pagesBeforeLast + 1 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
                    )}
                    {pagesBetween.map((i) => (
                      <button
                        key={i}
                        onClick={() => onPage(i)}
                        className={classNames(
                          i === page ? 'border-wa21-500 text-wa21-600' : 'border-transparent text-gray-500',
                          'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700'
                        )}>
                        {i + 1}
                      </button>
                    ))}
                    {pagesAfter.length > 0 && pagesAfter[0] > pagesBetweenLast + 1 && (
                      <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
                    )}
                    {pagesAfter.map((i) => (
                      <button
                        key={i}
                        onClick={() => onPage(i)}
                        className={classNames(
                          i === page ? 'border-wa21-500 text-wa21-600' : 'border-transparent text-gray-500',
                          'inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700'
                        )}>
                        {i + 1}
                      </button>
                    ))}
                  </div>
                  <div className="-mt-px flex w-0 flex-1 justify-end space-x-4">
                    <button
                      disabled={page === 0}
                      onClick={() => onPage(page - 1)}
                      className="group inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700 disabled:text-gray-300 disabled:border-transparent">
                      <ArrowLongLeftIcon
                        className={classNames(page === 0 ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 h-5 w-5')}
                        aria-hidden="true"
                      />
                      Previous
                    </button>
                    <button
                      disabled={page >= cachedTotal - 1}
                      onClick={() => onPage(page + 1)}
                      className="group inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:text-gray-700 disabled:text-gray-300  disabled:border-transparent">
                      Next
                      <ArrowLongRightIcon
                        className={classNames(page >= cachedTotal - 1 ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-500', 'ml-3 h-5 w-5')}
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </>
              )}
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}

export type DataTableProps = {
  table: DataTableModel
  placeholder: React.ReactNode
  condensed: boolean
  collapsed: boolean
  stickyHeader: boolean
  onClick: (row: DataTableRow) => void
  filename: string
}

export function DataTable({ table, onClick, ...props }: Partial<DataTableProps>) {
  let tableHeaders = useMemo(() => table?.header || [], [table])
  let tableColumns = useMemo(() => table?.columns || [], [table])
  let headers: TableColumn[] = useMemo(
    () =>
      tableHeaders.map((col, i) => {
        let first = i === 0
        let left = !first && col.separator
        let borderStyle = left
          ? {
              borderLeftColor: '#999',
            }
          : undefined

        return {
          name: `header-${i}`,
          label: col.description ? (
            <div>
              {col.name}
              <div className="text-xs text-gray-500 font-normal">{col.description}</div>
            </div>
          ) : (
            col.name
          ),
          labelText: col.name,
          align: col.name ? 'center' : 'left',
          span: col.span,
          headerStyle: { ...borderStyle },
        }
      }),
    [tableHeaders]
  )
  let columns: TableColumn[] = useMemo(
    () =>
      tableColumns.map((col, i) => {
        let first = i === 0
        let left = !first && col.separator
        let align: TableColumnAlign = 'left'
        let renderer: TableColumnRenderer | undefined = (value) => formatData(col.format, value, col.precision)
        let cellStyler: TableCellStyler | undefined = undefined
        let className: string = ''
        let borderStyle = left
          ? {
              borderLeftColor: '#999',
            }
          : undefined

        switch (col.format) {
          case DataFormat.Text:
            break
          case DataFormat.Integer:
            align = 'right'
            className = 'w-24'
            break
          case DataFormat.Decimal:
            align = 'right'
            className = 'w-24'
            break
          case DataFormat.Percentage:
            align = 'right'
            className = 'w-24'
            break
          case DataFormat.Duration:
            align = 'right'
            className = 'w-24'
            break
          case DataFormat.Grading:
            align = 'right'
            className = 'w-16'
            break
        }
        return {
          name: col.id,
          label: col.name.length > 0 ? col.name : '-',
          align,
          sortable: true,
          className,
          headerStyle: { ...borderStyle, borderBottomColor: '#999' },
          rowStyle: borderStyle,
          footerStyle: { ...borderStyle, borderTopWidth: '1px', borderTopColor: '#999' },
          renderer,
          cellStyler,
        }
      }),
    [tableColumns]
  )
  let [sort, setSort] = useState<{ name: string; desc: boolean; column?: DataTableColumn }>()
  let onSort = useCallback(
    (sort: string) => {
      let name = sort.startsWith('-') ? sort.substring(1) : sort
      let desc = sort.startsWith('-')
      let column = tableColumns.find((col) => col.id === name)

      setSort({ name, desc, column })
    },
    [tableColumns]
  )
  let rows = useMemo(() => {
    let data: ({ id: string } & { [k: string]: string })[] = (table?.rows || []).map((row) => ({
      id: row.id,
      ..._.fromPairs(_.zip(row.columns || columns.map((col) => col.name), row.values).filter(([, value]) => (value || '').length > 0)),
    }))

    if (sort?.column) {
      let columnId = sort.column.id
      let weight = sort.desc ? -1 : 1
      let comparator = (a: string | undefined | null, b: string | undefined | null) => {
        if (a === '' || a === undefined || a === null) {
          if (b === '' || b === undefined || b === null) {
            return 0
          }
          return 1
        } else if (b === '' || b === undefined || b === null) {
          return -1
        }
        return weight * a.localeCompare(b)
      }

      switch (sort.column.format) {
        case DataFormat.Integer:
        case DataFormat.Decimal:
        case DataFormat.Percentage:
        case DataFormat.Duration:
        case DataFormat.Grading:
          comparator = (a: string | undefined | null, b: string | undefined | null) => {
            if (a === '' || a === undefined || a === null) {
              if (b === '' || b === undefined || b === null) {
                return 0
              }
              return 1
            } else if (b === '' || b === undefined || b === null) {
              return -1
            }

            let fa = parseFloat(a)
            let fb = parseFloat(b)

            return weight * (fa < fb ? -1 : fa > fb ? 1 : 0)
          }
          break
      }
      data.sort((a, b) => comparator(a[columnId], b[columnId]))
    }
    return data
  }, [table, columns, sort])
  let footerRows = useMemo(
    () =>
      table?.footer
        ? [
            {
              id: table.footer.id,
              ..._.fromPairs(
                _.zip(table.footer.columns || columns.map((col) => col.name), table.footer.values).filter(([, value]) => (value || '').length > 0)
              ),
            },
          ]
        : [],
    [table, columns]
  )
  let onClickInternal = useMemo(
    () => (value: TableRow) => {
      if (onClick) {
        let row = table?.rows.find((other) => other.id === value.id)

        if (row) {
          onClick(row)
        }
      }
    },
    [table, onClick]
  )

  return (
    <Table
      {...props}
      headers={headers}
      columns={columns}
      rows={rows}
      footerRows={footerRows}
      loading={!table}
      hideFooter
      onClick={onClick && onClickInternal}
      sort={sort ? `${sort.desc ? '-' : ''}${sort.name}` : undefined}
      onSort={onSort}
    />
  )
}

export type DataTableFromChartProps = {
  chart: Chart
  placeholder: React.ReactNode
  condensed: boolean
  collapsed: boolean
  stickyHeader: boolean
  onClick: (serie: ChartSerie, x: any) => void
  filename: string
}

export function DataTableFromChart({ chart, onClick, ...props }: Partial<DataTableFromChartProps>) {
  let x = useMemo(() => chart?.labels || [], [chart]) // TODO: handle time-based x axis and xy plots
  let columns: TableColumn[] = useMemo(
    () =>
      [
        {
          name: 'serie',
          label: 'Serie',
          align: 'left',
          className: '',
          format: DataFormat.Text,
          sortable: true,
        },
        ...x.map((label) => ({
          name: `col-${label}`,
          label: label,
          align: 'right',
          className: 'w-24',
          format: DataFormat.Decimal,
          sortable: true,
        })),
      ] as TableColumn[],
    [x]
  )
  let [sort, setSort] = useState<{ name: string; desc: boolean }>()
  let onSort = useCallback((sort: string) => {
    let name = sort.startsWith('-') ? sort.substring(1) : sort
    let desc = sort.startsWith('-')

    setSort({ name, desc })
  }, [])
  let rows = useMemo(() => {
    let data: ({ id: string; serie: string | null } & { [k: string]: string | null | undefined })[] = (chart?.series || []).map((serie) => {
      let points: { [k: string]: string } = _.mapKeys(
        _.fromPairs(_.zip(serie.x || x, serie.y).map(([x, y]) => [x, y !== '' ? y : null])),
        (_y, x) => `col-${x}`
      )

      return {
        ...points,
        id: serie.id,
        serie: serie.name !== '' ? serie.name : null,
      }
    })

    if (sort) {
      let column = sort.name
      let weight = sort.desc ? -1 : 1
      let comparator =
        column === 'serie'
          ? (a: string | undefined | null, b: string | undefined | null) => {
              if (a === '' || a === undefined || a === null) {
                if (b === '' || b === undefined || b === null) {
                  return 0
                }
                return 1
              } else if (b === '' || b === undefined || b === null) {
                return -1
              }
              return weight * a.localeCompare(b)
            }
          : (a: string | undefined | null, b: string | undefined | null) => {
              if (a === '' || a === undefined || a === null) {
                if (b === '' || b === undefined || b === null) {
                  return 0
                }
                return 1
              } else if (b === '' || b === undefined || b === null) {
                return -1
              }

              let fa = parseFloat(a)
              let fb = parseFloat(b)

              return weight * (fa < fb ? -1 : fa > fb ? 1 : 0)
            }

      data.sort((a, b) => comparator(a[column], b[column]))
    }
    console.log(sort)
    return data
  }, [chart, x, sort])

  return (
    <Table
      {...props}
      columns={columns}
      rows={rows}
      loading={!chart}
      hideFooter
      condensed
      sort={sort ? `${sort.desc ? '-' : ''}${sort.name}` : undefined}
      onSort={onSort}
    />
  )
}

function mergeDataTableColumns(sharedColumns: string[], left: DataTableColumn[], right: DataTableColumn[]): DataTableColumn[] {
  let sharedGroup = left.filter((col) => sharedColumns.indexOf(col.id) >= 0)
  let leftGroup = left.filter((col) => sharedColumns.indexOf(col.id) < 0)
  let rightGroup = right.filter((col) => sharedColumns.indexOf(col.id) < 0)

  return [
    ...sharedGroup.map((col, i) => (i === 0 ? { ...col, separator: true } : col)),
    ...leftGroup.map((col, i) => (i === 0 ? { ...col, separator: true } : col)),
    ...rightGroup.map((col, i) => (i === 0 ? { ...col, separator: true } : col)),
  ]
}

function mergeDataTableRow(
  _sharedColumns: string[],
  leftColumns: string[],
  left: DataTableRow | null | undefined,
  rightColumns: string[],
  right: DataTableRow | null | undefined
): DataTableRow | undefined {
  if (left && right) {
    let data = { ..._.fromPairs(_.zip(left.columns || leftColumns, left.values)), ..._.fromPairs(_.zip(right.columns || rightColumns, right.values)) }

    return {
      id: `${left.id}/${right.id}`,
      columns: _.keys(data),
      values: _.values(data),
    }
  } else if (left) {
    return left
  } else if (right) {
    return right
  }
}

function mergeDataTableRows(
  sharedColumns: string[],
  leftColumns: string[],
  left: DataTableRow[],
  rightColumns: string[],
  right: DataTableRow[]
): DataTableRow[] {
  function rowKey(data: _.Dictionary<any>): string {
    return sharedColumns.map((name) => data[name]).join('/')
  }

  let leftRows = left.map((row) => {
    let data = _.fromPairs(_.zip(row.columns || leftColumns, row.values))

    return { ...row, key: rowKey(data), data }
  })
  let rightRows = right.map((row) => {
    let data = _.fromPairs(_.zip(row.columns || rightColumns, row.values))

    return { ...row, key: rowKey(data), data }
  })

  return [
    ...leftRows.map(
      (left) =>
        mergeDataTableRow(
          sharedColumns,
          leftColumns,
          left,
          rightColumns,
          rightRows.find((row) => row.key === left.key)
        ) as DataTableRow
    ),
    ...rightRows.filter((right) => !leftRows.some((row) => row.key === right.key)),
  ]
}

type MaybeDataTable = DataTableModel | null | undefined

export function mergeDataTables(tables: MaybeDataTable[], sharedColumns?: string[]): DataTableModel | undefined {
  const result = tables.reduce((left, right) => {
    if (left && right) {
      let leftColumns = left.columns.map((col) => col.id)
      let rightColumns = right.columns.map((col) => col.id)

      if (!sharedColumns) {
        sharedColumns = _.intersection(leftColumns, rightColumns)
      }

      return {
        id: `${left.id}-${right.id}`,
        columns: mergeDataTableColumns(sharedColumns, left.columns, right.columns),
        rows: mergeDataTableRows(sharedColumns, leftColumns, left.rows, rightColumns, right.rows),
        footer: mergeDataTableRow(sharedColumns, leftColumns, left.footer, rightColumns, right.footer),
      }
    } else if (left) {
      return left
    } else {
      return right
    }
  })

  return result || undefined
}
