import { useState } from 'react'
import { useNavigate, useSearchParams, Outlet } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import { DateTime } from 'luxon'

import * as api from '~/api'
import * as Components from '~/components'
import { User } from '~/graphql-codegen/graphql'
import { useAuth } from '~/session'
import { AppAction, useAppDispatch } from '~/state'

export function UsersListing() {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')
  const currentTab = searchParams.get('filter') || 'active'
  const deleted = currentTab === 'deleted'
  const active = deleted ? undefined : currentTab === 'active'

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsert() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const apollo = useApolloClient()
  const [enableUser] = useMutation(api.UPDATE_USER)
  const [deleteUser] = useMutation(api.DELETE_USER)
  const [busy, setBusy] = useState(false)

  async function onEnable(user: Partial<User>) {
    const id = user.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await enableUser({ variables: { id, fields: { active: true } } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to enable user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onDisable(user: Partial<User>) {
    const id = user.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await enableUser({ variables: { id, fields: { active: false } } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to disable user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onDelete(user: Partial<User>) {
    const id = user.id

    if (!id) {
      return
    }
    const confirm =
      !user.deletedAt ||
      (await new Promise<boolean>((resolve) => {
        dispatch({
          action: AppAction.RequestConfirmation,
          confirmation: {
            title: 'Confirmation',
            message: (
              <span>
                Are you sure you want to delete <span className="font-medium">{user.fullname || user.email}</span>?
              </span>
            ),
            resolve,
          },
        })
      }))

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteUser({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestore(user: Partial<User>) {
    const id = user.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteUser({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const listUsers = useQuery(api.LIST_USERS, { variables: { request: { page, limit: 25, deleted, active } } })
  const userColumns: Components.TableColumn[] = [
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'name',
      label: 'Name',
      className: 'w-96',
    },
    {
      name: 'role',
      label: 'Role',
      className: 'w-32 text-center',
    },
    {
      name: 'last_signin_at',
      label: 'Last signin at',
      className: 'w-32 text-center',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-40 text-right',
      interactive: true,
    },
  ]
  const userRows: Components.TableRow[] = (listUsers.data?.currentOrganization.users.items || []).map((user) => ({
    id: user.id,
    email: user.email,
    name: user.fullname,
    role: user.role,
    last_signin_at: user.lastSignInAt ? DateTime.fromSeconds(user.lastSignInAt).toLocaleString(DateTime.DATE_FULL) : 'Never',
    actions: (
      <div className="space-x-2">
        {!user.deletedAt && !user.active && (
          <button onClick={() => onEnable(user)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            Enable
          </button>
        )}
        {!user.deletedAt && user.active && user.id !== auth.current?.userId && (
          <button onClick={() => onDisable(user)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            Disable
          </button>
        )}
        {user.deletedAt && (
          <button onClick={() => onRestore(user)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            Restore
          </button>
        )}
        {user.id !== auth.current?.userId && (
          <button onClick={() => onDelete(user)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
            {!user.deletedAt ? 'Trash' : 'Delete'}
          </button>
        )}
      </div>
    ),
  }))

  const tabs: Components.HeaderTab[] = [
    {
      to: { search: '' },
      value: 'active',
      label: 'Active',
      // pill: currentTab === 'active' && listUsers.data?.organizationUsers.total,
    },
    {
      to: { search: 'filter=disabled' },
      value: 'disabled',
      label: 'Disabled',
      // pill: currentTab === 'disabled' && listUsers.data?.organizationUsers.total,
    },
    {
      to: { search: 'filter=deleted' },
      value: 'deleted',
      label: 'Trash bin',
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Users</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>
              Invite user
            </Components.HeaderPrimaryButton>
          </div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Components.Table
          columns={userColumns}
          rows={userRows}
          stickyHeader
          page={page}
          pages={listUsers.data?.currentOrganization.users.pages}
          total={listUsers.data?.currentOrganization.users.total}
          onClick={onUpdate}
          onPage={onPage}
          loading={listUsers.loading}
        />
      </main>

      <Outlet />
    </div>
  )
}
