import { useEffect, useMemo } from 'react'

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  Bars2Icon,
  Bars3Icon,
  Bars4Icon,
  MinusIcon,
  MapPinIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline'

import {
  Background,
  Controls,
  Node,
  Edge,
  DefaultEdgeOptions,
  FitViewOptions,
  NodeProps,
  Handle,
  Position,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow'

import _ from 'lodash'
import classNames from 'classnames'
import * as layout from '@msagl/core'

import { Graph as GraphModel, GraphNode, GraphNodeType } from '~/graphql-codegen/graphql'

const handleStyles = { background: '#ccc', width: '2px', height: '2px' }

export type GraphNodeProps = GraphNode & {
  onClick?: (node: GraphNode) => void
}

function GraphNodeComponent({ data, selected }: NodeProps<GraphNodeProps>) {
  let properties = _.fromPairs((data.properties || []).map((property) => [property.name, property.value]))

  switch (properties['style']) {
    case 'poi':
      return (
        <div
          title={data.name}
          className={classNames(
            selected ? 'border-blue-500' : 'border-blue-300',
            data.onClick ? 'hover:bg-gray-50 cursor-pointer' : '',
            'relative flex items-center w-28 h-8 rounded border shadow text-center text-xs text-blue-600'
          )}>
          <span className="absolute inset-y-0 left-0 flex items-center px-1 rounded-l border-r bg-gray-50 opacity-75 text-blue-600">
            <MapPinIcon className="w-4 h-4" />
          </span>
          <span className="w-full ml-6 p-1 flex-initial truncate font-medium">{data.name}</span>
        </div>
      )
    case 'upstream-pathway':
      return (
        <div
          title={data.name}
          className={classNames(
            selected ? 'border-wa21-300' : 'border-wa21-200',
            data.onClick ? 'hover:bg-gray-50 cursor-pointer' : '',
            'relative flex items-center w-24 h-8 rounded border shadow text-center text-xs text-wa21-500'
          )}>
          <span className="absolute inset-y-0 left-0 flex items-center px-1 rounded-l border-r bg-gray-50 opacity-75 text-wa21-500">
            <ArrowLongRightIcon className="w-4 h-4" />
          </span>
          <span className="w-full ml-6 p-1 flex-initial truncate font-medium">{data.name}</span>
        </div>
      )
    case 'downstream-pathway':
      return (
        <div
          title={data.name}
          className={classNames(
            selected ? 'border-wa21-300' : 'border-wa21-200',
            data.onClick ? 'hover:bg-gray-50 cursor-pointer' : '',
            'relative flex items-center w-24 h-8 rounded border shadow text-center text-xs text-wa21-500'
          )}>
          <span className="absolute inset-y-0 left-0 flex items-center px-1 rounded-l border-r bg-gray-50 opacity-75 text-wa21-500">
            <ArrowLongLeftIcon className="w-4 h-4" />
          </span>
          <span className="w-full ml-6 p-1 flex-initial truncate font-medium">{data.name}</span>
        </div>
      )
    case 'antenna':
    case 'upstream-antenna':
    case 'downstream-antenna':
      let antennas: string[] = JSON.parse(properties['antennas'] || '[]')

      return (
        <div
          title={data.name}
          className={classNames(
            selected ? 'border-wa21-secondary-400' : 'border-wa21-secondary-300',
            data.onClick ? 'hover:bg-gray-50 cursor-pointer' : '',
            'relative flex items-center w-28 h-8 rounded border shadow text-center text-xs text-wa21-secondary-600'
          )}>
          <div className="flex items-center justify-center w-4 h-8 rounded-r border border-transparent text-wa21-secondary-600">
            {properties['style'] === 'downstream-antenna' && <ArrowLeftIcon className="w-2 h-2 scale-y-150" />}
            {properties['style'] === 'upstream-antenna' && <ArrowRightIcon className="w-2 h-2 scale-y-150" />}
          </div>
          <div className="flex justify-center items-center flex-auto grow h-8 p-1 truncate text-xxs border-x">
            <div className="flex flex-col">
              <span className="font-medium truncate">{data.name}</span>
              <span className="text-xxxs truncate">{antennas.join(properties['style'] === 'downstream-antenna' ? '←' : '→')}</span>
            </div>
          </div>
          <div className="flex flex-shrink-0 items-center justify-center w-4 h-8 rounded-l border border-transparent text-wa21-secondary-600">
            {antennas.length === 1 && <MinusIcon className="w-4 h-4 rotate-90 scale-x-150" />}
            {antennas.length === 2 && <Bars2Icon className="w-4 h-4 rotate-90 scale-x-150" />}
            {antennas.length === 3 && <Bars3Icon className="w-4 h-4 rotate-90 scale-x-150" />}
            {antennas.length >= 4 && <Bars4Icon className="w-4 h-4 rotate-90 scale-x-150" />}
          </div>
        </div>
      )
  }
  return (
    <div
      className={classNames(selected ? 'border-gray-500' : 'border-gray-300', 'w-24 truncate rounded border shadow-sm p-1 text-center text-xs text-gray-500')}>
      {data.name}
    </div>
  )
}

function GraphNodeRender({ data, ...props }: NodeProps<GraphNodeProps>) {
  return (
    <>
      <GraphNodeComponent {...props} data={data} />
      {(data.nodeType === GraphNodeType.Output || data.nodeType === GraphNodeType.InputOutput) && (
        <Handle type="source" position={Position.Left} isConnectable={false} style={handleStyles} />
      )}
      {(data.nodeType === GraphNodeType.Input || data.nodeType === GraphNodeType.InputOutput) && (
        <Handle type="target" position={Position.Right} isConnectable={false} style={handleStyles} />
      )}
    </>
  )
}

export type GraphProps = {
  model?: GraphModel
  onClick?: (node: GraphNode) => void
}

const defaultNodeProps: Partial<Node> = {
  draggable: false,
  connectable: false,
  deletable: false,
}
const defaultEdgeProps: Partial<Edge> = {
  updatable: false,
  style: { stroke: '#7080ff' },
}
const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
}
const nodeTypes = { GraphNode: GraphNodeRender }

function GraphInternal({ model, onClick }: GraphProps) {
  const rf = useReactFlow()
  const positions = useMemo(() => {
    let result: { [k: string]: { x: number; y: number } } = {}

    if (model?.validLayout) {
      for (let node of model?.nodes || []) {
        result[node.id] = {
          x: node.x * 180,
          y: node.y * 40,
        }
      }
    } else {
      let g = new layout.Graph()
      let gg = new layout.GeomGraph(g)

      for (let node of model?.nodes || []) {
        let n = new layout.Node(node.id)
        let gn = new layout.GeomNode(n)

        gn.boundaryCurve = layout.CurveFactory.createRectangle(1, 1, new layout.Point(0, 0))
        g.addNode(n)
      }
      for (let edge of model?.edges || []) {
        let source = g.findNode(edge.source)
        let destination = g.findNode(edge.destination)

        if (source && destination) {
          let e = new layout.Edge(source, destination)

          new layout.GeomEdge(e)
        }
      }

      let cancel = new layout.CancelToken()
      let settings = new layout.SugiyamaLayoutSettings()

      settings.layerDirection = layout.LayerDirectionEnum.RL
      settings.MinNodeWidth = 1
      settings.MinNodeHeight = 1
      settings.GridSizeByX = 1
      settings.GridSizeByY = 1
      gg.layoutSettings = settings
      layout.layoutGraphWithSugiayma(gg, cancel, false)

      for (let node of model?.nodes || []) {
        let gn = gg.findNode(node.id)

        result[gn.id] = {
          x: gn.boundaryCurve.boundingBox.center.x * 5,
          y: gn.boundaryCurve.boundingBox.center.y * 2,
        }
      }
    }
    return result
  }, [model])
  const [nodes, edges, fitViewOptions] = useMemo(() => {
    let nodes: Node[] = (model?.nodes || []).map((node) => ({
      id: node.id,
      type: 'GraphNode',
      data: { ...node, onClick: node.nodeId !== 'downstream' && node.nodeId !== 'upstream' ? onClick : undefined },
      position: positions[node.id] || { x: node.x * 150, y: node.y * 25 },
      ...defaultNodeProps,
    }))
    let edges: Edge[] = (model?.edges || []).map((edge) => ({
      id: edge.id,
      type: 'smoothstep',
      source: edge.source,
      target: edge.destination,
      ...defaultEdgeProps,
    }))
    let fitViewOptions: FitViewOptions = {
      padding: 0.2,
      nodes: nodes,
    }
    // console.log(nodes, edges, fitViewOptions)
    return [nodes, edges, fitViewOptions]
  }, [model, positions, onClick])

  useEffect(() => {
    rf.fitView(fitViewOptions)
    setTimeout(() => rf.fitView(fitViewOptions), 100)
  }, [rf, nodes, positions, fitViewOptions])

  function onClickInternal(_ev: Event, node: Node<GraphNode>) {
    if (onClick && node.data.nodeId !== 'downstream' && node.data.nodeId !== 'upstream') {
      onClick(node.data)
    }
  }

  return (
    <ReactFlow
      id={model?.id}
      nodeOrigin={[0.0, 0.0]}
      nodeTypes={nodeTypes}
      defaultEdgeOptions={defaultEdgeOptions}
      preventScrolling={false}
      edgesUpdatable={false}
      nodesDraggable={false}
      nodesConnectable={false}
      nodesFocusable={false}
      edgesFocusable={false}
      elementsSelectable={false}
      panOnDrag={true}
      nodes={nodes}
      edges={edges}
      fitView
      fitViewOptions={fitViewOptions}
      onNodeClick={onClickInternal}>
      <Background color="#ccc" />
      <Controls showFitView={true} showInteractive={false} position="top-right" />
    </ReactFlow>
  )
}

export function Graph(props: GraphProps) {
  return (
    <ReactFlowProvider>
      <GraphInternal {...props} />
    </ReactFlowProvider>
  )
}
