import { TargetedEvent, useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import classNames from 'classnames'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import {
  DataSourceFilterOperator,
  DetectionDataset,
  DetectionEvent,
  DetectionEventSort,
  StudyState,
  UpdateDetectionDatasetMutationVariables,
  UpdateDetectionEventMutationVariables,
  UpdateDetectionEventsMutationVariables,
  UpdateOrganismScope,
  UpdateOrganismsMutationVariables,
  UpsertDetectionEventFields,
  UpsertOrganismDatasetFields,
  UpsertOrganismFields,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
    detectedByArray: true,
    detectedOn: true,
    validity: true,
  },
}

export function StudyDetectionDatasetUpdate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const datasetId = params.datasetId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const page = parseInt(searchParams.get('page') || '0')
  const sort = searchParams.get('sort') || 'dataset'
  const sortBy = (sort.startsWith('-') ? sort.substring(1) : sort).toUpperCase() as DetectionEventSort
  const sortByReverse = sort.startsWith('-')

  const apollo = useApolloClient()
  const [updateDataset] = useMutation(api.UPDATE_DETECTION_DATASET)
  const [parseDataset] = useMutation(api.PARSE_DETECTION_DATASET)
  const [updateEvent] = useMutation(api.UPDATE_DETECTION_EVENT)
  const [updateEvents] = useMutation(api.UPDATE_DETECTION_EVENTS)
  const [updateOrganisms] = useMutation(api.UPDATE_ORGANISMS)
  // const [deleteEvent] = useMutation(api.DELETE_DETECTION_EVENT)
  const [busy, setBusy] = useState(false)
  const [abort] = useState(new AbortController())

  async function onUpdate<K extends keyof DetectionDataset>(key: K, value: DetectionDataset[K]) {
    setBusy(true)
    try {
      const variables: UpdateDetectionDatasetMutationVariables = {
        id: datasetId,
        fields: {},
      }

      if (key === 'name') {
        variables.name = value
      } else {
        variables.fields[key as keyof UpsertOrganismDatasetFields] = value
      }
      await updateDataset({ variables })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update dataset.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onParse() {
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyDetectionDatasetUpdate.onParse',
        title: 'Processing dataset',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await parseDataset({ variables: { id: datasetId } })
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to parse dataset.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyDetectionDatasetUpdate.onParse' } })
      setBusy(false)
    }
  }

  async function onUpdateCSV(ev: TargetedEvent<HTMLInputElement>) {
    const file = (ev.currentTarget.files?.length || 0) > 0 ? ev.currentTarget.files?.item(0) : null

    if (!file) {
      return
    }

    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyDetectionDatasetUpdate.onImport',
        title: 'Processing dataset',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      if (file.type !== 'text/csv') {
        throw new Error(`invalid file type (${file.type})`)
      }
      if (file.size > 100 * 1024 * 1024) {
        throw new Error('file too large (max 100mb)')
      }

      const uploadResponse = await fetch('/api/store/', {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': file.type, 'X-Upload-Filename': file.name }),
        body: file,
        credentials: 'same-origin',
        cache: 'no-cache',
        signal: abort.signal,
      })

      if (!uploadResponse.ok) {
        throw new Error('file transfer error')
      }

      const store = (await uploadResponse.json()) as api.StoreResponse

      await updateDataset({
        variables: {
          id: datasetId,
          name: store.filename || 'file.csv',
          fields: { storeId: store.id },
        },
      })
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: file.name, message: `${e}`, exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyDetectionDatasetUpdate.onImport' } })
      setBusy(false)
    }
  }

  async function onUpdateEvent<K extends keyof DetectionEvent>(id: string, key: K, value: DetectionEvent[K]) {
    setBusy(true)
    try {
      const variables: UpdateDetectionEventMutationVariables = {
        id,
        fields: {},
      }

      if (key === 'triggeredBy') {
        variables.triggeredBy = value
      } else if (key === 'detectedOn') {
        variables.detectedOn = value
      } else if (key === 'detectedByArray') {
        variables.detectedByArray = value
      } else {
        variables.fields[key as keyof UpsertDetectionEventFields] = value
      }
      await updateEvent({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individual.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateEvents<K extends keyof UpsertDetectionEventFields>(key: K, value: UpsertDetectionEventFields[K]) {
    setBusy(true)
    try {
      const variables: UpdateDetectionEventsMutationVariables = {
        studyId,
        filters: [
          { key: 'event.datasetId', operator: DataSourceFilterOperator.Equal, params: [datasetId] },
          ...(Components.buildDataSourceQuery(filters).filters || []),
        ],
        fields: {},
      }

      variables.fields[key] = value
      await updateEvents({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update events.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateOrganisms<K extends keyof UpsertOrganismFields>(key: K, value: UpsertOrganismFields[K]) {
    setBusy(true)
    try {
      const mergedFilters = [
        { key: 'event.datasetId', operator: DataSourceFilterOperator.Equal, params: [datasetId] },
        ...(Components.buildDataSourceQuery(filters).filters || []),
      ]

      const variables: UpdateOrganismsMutationVariables = {
        studyId,
        filters: mergedFilters,
        fields: {},
        scope: UpdateOrganismScope.DetectionEvents,
      }

      variables.fields[key] = value
      await updateOrganisms({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individuals.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateLabels() {
    const label = await new Promise<string | number | null>((resolve) => {
      dispatch({
        action: AppAction.RequestPrompt,
        prompt: {
          title: 'New label',
          placeholder: 'Enter label...',
          resolve,
        },
      })
    })

    if (label !== null) {
      await onUpdateOrganisms('label', label as string)
    }
  }

  async function onClearLabels() {
    await onUpdateOrganisms('label', null)
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onSort(sort: string) {
    setSearchParams((prev) => {
      prev.delete('page')
      if (sort !== 'dataset') {
        prev.set('sort', sort)
      } else {
        prev.delete('sort')
      }
      return prev
    })
  }

  // function onInsertEvent() {
  //   navigate({ pathname: 'new', search: searchParams.toString() })
  // }

  function onEditEvent(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getDataset = useQuery(api.GET_DETECTION_DATASET, { variables: { id: datasetId } })
  const dataset = getDataset.data?.detectionDataset

  const listDetectionEventsFilters = Components.parseStudyFilters(searchParams)
  const listDetectionEvents = useQuery(api.LIST_DETECTION_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        sortBy,
        sortByReverse,
        datasetIds: listDetectionEventsFilters.organism.datasets,
        valid: listDetectionEventsFilters.event.validity,
        speciesIds: listDetectionEventsFilters.organism.species,
        tags: listDetectionEventsFilters.organism.tags,
        tagQuery: Components.encodeLikeQuery(listDetectionEventsFilters.organism.tagQuery),
        labels: listDetectionEventsFilters.organism.labels,
        capturedAt: listDetectionEventsFilters.organism.capturedAt,
        releasedAt: listDetectionEventsFilters.organism.releasedAt,
        detectionDatasetIds: [datasetId],
        detectedOn: listDetectionEventsFilters.event.detectedOn,
        detectedByArray: listDetectionEventsFilters.event.detectedByArray,
      },
    },
  })
  const eventColumns: Components.TableColumn[] = [
    {
      name: 'dataset',
      label: '#',
      align: 'center',
      className: 'w-20',
      sortable: true,
    },
    {
      name: 'detected_on',
      label: 'Time',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'detected_for',
      label: 'Duration (s)',
      align: 'right',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'tag',
      label: 'Individual',
      sortable: true,
    },
    {
      name: 'detected_by',
      label: 'Antenna',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'valid',
      label: 'Included',
      align: 'center',
      className: 'w-16',
    },
    {
      name: 'actions',
      label: 'Actions',
      align: 'right',
      className: 'w-24',
      interactive: true,
    },
  ]
  const eventRows: Components.TableRow[] = (listDetectionEvents.data?.detectionEvents.items || []).map((event) => ({
    id: event.id,
    dataset: event.line || event.id,
    detected_on: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    detected_for: event.detectedFor ? numeral(event.detectedFor).format('0,0.0') : undefined,
    tag: <Components.OrganismTagDisplay organismId={event.triggeredById} />,
    detected_by: <Components.AntennaNameDisplay antennaArrayId={event.detectedByArrayId} antenna={event.detectedByAntenna} />,
    valid: event.valid ? <CheckCircleIcon className="inline w-5 h-5 text-wa21-500" /> : <XCircleIcon className="inline w-5 h-5 text-wa21-danger-500" />,
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {!event.valid && (
          <button onClick={() => onUpdateEvent(event.id, 'valid', true)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Include
          </button>
        )}
        {event.valid && (
          <button onClick={() => onUpdateEvent(event.id, 'valid', false)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Exclude
          </button>
        )}
        {/*(
          <button onClick={() => onDeleteEvent(event as DetectionEvent)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Delete
          </button>
        )*/}
      </div>
    ),
  }))

  const exportUrlParams = Components.encodeStudyFilters(filters, true, { 'eds[]': datasetId })
  const exportUrl = `/api/csv/events/${studyId}?${exportUrlParams}`
  const actionGroups: Components.StudyFilterAction[][] = [
    [
      {
        name: 'Include all',
        onClick: () => onUpdateEvents('valid', true),
        disabled: study?.state !== StudyState.Active || study?.deletedAt || (dataset?.validEventCount || 0) >= (dataset?.eventCount || 0),
      },
      {
        name: 'Exclude all',
        onClick: () => onUpdateEvents('valid', false),
        disabled: study?.state !== StudyState.Active || study?.deletedAt || (dataset?.validEventCount || 0) === 0,
      },
    ],
    [
      { name: 'Set labels', onClick: () => onUpdateLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Clear labels', onClick: () => onClearLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [{ name: 'Export CSV', url: exportUrl }],
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../datasets/detections" className="hover:text-wa21-100">
                Detection events
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>{dataset?.name}</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {dataset?.store && dataset?.dirty && (
                <Components.HeaderPrimaryButton onClick={onParse} disabled={busy}>
                  Parse dataset
                </Components.HeaderPrimaryButton>
              )}
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Dataset overview</h2>
        </div>
        {!!dataset && (
          <dl className="divide-y divide-gray-200">
            {dataset?.store && (
              <Components.ReadonlyProperty
                label="CSV source file"
                name="upload"
                currentValue={
                  <>
                    <a href={`/api/store/${dataset.store.id}`} target="_blank" rel="noreferrer" className="text-wa21-600 hover:text-wa21-500">
                      {dataset.store.filename}
                    </a>
                    <span className="mx-2 text-gray-500 text-xs">({numeral(dataset.store.size).format('0.0b')})</span>
                    by {dataset.store.uploadedBy?.fullname || dataset.store.uploadedBy?.email}
                    {study?.state === StudyState.Active && !study?.deletedAt && (
                      <label
                        htmlFor="import-csv-file"
                        className="ml-4 relative rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer ">
                        <span>Update from CSV</span>
                        <input id="import-csv-file" name="import-csv-file" type="file" className="sr-only" accept=".csv,text/csv" onChange={onUpdateCSV} />
                      </label>
                    )}
                  </>
                }
              />
            )}
            <Components.TextProperty
              label="Name"
              name="name"
              currentValue={dataset?.name}
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('name', value || '')}
            />
            <Components.ReadonlyProperty label="Included rows" name="valid" currentValue={`${dataset?.validEventCount} / ${dataset?.eventCount}`} />
            {dataset?.store && (dataset?.errors?.length || 0) > 0 && (
              <Components.ReadonlyProperty
                label="Unparsable rows"
                name="errors"
                currentValue={dataset?.errors?.length || 0}
                className={classNames({ 'text-wa21-danger-500': (dataset.errors?.length || 0) > 0 })}
              />
            )}
            {(dataset?.warningCount || 0) > 0 && <Components.ReadonlyProperty label="Warnings" name="warnings" currentValue={dataset?.warningCount} />}
            {(dataset?.undefinedTags?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined tags"
                name="undefinedTags"
                currentValue={dataset?.undefinedTags}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-tags`}
              />
            )}
            {(dataset?.undefinedArrays?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined arrays"
                name="undefinedArrays"
                currentValue={dataset?.undefinedArrays}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-arrays`}
              />
            )}
          </dl>
        )}
        <div className="pt-10 pb-5 mb-2 border-b border-gray-200">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Dataset rows</h2>
        </div>
        <div className="relative z-20 pb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actionGroups={actionGroups} pageKey="page" />
        </div>
        <Components.Table
          columns={eventColumns}
          rows={eventRows}
          condensed
          stickyHeader
          page={page}
          pages={listDetectionEvents.data?.detectionEvents.pages}
          total={listDetectionEvents.data?.detectionEvents.total}
          loading={listDetectionEvents.loading}
          onClick={onEditEvent}
          onPage={onPage}
          sort={sort}
          onSort={onSort}
        />
      </main>

      <Outlet />
    </>
  )
}
