import { useState } from 'react'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { CsvDialect, UpdateUserMutationVariables, UpsertUserFields, User } from '~/graphql-codegen/graphql'

import * as Components from '~/components'
import * as api from '~/api'
import { AppAction, useAppDispatch } from '~/state'

export function UserSettings() {
  const dispatch = useAppDispatch()

  const apollo = useApolloClient()
  const [updateUser] = useMutation(api.UPDATE_USER)
  const [, setBusy] = useState(false)

  async function onUpdate<K extends keyof User>(key: K, value: User[K]) {
    setBusy(true)
    try {
      const variables: UpdateUserMutationVariables = {
        id: user?.id || '',
        fields: {},
      }

      if (key === 'email') {
        variables.email = value
      } else {
        variables.fields[key as keyof UpsertUserFields] = value
      }
      await updateUser({ variables })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const currentUser = useQuery(api.CURRENT_USER)
  const user = currentUser.data?.currentUser
  const csvDialects: Components.EnumPropertyItem[] = [
    {
      value: CsvDialect.Standard,
      label: 'Standard (",")',
    },
    {
      value: CsvDialect.Excel,
      label: 'Excel (";")',
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">User settings</h1>
          </div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        {user && (
          <dl className="divide-y divide-gray-200">
            <Components.TextProperty label="Email" name="email" currentValue={user?.email} disabled required />
            <Components.TextProperty label="Firstname" name="firstname" currentValue={user?.firstname} onUpdate={(value) => onUpdate('firstname', value)} />
            <Components.TextProperty label="Surname" name="surname" currentValue={user?.surname} onUpdate={(value) => onUpdate('surname', value)} />
            <Components.EnumProperty
              label="CSV dialect"
              name="csvDialect"
              currentValue={user?.csvDialect}
              onUpdate={(value) => onUpdate('csvDialect', value as CsvDialect)}
              items={csvDialects}
            />
          </dl>
        )}
      </main>
    </div>
  )
}
