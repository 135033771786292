import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { BlockMath } from 'react-katex'

import * as api from '~/api'
import * as Components from '~/components'
import { ResultChartDataSource, ResultTableDataSource } from '~/graphql-codegen/graphql'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
    detectedOn: true,
  },
}

export function StudyResultsPassage() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getPassageEfficiency = useQuery(api.RESULT_TABLE, {
    variables: {
      studyId,
      datasource: ResultTableDataSource.PassageEfficiency,
      query,
    },
  })

  const getLengthDistribution = useQuery(api.RESULT_CHART, {
    variables: {
      studyId,
      datasource: ResultChartDataSource.PassageEfficiencyByLength,
      query,
    },
  })

  const equation1 = `
     \\frac{n_{out}}{n_{in}}
  `
  const equation2 = `
     \\text{where} \\quad
     \\begin{array}{cl}
     n_{out} & \\text{individuals detected on any exit antenna (ie. climbed)} \\\\
     n_{in} & \\text{individuals detected on last entry antenna (ie. entered)}
     \\end{array}
  `

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../analysis/summary" className="hover:text-wa21-100">
                Results
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Passage efficiency</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="relative z-20 pb-5 mb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actions={[]} />
        </div>
        <div className="mt-10">
          <div className="border-b border-gray-200 pb-5 mb-5">
            <h2 className="text-base font-semibold leading-6 text-gray-900">Passage efficiency by species and pathway</h2>
          </div>
          <div className="px-5">
            <Components.DataTable table={getPassageEfficiency.data?.resultTable} condensed collapsed filename={`exit-${study?.name}`} />
          </div>
        </div>
        <div className="mt-10">
          <div className="border-b border-gray-200 pb-5 mb-5">
            <h2 className="text-base font-semibold leading-6 text-gray-900">Passage efficiency by length distribution and pathway</h2>
          </div>
          <Components.BarChart chart={getLengthDistribution.data?.resultChart} filename={`exit-length-${study?.name}`} y1AxisOptions={{ suggestedMax: 0.33 }} />
        </div>
        <article className="mt-5 pt-5 prose prose-sm max-w-none border-t border-gray-200">
          <p>
            Passage efficiency is the ratio of tagged individuals who successfully travel from entry to exit antenna arrays vs the individuals who at least
            entered the pathway.
          </p>
          <BlockMath>{equation1}</BlockMath>
          <BlockMath>{equation2}</BlockMath>
        </article>
      </main>
    </>
  )
}
