import { useState, useRef, useEffect, ChangeEvent } from 'react'

import _ from 'lodash'
import classNames from 'classnames'
import { Decimal } from 'decimal.js'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import { PrimaryCoordinate, Coordinates, LocalizedValue } from '~/graphql-codegen/graphql'

import { localizedMapToArray } from './localized'

type FieldAttributes<T> = {
  label?: React.ReactNode
  description?: React.ReactNode
  onUpdate?: (value: T | null) => void
}

type NumberAttributes = {
  numberFormat?: string
}

export function TextField({
  label,
  description,
  defaultValue,
  onUpdate = () => {},
  numberFormat = '0',
  ...props
}: React.HTMLAttributes<HTMLInputElement> & FieldAttributes<string | number> & NumberAttributes) {
  function onChange(ev: ChangeEvent<HTMLInputElement>) {
    const value = ev.currentTarget.value

    switch (props.type) {
      case 'date':
        onUpdate(value ? DateTime.fromFormat(value, 'yyyy-MM-dd').toSeconds().toString() : null)
        break
      case 'datetime-local':
        onUpdate(value ? DateTime.fromISO(value).toSeconds().toString() : null)
        break
      case 'number':
        onUpdate(value ? numeral(value).format(numberFormat) : null)
        break
      default:
        onUpdate(value)
        break
    }
  }

  function dateValue(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value).toISODate() : null

    return isoValue || undefined
  }

  function dateTimeValue(value: any): string | undefined {
    const isoValue = value ? DateTime.fromSeconds(value).toISO({ includeOffset: false, suppressSeconds: true, suppressMilliseconds: true }) : null

    return isoValue || undefined
  }

  function numberText(value: any): string | undefined {
    return value ? numeral(value).format(numberFormat) : undefined
  }

  function renderValue(value: any): string | undefined {
    switch (props.type) {
      case 'date':
        return dateValue(value)
      case 'datetime-local':
        return dateTimeValue(value)
      case 'number':
        return numberText(value)
      default:
        return value
    }
  }

  return (
    <div>
      {label && (
        <label htmlFor={props.name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          {...props}
          id={props.name}
          className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100"
          defaultValue={renderValue(defaultValue)}
          onChange={onChange}
        />
        {description && <p className="text-xs leading-5 text-gray-600">{description}</p>}
      </div>
    </div>
  )
}

type LocalizedFieldAttributes = {
  langs?: string[]
  items?: LocalizedValue[] | null
} & FieldAttributes<LocalizedValue[]>

export function LocalizedTextField({
  name,
  label,
  description,
  onUpdate = () => {},
  langs = ['de', 'en', 'fr', 'it'],
  items = [],
  ...props
}: React.HTMLAttributes<HTMLInputElement> & LocalizedFieldAttributes) {
  const [value, setValue] = useState<{ [k: string]: string }>({})
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const result = _.fromPairs((items || []).map((item) => [item.lang, item.value]))

    if (!_.isEqual(result, value)) {
      setValue(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  function onChange(ev: React.ChangeEvent<HTMLInputElement>, lang: string) {
    const newValue = { ...value }

    newValue[lang] = ev.currentTarget.value
    setValue(newValue)

    onUpdate(localizedMapToArray(newValue))

    if (inputRef.current) {
      inputRef.current.value = JSON.stringify(newValue)
    }
  }

  return (
    <div>
      {label && (
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input ref={inputRef} type="hidden" name={name} defaultValue={JSON.stringify(value)} />
        {langs.map((lang, i) => (
          <div key={i}>
            <div className="flex rounded-md shadow-sm">
              <span className="inline-flex w-8 justify-center uppercase items-center rounded-l-md border border-r-0 border-gray-300 px-2 text-gray-500 text-xs">
                {lang}
              </span>
              <input
                {...props}
                id={`${name}-${lang}`}
                defaultValue={_.first((items || []).filter((item) => item.lang === lang))?.value}
                className="form-input block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                onChange={(ev) => onChange(ev, lang)}
              />
            </div>
          </div>
        ))}
        {description && <p className="text-xs leading-5 text-gray-600">{description}</p>}
      </div>
    </div>
  )
}

export function TextAreaField({
  label,
  description,
  onUpdate = () => {},
  children,
  ...props
}: React.HTMLAttributes<HTMLTextAreaElement> & FieldAttributes<string>) {
  function onChange(ev: ChangeEvent<HTMLTextAreaElement>) {
    onUpdate(ev.currentTarget.value)
  }

  return (
    <div>
      {label && (
        <label htmlFor={props.id} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          {...props}
          id={props.name}
          className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100"
          onChange={onChange}>
          {children}
        </textarea>
        {description && <p className="text-xs leading-5 text-gray-600">{description}</p>}
      </div>
    </div>
  )
}

export function CheckboxField({
  label,
  description,
  name,
  onUpdate = () => {},
  defaultChecked,
  defaultValue,
  ...props
}: React.HTMLAttributes<HTMLInputElement> & FieldAttributes<string>) {
  const value = defaultChecked || defaultValue === 'true'
  const inputRef = useRef<HTMLInputElement>(null)

  function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (inputRef.current) {
      inputRef.current.value = ev.currentTarget.checked ? 'true' : 'false'
    }
  }

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input ref={inputRef} type="hidden" name={name} defaultValue={value ? 'true' : 'false'} />
        <input
          {...props}
          id={name}
          type="checkbox"
          className="form-checkbox h-4 w-4 rounded border-gray-300 text-wa21-600 focus:ring-wa21-600 disabled:text-wa21-300"
          defaultChecked={value}
          onChange={onChange}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={name} className="font-medium text-gray-900">
          {label}
        </label>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  )
}

export type EnumFieldItem = {
  value: string
  label: React.ReactNode
  description?: React.ReactNode
} & React.HTMLAttributes<HTMLInputElement>

type EnumFieldAttributes = {
  name: string
  items: EnumFieldItem[]
  required?: boolean
  allowEmpty?: boolean
  displayRadio?: number
}

export function EnumField({
  label,
  description,
  onUpdate = () => {},
  items,
  allowEmpty,
  displayRadio = 0,
  ...props
}: React.HTMLAttributes<HTMLInputElement | HTMLSelectElement> & FieldAttributes<string> & EnumFieldAttributes) {
  const [valueDescription, setValueDescription] = useState<React.ReactNode>(
    _.first(items.filter((item) => item.value === props.defaultValue || item.defaultChecked))?.description
  )

  function onSelectChange(ev: ChangeEvent<HTMLSelectElement>) {
    let item = _.first((items || []).filter((item) => item.value === ev.currentTarget.value))

    setValueDescription(item?.description)
    onUpdate(ev.currentTarget.value)
  }

  function onRadioChange(ev: ChangeEvent<HTMLInputElement>) {
    let item = _.first((items || []).filter((item) => item.value === ev.currentTarget.value))

    if (ev.currentTarget.checked) {
      setValueDescription(item?.description)
      onUpdate(ev.currentTarget.value)
    }
  }

  return (
    <fieldset>
      {label && <legend className="text-sm font-medium leading-6 text-gray-900">{label}</legend>}
      {description && <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>}
      <div className="mt-2 space-y-4">
        {items.length > displayRadio && (
          <div>
            <select
              {...(props as React.HTMLAttributes<HTMLSelectElement>)}
              id={props.name}
              defaultValue={props.defaultValue || ''}
              className="form-select block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100"
              onChange={onSelectChange}>
              {allowEmpty && <option value="">-</option>}
              {(items || []).map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {valueDescription && <p className="px-3 mt-1 text-sm text-gray-500">{valueDescription}</p>}
          </div>
        )}
        {items.length <= displayRadio &&
          items.map(({ value, label: itemLabel, description: itemDescription, ...itemProps }, i) => (
            <div key={i} className="relative flex items-start">
              <div className="absolute flex h-6 items-center">
                <input
                  {...itemProps}
                  type="radio"
                  id={`${props.name}-${value}`}
                  name={props.name}
                  defaultValue={value}
                  defaultChecked={value === props.defaultValue}
                  className="form-radio h-4 w-4 border-gray-300 text-wa21-600 focus:ring-wa21-600 disabled:text-wa21-300"
                  onChange={onRadioChange}
                />
              </div>
              <div className="pl-7 text-sm leading-6">
                <label htmlFor={`${props.name}-${value}`} className="font-medium text-gray-900">
                  {itemLabel}
                </label>
                {itemDescription && <p className="text-gray-500">{itemDescription}</p>}
              </div>
            </div>
          ))}
      </div>
    </fieldset>
  )
}

type TagFieldAttributes = {
  sorted?: boolean
  allowDuplicates?: boolean
}

export function TagsField({
  label,
  description,
  onUpdate = () => {},
  defaultValue,
  sorted,
  allowDuplicates,
  ...props
}: React.HTMLAttributes<HTMLInputElement> & FieldAttributes<string[]> & TagFieldAttributes) {
  const initialValue = defaultValue instanceof Array ? [...defaultValue] : []
  const [currentValue, setCurrentValue] = useState(initialValue)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = JSON.stringify(currentValue)
    }
  }, [currentValue])

  function onInsert(value: string) {
    if (value.length === 0) {
      return
    }

    let values = _.concat(currentValue, [value])

    if (!allowDuplicates) {
      values = _.uniq(values)
    }
    if (sorted) {
      values.sort()
    }
    setCurrentValue(values)
    onUpdate(values)
  }

  function onRemove(value: string) {
    let values = currentValue.filter((other) => other !== value)

    setCurrentValue(values)
    onUpdate(values)
  }

  function onKey(ev: KeyboardEvent) {
    if (ev.code === 'Enter') {
      ev.preventDefault()
      if (ev.currentTarget instanceof HTMLInputElement) {
        onInsert(ev.currentTarget.value.trim())
        ev.currentTarget.value = ''
      }
    }
  }

  return (
    <div>
      {label && (
        <label htmlFor={props.name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input ref={inputRef} type="hidden" name={props.name} />
        <label
          htmlFor={props.name}
          className={classNames(
            props.disabled ? 'bg-gray-100' : 'cursor-text',
            'form-input flex items-start w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100'
          )}>
          <div className="flex flex-initial flex-wrap -mb-2">
            {currentValue.map((value, i) => (
              <span
                key={i}
                className="inline-flex whitespace-nowrap items-center gap-x-0.5 rounded-md bg-gray-50 mr-2 mb-2 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {value}
                <button
                  type="button"
                  className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20 disabled:bg-transparent"
                  disabled={props.disabled}
                  onClick={() => onRemove(value)}>
                  <span className="sr-only">Remove</span>
                  <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-600/50 group-hover:stroke-gray-600/75">
                    <path d="M4 4l6 6m0-6l-6 6" />
                  </svg>
                  <span className="absolute -inset-1" />
                </button>
              </span>
            ))}
          </div>
          <input
            {...props}
            id={props.name}
            className="flex flex-auto basis-0 grow min-w-[33%] placeholder:text-gray-400 outline-none focus:ring-0 bg-transparent"
            onKeyDown={onKey}
          />
        </label>
        {description && <p className="text-xs leading-5 text-gray-600">{description}</p>}
      </div>
    </div>
  )
}

export function CoordinatesField({
  label,
  description,
  onUpdate = () => {},
  defaultValue,
  ...props
}: React.HTMLAttributes<HTMLInputElement | HTMLSelectElement> & FieldAttributes<Coordinates>) {
  const { primary, lv03, lv95, wgs84 } = (defaultValue ? JSON.parse(defaultValue?.toString()) : {}) as Coordinates
  const [mode, setMode] = useState<PrimaryCoordinate>(primary || PrimaryCoordinate.Lv95)
  const [a, setA] = useState<string>('')
  const [b, setB] = useState<string>('')
  const [c, setC] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)

  function build(): Coordinates | undefined {
    let altitude = numeral(c).value()
    let value: Coordinates = {
      primary: PrimaryCoordinate.None,
    }

    switch (mode) {
      case PrimaryCoordinate.Lv03:
        let x = numeral(a).value()
        let y = numeral(b).value()

        if (x === null || y === null) {
          return
        }
        value = {
          primary: mode,
          lv03: {
            x: x.toString(),
            y: y.toString(),
            altitude: altitude?.toString(),
          },
        }
        break
      case PrimaryCoordinate.Lv95:
        let east = numeral(a).value()
        let north = numeral(b).value()

        if (north === null || east === null) {
          return
        }
        value = {
          primary: mode,
          lv95: {
            east: east.toString(),
            north: north.toString(),
            altitude: altitude?.toString(),
          },
        }
        break
      case PrimaryCoordinate.Wgs84:
        let latitude = numeral(a).value()
        let longitude = numeral(b).value()

        if (latitude === null || longitude === null) {
          return
        }
        value = {
          primary: mode,
          wgs84: {
            latitude: latitude.toString(),
            longitude: longitude.toString(),
            altitude: altitude?.toString(),
          },
        }
        break
    }
    return value
  }

  useEffect(() => {
    function fixed(value: string | null | undefined, dp: number = 0): string {
      try {
        return value ? new Decimal(value).toDP(dp).toString() : ''
      } catch (e) {
        return ''
      }
    }

    switch (mode) {
      case PrimaryCoordinate.None:
        setA('')
        setB('')
        setC('')
        break
      case PrimaryCoordinate.Lv03:
        setA(fixed(lv03?.x))
        setB(fixed(lv03?.y))
        setC(fixed(lv03?.altitude, 2))
        break
      case PrimaryCoordinate.Lv95:
        setA(fixed(lv95?.east))
        setB(fixed(lv95?.north))
        setC(fixed(lv95?.altitude, 2))
        break
      case PrimaryCoordinate.Wgs84:
        setA(fixed(wgs84?.latitude, 6))
        setB(fixed(wgs84?.longitude, 6))
        setC(fixed(wgs84?.altitude, 2))
        break
    }

    const coordinates = build()

    if (coordinates) {
      if (inputRef.current) {
        inputRef.current.value = JSON.stringify(coordinates)
      }
      onUpdate(coordinates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  useEffect(() => {
    const coordinates = build()

    if (coordinates) {
      if (inputRef.current) {
        inputRef.current.value = JSON.stringify(coordinates)
      }
      onUpdate(coordinates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a, b, c])

  function onChange(field: 'a' | 'b' | 'c', value: string) {
    value = value.replace(',', '.').replace("'", '')
    switch (field) {
      case 'a':
        setA(value)
        break
      case 'b':
        setB(value)
        break
      case 'c':
        setC(value)
        break
    }
  }

  return (
    <div>
      {label && (
        <label htmlFor={props.name} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input ref={inputRef} type="hidden" name={props.name} />
        <div
          className={classNames(
            props.disabled ? 'bg-gray-100' : '',
            'form-input grid grid-cols-4 gap-3 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6'
          )}>
          <select
            {...(props as React.HTMLAttributes<HTMLSelectElement>)}
            ref={selectRef}
            value={mode}
            className="block w-full text-gray-900 bg-white placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500 disabled:bg-gray-100"
            onChange={(ev) => setMode(ev.currentTarget.value as PrimaryCoordinate)}>
            <option value={PrimaryCoordinate.Lv95}>LV95</option>
            <option value={PrimaryCoordinate.Lv03}>LV03</option>
            <option value={PrimaryCoordinate.Wgs84}>WGS84</option>
            <option value={PrimaryCoordinate.None}>None</option>
          </select>
          <div className="relative flex justify-center">
            <input
              {...(props as React.HTMLAttributes<HTMLInputElement>)}
              value={a}
              disabled={mode === PrimaryCoordinate.None || props.disabled}
              className="block w-full pr-6 border-b border-transparent bg-transparent text-right text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500"
              onChange={(ev) => onChange('a', ev.currentTarget.value)}
            />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-xs text-gray-400">
              {mode === PrimaryCoordinate.Lv03 && 'X'}
              {mode === PrimaryCoordinate.Lv95 && 'E'}
              {mode === PrimaryCoordinate.Wgs84 && 'LA'}
            </span>
          </div>
          <div className="relative flex justify-center">
            <input
              {...(props as React.HTMLAttributes<HTMLInputElement>)}
              value={b}
              disabled={mode === PrimaryCoordinate.None || props.disabled}
              className="block w-full pr-6 border-b border-transparent bg-transparent text-right text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500"
              onChange={(ev) => onChange('b', ev.currentTarget.value)}
            />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-xs text-gray-400">
              {mode === PrimaryCoordinate.Lv03 && 'Y'}
              {mode === PrimaryCoordinate.Lv95 && 'N'}
              {mode === PrimaryCoordinate.Wgs84 && 'LO'}
            </span>
          </div>
          <div className="relative flex justify-center">
            <input
              {...(props as React.HTMLAttributes<HTMLInputElement>)}
              value={c}
              disabled={mode === PrimaryCoordinate.None || props.disabled}
              className="block w-full pr-6 border-b border-transparent bg-transparent text-right text-xs text-gray-900 placeholder:text-gray-400 outline-none focus:ring-0 focus:border-wa21-500"
              onChange={(ev) => onChange('c', ev.currentTarget.value)}
            />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-xs text-gray-400">H</span>
          </div>
        </div>
        {description && <p className="text-xs leading-5 text-gray-600">{description}</p>}
      </div>
    </div>
  )
}
