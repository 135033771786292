import { graphql } from '~/graphql-codegen'

export type StoreResponse = {
  id: string
  size: number
  contentType: string
  filename?: string
}

export const StoreFields = graphql(`
  fragment StoreFields on Store {
    id
    uploadedBy {
      id
      email
      fullname
    }
    size
    contentType
    filename
  }
`)

export const CoordinatesFields = graphql(`
  fragment CoordinatesFields on Coordinates {
    primary
    lv03 {
      x
      y
      altitude
    }
    lv95 {
      north
      east
      altitude
    }
    wgs84 {
      latitude
      longitude
      altitude
    }
  }
`)

export const IndicatorsFields = graphql(`
  fragment IndicatorsFields on Indicators {
    id
    items {
      id
      title
      format
      precision
      values {
        value
        label
      }
    }
  }
`)

export const DataTableFields = graphql(`
  fragment DataTableFields on DataTable {
    id
    header {
      name
      description
      span
      separator
    }
    columns {
      id
      name
      format
      precision
      separator
      sortable
    }
    rows {
      id
      columns
      values
    }
    footer {
      id
      columns
      values
    }
  }
`)

export const ChartFields = graphql(`
  fragment ChartFields on Chart {
    id
    labels
    series {
      id
      name
      x
      y
      yAxis
    }
    xAxis {
      name
      unit
      format
      stacked
    }
    yAxes {
      name
      unit
      format
      stacked
    }
  }
`)

export const CategoryValueFields = graphql(`
  fragment CategoryValueFields on CategoryValue {
    id
    deletedAt
    index
    name
    localizedName {
      lang
      value
    }
    localizedDisplay
    metadata
  }
`)

export const CategoryFields = graphql(`
  fragment CategoryFields on Category {
    id
    deletedAt
    storeId
    name
    localizedName {
      lang
      value
    }
    store {
      ...StoreFields
    }

    categoryValues(request: { page: 0, limit: 0, deleted: false }) {
      items {
        ...CategoryValueFields
      }
    }
  }
`)

export const LIST_CATEGORIES = graphql(`
  query ListCategories($request: ListCategoryRequest!) {
    categories(request: $request) {
      items {
        ...CategoryFields
      }
      pages
      total
    }
  }
`)

export const GET_CATEGORY = graphql(`
  query GetCategory($id: ID!) {
    category(id: $id) {
      ...CategoryFields
    }
  }
`)

export const FIND_CATEGORY = graphql(`
  query FindCategory($name: WellKnownCategories!) {
    findCategory(name: $name) {
      ...CategoryFields
    }
  }
`)

export const FIND_CATEGORY_VALUE = graphql(`
  query FindCategoryValue($name: WellKnownCategories!, $id: ID!) {
    findCategoryValue(name: $name, id: $id) {
      ...CategoryValueFields
    }
  }
`)

export const INSERT_CATEGORY = graphql(`
  mutation InsertCategory($name: String!, $storeId: ID, $fields: UpsertCategoryFields!) {
    insertCategory(name: $name, storeId: $storeId, fields: $fields) {
      ...CategoryFields
    }
  }
`)

export const UPDATE_CATEGORY = graphql(`
  mutation UpdateCategory($id: ID!, $name: String, $storeId: ID, $fields: UpsertCategoryFields!) {
    updateCategory(id: $id, name: $name, storeId: $storeId, fields: $fields) {
      ...CategoryFields
    }
  }
`)

export const DELETE_CATEGORY = graphql(`
  mutation DeleteCategory($id: ID!, $delete: Boolean!) {
    deleteCategory(id: $id, delete: $delete) {
      ...CategoryFields
    }
  }
`)

export const GraphFields = graphql(`
  fragment GraphFields on Graph {
    id
    validLayout

    nodes {
      id
      nodeId
      nodeType
      name
      x
      y
      properties {
        name
        value
      }
    }

    edges {
      id
      source
      destination
      properties {
        name
        value
      }
    }
  }
`)

export const OrganizationFields = graphql(`
  fragment OrganizationFields on Organization {
    id
    createdAt
    deletedAt
    active
    role
    name
    street
    postcode
    city
    state
    country
    email
    phone
    website
    userCount
    studyCount
  }
`)

export const CURRENT_ORGANIZATION = graphql(`
  query CurrentOrganization {
    currentOrganization {
      ...OrganizationFields
    }
  }
`)

export const LIST_ORGANIZATIONS = graphql(`
  query ListOrganizations($request: ListOrganizationRequest!) {
    organizations(request: $request) {
      items {
        ...OrganizationFields
      }
      pages
      total
    }
  }
`)

export const GET_ORGANIZATION = graphql(`
  query GetOrganization($id: ID!) {
    organization(id: $id) {
      ...OrganizationFields
    }
  }
`)

export const INSERT_ORGANIZATION = graphql(`
  mutation InsertOrganization($name: String!, $fields: UpsertOrganizationFields!) {
    insertOrganization(name: $name, fields: $fields) {
      ...OrganizationFields
    }
  }
`)

export const UPDATE_ORGANIZATION = graphql(`
  mutation UpdateOrganization($id: ID!, $name: String, $fields: UpsertOrganizationFields!) {
    updateOrganization(id: $id, name: $name, fields: $fields) {
      ...OrganizationFields
    }
  }
`)

export const DELETE_ORGANIZATION = graphql(`
  mutation DeleteOrganization($id: ID!, $delete: Boolean!) {
    deleteOrganization(id: $id, delete: $delete) {
      ...OrganizationFields
    }
  }
`)

export const UserFields = graphql(`
  fragment UserFields on User {
    id
    createdAt
    deletedAt
    lastSignInAt
    active
    role
    email
    fullname
    firstname
    surname
    csvDialect
  }
`)

export const CURRENT_USER = graphql(`
  query CurrentUser {
    currentUser {
      ...UserFields

      organization {
        ...OrganizationFields
      }
    }
  }
`)

export const LIST_USERS = graphql(`
  query ListUsers($request: ListUserRequest!) {
    currentOrganization {
      users(request: $request) {
        items {
          ...UserFields
        }
        pages
        total
      }
    }
  }
`)

export const GET_USER = graphql(`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...UserFields
    }
  }
`)

export const INSERT_USER = graphql(`
  mutation InsertUser($organizationId: ID!, $email: String!, $fields: UpsertUserFields!) {
    insertUser(organizationId: $organizationId, email: $email, fields: $fields) {
      ...UserFields
    }
  }
`)

export const UPDATE_USER = graphql(`
  mutation UpdateUser($id: ID!, $email: String, $fields: UpsertUserFields!) {
    updateUser(id: $id, email: $email, fields: $fields) {
      ...UserFields
    }
  }
`)

export const UPDATE_USER_PASSWORD = graphql(`
  mutation UpdateUserPassword($id: ID!, $oldPassword: String, $newPassword: String) {
    updateUserPassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {
      ...UserFields
    }
  }
`)

export const DELETE_USER = graphql(`
  mutation DeleteUser($id: ID!, $delete: Boolean!) {
    deleteUser(id: $id, delete: $delete) {
      ...UserFields
    }
  }
`)

export const StudyFields = graphql(`
  fragment StudyFields on Study {
    id
    createdAt
    deletedAt
    state
    name
    validFrom
    validUntil
    waterBody
    waterBodyTypeId
    description
    location {
      ...CoordinatesFields
    }
    dirty
    studyNodeCount
    enableRedetectionRate
    enableEntryEfficiency
    enableExitEfficiency
    enablePassageEfficiency
    excludeBottomSwimmers
    validOrganismCount
    validDetectionCount
  }
`)

export const LIST_STUDIES = graphql(`
  query ListStudies($request: ListStudyRequest!) {
    currentOrganization {
      studies(request: $request) {
        items {
          ...StudyFields
        }
        pages
        total
      }
    }
  }
`)

export const GET_STUDY = graphql(`
  query GetStudy($id: ID!) {
    study(id: $id) {
      ...StudyFields
    }
  }
`)

export const INSERT_STUDY = graphql(`
  mutation InsertStudy($name: String!, $fields: UpsertStudyFields!) {
    insertStudy(name: $name, fields: $fields) {
      ...StudyFields
    }
  }
`)

export const UPDATE_STUDY = graphql(`
  mutation UpdateStudy($id: ID!, $name: String, $fields: UpsertStudyFields!) {
    updateStudy(id: $id, name: $name, fields: $fields) {
      ...StudyFields
    }
  }
`)

export const DELETE_STUDY = graphql(`
  mutation DeleteStudy($id: ID!, $delete: Boolean!) {
    deleteStudy(id: $id, delete: $delete) {
      ...StudyFields
    }
  }
`)

export const CLONE_STUDY = graphql(`
  mutation CloneStudy($id: ID!, $name: String!, $includeOrganisms: Boolean!, $includeEvents: Boolean!) {
    cloneStudy(id: $id, name: $name, includeOrganisms: $includeOrganisms, includeEvents: $includeEvents) {
      ...StudyFields
    }
  }
`)

export const StudyNodeFields = graphql(`
  fragment StudyNodeFields on StudyNode {
    id
    createdAt
    deletedAt
    nodeType
    sequence
    name
    description
    denivelation
    location {
      ...CoordinatesFields
    }
    pathwayTopologyId
    pathwayTopology {
      ...CategoryValueFields
    }
    enableRedetectionRate
    enableEntryEfficiency
    enableExitEfficiency
    enablePassageEfficiency
    antennaArrayCount
  }
`)

export const AntennaArrayFields = graphql(`
  fragment AntennaArrayFields on AntennaArray {
    id
    createdAt
    deletedAt
    studyNodeId
    name
    description
    antennaNames
    location {
      ...CoordinatesFields
    }
  }
`)

export const LIST_STUDY_NODES = graphql(`
  query ListStudyNodes($studyId: ID!, $request: ListStudyNodeRequest!) {
    studyNodes(studyId: $studyId, request: $request) {
      items {
        ...StudyNodeFields
      }
      pages
      total
    }
  }
`)

export const GET_STUDY_NODE_GRAPH = graphql(`
  query GetStudyNodeGraph($studyId: ID!) {
    studyNodeGraph(studyId: $studyId) {
      ...GraphFields
    }
  }
`)

export const GET_STUDY_NODE = graphql(`
  query GetStudyNode($id: ID!) {
    studyNode(id: $id) {
      study {
        name
      }

      ...StudyNodeFields
    }
  }
`)

export const INSERT_STUDY_NODE = graphql(`
  mutation InsertStudyNode($studyId: ID!, $nodeType: StudyNodeType!, $sequence: Int!, $name: String!, $denivelation: String!, $fields: UpsertStudyNodeFields!) {
    insertStudyNode(studyId: $studyId, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {
      ...StudyNodeFields
    }
  }
`)

export const UPDATE_STUDY_NODE = graphql(`
  mutation UpdateStudyNode($id: ID!, $nodeType: StudyNodeType, $sequence: Int, $name: String, $denivelation: String, $fields: UpsertStudyNodeFields!) {
    updateStudyNode(id: $id, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {
      ...StudyNodeFields
    }
  }
`)

export const DELETE_STUDY_NODE = graphql(`
  mutation DeleteStudyNode($id: ID!, $delete: Boolean!) {
    deleteStudyNode(id: $id, delete: $delete) {
      ...StudyNodeFields
    }
  }
`)

export const LIST_ANTENNA_ARRAYS = graphql(`
  query ListAntennaArrays($studyId: ID!, $request: ListAntennaArrayRequest!) {
    antennaArrays(studyId: $studyId, request: $request) {
      items {
        ...AntennaArrayFields
      }
      pages
      total
    }
  }
`)

export const GET_ANTENNA_ARRAY_GRAPH = graphql(`
  query GetAntennaArrayGraph($studyNodeId: ID!) {
    antennaArrayGraph(studyNodeId: $studyNodeId) {
      ...GraphFields
    }
  }
`)

export const GET_ANTENNA_ARRAY = graphql(`
  query GetAntennaArray($id: ID!) {
    antennaArray(id: $id) {
      ...AntennaArrayFields
    }
  }
`)

export const INSERT_ANTENNA_ARRAY = graphql(`
  mutation InsertAntennaArray($studyNodeId: ID!, $name: String!, $fields: UpsertAntennaArrayFields!) {
    insertAntennaArray(studyNodeId: $studyNodeId, name: $name, fields: $fields) {
      ...AntennaArrayFields
    }
  }
`)

export const UPDATE_ANTENNA_ARRAY = graphql(`
  mutation UpdateAntennaArray($id: ID!, $name: String, $fields: UpsertAntennaArrayFields!) {
    updateAntennaArray(id: $id, name: $name, fields: $fields) {
      ...AntennaArrayFields
    }
  }
`)

export const DELETE_ANTENNA_ARRAY = graphql(`
  mutation DeleteAntennaArray($id: ID!, $delete: Boolean!) {
    deleteAntennaArray(id: $id, delete: $delete) {
      ...AntennaArrayFields
    }
  }
`)

export const OrganismDatasetFields = graphql(`
  fragment OrganismDatasetFields on OrganismDataset {
    id
    createdAt
    deletedAt
    name
    state
    errors
    undefinedSpecies
    undefinedCaptureMethods
    undefinedLocations
    undefinedUsers
    validated
    validatedBy {
      email
      fullname
    }
    dirty
    store {
      ...StoreFields
    }
    organismCount
    validOrganismCount
    warningCount
  }
`)

export const LIST_ORGANISM_DATASETS = graphql(`
  query ListOrganismDatasets($studyId: ID!, $request: ListOrganismDatasetRequest!) {
    study(id: $studyId) {
      ...StudyFields

      organismDatasets(request: $request) {
        items {
          ...OrganismDatasetFields
        }
        pages
        total
      }
    }
  }
`)

export const GET_ORGANISM_DATASET = graphql(`
  query GetOrganismDataset($id: ID!) {
    organismDataset(id: $id) {
      study {
        name
      }

      ...OrganismDatasetFields
    }
  }
`)

export const INSERT_ORGANISM_DATASET = graphql(`
  mutation InsertOrganismDataset($studyId: ID!, $name: String!, $fields: UpsertOrganismDatasetFields!) {
    insertOrganismDataset(studyId: $studyId, name: $name, fields: $fields) {
      ...OrganismDatasetFields
    }
  }
`)

export const UPDATE_ORGANISM_DATASET = graphql(`
  mutation UpdateOrganismDataset($id: ID!, $name: String, $fields: UpsertOrganismDatasetFields!) {
    updateOrganismDataset(id: $id, name: $name, fields: $fields) {
      ...OrganismDatasetFields
    }
  }
`)

export const DELETE_ORGANISM_DATASET = graphql(`
  mutation DeleteOrganismDataset($id: ID!, $delete: Boolean!) {
    deleteOrganismDataset(id: $id, delete: $delete) {
      ...OrganismDatasetFields
    }
  }
`)

export const PARSE_ORGANISM_DATASET = graphql(`
  mutation ParseOrganismDataset($id: ID!) {
    parseOrganismDataset(id: $id) {
      ...OrganismDatasetFields
    }
  }
`)

export const OrganismFields = graphql(`
  fragment OrganismFields on Organism {
    id
    datasetId
    line
    warning
    valid
    edited
    speciesId
    tag
    tagType
    taggedOn
    taggedById
    capturedOn
    capturedById
    capturedAtId
    capturedWithMethodId
    capturedLocation {
      ...CoordinatesFields
    }
    releasedOn
    releasedById
    releasedAtId
    releasedLocation {
      ...CoordinatesFields
    }
    length
    width
    height
    weight
    age
    label
    description
  }
`)

export const LIST_ORGANISMS = graphql(`
  query ListOrganisms($studyId: ID!, $request: ListOrganismRequest!) {
    organisms(studyId: $studyId, request: $request) {
      items {
        ...OrganismFields
      }
      pages
      total
    }
  }
`)

export const GET_ORGANISM = graphql(`
  query GetOrganism($id: ID!) {
    organism(id: $id) {
      ...OrganismFields
    }
  }
`)

export const FIND_ORGANISM = graphql(`
  query FindOrganism($studyId: ID!, $tag: String!) {
    findOrganism(studyId: $studyId, tag: $tag) {
      ...OrganismFields
    }
  }
`)

export const INSERT_ORGANISM = graphql(`
  mutation InsertOrganism($datasetId: ID!, $tag: String!, $fields: UpsertOrganismFields!) {
    insertOrganism(datasetId: $datasetId, tag: $tag, fields: $fields) {
      ...OrganismFields
    }
  }
`)

export const UPDATE_ORGANISM = graphql(`
  mutation UpdateOrganism($id: ID!, $tag: String, $fields: UpsertOrganismFields!) {
    updateOrganism(id: $id, tag: $tag, fields: $fields) {
      ...OrganismFields
    }
  }
`)

export const UPDATE_ORGANISMS = graphql(`
  mutation UpdateOrganisms($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertOrganismFields!, $scope: UpdateOrganismScope!) {
    updateOrganisms(studyId: $studyId, filters: $filters, fields: $fields, scope: $scope)
  }
`)

export const DELETE_ORGANISM = graphql(`
  mutation DeleteOrganism($id: ID!) {
    deleteOrganism(id: $id)
  }
`)

export const DetectionDatasetFields = graphql(`
  fragment DetectionDatasetFields on DetectionDataset {
    id
    createdAt
    deletedAt
    name
    state
    errors
    undefinedTags
    undefinedArrays
    validated
    validatedBy {
      email
      fullname
    }
    dirty
    store {
      ...StoreFields
    }
    eventCount
    validEventCount
    warningCount
  }
`)

export const LIST_DETECTION_DATASETS = graphql(`
  query ListDetectionDatasets($studyId: ID!, $request: ListDetectionDatasetRequest!) {
    study(id: $studyId) {
      ...StudyFields

      detectionDatasets(request: $request) {
        items {
          ...DetectionDatasetFields
        }
        pages
        total
      }
    }
  }
`)

export const GET_DETECTION_DATASET = graphql(`
  query GetDetectionDataset($id: ID!) {
    detectionDataset(id: $id) {
      study {
        name
      }

      ...DetectionDatasetFields
    }
  }
`)

export const INSERT_DETECTION_DATASET = graphql(`
  mutation InsertDetectionDataset($studyId: ID!, $name: String!, $fields: UpsertDetectionDatasetFields!) {
    insertDetectionDataset(studyId: $studyId, name: $name, fields: $fields) {
      ...DetectionDatasetFields
    }
  }
`)

export const UPDATE_DETECTION_DATASET = graphql(`
  mutation UpdateDetectionDataset($id: ID!, $name: String, $fields: UpsertDetectionDatasetFields!) {
    updateDetectionDataset(id: $id, name: $name, fields: $fields) {
      ...DetectionDatasetFields
    }
  }
`)

export const DELETE_DETECTION_DATASET = graphql(`
  mutation DeleteDetectionDataset($id: ID!, $delete: Boolean!) {
    deleteDetectionDataset(id: $id, delete: $delete) {
      ...DetectionDatasetFields
    }
  }
`)

export const PARSE_DETECTION_DATASET = graphql(`
  mutation ParseDetectionDataset($id: ID!) {
    parseDetectionDataset(id: $id) {
      ...DetectionDatasetFields
    }
  }
`)

export const DetectionEventFields = graphql(`
  fragment DetectionEventFields on DetectionEvent {
    id
    datasetId
    line
    warning
    valid
    edited
    triggeredById
    detectedOn
    detectedFor
    detectedByArrayId
    detectedByAntenna
  }
`)

export const LIST_DETECTION_EVENTS = graphql(`
  query ListDetectionEvents($studyId: ID!, $request: ListDetectionEventRequest!) {
    detectionEvents(studyId: $studyId, request: $request) {
      items {
        ...DetectionEventFields
      }
      pages
      total
    }
  }
`)

export const GET_DETECTION_EVENT = graphql(`
  query GetDetectionEvent($id: ID!) {
    detectionEvent(id: $id) {
      ...DetectionEventFields
    }
  }
`)

export const INSERT_DETECTION_EVENT = graphql(`
  mutation InsertDetectionEvent($datasetId: ID!, $triggeredBy: ID!, $detectedOn: NaiveDateTime!, $detectedByArray: ID!, $fields: UpsertDetectionEventFields!) {
    insertDetectionEvent(datasetId: $datasetId, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {
      ...DetectionEventFields
    }
  }
`)

export const UPDATE_DETECTION_EVENT = graphql(`
  mutation UpdateDetectionEvent($id: ID!, $triggeredBy: ID, $detectedOn: NaiveDateTime, $detectedByArray: ID, $fields: UpsertDetectionEventFields!) {
    updateDetectionEvent(id: $id, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {
      ...DetectionEventFields
    }
  }
`)

export const UPDATE_DETECTION_EVENTS = graphql(`
  mutation UpdateDetectionEvents($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertDetectionEventFields!) {
    updateDetectionEvents(studyId: $studyId, filters: $filters, fields: $fields)
  }
`)

export const DELETE_DETECTION_EVENT = graphql(`
  mutation DeleteDetectionEvent($id: ID!) {
    deleteDetectionEvent(id: $id)
  }
`)

export const AGGREGATE_STUDY = graphql(`
  mutation AggregateStudy($id: ID!) {
    aggregateStudy(id: $id)
  }
`)

export const ResultEventFields = graphql(`
  fragment ResultEventFields on ResultEvent {
    id
    resultType
    organismId
    studyNodeId
    fromId
    toId
    detectedOn
    timeOfDay
    duration
    prefixDelay
    suffixDelay
    redetectionDelay
  }
`)

export const LIST_RESULT_EVENTS = graphql(`
  query ListResultEvents($studyId: ID!, $request: ListResultEventRequest!) {
    resultEvents(studyId: $studyId, request: $request) {
      items {
        ...ResultEventFields
      }
      pages
      total
    }
  }
`)

export const FIND_STUDY_SPECIES = graphql(`
  query FindStudySpecies($studyId: ID!) {
    studySpecies(studyId: $studyId) {
      ...CategoryValueFields
    }
  }
`)

export const FIND_STUDY_CAPTURE_LOCATIONS = graphql(`
  query FindStudyCaptureLocations($studyId: ID!) {
    studyCaptureLocations(studyId: $studyId) {
      ...StudyNodeFields
    }
  }
`)

export const FIND_STUDY_RELEASE_LOCATIONS = graphql(`
  query FindStudyReleaseLocations($studyId: ID!) {
    studyReleaseLocations(studyId: $studyId) {
      ...StudyNodeFields
    }
  }
`)

export const FIND_STUDY_LABELS = graphql(`
  query FindStudyLabels($studyId: ID!) {
    studyLabels(studyId: $studyId)
  }
`)

export const ORGANISM_TABLE = graphql(`
  query OrganismDataTable($studyId: ID!, $datasource: OrganismTableDataSource!, $query: DataSourceQuery) {
    organismTable(studyId: $studyId, datasource: $datasource, query: $query) {
      ...DataTableFields
    }
  }
`)

export const ORGANISM_CHART = graphql(`
  query OrganismChart($studyId: ID!, $datasource: OrganismChartDataSource!, $query: DataSourceQuery) {
    organismChart(studyId: $studyId, datasource: $datasource, query: $query) {
      ...ChartFields
    }
  }
`)

export const DETECTION_EVENT_CHART = graphql(`
  query DetectionEventChart($studyId: ID!, $datasource: DetectionEventChartDataSource!, $query: DataSourceQuery) {
    detectionEventChart(studyId: $studyId, datasource: $datasource, query: $query) {
      ...ChartFields
    }
  }
`)

export const RESULT_TABLE = graphql(`
  query ResultDataTable($studyId: ID!, $datasource: ResultTableDataSource!, $query: DataSourceQuery) {
    resultTable(studyId: $studyId, datasource: $datasource, query: $query) {
      ...DataTableFields
    }
  }
`)

export const RESULT_CHART = graphql(`
  query ResultChart($studyId: ID!, $datasource: ResultChartDataSource!, $query: DataSourceQuery) {
    resultChart(studyId: $studyId, datasource: $datasource, query: $query) {
      ...ChartFields
    }
  }
`)

export const EVALUATION_INDICATORS = graphql(`
  query EvaluationIndicators($studyId: ID!, $datasource: EvaluationIndicatorsDataSource!, $query: DataSourceQuery) {
    evaluationIndicators(studyId: $studyId, datasource: $datasource, query: $query) {
      ...IndicatorsFields
    }
  }
`)

export const EVALUATION_TABLE = graphql(`
  query EvaluationDataTable($studyId: ID!, $datasource: EvaluationTableDataSource!, $query: DataSourceQuery) {
    evaluationTable(studyId: $studyId, datasource: $datasource, query: $query) {
      ...DataTableFields
    }
  }
`)

export const MARK_ALL_STUDIES = graphql(`
  mutation MarkAllStudies($aggregate: Boolean) {
    markStudies(aggregate: $aggregate) {
      studyId
      success
    }
  }
`)

export const CATEGORY_QUERIES = [LIST_CATEGORIES, GET_CATEGORY, FIND_CATEGORY, FIND_CATEGORY_VALUE]

export const ORGANIZATION_QUERIES = [CURRENT_ORGANIZATION, LIST_ORGANIZATIONS, GET_ORGANIZATION, CURRENT_USER, LIST_USERS, GET_USER]

export const STUDY_QUERIES = [
  LIST_STUDIES,
  GET_STUDY,
  LIST_STUDY_NODES,
  GET_STUDY_NODE_GRAPH,
  GET_STUDY_NODE,
  LIST_ANTENNA_ARRAYS,
  GET_ANTENNA_ARRAY_GRAPH,
  GET_ANTENNA_ARRAY,
]

export const STUDY_FILTER_QUERIES = [FIND_STUDY_SPECIES, FIND_STUDY_LABELS, FIND_STUDY_CAPTURE_LOCATIONS, FIND_STUDY_RELEASE_LOCATIONS]

export const ORGANISM_QUERIES = [LIST_ORGANISM_DATASETS, GET_ORGANISM_DATASET, LIST_ORGANISMS, GET_ORGANISM, FIND_ORGANISM, ORGANISM_TABLE, ORGANISM_CHART]

export const EVENT_QUERIES = [LIST_DETECTION_DATASETS, GET_DETECTION_DATASET, LIST_DETECTION_EVENTS, GET_DETECTION_EVENT, DETECTION_EVENT_CHART]

export const RESULT_QUERIES = [LIST_RESULT_EVENTS, RESULT_TABLE, RESULT_CHART]

export const EVALUATION_QUERIES = [EVALUATION_INDICATORS, EVALUATION_TABLE]

export const ALL_QUERIES = [
  ...CATEGORY_QUERIES,
  ...ORGANIZATION_QUERIES,
  ...STUDY_QUERIES,
  ...STUDY_FILTER_QUERIES,
  ...ORGANISM_QUERIES,
  ...EVENT_QUERIES,
  ...RESULT_QUERIES,
  ...EVALUATION_QUERIES,
]

export const ALL_STUDY_QUERIES = [...STUDY_QUERIES, ...STUDY_FILTER_QUERIES, ...ORGANISM_QUERIES, ...EVENT_QUERIES, ...RESULT_QUERIES, ...EVALUATION_QUERIES]

export const ALL_ORGANISM_QUERIES = [
  LIST_STUDIES,
  GET_STUDY,
  ...STUDY_FILTER_QUERIES,
  ...ORGANISM_QUERIES,
  ...EVENT_QUERIES,
  ...RESULT_QUERIES,
  ...EVALUATION_QUERIES,
]

export const ALL_EVENT_QUERIES = [LIST_STUDIES, GET_STUDY, ...EVENT_QUERIES, ...RESULT_QUERIES, ...EVALUATION_QUERIES]

export const ALL_RESULT_QUERIES = [LIST_STUDIES, GET_STUDY, ...RESULT_QUERIES, ...EVALUATION_QUERIES]
