import { Fragment, useState } from 'react'
import { Outlet, NavLink, Link, useSubmit } from 'react-router-dom'
import classNames from 'classnames'

import { useApolloClient, useQuery } from '@apollo/client'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, EllipsisVerticalIcon, XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/outline'

import * as api from '~/api'
import { OrganizationRole, UserRole } from '~/graphql-codegen/graphql'
import { useAppState, useAppDispatch, AppAction } from '~/state'

export function Layout() {
  const submit = useSubmit()
  const appState = useAppState()
  const appDispatch = useAppDispatch()
  const [refresh, setRefresh] = useState(false)

  const currentUser = useQuery(api.CURRENT_USER)
  const currentUserRole = currentUser.data?.currentUser.role
  const currentUserName = currentUser.data?.currentUser?.fullname || currentUser.data?.currentUser?.email
  const currentOrganizationRole = currentUser.data?.currentUser?.organization?.role
  const currentOrganizationName = currentUser.data?.currentUser?.organization?.name

  const apollo = useApolloClient()

  async function onRefresh() {
    setRefresh(true)
    try {
      await apollo.refetchQueries({ include: 'active' })
    } catch (e) {
      appDispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to refresh screen.', exception: e },
      })
    } finally {
      setRefresh(false)
    }
  }

  function onSignOut() {
    submit(null, { action: '/sign-out', method: 'delete' })
  }

  const items = [
    {
      name: 'Studies',
      to: './studies',
    },
  ]

  if (currentUserRole === UserRole.Admin) {
    items.push({
      name: 'Users',
      to: './users',
    })
  }
  if (currentOrganizationRole === OrganizationRole.SuperAdmin) {
    items.push({
      name: 'Organizations',
      to: './organizations',
    })
    items.push({
      name: 'Categories',
      to: './categories',
    })
  }

  return (
    <div className="min-h-full">
      <div className="bg-wa21-header pb-64">
        <Disclosure>
          {({ open }) => (
            <div className="relative z-30 border-b border-wa21-200 border-opacity-25 bg-wa21-menu lg:border-none shadow">
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-wa21-200 lg:border-opacity-25">
                  <div className="flex items-center px-2 lg:px-0">
                    {appState.subMenu && (
                      <div className="flex mr-4 lg:hidden">
                        <button
                          className="inline-flex items-center justify-center rounded-md bg-wa21-600 p-2 text-wa21-200 hover:bg-wa21-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-wa21-600"
                          onClick={() => appDispatch({ action: AppAction.ShowSubMenu })}>
                          <span className="sr-only">Open main menu</span>
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    )}
                    <div className="flex-shrink-0">
                      <Link to="/" className="font-title text-2xl">
                        PIT WebTool
                      </Link>
                    </div>
                    <div className="hidden lg:ml-10 lg:block">
                      <div className="flex space-x-4">
                        {items.map((item, i) => (
                          <NavLink
                            key={i}
                            className={({ isActive }) =>
                              classNames(
                                isActive ? 'bg-wa21-800 text-white' : 'text-wa21-800 hover:border-wa21-800',
                                'rounded-md border border-transparent py-2 px-3 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-600'
                              )
                            }
                            to={item.to}>
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                    <p className="text-sm text-wa21-800" title={`${currentOrganizationName} (${currentUserName})`}>
                      {currentOrganizationName}
                    </p>
                  </div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 border border-transparent text-wa21-600 hover:border-wa21-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-wa21-600">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <EllipsisVerticalIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:ml-4 lg:block">
                    <div className="flex items-center">
                      {/* Profile dropdown */}
                      <Menu>
                        <div className="relative ml-3 flex-shrink-0">
                          <div>
                            <Menu.Button className="flex rounded-full border border-white bg-wa21-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-wa21-600">
                              <span className="sr-only">Open user menu</span>
                              <UserIcon className="h-6 w-6" aria-hidden="true" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95">
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                              <div className="px-4 py-2 text-sm text-gray-500 truncate" title={currentUserName}>
                                {currentUserName}
                              </div>
                              <div>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link className={classNames({ 'bg-wa21-100': active }, 'block py-2 px-4 text-sm text-wa21-800')} to="/app/settings/user">
                                      User settings
                                    </Link>
                                  )}
                                </Menu.Item>
                                {currentUserRole === UserRole.Admin && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link
                                        className={classNames({ 'bg-wa21-100': active }, 'block py-2 px-4 text-sm text-wa21-800')}
                                        to="/app/settings/organization">
                                        Organisation settings
                                      </Link>
                                    )}
                                  </Menu.Item>
                                )}
                              </div>
                              <div>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      className={classNames({ 'bg-wa21-100': active }, 'block py-2 px-4 text-sm text-wa21-800')}
                                      to="/app/settings/user/change-password">
                                      Change password
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                              <div>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames({ 'bg-wa21-100': active }, 'block w-full text-left py-2 px-4 text-sm text-wa21-800')}
                                      onClick={onSignOut}>
                                      Sign out
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </div>
                      </Menu>
                    </div>
                  </div>
                  <div className="flex">
                    {/* Mobile menu button */}
                    <button
                      className="inline-flex items-center justify-center ml-2 p-2 border border-transparent text-wa21-600 hover:text-wa21-800 focus:outline-none"
                      onClick={onRefresh}>
                      <span className="sr-only">Refresh</span>
                      <ArrowPathIcon className={classNames({ 'animate-spin': refresh }, 'block h-6 w-6')} aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {items.map((item, i) => (
                    <NavLink
                      key={i}
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-wa21-800 text-white' : 'text-wa21-800 hover:text-white hover:bg-wa21-500 hover:bg-opacity-75',
                          'block rounded-md py-2 px-3 text-base font-medium'
                        )
                      }
                      to={item.to}>
                      {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="border-t border-wa21-300 pt-4 pb-3">
                  <div className="flex items-center px-5">
                    <div className="text-sm font-medium text-gray-500 truncate" title={currentUserName}>
                      {currentUserName}
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    <NavLink
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-wa21-800 text-white' : 'text-wa21-800 hover:text-white hover:bg-wa21-500 hover:bg-opacity-75',
                          'block rounded-md py-2 px-3 text-base font-medium'
                        )
                      }
                      to="/app/settings/user">
                      User settings
                    </NavLink>
                    {currentUserRole === UserRole.Admin && (
                      <NavLink
                        className={({ isActive }) =>
                          classNames(
                            isActive ? 'bg-wa21-800 text-white' : 'text-wa21-800 hover:text-white hover:bg-wa21-500 hover:bg-opacity-75',
                            'block rounded-md py-2 px-3 text-base font-medium'
                          )
                        }
                        to="/app/settings/organization">
                        Organisation settings
                      </NavLink>
                    )}
                    <NavLink
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-wa21-800 text-white' : 'text-wa21-800 hover:text-white hover:bg-wa21-500 hover:bg-opacity-75',
                          'block rounded-md py-2 px-3 text-base font-medium'
                        )
                      }
                      to="/app/settings/user/change-password">
                      Change password
                    </NavLink>
                    <button
                      className="block rounded-md w-full py-2 px-3 text-left text-base font-medium text-wa21-800 hover:text-white hover:bg-wa21-500 hover:bg-opacity-75"
                      onClick={onSignOut}>
                      Sign out
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      </div>

      <main className="-mt-64">
        <Outlet />
      </main>
    </div>
  )
}
