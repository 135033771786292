/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment StoreFields on Store {\n    id\n    uploadedBy {\n      id\n      email\n      fullname\n    }\n    size\n    contentType\n    filename\n  }\n": types.StoreFieldsFragmentDoc,
    "\n  fragment CoordinatesFields on Coordinates {\n    primary\n    lv03 {\n      x\n      y\n      altitude\n    }\n    lv95 {\n      north\n      east\n      altitude\n    }\n    wgs84 {\n      latitude\n      longitude\n      altitude\n    }\n  }\n": types.CoordinatesFieldsFragmentDoc,
    "\n  fragment IndicatorsFields on Indicators {\n    id\n    items {\n      id\n      title\n      format\n      precision\n      values {\n        value\n        label\n      }\n    }\n  }\n": types.IndicatorsFieldsFragmentDoc,
    "\n  fragment DataTableFields on DataTable {\n    id\n    header {\n      name\n      description\n      span\n      separator\n    }\n    columns {\n      id\n      name\n      format\n      precision\n      separator\n      sortable\n    }\n    rows {\n      id\n      columns\n      values\n    }\n    footer {\n      id\n      columns\n      values\n    }\n  }\n": types.DataTableFieldsFragmentDoc,
    "\n  fragment ChartFields on Chart {\n    id\n    labels\n    series {\n      id\n      name\n      x\n      y\n      yAxis\n    }\n    xAxis {\n      name\n      unit\n      format\n      stacked\n    }\n    yAxes {\n      name\n      unit\n      format\n      stacked\n    }\n  }\n": types.ChartFieldsFragmentDoc,
    "\n  fragment CategoryValueFields on CategoryValue {\n    id\n    deletedAt\n    index\n    name\n    localizedName {\n      lang\n      value\n    }\n    localizedDisplay\n    metadata\n  }\n": types.CategoryValueFieldsFragmentDoc,
    "\n  fragment CategoryFields on Category {\n    id\n    deletedAt\n    storeId\n    name\n    localizedName {\n      lang\n      value\n    }\n    store {\n      ...StoreFields\n    }\n\n    categoryValues(request: { page: 0, limit: 0, deleted: false }) {\n      items {\n        ...CategoryValueFields\n      }\n    }\n  }\n": types.CategoryFieldsFragmentDoc,
    "\n  query ListCategories($request: ListCategoryRequest!) {\n    categories(request: $request) {\n      items {\n        ...CategoryFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListCategoriesDocument,
    "\n  query GetCategory($id: ID!) {\n    category(id: $id) {\n      ...CategoryFields\n    }\n  }\n": types.GetCategoryDocument,
    "\n  query FindCategory($name: WellKnownCategories!) {\n    findCategory(name: $name) {\n      ...CategoryFields\n    }\n  }\n": types.FindCategoryDocument,
    "\n  query FindCategoryValue($name: WellKnownCategories!, $id: ID!) {\n    findCategoryValue(name: $name, id: $id) {\n      ...CategoryValueFields\n    }\n  }\n": types.FindCategoryValueDocument,
    "\n  mutation InsertCategory($name: String!, $storeId: ID, $fields: UpsertCategoryFields!) {\n    insertCategory(name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n": types.InsertCategoryDocument,
    "\n  mutation UpdateCategory($id: ID!, $name: String, $storeId: ID, $fields: UpsertCategoryFields!) {\n    updateCategory(id: $id, name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n": types.UpdateCategoryDocument,
    "\n  mutation DeleteCategory($id: ID!, $delete: Boolean!) {\n    deleteCategory(id: $id, delete: $delete) {\n      ...CategoryFields\n    }\n  }\n": types.DeleteCategoryDocument,
    "\n  fragment GraphFields on Graph {\n    id\n    validLayout\n\n    nodes {\n      id\n      nodeId\n      nodeType\n      name\n      x\n      y\n      properties {\n        name\n        value\n      }\n    }\n\n    edges {\n      id\n      source\n      destination\n      properties {\n        name\n        value\n      }\n    }\n  }\n": types.GraphFieldsFragmentDoc,
    "\n  fragment OrganizationFields on Organization {\n    id\n    createdAt\n    deletedAt\n    active\n    role\n    name\n    street\n    postcode\n    city\n    state\n    country\n    email\n    phone\n    website\n    userCount\n    studyCount\n  }\n": types.OrganizationFieldsFragmentDoc,
    "\n  query CurrentOrganization {\n    currentOrganization {\n      ...OrganizationFields\n    }\n  }\n": types.CurrentOrganizationDocument,
    "\n  query ListOrganizations($request: ListOrganizationRequest!) {\n    organizations(request: $request) {\n      items {\n        ...OrganizationFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListOrganizationsDocument,
    "\n  query GetOrganization($id: ID!) {\n    organization(id: $id) {\n      ...OrganizationFields\n    }\n  }\n": types.GetOrganizationDocument,
    "\n  mutation InsertOrganization($name: String!, $fields: UpsertOrganizationFields!) {\n    insertOrganization(name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n": types.InsertOrganizationDocument,
    "\n  mutation UpdateOrganization($id: ID!, $name: String, $fields: UpsertOrganizationFields!) {\n    updateOrganization(id: $id, name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n": types.UpdateOrganizationDocument,
    "\n  mutation DeleteOrganization($id: ID!, $delete: Boolean!) {\n    deleteOrganization(id: $id, delete: $delete) {\n      ...OrganizationFields\n    }\n  }\n": types.DeleteOrganizationDocument,
    "\n  fragment UserFields on User {\n    id\n    createdAt\n    deletedAt\n    lastSignInAt\n    active\n    role\n    email\n    fullname\n    firstname\n    surname\n    csvDialect\n  }\n": types.UserFieldsFragmentDoc,
    "\n  query CurrentUser {\n    currentUser {\n      ...UserFields\n\n      organization {\n        ...OrganizationFields\n      }\n    }\n  }\n": types.CurrentUserDocument,
    "\n  query ListUsers($request: ListUserRequest!) {\n    currentOrganization {\n      users(request: $request) {\n        items {\n          ...UserFields\n        }\n        pages\n        total\n      }\n    }\n  }\n": types.ListUsersDocument,
    "\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...UserFields\n    }\n  }\n": types.GetUserDocument,
    "\n  mutation InsertUser($organizationId: ID!, $email: String!, $fields: UpsertUserFields!) {\n    insertUser(organizationId: $organizationId, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n": types.InsertUserDocument,
    "\n  mutation UpdateUser($id: ID!, $email: String, $fields: UpsertUserFields!) {\n    updateUser(id: $id, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation UpdateUserPassword($id: ID!, $oldPassword: String, $newPassword: String) {\n    updateUserPassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {\n      ...UserFields\n    }\n  }\n": types.UpdateUserPasswordDocument,
    "\n  mutation DeleteUser($id: ID!, $delete: Boolean!) {\n    deleteUser(id: $id, delete: $delete) {\n      ...UserFields\n    }\n  }\n": types.DeleteUserDocument,
    "\n  fragment StudyFields on Study {\n    id\n    createdAt\n    deletedAt\n    state\n    name\n    validFrom\n    validUntil\n    waterBody\n    waterBodyTypeId\n    description\n    location {\n      ...CoordinatesFields\n    }\n    dirty\n    studyNodeCount\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    excludeBottomSwimmers\n    validOrganismCount\n    validDetectionCount\n  }\n": types.StudyFieldsFragmentDoc,
    "\n  query ListStudies($request: ListStudyRequest!) {\n    currentOrganization {\n      studies(request: $request) {\n        items {\n          ...StudyFields\n        }\n        pages\n        total\n      }\n    }\n  }\n": types.ListStudiesDocument,
    "\n  query GetStudy($id: ID!) {\n    study(id: $id) {\n      ...StudyFields\n    }\n  }\n": types.GetStudyDocument,
    "\n  mutation InsertStudy($name: String!, $fields: UpsertStudyFields!) {\n    insertStudy(name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n": types.InsertStudyDocument,
    "\n  mutation UpdateStudy($id: ID!, $name: String, $fields: UpsertStudyFields!) {\n    updateStudy(id: $id, name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n": types.UpdateStudyDocument,
    "\n  mutation DeleteStudy($id: ID!, $delete: Boolean!) {\n    deleteStudy(id: $id, delete: $delete) {\n      ...StudyFields\n    }\n  }\n": types.DeleteStudyDocument,
    "\n  mutation CloneStudy($id: ID!, $name: String!, $includeOrganisms: Boolean!, $includeEvents: Boolean!) {\n    cloneStudy(id: $id, name: $name, includeOrganisms: $includeOrganisms, includeEvents: $includeEvents) {\n      ...StudyFields\n    }\n  }\n": types.CloneStudyDocument,
    "\n  fragment StudyNodeFields on StudyNode {\n    id\n    createdAt\n    deletedAt\n    nodeType\n    sequence\n    name\n    description\n    denivelation\n    location {\n      ...CoordinatesFields\n    }\n    pathwayTopologyId\n    pathwayTopology {\n      ...CategoryValueFields\n    }\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    antennaArrayCount\n  }\n": types.StudyNodeFieldsFragmentDoc,
    "\n  fragment AntennaArrayFields on AntennaArray {\n    id\n    createdAt\n    deletedAt\n    studyNodeId\n    name\n    description\n    antennaNames\n    location {\n      ...CoordinatesFields\n    }\n  }\n": types.AntennaArrayFieldsFragmentDoc,
    "\n  query ListStudyNodes($studyId: ID!, $request: ListStudyNodeRequest!) {\n    studyNodes(studyId: $studyId, request: $request) {\n      items {\n        ...StudyNodeFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListStudyNodesDocument,
    "\n  query GetStudyNodeGraph($studyId: ID!) {\n    studyNodeGraph(studyId: $studyId) {\n      ...GraphFields\n    }\n  }\n": types.GetStudyNodeGraphDocument,
    "\n  query GetStudyNode($id: ID!) {\n    studyNode(id: $id) {\n      study {\n        name\n      }\n\n      ...StudyNodeFields\n    }\n  }\n": types.GetStudyNodeDocument,
    "\n  mutation InsertStudyNode($studyId: ID!, $nodeType: StudyNodeType!, $sequence: Int!, $name: String!, $denivelation: String!, $fields: UpsertStudyNodeFields!) {\n    insertStudyNode(studyId: $studyId, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n": types.InsertStudyNodeDocument,
    "\n  mutation UpdateStudyNode($id: ID!, $nodeType: StudyNodeType, $sequence: Int, $name: String, $denivelation: String, $fields: UpsertStudyNodeFields!) {\n    updateStudyNode(id: $id, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n": types.UpdateStudyNodeDocument,
    "\n  mutation DeleteStudyNode($id: ID!, $delete: Boolean!) {\n    deleteStudyNode(id: $id, delete: $delete) {\n      ...StudyNodeFields\n    }\n  }\n": types.DeleteStudyNodeDocument,
    "\n  query ListAntennaArrays($studyId: ID!, $request: ListAntennaArrayRequest!) {\n    antennaArrays(studyId: $studyId, request: $request) {\n      items {\n        ...AntennaArrayFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListAntennaArraysDocument,
    "\n  query GetAntennaArrayGraph($studyNodeId: ID!) {\n    antennaArrayGraph(studyNodeId: $studyNodeId) {\n      ...GraphFields\n    }\n  }\n": types.GetAntennaArrayGraphDocument,
    "\n  query GetAntennaArray($id: ID!) {\n    antennaArray(id: $id) {\n      ...AntennaArrayFields\n    }\n  }\n": types.GetAntennaArrayDocument,
    "\n  mutation InsertAntennaArray($studyNodeId: ID!, $name: String!, $fields: UpsertAntennaArrayFields!) {\n    insertAntennaArray(studyNodeId: $studyNodeId, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n": types.InsertAntennaArrayDocument,
    "\n  mutation UpdateAntennaArray($id: ID!, $name: String, $fields: UpsertAntennaArrayFields!) {\n    updateAntennaArray(id: $id, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n": types.UpdateAntennaArrayDocument,
    "\n  mutation DeleteAntennaArray($id: ID!, $delete: Boolean!) {\n    deleteAntennaArray(id: $id, delete: $delete) {\n      ...AntennaArrayFields\n    }\n  }\n": types.DeleteAntennaArrayDocument,
    "\n  fragment OrganismDatasetFields on OrganismDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedSpecies\n    undefinedCaptureMethods\n    undefinedLocations\n    undefinedUsers\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    organismCount\n    validOrganismCount\n    warningCount\n  }\n": types.OrganismDatasetFieldsFragmentDoc,
    "\n  query ListOrganismDatasets($studyId: ID!, $request: ListOrganismDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      organismDatasets(request: $request) {\n        items {\n          ...OrganismDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n": types.ListOrganismDatasetsDocument,
    "\n  query GetOrganismDataset($id: ID!) {\n    organismDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...OrganismDatasetFields\n    }\n  }\n": types.GetOrganismDatasetDocument,
    "\n  mutation InsertOrganismDataset($studyId: ID!, $name: String!, $fields: UpsertOrganismDatasetFields!) {\n    insertOrganismDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n": types.InsertOrganismDatasetDocument,
    "\n  mutation UpdateOrganismDataset($id: ID!, $name: String, $fields: UpsertOrganismDatasetFields!) {\n    updateOrganismDataset(id: $id, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n": types.UpdateOrganismDatasetDocument,
    "\n  mutation DeleteOrganismDataset($id: ID!, $delete: Boolean!) {\n    deleteOrganismDataset(id: $id, delete: $delete) {\n      ...OrganismDatasetFields\n    }\n  }\n": types.DeleteOrganismDatasetDocument,
    "\n  mutation ParseOrganismDataset($id: ID!) {\n    parseOrganismDataset(id: $id) {\n      ...OrganismDatasetFields\n    }\n  }\n": types.ParseOrganismDatasetDocument,
    "\n  fragment OrganismFields on Organism {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    speciesId\n    tag\n    tagType\n    taggedOn\n    taggedById\n    capturedOn\n    capturedById\n    capturedAtId\n    capturedWithMethodId\n    capturedLocation {\n      ...CoordinatesFields\n    }\n    releasedOn\n    releasedById\n    releasedAtId\n    releasedLocation {\n      ...CoordinatesFields\n    }\n    length\n    width\n    height\n    weight\n    age\n    label\n    description\n  }\n": types.OrganismFieldsFragmentDoc,
    "\n  query ListOrganisms($studyId: ID!, $request: ListOrganismRequest!) {\n    organisms(studyId: $studyId, request: $request) {\n      items {\n        ...OrganismFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListOrganismsDocument,
    "\n  query GetOrganism($id: ID!) {\n    organism(id: $id) {\n      ...OrganismFields\n    }\n  }\n": types.GetOrganismDocument,
    "\n  query FindOrganism($studyId: ID!, $tag: String!) {\n    findOrganism(studyId: $studyId, tag: $tag) {\n      ...OrganismFields\n    }\n  }\n": types.FindOrganismDocument,
    "\n  mutation InsertOrganism($datasetId: ID!, $tag: String!, $fields: UpsertOrganismFields!) {\n    insertOrganism(datasetId: $datasetId, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n": types.InsertOrganismDocument,
    "\n  mutation UpdateOrganism($id: ID!, $tag: String, $fields: UpsertOrganismFields!) {\n    updateOrganism(id: $id, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n": types.UpdateOrganismDocument,
    "\n  mutation UpdateOrganisms($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertOrganismFields!, $scope: UpdateOrganismScope!) {\n    updateOrganisms(studyId: $studyId, filters: $filters, fields: $fields, scope: $scope)\n  }\n": types.UpdateOrganismsDocument,
    "\n  mutation DeleteOrganism($id: ID!) {\n    deleteOrganism(id: $id)\n  }\n": types.DeleteOrganismDocument,
    "\n  fragment DetectionDatasetFields on DetectionDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedTags\n    undefinedArrays\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    eventCount\n    validEventCount\n    warningCount\n  }\n": types.DetectionDatasetFieldsFragmentDoc,
    "\n  query ListDetectionDatasets($studyId: ID!, $request: ListDetectionDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      detectionDatasets(request: $request) {\n        items {\n          ...DetectionDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n": types.ListDetectionDatasetsDocument,
    "\n  query GetDetectionDataset($id: ID!) {\n    detectionDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...DetectionDatasetFields\n    }\n  }\n": types.GetDetectionDatasetDocument,
    "\n  mutation InsertDetectionDataset($studyId: ID!, $name: String!, $fields: UpsertDetectionDatasetFields!) {\n    insertDetectionDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n": types.InsertDetectionDatasetDocument,
    "\n  mutation UpdateDetectionDataset($id: ID!, $name: String, $fields: UpsertDetectionDatasetFields!) {\n    updateDetectionDataset(id: $id, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n": types.UpdateDetectionDatasetDocument,
    "\n  mutation DeleteDetectionDataset($id: ID!, $delete: Boolean!) {\n    deleteDetectionDataset(id: $id, delete: $delete) {\n      ...DetectionDatasetFields\n    }\n  }\n": types.DeleteDetectionDatasetDocument,
    "\n  mutation ParseDetectionDataset($id: ID!) {\n    parseDetectionDataset(id: $id) {\n      ...DetectionDatasetFields\n    }\n  }\n": types.ParseDetectionDatasetDocument,
    "\n  fragment DetectionEventFields on DetectionEvent {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    triggeredById\n    detectedOn\n    detectedFor\n    detectedByArrayId\n    detectedByAntenna\n  }\n": types.DetectionEventFieldsFragmentDoc,
    "\n  query ListDetectionEvents($studyId: ID!, $request: ListDetectionEventRequest!) {\n    detectionEvents(studyId: $studyId, request: $request) {\n      items {\n        ...DetectionEventFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListDetectionEventsDocument,
    "\n  query GetDetectionEvent($id: ID!) {\n    detectionEvent(id: $id) {\n      ...DetectionEventFields\n    }\n  }\n": types.GetDetectionEventDocument,
    "\n  mutation InsertDetectionEvent($datasetId: ID!, $triggeredBy: ID!, $detectedOn: NaiveDateTime!, $detectedByArray: ID!, $fields: UpsertDetectionEventFields!) {\n    insertDetectionEvent(datasetId: $datasetId, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n": types.InsertDetectionEventDocument,
    "\n  mutation UpdateDetectionEvent($id: ID!, $triggeredBy: ID, $detectedOn: NaiveDateTime, $detectedByArray: ID, $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvent(id: $id, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n": types.UpdateDetectionEventDocument,
    "\n  mutation UpdateDetectionEvents($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvents(studyId: $studyId, filters: $filters, fields: $fields)\n  }\n": types.UpdateDetectionEventsDocument,
    "\n  mutation DeleteDetectionEvent($id: ID!) {\n    deleteDetectionEvent(id: $id)\n  }\n": types.DeleteDetectionEventDocument,
    "\n  mutation AggregateStudy($id: ID!) {\n    aggregateStudy(id: $id)\n  }\n": types.AggregateStudyDocument,
    "\n  fragment ResultEventFields on ResultEvent {\n    id\n    resultType\n    organismId\n    studyNodeId\n    fromId\n    toId\n    detectedOn\n    timeOfDay\n    duration\n    prefixDelay\n    suffixDelay\n    redetectionDelay\n  }\n": types.ResultEventFieldsFragmentDoc,
    "\n  query ListResultEvents($studyId: ID!, $request: ListResultEventRequest!) {\n    resultEvents(studyId: $studyId, request: $request) {\n      items {\n        ...ResultEventFields\n      }\n      pages\n      total\n    }\n  }\n": types.ListResultEventsDocument,
    "\n  query FindStudySpecies($studyId: ID!) {\n    studySpecies(studyId: $studyId) {\n      ...CategoryValueFields\n    }\n  }\n": types.FindStudySpeciesDocument,
    "\n  query FindStudyCaptureLocations($studyId: ID!) {\n    studyCaptureLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n": types.FindStudyCaptureLocationsDocument,
    "\n  query FindStudyReleaseLocations($studyId: ID!) {\n    studyReleaseLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n": types.FindStudyReleaseLocationsDocument,
    "\n  query FindStudyLabels($studyId: ID!) {\n    studyLabels(studyId: $studyId)\n  }\n": types.FindStudyLabelsDocument,
    "\n  query OrganismDataTable($studyId: ID!, $datasource: OrganismTableDataSource!, $query: DataSourceQuery) {\n    organismTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n": types.OrganismDataTableDocument,
    "\n  query OrganismChart($studyId: ID!, $datasource: OrganismChartDataSource!, $query: DataSourceQuery) {\n    organismChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n": types.OrganismChartDocument,
    "\n  query DetectionEventChart($studyId: ID!, $datasource: DetectionEventChartDataSource!, $query: DataSourceQuery) {\n    detectionEventChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n": types.DetectionEventChartDocument,
    "\n  query ResultDataTable($studyId: ID!, $datasource: ResultTableDataSource!, $query: DataSourceQuery) {\n    resultTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n": types.ResultDataTableDocument,
    "\n  query ResultChart($studyId: ID!, $datasource: ResultChartDataSource!, $query: DataSourceQuery) {\n    resultChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n": types.ResultChartDocument,
    "\n  query EvaluationIndicators($studyId: ID!, $datasource: EvaluationIndicatorsDataSource!, $query: DataSourceQuery) {\n    evaluationIndicators(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...IndicatorsFields\n    }\n  }\n": types.EvaluationIndicatorsDocument,
    "\n  query EvaluationDataTable($studyId: ID!, $datasource: EvaluationTableDataSource!, $query: DataSourceQuery) {\n    evaluationTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n": types.EvaluationDataTableDocument,
    "\n  mutation MarkAllStudies($aggregate: Boolean) {\n    markStudies(aggregate: $aggregate) {\n      studyId\n      success\n    }\n  }\n": types.MarkAllStudiesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StoreFields on Store {\n    id\n    uploadedBy {\n      id\n      email\n      fullname\n    }\n    size\n    contentType\n    filename\n  }\n"): (typeof documents)["\n  fragment StoreFields on Store {\n    id\n    uploadedBy {\n      id\n      email\n      fullname\n    }\n    size\n    contentType\n    filename\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CoordinatesFields on Coordinates {\n    primary\n    lv03 {\n      x\n      y\n      altitude\n    }\n    lv95 {\n      north\n      east\n      altitude\n    }\n    wgs84 {\n      latitude\n      longitude\n      altitude\n    }\n  }\n"): (typeof documents)["\n  fragment CoordinatesFields on Coordinates {\n    primary\n    lv03 {\n      x\n      y\n      altitude\n    }\n    lv95 {\n      north\n      east\n      altitude\n    }\n    wgs84 {\n      latitude\n      longitude\n      altitude\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment IndicatorsFields on Indicators {\n    id\n    items {\n      id\n      title\n      format\n      precision\n      values {\n        value\n        label\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment IndicatorsFields on Indicators {\n    id\n    items {\n      id\n      title\n      format\n      precision\n      values {\n        value\n        label\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DataTableFields on DataTable {\n    id\n    header {\n      name\n      description\n      span\n      separator\n    }\n    columns {\n      id\n      name\n      format\n      precision\n      separator\n      sortable\n    }\n    rows {\n      id\n      columns\n      values\n    }\n    footer {\n      id\n      columns\n      values\n    }\n  }\n"): (typeof documents)["\n  fragment DataTableFields on DataTable {\n    id\n    header {\n      name\n      description\n      span\n      separator\n    }\n    columns {\n      id\n      name\n      format\n      precision\n      separator\n      sortable\n    }\n    rows {\n      id\n      columns\n      values\n    }\n    footer {\n      id\n      columns\n      values\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ChartFields on Chart {\n    id\n    labels\n    series {\n      id\n      name\n      x\n      y\n      yAxis\n    }\n    xAxis {\n      name\n      unit\n      format\n      stacked\n    }\n    yAxes {\n      name\n      unit\n      format\n      stacked\n    }\n  }\n"): (typeof documents)["\n  fragment ChartFields on Chart {\n    id\n    labels\n    series {\n      id\n      name\n      x\n      y\n      yAxis\n    }\n    xAxis {\n      name\n      unit\n      format\n      stacked\n    }\n    yAxes {\n      name\n      unit\n      format\n      stacked\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CategoryValueFields on CategoryValue {\n    id\n    deletedAt\n    index\n    name\n    localizedName {\n      lang\n      value\n    }\n    localizedDisplay\n    metadata\n  }\n"): (typeof documents)["\n  fragment CategoryValueFields on CategoryValue {\n    id\n    deletedAt\n    index\n    name\n    localizedName {\n      lang\n      value\n    }\n    localizedDisplay\n    metadata\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CategoryFields on Category {\n    id\n    deletedAt\n    storeId\n    name\n    localizedName {\n      lang\n      value\n    }\n    store {\n      ...StoreFields\n    }\n\n    categoryValues(request: { page: 0, limit: 0, deleted: false }) {\n      items {\n        ...CategoryValueFields\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CategoryFields on Category {\n    id\n    deletedAt\n    storeId\n    name\n    localizedName {\n      lang\n      value\n    }\n    store {\n      ...StoreFields\n    }\n\n    categoryValues(request: { page: 0, limit: 0, deleted: false }) {\n      items {\n        ...CategoryValueFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListCategories($request: ListCategoryRequest!) {\n    categories(request: $request) {\n      items {\n        ...CategoryFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListCategories($request: ListCategoryRequest!) {\n    categories(request: $request) {\n      items {\n        ...CategoryFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCategory($id: ID!) {\n    category(id: $id) {\n      ...CategoryFields\n    }\n  }\n"): (typeof documents)["\n  query GetCategory($id: ID!) {\n    category(id: $id) {\n      ...CategoryFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindCategory($name: WellKnownCategories!) {\n    findCategory(name: $name) {\n      ...CategoryFields\n    }\n  }\n"): (typeof documents)["\n  query FindCategory($name: WellKnownCategories!) {\n    findCategory(name: $name) {\n      ...CategoryFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindCategoryValue($name: WellKnownCategories!, $id: ID!) {\n    findCategoryValue(name: $name, id: $id) {\n      ...CategoryValueFields\n    }\n  }\n"): (typeof documents)["\n  query FindCategoryValue($name: WellKnownCategories!, $id: ID!) {\n    findCategoryValue(name: $name, id: $id) {\n      ...CategoryValueFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertCategory($name: String!, $storeId: ID, $fields: UpsertCategoryFields!) {\n    insertCategory(name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertCategory($name: String!, $storeId: ID, $fields: UpsertCategoryFields!) {\n    insertCategory(name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCategory($id: ID!, $name: String, $storeId: ID, $fields: UpsertCategoryFields!) {\n    updateCategory(id: $id, name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCategory($id: ID!, $name: String, $storeId: ID, $fields: UpsertCategoryFields!) {\n    updateCategory(id: $id, name: $name, storeId: $storeId, fields: $fields) {\n      ...CategoryFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCategory($id: ID!, $delete: Boolean!) {\n    deleteCategory(id: $id, delete: $delete) {\n      ...CategoryFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCategory($id: ID!, $delete: Boolean!) {\n    deleteCategory(id: $id, delete: $delete) {\n      ...CategoryFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GraphFields on Graph {\n    id\n    validLayout\n\n    nodes {\n      id\n      nodeId\n      nodeType\n      name\n      x\n      y\n      properties {\n        name\n        value\n      }\n    }\n\n    edges {\n      id\n      source\n      destination\n      properties {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GraphFields on Graph {\n    id\n    validLayout\n\n    nodes {\n      id\n      nodeId\n      nodeType\n      name\n      x\n      y\n      properties {\n        name\n        value\n      }\n    }\n\n    edges {\n      id\n      source\n      destination\n      properties {\n        name\n        value\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganizationFields on Organization {\n    id\n    createdAt\n    deletedAt\n    active\n    role\n    name\n    street\n    postcode\n    city\n    state\n    country\n    email\n    phone\n    website\n    userCount\n    studyCount\n  }\n"): (typeof documents)["\n  fragment OrganizationFields on Organization {\n    id\n    createdAt\n    deletedAt\n    active\n    role\n    name\n    street\n    postcode\n    city\n    state\n    country\n    email\n    phone\n    website\n    userCount\n    studyCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CurrentOrganization {\n    currentOrganization {\n      ...OrganizationFields\n    }\n  }\n"): (typeof documents)["\n  query CurrentOrganization {\n    currentOrganization {\n      ...OrganizationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListOrganizations($request: ListOrganizationRequest!) {\n    organizations(request: $request) {\n      items {\n        ...OrganizationFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListOrganizations($request: ListOrganizationRequest!) {\n    organizations(request: $request) {\n      items {\n        ...OrganizationFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrganization($id: ID!) {\n    organization(id: $id) {\n      ...OrganizationFields\n    }\n  }\n"): (typeof documents)["\n  query GetOrganization($id: ID!) {\n    organization(id: $id) {\n      ...OrganizationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertOrganization($name: String!, $fields: UpsertOrganizationFields!) {\n    insertOrganization(name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertOrganization($name: String!, $fields: UpsertOrganizationFields!) {\n    insertOrganization(name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrganization($id: ID!, $name: String, $fields: UpsertOrganizationFields!) {\n    updateOrganization(id: $id, name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrganization($id: ID!, $name: String, $fields: UpsertOrganizationFields!) {\n    updateOrganization(id: $id, name: $name, fields: $fields) {\n      ...OrganizationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOrganization($id: ID!, $delete: Boolean!) {\n    deleteOrganization(id: $id, delete: $delete) {\n      ...OrganizationFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOrganization($id: ID!, $delete: Boolean!) {\n    deleteOrganization(id: $id, delete: $delete) {\n      ...OrganizationFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserFields on User {\n    id\n    createdAt\n    deletedAt\n    lastSignInAt\n    active\n    role\n    email\n    fullname\n    firstname\n    surname\n    csvDialect\n  }\n"): (typeof documents)["\n  fragment UserFields on User {\n    id\n    createdAt\n    deletedAt\n    lastSignInAt\n    active\n    role\n    email\n    fullname\n    firstname\n    surname\n    csvDialect\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CurrentUser {\n    currentUser {\n      ...UserFields\n\n      organization {\n        ...OrganizationFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query CurrentUser {\n    currentUser {\n      ...UserFields\n\n      organization {\n        ...OrganizationFields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListUsers($request: ListUserRequest!) {\n    currentOrganization {\n      users(request: $request) {\n        items {\n          ...UserFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListUsers($request: ListUserRequest!) {\n    currentOrganization {\n      users(request: $request) {\n        items {\n          ...UserFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...UserFields\n    }\n  }\n"): (typeof documents)["\n  query GetUser($id: ID!) {\n    user(id: $id) {\n      ...UserFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertUser($organizationId: ID!, $email: String!, $fields: UpsertUserFields!) {\n    insertUser(organizationId: $organizationId, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertUser($organizationId: ID!, $email: String!, $fields: UpsertUserFields!) {\n    insertUser(organizationId: $organizationId, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUser($id: ID!, $email: String, $fields: UpsertUserFields!) {\n    updateUser(id: $id, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($id: ID!, $email: String, $fields: UpsertUserFields!) {\n    updateUser(id: $id, email: $email, fields: $fields) {\n      ...UserFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserPassword($id: ID!, $oldPassword: String, $newPassword: String) {\n    updateUserPassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {\n      ...UserFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserPassword($id: ID!, $oldPassword: String, $newPassword: String) {\n    updateUserPassword(id: $id, oldPassword: $oldPassword, newPassword: $newPassword) {\n      ...UserFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteUser($id: ID!, $delete: Boolean!) {\n    deleteUser(id: $id, delete: $delete) {\n      ...UserFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser($id: ID!, $delete: Boolean!) {\n    deleteUser(id: $id, delete: $delete) {\n      ...UserFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudyFields on Study {\n    id\n    createdAt\n    deletedAt\n    state\n    name\n    validFrom\n    validUntil\n    waterBody\n    waterBodyTypeId\n    description\n    location {\n      ...CoordinatesFields\n    }\n    dirty\n    studyNodeCount\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    excludeBottomSwimmers\n    validOrganismCount\n    validDetectionCount\n  }\n"): (typeof documents)["\n  fragment StudyFields on Study {\n    id\n    createdAt\n    deletedAt\n    state\n    name\n    validFrom\n    validUntil\n    waterBody\n    waterBodyTypeId\n    description\n    location {\n      ...CoordinatesFields\n    }\n    dirty\n    studyNodeCount\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    excludeBottomSwimmers\n    validOrganismCount\n    validDetectionCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListStudies($request: ListStudyRequest!) {\n    currentOrganization {\n      studies(request: $request) {\n        items {\n          ...StudyFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListStudies($request: ListStudyRequest!) {\n    currentOrganization {\n      studies(request: $request) {\n        items {\n          ...StudyFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudy($id: ID!) {\n    study(id: $id) {\n      ...StudyFields\n    }\n  }\n"): (typeof documents)["\n  query GetStudy($id: ID!) {\n    study(id: $id) {\n      ...StudyFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertStudy($name: String!, $fields: UpsertStudyFields!) {\n    insertStudy(name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertStudy($name: String!, $fields: UpsertStudyFields!) {\n    insertStudy(name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateStudy($id: ID!, $name: String, $fields: UpsertStudyFields!) {\n    updateStudy(id: $id, name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStudy($id: ID!, $name: String, $fields: UpsertStudyFields!) {\n    updateStudy(id: $id, name: $name, fields: $fields) {\n      ...StudyFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteStudy($id: ID!, $delete: Boolean!) {\n    deleteStudy(id: $id, delete: $delete) {\n      ...StudyFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteStudy($id: ID!, $delete: Boolean!) {\n    deleteStudy(id: $id, delete: $delete) {\n      ...StudyFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CloneStudy($id: ID!, $name: String!, $includeOrganisms: Boolean!, $includeEvents: Boolean!) {\n    cloneStudy(id: $id, name: $name, includeOrganisms: $includeOrganisms, includeEvents: $includeEvents) {\n      ...StudyFields\n    }\n  }\n"): (typeof documents)["\n  mutation CloneStudy($id: ID!, $name: String!, $includeOrganisms: Boolean!, $includeEvents: Boolean!) {\n    cloneStudy(id: $id, name: $name, includeOrganisms: $includeOrganisms, includeEvents: $includeEvents) {\n      ...StudyFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment StudyNodeFields on StudyNode {\n    id\n    createdAt\n    deletedAt\n    nodeType\n    sequence\n    name\n    description\n    denivelation\n    location {\n      ...CoordinatesFields\n    }\n    pathwayTopologyId\n    pathwayTopology {\n      ...CategoryValueFields\n    }\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    antennaArrayCount\n  }\n"): (typeof documents)["\n  fragment StudyNodeFields on StudyNode {\n    id\n    createdAt\n    deletedAt\n    nodeType\n    sequence\n    name\n    description\n    denivelation\n    location {\n      ...CoordinatesFields\n    }\n    pathwayTopologyId\n    pathwayTopology {\n      ...CategoryValueFields\n    }\n    enableRedetectionRate\n    enableEntryEfficiency\n    enableExitEfficiency\n    enablePassageEfficiency\n    antennaArrayCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AntennaArrayFields on AntennaArray {\n    id\n    createdAt\n    deletedAt\n    studyNodeId\n    name\n    description\n    antennaNames\n    location {\n      ...CoordinatesFields\n    }\n  }\n"): (typeof documents)["\n  fragment AntennaArrayFields on AntennaArray {\n    id\n    createdAt\n    deletedAt\n    studyNodeId\n    name\n    description\n    antennaNames\n    location {\n      ...CoordinatesFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListStudyNodes($studyId: ID!, $request: ListStudyNodeRequest!) {\n    studyNodes(studyId: $studyId, request: $request) {\n      items {\n        ...StudyNodeFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListStudyNodes($studyId: ID!, $request: ListStudyNodeRequest!) {\n    studyNodes(studyId: $studyId, request: $request) {\n      items {\n        ...StudyNodeFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudyNodeGraph($studyId: ID!) {\n    studyNodeGraph(studyId: $studyId) {\n      ...GraphFields\n    }\n  }\n"): (typeof documents)["\n  query GetStudyNodeGraph($studyId: ID!) {\n    studyNodeGraph(studyId: $studyId) {\n      ...GraphFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetStudyNode($id: ID!) {\n    studyNode(id: $id) {\n      study {\n        name\n      }\n\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  query GetStudyNode($id: ID!) {\n    studyNode(id: $id) {\n      study {\n        name\n      }\n\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertStudyNode($studyId: ID!, $nodeType: StudyNodeType!, $sequence: Int!, $name: String!, $denivelation: String!, $fields: UpsertStudyNodeFields!) {\n    insertStudyNode(studyId: $studyId, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertStudyNode($studyId: ID!, $nodeType: StudyNodeType!, $sequence: Int!, $name: String!, $denivelation: String!, $fields: UpsertStudyNodeFields!) {\n    insertStudyNode(studyId: $studyId, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateStudyNode($id: ID!, $nodeType: StudyNodeType, $sequence: Int, $name: String, $denivelation: String, $fields: UpsertStudyNodeFields!) {\n    updateStudyNode(id: $id, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStudyNode($id: ID!, $nodeType: StudyNodeType, $sequence: Int, $name: String, $denivelation: String, $fields: UpsertStudyNodeFields!) {\n    updateStudyNode(id: $id, nodeType: $nodeType, sequence: $sequence, name: $name, denivelation: $denivelation, fields: $fields) {\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteStudyNode($id: ID!, $delete: Boolean!) {\n    deleteStudyNode(id: $id, delete: $delete) {\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteStudyNode($id: ID!, $delete: Boolean!) {\n    deleteStudyNode(id: $id, delete: $delete) {\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListAntennaArrays($studyId: ID!, $request: ListAntennaArrayRequest!) {\n    antennaArrays(studyId: $studyId, request: $request) {\n      items {\n        ...AntennaArrayFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListAntennaArrays($studyId: ID!, $request: ListAntennaArrayRequest!) {\n    antennaArrays(studyId: $studyId, request: $request) {\n      items {\n        ...AntennaArrayFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAntennaArrayGraph($studyNodeId: ID!) {\n    antennaArrayGraph(studyNodeId: $studyNodeId) {\n      ...GraphFields\n    }\n  }\n"): (typeof documents)["\n  query GetAntennaArrayGraph($studyNodeId: ID!) {\n    antennaArrayGraph(studyNodeId: $studyNodeId) {\n      ...GraphFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAntennaArray($id: ID!) {\n    antennaArray(id: $id) {\n      ...AntennaArrayFields\n    }\n  }\n"): (typeof documents)["\n  query GetAntennaArray($id: ID!) {\n    antennaArray(id: $id) {\n      ...AntennaArrayFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertAntennaArray($studyNodeId: ID!, $name: String!, $fields: UpsertAntennaArrayFields!) {\n    insertAntennaArray(studyNodeId: $studyNodeId, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertAntennaArray($studyNodeId: ID!, $name: String!, $fields: UpsertAntennaArrayFields!) {\n    insertAntennaArray(studyNodeId: $studyNodeId, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateAntennaArray($id: ID!, $name: String, $fields: UpsertAntennaArrayFields!) {\n    updateAntennaArray(id: $id, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAntennaArray($id: ID!, $name: String, $fields: UpsertAntennaArrayFields!) {\n    updateAntennaArray(id: $id, name: $name, fields: $fields) {\n      ...AntennaArrayFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAntennaArray($id: ID!, $delete: Boolean!) {\n    deleteAntennaArray(id: $id, delete: $delete) {\n      ...AntennaArrayFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAntennaArray($id: ID!, $delete: Boolean!) {\n    deleteAntennaArray(id: $id, delete: $delete) {\n      ...AntennaArrayFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganismDatasetFields on OrganismDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedSpecies\n    undefinedCaptureMethods\n    undefinedLocations\n    undefinedUsers\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    organismCount\n    validOrganismCount\n    warningCount\n  }\n"): (typeof documents)["\n  fragment OrganismDatasetFields on OrganismDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedSpecies\n    undefinedCaptureMethods\n    undefinedLocations\n    undefinedUsers\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    organismCount\n    validOrganismCount\n    warningCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListOrganismDatasets($studyId: ID!, $request: ListOrganismDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      organismDatasets(request: $request) {\n        items {\n          ...OrganismDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListOrganismDatasets($studyId: ID!, $request: ListOrganismDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      organismDatasets(request: $request) {\n        items {\n          ...OrganismDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrganismDataset($id: ID!) {\n    organismDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...OrganismDatasetFields\n    }\n  }\n"): (typeof documents)["\n  query GetOrganismDataset($id: ID!) {\n    organismDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...OrganismDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertOrganismDataset($studyId: ID!, $name: String!, $fields: UpsertOrganismDatasetFields!) {\n    insertOrganismDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertOrganismDataset($studyId: ID!, $name: String!, $fields: UpsertOrganismDatasetFields!) {\n    insertOrganismDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrganismDataset($id: ID!, $name: String, $fields: UpsertOrganismDatasetFields!) {\n    updateOrganismDataset(id: $id, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrganismDataset($id: ID!, $name: String, $fields: UpsertOrganismDatasetFields!) {\n    updateOrganismDataset(id: $id, name: $name, fields: $fields) {\n      ...OrganismDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOrganismDataset($id: ID!, $delete: Boolean!) {\n    deleteOrganismDataset(id: $id, delete: $delete) {\n      ...OrganismDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOrganismDataset($id: ID!, $delete: Boolean!) {\n    deleteOrganismDataset(id: $id, delete: $delete) {\n      ...OrganismDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ParseOrganismDataset($id: ID!) {\n    parseOrganismDataset(id: $id) {\n      ...OrganismDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation ParseOrganismDataset($id: ID!) {\n    parseOrganismDataset(id: $id) {\n      ...OrganismDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganismFields on Organism {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    speciesId\n    tag\n    tagType\n    taggedOn\n    taggedById\n    capturedOn\n    capturedById\n    capturedAtId\n    capturedWithMethodId\n    capturedLocation {\n      ...CoordinatesFields\n    }\n    releasedOn\n    releasedById\n    releasedAtId\n    releasedLocation {\n      ...CoordinatesFields\n    }\n    length\n    width\n    height\n    weight\n    age\n    label\n    description\n  }\n"): (typeof documents)["\n  fragment OrganismFields on Organism {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    speciesId\n    tag\n    tagType\n    taggedOn\n    taggedById\n    capturedOn\n    capturedById\n    capturedAtId\n    capturedWithMethodId\n    capturedLocation {\n      ...CoordinatesFields\n    }\n    releasedOn\n    releasedById\n    releasedAtId\n    releasedLocation {\n      ...CoordinatesFields\n    }\n    length\n    width\n    height\n    weight\n    age\n    label\n    description\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListOrganisms($studyId: ID!, $request: ListOrganismRequest!) {\n    organisms(studyId: $studyId, request: $request) {\n      items {\n        ...OrganismFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListOrganisms($studyId: ID!, $request: ListOrganismRequest!) {\n    organisms(studyId: $studyId, request: $request) {\n      items {\n        ...OrganismFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrganism($id: ID!) {\n    organism(id: $id) {\n      ...OrganismFields\n    }\n  }\n"): (typeof documents)["\n  query GetOrganism($id: ID!) {\n    organism(id: $id) {\n      ...OrganismFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindOrganism($studyId: ID!, $tag: String!) {\n    findOrganism(studyId: $studyId, tag: $tag) {\n      ...OrganismFields\n    }\n  }\n"): (typeof documents)["\n  query FindOrganism($studyId: ID!, $tag: String!) {\n    findOrganism(studyId: $studyId, tag: $tag) {\n      ...OrganismFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertOrganism($datasetId: ID!, $tag: String!, $fields: UpsertOrganismFields!) {\n    insertOrganism(datasetId: $datasetId, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertOrganism($datasetId: ID!, $tag: String!, $fields: UpsertOrganismFields!) {\n    insertOrganism(datasetId: $datasetId, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrganism($id: ID!, $tag: String, $fields: UpsertOrganismFields!) {\n    updateOrganism(id: $id, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrganism($id: ID!, $tag: String, $fields: UpsertOrganismFields!) {\n    updateOrganism(id: $id, tag: $tag, fields: $fields) {\n      ...OrganismFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOrganisms($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertOrganismFields!, $scope: UpdateOrganismScope!) {\n    updateOrganisms(studyId: $studyId, filters: $filters, fields: $fields, scope: $scope)\n  }\n"): (typeof documents)["\n  mutation UpdateOrganisms($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertOrganismFields!, $scope: UpdateOrganismScope!) {\n    updateOrganisms(studyId: $studyId, filters: $filters, fields: $fields, scope: $scope)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteOrganism($id: ID!) {\n    deleteOrganism(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteOrganism($id: ID!) {\n    deleteOrganism(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DetectionDatasetFields on DetectionDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedTags\n    undefinedArrays\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    eventCount\n    validEventCount\n    warningCount\n  }\n"): (typeof documents)["\n  fragment DetectionDatasetFields on DetectionDataset {\n    id\n    createdAt\n    deletedAt\n    name\n    state\n    errors\n    undefinedTags\n    undefinedArrays\n    validated\n    validatedBy {\n      email\n      fullname\n    }\n    dirty\n    store {\n      ...StoreFields\n    }\n    eventCount\n    validEventCount\n    warningCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListDetectionDatasets($studyId: ID!, $request: ListDetectionDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      detectionDatasets(request: $request) {\n        items {\n          ...DetectionDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListDetectionDatasets($studyId: ID!, $request: ListDetectionDatasetRequest!) {\n    study(id: $studyId) {\n      ...StudyFields\n\n      detectionDatasets(request: $request) {\n        items {\n          ...DetectionDatasetFields\n        }\n        pages\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDetectionDataset($id: ID!) {\n    detectionDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...DetectionDatasetFields\n    }\n  }\n"): (typeof documents)["\n  query GetDetectionDataset($id: ID!) {\n    detectionDataset(id: $id) {\n      study {\n        name\n      }\n\n      ...DetectionDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertDetectionDataset($studyId: ID!, $name: String!, $fields: UpsertDetectionDatasetFields!) {\n    insertDetectionDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertDetectionDataset($studyId: ID!, $name: String!, $fields: UpsertDetectionDatasetFields!) {\n    insertDetectionDataset(studyId: $studyId, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateDetectionDataset($id: ID!, $name: String, $fields: UpsertDetectionDatasetFields!) {\n    updateDetectionDataset(id: $id, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateDetectionDataset($id: ID!, $name: String, $fields: UpsertDetectionDatasetFields!) {\n    updateDetectionDataset(id: $id, name: $name, fields: $fields) {\n      ...DetectionDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteDetectionDataset($id: ID!, $delete: Boolean!) {\n    deleteDetectionDataset(id: $id, delete: $delete) {\n      ...DetectionDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteDetectionDataset($id: ID!, $delete: Boolean!) {\n    deleteDetectionDataset(id: $id, delete: $delete) {\n      ...DetectionDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ParseDetectionDataset($id: ID!) {\n    parseDetectionDataset(id: $id) {\n      ...DetectionDatasetFields\n    }\n  }\n"): (typeof documents)["\n  mutation ParseDetectionDataset($id: ID!) {\n    parseDetectionDataset(id: $id) {\n      ...DetectionDatasetFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DetectionEventFields on DetectionEvent {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    triggeredById\n    detectedOn\n    detectedFor\n    detectedByArrayId\n    detectedByAntenna\n  }\n"): (typeof documents)["\n  fragment DetectionEventFields on DetectionEvent {\n    id\n    datasetId\n    line\n    warning\n    valid\n    edited\n    triggeredById\n    detectedOn\n    detectedFor\n    detectedByArrayId\n    detectedByAntenna\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListDetectionEvents($studyId: ID!, $request: ListDetectionEventRequest!) {\n    detectionEvents(studyId: $studyId, request: $request) {\n      items {\n        ...DetectionEventFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListDetectionEvents($studyId: ID!, $request: ListDetectionEventRequest!) {\n    detectionEvents(studyId: $studyId, request: $request) {\n      items {\n        ...DetectionEventFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDetectionEvent($id: ID!) {\n    detectionEvent(id: $id) {\n      ...DetectionEventFields\n    }\n  }\n"): (typeof documents)["\n  query GetDetectionEvent($id: ID!) {\n    detectionEvent(id: $id) {\n      ...DetectionEventFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InsertDetectionEvent($datasetId: ID!, $triggeredBy: ID!, $detectedOn: NaiveDateTime!, $detectedByArray: ID!, $fields: UpsertDetectionEventFields!) {\n    insertDetectionEvent(datasetId: $datasetId, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n"): (typeof documents)["\n  mutation InsertDetectionEvent($datasetId: ID!, $triggeredBy: ID!, $detectedOn: NaiveDateTime!, $detectedByArray: ID!, $fields: UpsertDetectionEventFields!) {\n    insertDetectionEvent(datasetId: $datasetId, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateDetectionEvent($id: ID!, $triggeredBy: ID, $detectedOn: NaiveDateTime, $detectedByArray: ID, $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvent(id: $id, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateDetectionEvent($id: ID!, $triggeredBy: ID, $detectedOn: NaiveDateTime, $detectedByArray: ID, $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvent(id: $id, triggeredBy: $triggeredBy, detectedOn: $detectedOn, detectedByArray: $detectedByArray, fields: $fields) {\n      ...DetectionEventFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateDetectionEvents($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvents(studyId: $studyId, filters: $filters, fields: $fields)\n  }\n"): (typeof documents)["\n  mutation UpdateDetectionEvents($studyId: ID!, $filters: [DataSourceFilter!], $fields: UpsertDetectionEventFields!) {\n    updateDetectionEvents(studyId: $studyId, filters: $filters, fields: $fields)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteDetectionEvent($id: ID!) {\n    deleteDetectionEvent(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteDetectionEvent($id: ID!) {\n    deleteDetectionEvent(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AggregateStudy($id: ID!) {\n    aggregateStudy(id: $id)\n  }\n"): (typeof documents)["\n  mutation AggregateStudy($id: ID!) {\n    aggregateStudy(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ResultEventFields on ResultEvent {\n    id\n    resultType\n    organismId\n    studyNodeId\n    fromId\n    toId\n    detectedOn\n    timeOfDay\n    duration\n    prefixDelay\n    suffixDelay\n    redetectionDelay\n  }\n"): (typeof documents)["\n  fragment ResultEventFields on ResultEvent {\n    id\n    resultType\n    organismId\n    studyNodeId\n    fromId\n    toId\n    detectedOn\n    timeOfDay\n    duration\n    prefixDelay\n    suffixDelay\n    redetectionDelay\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListResultEvents($studyId: ID!, $request: ListResultEventRequest!) {\n    resultEvents(studyId: $studyId, request: $request) {\n      items {\n        ...ResultEventFields\n      }\n      pages\n      total\n    }\n  }\n"): (typeof documents)["\n  query ListResultEvents($studyId: ID!, $request: ListResultEventRequest!) {\n    resultEvents(studyId: $studyId, request: $request) {\n      items {\n        ...ResultEventFields\n      }\n      pages\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindStudySpecies($studyId: ID!) {\n    studySpecies(studyId: $studyId) {\n      ...CategoryValueFields\n    }\n  }\n"): (typeof documents)["\n  query FindStudySpecies($studyId: ID!) {\n    studySpecies(studyId: $studyId) {\n      ...CategoryValueFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindStudyCaptureLocations($studyId: ID!) {\n    studyCaptureLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  query FindStudyCaptureLocations($studyId: ID!) {\n    studyCaptureLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindStudyReleaseLocations($studyId: ID!) {\n    studyReleaseLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n"): (typeof documents)["\n  query FindStudyReleaseLocations($studyId: ID!) {\n    studyReleaseLocations(studyId: $studyId) {\n      ...StudyNodeFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FindStudyLabels($studyId: ID!) {\n    studyLabels(studyId: $studyId)\n  }\n"): (typeof documents)["\n  query FindStudyLabels($studyId: ID!) {\n    studyLabels(studyId: $studyId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganismDataTable($studyId: ID!, $datasource: OrganismTableDataSource!, $query: DataSourceQuery) {\n    organismTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"): (typeof documents)["\n  query OrganismDataTable($studyId: ID!, $datasource: OrganismTableDataSource!, $query: DataSourceQuery) {\n    organismTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganismChart($studyId: ID!, $datasource: OrganismChartDataSource!, $query: DataSourceQuery) {\n    organismChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"): (typeof documents)["\n  query OrganismChart($studyId: ID!, $datasource: OrganismChartDataSource!, $query: DataSourceQuery) {\n    organismChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DetectionEventChart($studyId: ID!, $datasource: DetectionEventChartDataSource!, $query: DataSourceQuery) {\n    detectionEventChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"): (typeof documents)["\n  query DetectionEventChart($studyId: ID!, $datasource: DetectionEventChartDataSource!, $query: DataSourceQuery) {\n    detectionEventChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ResultDataTable($studyId: ID!, $datasource: ResultTableDataSource!, $query: DataSourceQuery) {\n    resultTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"): (typeof documents)["\n  query ResultDataTable($studyId: ID!, $datasource: ResultTableDataSource!, $query: DataSourceQuery) {\n    resultTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ResultChart($studyId: ID!, $datasource: ResultChartDataSource!, $query: DataSourceQuery) {\n    resultChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"): (typeof documents)["\n  query ResultChart($studyId: ID!, $datasource: ResultChartDataSource!, $query: DataSourceQuery) {\n    resultChart(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...ChartFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EvaluationIndicators($studyId: ID!, $datasource: EvaluationIndicatorsDataSource!, $query: DataSourceQuery) {\n    evaluationIndicators(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...IndicatorsFields\n    }\n  }\n"): (typeof documents)["\n  query EvaluationIndicators($studyId: ID!, $datasource: EvaluationIndicatorsDataSource!, $query: DataSourceQuery) {\n    evaluationIndicators(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...IndicatorsFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EvaluationDataTable($studyId: ID!, $datasource: EvaluationTableDataSource!, $query: DataSourceQuery) {\n    evaluationTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"): (typeof documents)["\n  query EvaluationDataTable($studyId: ID!, $datasource: EvaluationTableDataSource!, $query: DataSourceQuery) {\n    evaluationTable(studyId: $studyId, datasource: $datasource, query: $query) {\n      ...DataTableFields\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkAllStudies($aggregate: Boolean) {\n    markStudies(aggregate: $aggregate) {\n      studyId\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation MarkAllStudies($aggregate: Boolean) {\n    markStudies(aggregate: $aggregate) {\n      studyId\n      success\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;