import { createContext, useContext, Dispatch } from 'react'

export type AppNotification = {
  id: string
  level: 'info' | 'warn' | 'error'
  title: React.ReactNode
  message: React.ReactNode
  exception?: any
  forced: boolean
  spinner: boolean
  timeout: number
}

export type AppConfirmation = {
  id: string
  level: 'info' | 'warn' | 'error'
  title: React.ReactNode
  message: React.ReactNode
  choices: 'okcancel' | 'yesno'
  resolve: (confirmed: boolean) => void
}

export type AppPrompt = {
  id: string
  level: 'info' | 'warn' | 'error'
  title: React.ReactNode
  label: React.ReactNode
  description: React.ReactNode
  type: 'text' | 'number' | 'password'
  name: string | undefined
  defaultValue: string | number | undefined
  placeholder: string
  choices: 'okcancel' | 'yesno'
  resolve: (value: string | number | null) => void
}

export type AppSettings = {
  allowUserRegistration: boolean
  allowForcedAggregation: boolean
}

export type AppState = {
  settings: AppSettings
  subMenu: boolean
  subMenuOpen: boolean
  notifications: AppNotification[]
  nextNotificationId: number
  confirmations: AppConfirmation[]
  nextConfirmationId: number
  prompts: AppPrompt[]
  nextPromptId: number
}

export enum AppAction {
  UpdateSettings,
  EnableSubMenu,
  DisableSubMenu,
  ShowSubMenu,
  HideSubMenu,
  ToggleSubMenu,
  PublishNotification,
  DismissNotification,
  RequestConfirmation,
  DismissConfirmation,
  RequestPrompt,
  DismissPrompt,
}

export type AppActionPayload = {
  action: AppAction
  settings?: Partial<AppSettings>
  notification?: Partial<AppNotification>
  confirmation?: Partial<AppConfirmation>
  prompt?: Partial<AppPrompt>
}

export const appStateInitial: AppState = {
  settings: {
    allowUserRegistration: false,
    allowForcedAggregation: false,
  },
  subMenu: false,
  subMenuOpen: false,
  notifications: [],
  nextNotificationId: 1,
  confirmations: [],
  nextConfirmationId: 1,
  prompts: [],
  nextPromptId: 1,
}

export function appStateReducer(prev: AppState, action: AppActionPayload): AppState {
  switch (action.action) {
    case AppAction.UpdateSettings:
      return { ...prev, settings: { ...prev.settings, ...action.settings } }
    case AppAction.EnableSubMenu:
      return { ...prev, subMenu: true }
    case AppAction.DisableSubMenu:
      return { ...prev, subMenu: false }
    case AppAction.ShowSubMenu:
      return { ...prev, subMenuOpen: true }
    case AppAction.HideSubMenu:
      return { ...prev, subMenuOpen: false }
    case AppAction.ToggleSubMenu:
      return { ...prev, subMenuOpen: !prev.subMenuOpen }
    case AppAction.PublishNotification:
      if (action.notification?.exception) {
        console.warn(action.notification.exception)
      }
      return {
        ...prev,
        notifications: [
          ...prev.notifications,
          {
            id: prev.nextNotificationId.toString(),
            level: 'info',
            title: '',
            message: '',
            forced: false,
            spinner: false,
            timeout: action.notification?.forced ? 0 : 10,
            ...action.notification,
          },
        ],
        nextNotificationId: prev.nextNotificationId + 1,
      }
    case AppAction.DismissNotification:
      return { ...prev, notifications: prev.notifications.filter((item) => item !== action.notification && item.id !== action.notification?.id) }
    case AppAction.RequestConfirmation:
      return {
        ...prev,
        confirmations: [
          ...prev.confirmations,
          { id: prev.nextConfirmationId.toString(), level: 'info', title: '', message: '', choices: 'okcancel', resolve: () => {}, ...action.confirmation },
        ],
        nextConfirmationId: prev.nextConfirmationId + 1,
      }
    case AppAction.DismissConfirmation:
      return { ...prev, confirmations: prev.confirmations.filter((item) => item !== action.confirmation && item.id !== action.confirmation?.id) }
    case AppAction.RequestPrompt:
      return {
        ...prev,
        prompts: [
          ...prev.prompts,
          {
            id: prev.nextPromptId.toString(),
            level: 'info',
            title: '',
            label: '',
            description: '',
            type: 'text',
            name: undefined,
            defaultValue: undefined,
            placeholder: '',
            choices: 'okcancel',
            resolve: () => {},
            ...action.prompt,
          },
        ],
        nextPromptId: prev.nextPromptId + 1,
      }
    case AppAction.DismissPrompt:
      return { ...prev, prompts: prev.prompts.filter((item) => item !== action.prompt && item.id !== action.prompt?.id) }
    default:
      throw Error('unsupported action: ' + action.action)
  }
}

const appStateContext = createContext<AppState>(appStateInitial)
const appDispatchContext = createContext<Dispatch<AppActionPayload>>(() => {})

export const AppStateProvider = appStateContext.Provider
export const AppDispatchProvider = appDispatchContext.Provider

export function useAppState() {
  return useContext(appStateContext)
}

export function useAppDispatch() {
  return useContext(appDispatchContext)
}
