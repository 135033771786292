import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { OrganizationRole } from '~/graphql-codegen/graphql'
import { useAuth } from '~/session'
import { AppAction, useAppDispatch } from '~/state'

export function OrganizationUpsert() {
  const dispatch = useAppDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const organizationId = params.organizationId || 'new'
  const [searchParams] = useSearchParams()

  const apollo = useApolloClient()
  const [insertOrganization] = useMutation(api.INSERT_ORGANIZATION)
  const [updateOrganization] = useMutation(api.UPDATE_ORGANIZATION)
  const [busy, setBusy] = useState(false)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      if (organizationId === 'new') {
        await insertOrganization({
          variables: {
            name: data.get('name')?.toString() || '',
            fields: {
              active: data.get('active')?.toString() === 'true',
              role: data.get('role')?.toString() as OrganizationRole,
              street: data.get('street')?.toString(),
              postcode: data.get('postcode')?.toString(),
              city: data.get('city')?.toString(),
              state: data.get('state')?.toString(),
              country: data.get('country')?.toString(),
              phone: data.get('phone')?.toString(),
              email: data.get('email')?.toString(),
              website: data.get('website')?.toString(),
            },
          },
        })
      } else {
        await updateOrganization({
          variables: {
            id: organizationId,
            name: data.get('name')?.toString(),
            fields: {
              active: data.get('active')?.toString() === 'true',
              role: data.get('role')?.toString() as OrganizationRole,
              street: data.get('street')?.toString(),
              postcode: data.get('postcode')?.toString(),
              city: data.get('city')?.toString(),
              state: data.get('state')?.toString(),
              country: data.get('country')?.toString(),
              phone: data.get('phone')?.toString(),
              email: data.get('email')?.toString(),
              website: data.get('website')?.toString(),
            },
          },
        })
      }
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: organizationId === 'new' ? 'Failed to create organization.' : 'Failed to update organization.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  const getOrganization = useQuery(api.GET_ORGANIZATION, { variables: { id: organizationId }, skip: organizationId === 'new' })
  const organization = getOrganization.data?.organization

  const roles: Components.EnumFieldItem[] =
    organizationId === 'new' || organization
      ? [
          {
            value: OrganizationRole.Standard,
            label: 'Standard',
            description: 'Can create and work on studies.',
            disabled: organization?.id === auth.current?.organizationId || true,
          },
          {
            value: OrganizationRole.SuperAdmin,
            label: 'Super administrator',
            description: 'Can manage categories / organizations.',
            disabled: organization?.id === auth.current?.organizationId || true,
          },
        ]
      : []

  return (
    <Components.SlideOver
      title={organizationId !== 'new' ? 'Edit organization' : 'Invite organization'}
      busy={busy}
      primaryAction={organizationId !== 'new' ? 'Update' : 'Invite'}
      onPrimaryAction={onUpsert}>
      {(organizationId === 'new' || organization) && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              required
              type="text"
              name="name"
              label="Name"
              defaultValue={organization?.name}
              disabled={busy}
              placeholder="Organization name"
            />
            <Components.CheckboxField
              name="active"
              label="Active"
              defaultChecked={organization?.active}
              disabled={busy || organization?.id === auth.current?.organizationId}
              description="Enable this organization."
            />
            <Components.EnumField name="role" label="Role" items={roles} defaultValue={organization?.role || OrganizationRole.Standard} />
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              type="text"
              name="street"
              label="Street"
              defaultValue={organization?.street || undefined}
              disabled={busy}
              placeholder="Street name"
            />
            <Components.TextField
              type="text"
              name="postcode"
              label="Postcode"
              defaultValue={organization?.postcode || undefined}
              disabled={busy}
              placeholder="Code"
            />
            <Components.TextField type="text" name="city" label="City" defaultValue={organization?.city || undefined} disabled={busy} placeholder="City/town" />
            <Components.TextField
              type="text"
              name="state"
              label="State"
              defaultValue={organization?.state || undefined}
              disabled={busy}
              placeholder="State/province/canton"
            />
            <Components.TextField
              type="text"
              name="country"
              label="Country"
              defaultValue={organization?.country || undefined}
              disabled={busy}
              placeholder="Country"
            />
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              type="phone"
              name="phone"
              label="Phone"
              defaultValue={organization?.phone || undefined}
              disabled={busy}
              placeholder="Phone number"
            />
            <Components.TextField
              type="email"
              name="email"
              label="Email"
              defaultValue={organization?.email || undefined}
              disabled={busy}
              placeholder="Email address"
            />
            <Components.TextField
              type="url"
              name="website"
              label="Website"
              defaultValue={organization?.website || undefined}
              disabled={busy}
              placeholder="Website URL"
            />
          </div>
        </div>
      )}
    </Components.SlideOver>
  )
}
