import { useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ArrowLongLeftIcon, ArrowLongRightIcon, MapPinIcon } from '@heroicons/react/24/outline'

import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import { StudyNode, StudyNodeType, StudyState } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyNodesListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')

  const apollo = useApolloClient()
  const [deleteStudyNode] = useMutation(api.DELETE_STUDY_NODE)
  const [busy, setBusy] = useState(false)

  async function onDelete(studyNode: Partial<StudyNode>) {
    const id = studyNode.id

    if (!id) {
      return
    }

    const confirm = await new Promise<boolean>((resolve) => {
      dispatch({
        action: AppAction.RequestConfirmation,
        confirmation: {
          title: 'Confirmation',
          message: (
            <span>
              Are you sure you want to delete <span className="font-medium">{studyNode.name}</span>?
            </span>
          ),
          resolve,
        },
      })
    })

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteStudyNode({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete study node.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestore(studyNode: Partial<StudyNode>) {
    const id = studyNode.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteStudyNode({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore study node.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsert() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getStudyNodeGraph = useQuery(api.GET_STUDY_NODE_GRAPH, { variables: { studyId } })

  const listStudyNodes = useQuery(api.LIST_STUDY_NODES, { variables: { studyId, request: { page, limit: 25, deleted: false } } })
  const studyNodeColumns: Components.TableColumn[] = [
    {
      name: 'sequence',
      label: 'Seq #',
      className: 'w-20 text-center',
    },
    {
      name: 'nodeType',
      label: 'Type',
      className: 'w-16 text-center',
    },
    {
      name: 'arrays',
      label: 'Ant #',
      className: 'w-20 text-center',
    },
    {
      name: 'denivelation',
      label: 'denivelation',
      className: 'w-20 text-right',
    },
    {
      name: 'topology',
      label: 'Topology',
      className: 'w-36',
    },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-24 text-right',
      interactive: true,
    },
  ]
  const studyNodeRows: Components.TableRow[] = (listStudyNodes.data?.studyNodes.items || []).map((node) => ({
    id: node.id,
    sequence: node.sequence,
    nodeType: (
      <span className="flex flex-col items-center">
        {node.nodeType === StudyNodeType.PointOfInterest && <MapPinIcon className="w-5 h-5" />}
        {node.nodeType === StudyNodeType.UpstreamPathway && <ArrowLongRightIcon className="w-5 h-5" />}
        {node.nodeType === StudyNodeType.DownstreamPathway && <ArrowLongLeftIcon className="w-5 h-5" />}
      </span>
    ),
    name: node.name,
    topology: Components.localizedText(node.pathwayTopology?.name, node.pathwayTopology?.localizedName),
    arrays: node.nodeType === StudyNodeType.UpstreamPathway || node.nodeType === StudyNodeType.DownstreamPathway ? node.antennaArrayCount : null,
    denivelation:
      node.nodeType === StudyNodeType.UpstreamPathway || (node.nodeType === StudyNodeType.DownstreamPathway && node.denivelation)
        ? `${numeral(node.denivelation).format('0.00')} [m]`
        : null,
    actions: study?.state === StudyState.Active && !study.deletedAt && (
      <div className="space-x-2">
        {node.deletedAt && (
          <button onClick={() => onRestore(node as StudyNode)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Restore
          </button>
        )}
        <button onClick={() => onDelete(node as StudyNode)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
          Delete
        </button>
      </div>
    ),
  }))

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Topology</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study.deletedAt && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>
                Add node
              </Components.HeaderPrimaryButton>
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Pathways/POIs topology</h2>
        </div>
        <div className="h-64 mb-5 border-b">
          {!!study && !!getStudyNodeGraph.data && (
            <Components.Graph model={getStudyNodeGraph.data.studyNodeGraph} onClick={(node) => onUpdate({ id: node.nodeId })} />
          )}
        </div>

        <Components.Table
          columns={studyNodeColumns}
          rows={studyNodeRows}
          stickyHeader
          page={page}
          pages={listStudyNodes.data?.studyNodes.pages}
          total={listStudyNodes.data?.studyNodes.total}
          loading={listStudyNodes.loading}
          onClick={onUpdate}
          onPage={onPage}
        />
      </main>

      <Outlet />
    </>
  )
}
