import { useState, TargetedEvent } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { XMarkIcon } from '@heroicons/react/20/solid'

import { JsonEditor } from 'jsoneditor-react18'

import * as api from '~/api'
import * as Components from '~/components'
import { AppAction, useAppDispatch } from '~/state'

export function CategoryUpsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const categoryId = params.categoryId || 'new'
  const [searchParams] = useSearchParams()

  const [abort] = useState(new AbortController())
  const [file, setFile] = useState<File | null>(null)

  const apollo = useApolloClient()
  const [busy, setBusy] = useState(false)
  const [insertCategory] = useMutation(api.INSERT_CATEGORY)
  const [updateCategory] = useMutation(api.UPDATE_CATEGORY)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      let storeId: string | undefined = undefined

      if (file) {
        if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
          throw new Error(`invalid file type (${file.type})`)
        }
        if (file.size > 100 * 1024 * 1024) {
          throw new Error('file too large (max 100mb)')
        }

        const uploadResponse = await fetch('/api/store/', {
          method: 'PUT',
          headers: new Headers({ 'Content-Type': file.type, 'X-Upload-Filename': file.name }),
          body: file,
          credentials: 'same-origin',
          cache: 'no-cache',
          signal: abort.signal,
        })

        if (!uploadResponse.ok) {
          throw new Error('file transfer error')
        }

        const store = (await uploadResponse.json()) as api.StoreResponse

        storeId = store.id
      }

      if (categoryId === 'new') {
        await insertCategory({
          variables: {
            name: data.get('name')?.toString() || '',
            storeId,
            fields: {
              localizedName: Components.localizedTextToInput(data.get('localizedName')),
            },
          },
        })
      } else {
        await updateCategory({
          variables: {
            id: categoryId,
            name: data.get('name')?.toString(),
            storeId,
            fields: {
              localizedName: Components.localizedTextToInput(data.get('localizedName')),
            },
          },
        })
      }

      try {
        await apollo.refetchQueries({ include: api.ALL_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: categoryId === 'new' ? 'Failed to create category.' : 'Failed to update category.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpload(ev: TargetedEvent<HTMLInputElement>) {
    setBusy(true)
    try {
      for (let i = 0; i < (ev.currentTarget.files?.length || 0); i++) {
        const file = ev.currentTarget.files?.item(i)

        if (!file) {
          continue
        }
        setFile(file)
      }
    } catch (e) {
      console.warn(e)
    } finally {
      setBusy(false)
    }
  }

  const getCategory = useQuery(api.GET_CATEGORY, { variables: { id: categoryId }, skip: categoryId === 'new' })
  const category = getCategory.data?.category
  const valueColumns: Components.TableColumn[] = [
    {
      name: 'index',
      label: '#',
      className: 'w-12 text-center',
    },
    {
      name: 'name',
      label: 'Code',
      className: 'w-32 truncate',
    },
    {
      name: 'localizedName',
      label: 'Name',
      className: 'w-48 truncate overflow-hidden',
    },
    {
      name: 'metadata',
      label: 'Metadata',
      className: 'truncate overflow-hidden',
    },
  ]
  const valueRows: Components.TableRow[] = (category?.categoryValues.items || []).map((value) => ({
    id: value.id,
    index: value.index,
    name: value.name,
    localizedName: value.localizedName && value.localizedName.length > 0 ? <Components.LocalizedValueDisplay value={value.localizedName as any} /> : null,
    metadata: value.metadata && (
      <JsonEditor mode="view" navigationBar={false} statusBar={false} search={false} value={JSON.parse(value.metadata)} schema={{}}></JsonEditor>
    ),
  }))

  let downloadAction = category ? (
    <a
      href={`/api/csv/category/${category.id}`}
      target="_blank"
      rel="noreferrer"
      className="rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-100">
      Download CSV
    </a>
  ) : null

  return (
    <Components.SlideOver
      title={categoryId !== 'new' ? 'Edit category' : 'Add category'}
      busy={busy}
      primaryAction={categoryId !== 'new' ? 'Update' : 'Create'}
      onPrimaryAction={onUpsert}
      extraActions={downloadAction}
      width="7xl">
      {(categoryId === 'new' || category) && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              required
              type="text"
              name="name"
              label="System name / code"
              defaultValue={category?.name}
              disabled={busy}
              placeholder="Category name/code"
            />
            <Components.LocalizedTextField type="text" name="localizedName" label="Localized name" items={category?.localizedName} disabled={busy} />
          </div>
          {category && (
            <div className="space-y-6 pb-5 pt-6 overflow-x-hidden">
              <Components.Table columns={valueColumns} rows={valueRows} condensed hideFooter />
            </div>
          )}
          <div className="space-y-6 pb-5 pt-6">
            {!file && !busy && (
              <div className="mt-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 p-6">
                <div className="text-center">
                  <label htmlFor="import-csv-file" className="relative cursor-pointer bg-white font-semibold text-wa21-600 hover:text-wa21-500">
                    <span>Upload CSV file</span>
                    <input id="import-csv-file" name="import-csv-file" type="file" className="sr-only" accept=".csv,text/csv" onChange={onUpload} />
                  </label>
                </div>
              </div>
            )}
            {file && (
              <ul className="w-full divide-y divide-gray-200">
                <li className="flex justify-between px-4 py-4 sm:px-0">
                  <span className="text-gray-800">{file.name}</span>
                  {!busy && (
                    <button className="text-wa21-600" onClick={() => setFile(null)}>
                      <XMarkIcon className="w-5 h-5" />
                    </button>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      )}
    </Components.SlideOver>
  )
}
