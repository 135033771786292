import { Route, createBrowserRouter, createRoutesFromElements, redirect } from 'react-router-dom'

import { AuthController } from './session'

import { Root } from './routes/root'
import * as Anonymous from './routes/anonymous'
import * as Authentified from './routes/authentified'

async function auth_status_checker() {
  const session = await AuthController.instance.load()

  if (session) {
    if (session?.organizationRole === 'super_admin') {
      return redirect('/app/organizations')
    }
    return redirect('/app/studies')
  }
  return redirect('/sign-in')
}

async function anonymous_required_checker() {
  const session = await AuthController.instance.load()

  if (session) {
    if (session?.organizationRole === 'super_admin') {
      return redirect('/app/organizations')
    }
    return redirect('/app/studies')
  }
  return null
}

async function auth_required_checker() {
  const session = await AuthController.instance.load()

  if (session) {
    return null
  }
  return redirect('/sign-in')
}

async function admin_required_checker() {
  const session = await AuthController.instance.load()

  if (session?.organizationRole === 'super_admin') {
    return null
  }
  return redirect('/sign-in')
}

export async function sign_out() {
  try {
    await fetch('/api/session', {
      method: 'DELETE',
    })
  } catch (e) {
    console.warn(e)
  }
  AuthController.instance.clear()
  return redirect('/sign-in')
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route path="" loader={auth_status_checker} />

      <Route path="sign-up" element={<Anonymous.SignUp />} loader={anonymous_required_checker} />
      <Route path="sign-in" element={<Anonymous.SignIn />} loader={anonymous_required_checker} />
      <Route path="sign-out" element={<Anonymous.SignOut />} action={sign_out} />
      <Route path="recover" element={<Anonymous.Recover />} loader={anonymous_required_checker} />

      <Route path="change-password/:token" element={<Anonymous.ChangePassword />} />

      <Route path="app" element={<Authentified.Layout />} loader={auth_required_checker}>
        <Route path="settings/organization" element={<Authentified.OrganizationSettings />} />
        <Route path="settings/user" element={<Authentified.UserSettings />} />
        <Route path="settings/user/change-password" element={<Authentified.ChangePassword />} />

        <Route path="categories" element={<Authentified.CategoriesListing />} loader={admin_required_checker}>
          <Route path=":categoryId" element={<Authentified.CategoryUpsert />} />
        </Route>

        <Route path="organizations" element={<Authentified.OrganizationsListing />} loader={admin_required_checker}>
          <Route path=":organizationId" element={<Authentified.OrganizationUpsert />} />
        </Route>

        <Route path="studies" element={<Authentified.StudiesListing />}>
          <Route path="new" element={<Authentified.StudyInsert />} />
        </Route>
        <Route path="studies/:studyId" element={<Authentified.StudyLayout />}>
          <Route path="" loader={() => redirect('dashboard')} />
          <Route path="dashboard" element={<Authentified.StudyDashboard />} />

          <Route path="datasets/detections/all" element={<Authentified.StudyDetectionEventsListing />}>
            <Route path=":eventId" element={<Authentified.StudyDetectionEventUpsert />} />
          </Route>
          <Route path="datasets/detections/statistics" element={<Authentified.StudyDetectionDatasetsStatistics />} />
          <Route path="datasets/detections/:datasetId" element={<Authentified.StudyDetectionDatasetUpdate />}>
            <Route path=":eventId" element={<Authentified.StudyDetectionEventUpsert />} />
          </Route>
          <Route path="datasets/detections" element={<Authentified.StudyDetectionDatasetsListing />}>
            <Route path="import" element={<Authentified.StudyDetectionDatasetImport />} />
            <Route path="new" element={<Authentified.StudyDetectionDatasetInsert />} />
          </Route>

          <Route path="datasets/organisms/all" element={<Authentified.StudyOrganismsListing />}>
            <Route path=":organismId" element={<Authentified.StudyOrganismUpsert />} />
          </Route>
          <Route path="datasets/organisms/statistics" element={<Authentified.StudyOrganismDatasetsStatistics />} />
          <Route path="datasets/organisms/:datasetId" element={<Authentified.StudyOrganismDatasetUpdate />}>
            <Route path=":organismId" element={<Authentified.StudyOrganismUpsert />} />
          </Route>
          <Route path="datasets/organisms" element={<Authentified.StudyOrganismDatasetsListing />}>
            <Route path="import" element={<Authentified.StudyOrganismDatasetImport />} />
            <Route path="new" element={<Authentified.StudyOrganismDatasetInsert />} />
          </Route>

          <Route path="nodes/:studyNodeId" element={<Authentified.StudyNodeUpdate />}>
            <Route path=":antennaArrayId" element={<Authentified.StudyAntennaArrayUpsert />} />
          </Route>
          <Route path="nodes" element={<Authentified.StudyNodesListing />}>
            <Route path="new" element={<Authentified.StudyNodeInsert />} />
          </Route>

          <Route path="analysis/summary" element={<Authentified.StudyResultsSummary />} />
          <Route path="analysis/summary/:organismId" element={<Authentified.StudyResultsOrganismEvents />} />
          <Route path="analysis/redetection" element={<Authentified.StudyResultsRedetection />} />
          <Route path="analysis/passage" element={<Authentified.StudyResultsPassage />} />
          <Route path="analysis/delay" element={<Authentified.StudyResultsDelay />} />
          <Route path="analysis/duration" element={<Authentified.StudyResultsDuration />} />
          <Route path="analysis/entry" element={<Authentified.StudyResultsEntry />} />
          <Route path="analysis/exit" element={<Authentified.StudyResultsExit />} />

          <Route path="evaluation/summary" element={<Authentified.StudyEvaluationSummary />} />
          <Route path="evaluation/discoverability" element={<Authentified.StudyEvaluationDiscoverability />} />
          <Route path="evaluation/passability" element={<Authentified.StudyEvaluationPassability />} />
          <Route path="evaluation/global-passability" element={<Authentified.StudyEvaluationGlobalPassability />} />
        </Route>

        <Route path="users" element={<Authentified.UsersListing />}>
          <Route path=":userId" element={<Authentified.UserUpsert />} />
        </Route>
      </Route>

      <Route path="*" element={<Anonymous.NotFound />}></Route>
    </Route>
  )
)

export default router
