import { useState } from 'react'
import { useNavigate, useSearchParams, Outlet } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { Category } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function CategoriesListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')
  const currentTab = searchParams.get('filter') || 'active'
  const deleted = currentTab === 'deleted'

  const apollo = useApolloClient()
  const [deleteCategory] = useMutation(api.DELETE_CATEGORY)
  const [markStudies] = useMutation(api.MARK_ALL_STUDIES)
  const [busy, setBusy] = useState(false)

  async function onDelete(category: Partial<Category>) {
    const id = category.id

    if (!id) {
      return
    }

    const confirm = await new Promise<boolean>((resolve) => {
      dispatch({
        action: AppAction.RequestConfirmation,
        confirmation: {
          title: 'Confirmation',
          message: (
            <span>
              Are you sure you want to delete <span className="font-medium">{category.name}</span>?
            </span>
          ),
          resolve,
        },
      })
    })

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteCategory({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ALL_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete category.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestore(category: Partial<Category>) {
    const id = category.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteCategory({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ALL_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore category.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onMarkStudies() {
    setBusy(true)
    try {
      await markStudies()
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to mark studies.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onAggregateStudies() {
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'CategoriesListing.onAggregate',
        title: 'Processing all studies',
        message: 'This can take a long time, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      let response = await markStudies({ variables: { aggregate: true } })
      let failedStudies = (response.data?.markStudies || []).filter((item) => !item.success).map((item) => item.studyId)

      if (failedStudies.length > 0) {
        dispatch({
          action: AppAction.PublishNotification,
          notification: { level: 'error', title: 'Action failed', message: `Failed to aggregate studies (${failedStudies.join(', ')}).` },
        })
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to aggregate studies.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'CategoriesListing.onAggregate' } })
      setBusy(false)
    }
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsert() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const listCategories = useQuery(api.LIST_CATEGORIES, { variables: { request: { page, limit: 25, deleted } } })
  const categoryColumns: Components.TableColumn[] = [
    {
      name: 'name',
      label: 'Code',
      className: 'w-64',
    },
    {
      name: 'localizedName',
      label: 'Name',
    },
    {
      name: 'values',
      label: 'Values',
      className: 'w-32 text-center',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-28 text-right',
      interactive: true,
    },
  ]
  const categoryRows: Components.TableRow[] = (listCategories.data?.categories.items || []).map((category) => ({
    id: category.id,
    name: category.name,
    localizedName:
      category.localizedName && category.localizedName.length > 0 ? <Components.LocalizedValueDisplay value={category.localizedName as any} /> : null,
    values: category.categoryValues.items.length,
    actions: (
      <div className="space-x-2">
        {category.deletedAt && (
          <button onClick={() => onRestore(category as Category)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Restore
          </button>
        )}
        {!category.deletedAt && (
          <button onClick={() => onDelete(category as Category)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            {!category.deletedAt ? 'Trash' : 'Delete'}
          </button>
        )}
      </div>
    ),
  }))

  const tabs: Components.HeaderTab[] = [
    {
      to: { search: '' },
      value: 'active',
      label: 'Active',
      // pill: currentTab === 'active' && listUsers.data?.organizationUsers.total,
    },
    {
      to: { search: 'filter=deleted' },
      value: 'deleted',
      label: 'Trash bin',
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Categories</h1>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Components.HeaderSecondaryButton onClick={onAggregateStudies} disabled={busy}>
              Aggregate all studies
            </Components.HeaderSecondaryButton>
            <Components.HeaderSecondaryButton onClick={onMarkStudies} disabled={busy}>
              Mark all studies
            </Components.HeaderSecondaryButton>
            <Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>
              Add category
            </Components.HeaderPrimaryButton>
          </div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Components.Table
          columns={categoryColumns}
          rows={categoryRows}
          stickyHeader
          page={page}
          pages={listCategories.data?.categories.pages}
          total={listCategories.data?.categories.total}
          loading={listCategories.loading}
          onClick={onUpdate}
          onPage={onPage}
        />
      </main>

      <Outlet />
    </div>
  )
}
