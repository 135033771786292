import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { UserRole } from '~/graphql-codegen/graphql'
import { useAuth } from '~/session'
import { AppAction, useAppDispatch } from '~/state'

export function UserUpsert() {
  const auth = useAuth()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const userId = params.userId || 'new'
  const [searchParams] = useSearchParams()

  const userResponse = useQuery(api.GET_USER, { variables: { id: userId }, skip: userId === 'new' })
  const user = userResponse.data?.user

  const apollo = useApolloClient()
  const [insertUser] = useMutation(api.INSERT_USER)
  const [updateUser] = useMutation(api.UPDATE_USER)
  const [busy, setBusy] = useState(false)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      const fields = {
        role: Components.maybeEnum<UserRole>(data, 'role'),
        firstname: Components.maybeString(data, 'firstname'),
        surname: Components.maybeString(data, 'surname'),
      }

      if (userId === 'new') {
        await insertUser({
          variables: {
            organizationId: auth.current?.organizationId || '0',
            email: Components.requireData(Components.maybeString(data, 'email')),
            fields: {
              ...fields,
              active: true,
            },
          },
        })
      } else {
        await updateUser({
          variables: {
            id: userId,
            fields,
          },
        })
      }
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: userId === 'new' ? 'Failed to create user.' : 'Failed to update user.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const roles: Components.EnumFieldItem[] = [
    {
      value: UserRole.Admin,
      label: 'Administrator',
      description: 'Can perform all actions.',
      defaultChecked: user?.role === UserRole.Admin,
      disabled: user?.id === auth.current?.userId,
    },
    {
      value: UserRole.Standard,
      label: 'Standard',
      description: 'Can only manage studies.',
      defaultChecked: userId === 'new' || user?.role === UserRole.Standard,
      disabled: user?.id === auth.current?.userId,
    },
  ]

  return (
    <Components.SlideOver
      title={userId !== 'new' ? 'Edit user' : 'Invite user'}
      busy={busy}
      primaryAction={userId !== 'new' ? 'Update' : 'Invite'}
      onPrimaryAction={onUpsert}>
      {(userId === 'new' || user) && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.TextField
              type="email"
              name="email"
              label="Email"
              defaultValue={user?.email}
              disabled={busy || !!user}
              placeholder="Email address"
              required
            />
            <Components.TextField
              type="text"
              name="firstname"
              label="Firstname"
              defaultValue={user?.firstname || undefined}
              disabled={busy}
              placeholder="User's firstname"
            />
            <Components.TextField
              type="text"
              name="surname"
              label="Surname"
              defaultValue={user?.surname || undefined}
              disabled={busy}
              placeholder="User's surname"
            />
            <Components.EnumField name="role" label="Role" items={roles} disabled={busy || user?.id === auth.current?.userId} />
          </div>
        </div>
      )}
    </Components.SlideOver>
  )
}
