import { useState } from 'react'
import { useNavigate, useSearchParams, Outlet } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { Organization } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function OrganizationsListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')
  const currentTab = searchParams.get('filter') || 'active'
  const deleted = currentTab === 'deleted'
  const active = deleted ? undefined : currentTab === 'active'

  const apollo = useApolloClient()
  const [deleteOrganization] = useMutation(api.DELETE_ORGANIZATION)
  const [busy, setBusy] = useState(false)

  async function onDelete(organization: Partial<Organization>) {
    const id = organization.id

    if (!id) {
      return
    }

    const confirm =
      !organization.deletedAt ||
      (await new Promise<boolean>((resolve) => {
        dispatch({
          action: AppAction.RequestConfirmation,
          confirmation: {
            title: 'Confirmation',
            message: (
              <span>
                Are you sure you want to delete <span className="font-medium">{organization.name}</span>?
              </span>
            ),
            resolve,
          },
        })
      }))

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteOrganization({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete organization.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestore(organization: Partial<Organization>) {
    const id = organization.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteOrganization({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore organization.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsert() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getCurrentOrganization = useQuery(api.CURRENT_ORGANIZATION)
  const currentOrganization = getCurrentOrganization.data?.currentOrganization

  const listOrganizations = useQuery(api.LIST_ORGANIZATIONS, { variables: { request: { page, limit: 25, active, deleted } } })
  const organizationColumns: Components.TableColumn[] = [
    {
      name: 'name',
      label: 'Name',
      className: 'w-64',
    },
    {
      name: 'contact',
      label: 'Contact',
      interactive: true,
    },
    {
      name: 'role',
      label: 'Role',
      className: 'w-32 text-center',
    },
    {
      name: 'users',
      label: 'Users',
      className: 'w-32 text-right',
    },
    {
      name: 'studies',
      label: 'Studies',
      className: 'w-32 text-right',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-28 text-right',
      interactive: true,
    },
  ]
  const organizationRows: Components.TableRow[] = (listOrganizations.data?.organizations.items || []).map((organization) => ({
    id: organization.id,
    name: organization.name,
    contact: (
      <address>
        {organization.street && <p>{organization.street}</p>}
        {(organization.postcode || organization.city) && (
          <p>
            {organization.postcode} {organization.city}
          </p>
        )}
        {(organization.state || organization.country) && (
          <p>
            {organization.state} {organization.country}
          </p>
        )}
        {organization.phone && (
          <p>
            <a href={`tel:${organization.phone}`}>{organization.phone}</a>
          </p>
        )}
        {organization.email && (
          <p>
            <a href={`mailto:${organization.email}`}>{organization.email}</a>
          </p>
        )}
        {organization.website && (
          <p>
            <a href={organization.website} target="_blank" rel="noreferrer">
              {organization.website}
            </a>
          </p>
        )}
      </address>
    ),
    role: organization.role,
    users: organization.userCount,
    studies: organization.studyCount,
    actions: (
      <div className="space-x-2">
        {organization.deletedAt && (
          <button onClick={() => onRestore(organization as Organization)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Restore
          </button>
        )}
        {organization.id !== currentOrganization?.id && (
          <button onClick={() => onDelete(organization as Organization)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            {!organization.deletedAt ? 'Trash' : 'Delete'}
          </button>
        )}
      </div>
    ),
  }))

  const tabs: Components.HeaderTab[] = [
    {
      to: { search: '' },
      value: 'active',
      label: 'Active',
      // pill: currentTab === 'active' && listUsers.data?.organizationUsers.total,
    },
    {
      to: { search: 'filter=inactive' },
      value: 'inactive',
      label: 'Inactive',
    },
    {
      to: { search: 'filter=deleted' },
      value: 'deleted',
      label: 'Trash bin',
    },
  ]

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Organizations</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>
              Add organization
            </Components.HeaderPrimaryButton>
          </div>
        </div>
        <Components.HeaderTabs className="mt-2" tabs={tabs} currentTab={currentTab} />
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Components.Table
          columns={organizationColumns}
          rows={organizationRows}
          stickyHeader
          page={page}
          pages={listOrganizations.data?.organizations.pages}
          total={listOrganizations.data?.organizations.total}
          loading={listOrganizations.loading}
          onClick={onUpdate}
          onPage={onPage}
        />
      </main>

      <Outlet />
    </div>
  )
}
