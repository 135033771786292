import { useState } from 'react'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { Organization, UpdateOrganizationMutationVariables, UpsertOrganizationFields } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function OrganizationSettings() {
  const dispatch = useAppDispatch()

  const apollo = useApolloClient()
  const [updateOrganization] = useMutation(api.UPDATE_ORGANIZATION)
  const [, setBusy] = useState(false)

  async function onUpdate<K extends keyof Organization>(key: K, value: Organization[K]) {
    setBusy(true)
    try {
      const variables: UpdateOrganizationMutationVariables = {
        id: organization?.id || '',
        fields: {},
      }

      if (key === 'name') {
        variables.name = value
      } else {
        variables.fields[key as keyof UpsertOrganizationFields] = value
      }
      await updateOrganization({ variables })
      try {
        await apollo.refetchQueries({ include: api.ORGANIZATION_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update organization.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const currentOrganization = useQuery(api.CURRENT_ORGANIZATION)
  const organization = currentOrganization.data?.currentOrganization

  return (
    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Organisation settings</h1>
          </div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        {organization && (
          <dl className="divide-y divide-gray-200">
            <Components.TextProperty
              label="Organization name"
              name="name"
              currentValue={organization?.name}
              required
              onUpdate={(value) => onUpdate('name', value || '')}
            />
            <Components.TextProperty label="Street" name="street" currentValue={organization?.street} onUpdate={(value) => onUpdate('street', value)} />
            <Components.TextProperty label="Postcode" name="postcode" currentValue={organization?.postcode} onUpdate={(value) => onUpdate('postcode', value)} />
            <Components.TextProperty label="City/Town" name="city" currentValue={organization?.city} onUpdate={(value) => onUpdate('city', value)} />
            <Components.TextProperty label="State/Province" name="state" currentValue={organization?.state} onUpdate={(value) => onUpdate('state', value)} />
            <Components.TextProperty label="Country" name="country" currentValue={organization?.country} onUpdate={(value) => onUpdate('country', value)} />
            <Components.TextProperty
              type="phone"
              label="Phone"
              name="phone"
              currentValue={organization?.phone}
              onUpdate={(value) => onUpdate('phone', value)}
            />
            <Components.TextProperty
              type="email"
              label="Email"
              name="email"
              currentValue={organization?.email}
              onUpdate={(value) => onUpdate('email', value)}
            />
            <Components.TextProperty
              type="url"
              label="Website"
              name="website"
              currentValue={organization?.website}
              onUpdate={(value) => onUpdate('website', value)}
            />
          </dl>
        )}
      </main>
    </div>
  )
}
