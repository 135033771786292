import { useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import _ from 'lodash'

import * as api from '~/api'
import * as Components from '~/components'
import {
  AntennaArray,
  PrimaryCoordinate,
  StudyNode,
  StudyNodeType,
  StudyState,
  UpdateStudyNodeMutationVariables,
  UpsertStudyNodeFields,
  WellKnownCategories,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyNodeUpdate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const studyNodeId = params.studyNodeId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')

  const apollo = useApolloClient()
  const [updateStudyNode] = useMutation(api.UPDATE_STUDY_NODE)
  const [deleteAntennaArray] = useMutation(api.DELETE_ANTENNA_ARRAY)
  const [busy, setBusy] = useState(false)

  async function onUpdate<K extends keyof StudyNode>(key: K, value: StudyNode[K]) {
    setBusy(true)
    try {
      const variables: UpdateStudyNodeMutationVariables = {
        id: studyNodeId,
        fields: {},
      }

      if (key === 'sequence') {
        variables.sequence = value
      } else if (key === 'name') {
        variables.name = value
      } else if (key === 'nodeType') {
        variables.nodeType = value
      } else if (key === 'denivelation') {
        variables.denivelation = value
      } else {
        variables.fields[key as keyof UpsertStudyNodeFields] = value
      }
      await updateStudyNode({ variables })
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update study node.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateResults(results: string[] | null | undefined) {
    setBusy(true)
    try {
      const variables: UpdateStudyNodeMutationVariables = {
        id: studyNodeId,
        fields: {
          enableRedetectionRate: (results || []).indexOf('redetection') >= 0,
          enableEntryEfficiency: (results || []).indexOf('entry') >= 0,
          enableExitEfficiency: (results || []).indexOf('exit') >= 0,
          enablePassageEfficiency: (results || []).indexOf('passage') >= 0,
        },
      }

      await updateStudyNode({ variables })
      try {
        await apollo.refetchQueries({ include: api.RESULT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update study node.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onDeleteAntennaArray(antennaArray: Partial<AntennaArray>) {
    const id = antennaArray.id

    if (!id) {
      return
    }

    const confirm = await new Promise<boolean>((resolve) => {
      dispatch({
        action: AppAction.RequestConfirmation,
        confirmation: {
          title: 'Confirmation',
          message: (
            <span>
              Are you sure you want to delete <span className="font-medium">{antennaArray.name}</span>?
            </span>
          ),
          resolve,
        },
      })
    })

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteAntennaArray({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete antenna array.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestoreAntennaArray(antennaArray: Partial<AntennaArray>) {
    const id = antennaArray.id

    if (!id) {
      return
    }
    setBusy(true)
    try {
      await deleteAntennaArray({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore antenna array.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onInsertAntennaArray() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onUpdateAntennaArray(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getStudyNode = useQuery(api.GET_STUDY_NODE, { variables: { id: studyNodeId } })
  const studyNode = getStudyNode.data?.studyNode

  const getAntennaArrayGraph = useQuery(api.GET_ANTENNA_ARRAY_GRAPH, { variables: { studyNodeId } })

  const getPathwayTopology = useQuery(api.FIND_CATEGORY, { variables: { name: WellKnownCategories.StudyNodePathwayTopology } })
  const pathwayTopologies = (getPathwayTopology.data?.findCategory.categoryValues.items || []).map((value) => ({
    value: value.id,
    label: Components.localizedText(value.name, value.localizedName),
  }))
  const pathwayTopology = _.first(
    (getPathwayTopology.data?.findCategory.categoryValues.items || []).filter((value) => value.id === studyNode?.pathwayTopologyId)
  )

  const listAntennaArrays = useQuery(api.LIST_ANTENNA_ARRAYS, { variables: { studyId, request: { page, limit: 25, deleted: false, studyNodeId } } })
  const antennaArrayColumns: Components.TableColumn[] = [
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'antennas',
      label: 'Antennas',
      className: 'w-64',
    },
    {
      name: 'location',
      label: 'Coordinates',
      className: 'w-64',
    },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-24 text-right',
      interactive: true,
    },
  ]
  const antennaArrayRows: Components.TableRow[] = (listAntennaArrays.data?.antennaArrays.items || []).map((antennaArray) => ({
    id: antennaArray.id,
    name: (
      <div className="flex">
        <span className="flex-shrink-0">{antennaArray.name}</span>
        {antennaArray.description && <span className="ml-2 flex-auto max-w-lg italic text-gray-500 truncate">({antennaArray.description})</span>}
      </div>
    ),
    location: <Components.CoordinatesDisplay value={antennaArray.location as any} />,
    antennas:
      antennaArray.antennaNames.length > 0
        ? ['D', ...antennaArray.antennaNames, 'U'].join(studyNode?.nodeType === StudyNodeType.DownstreamPathway ? ' ← ' : ' → ')
        : undefined,
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {antennaArray.deletedAt && (
          <button onClick={() => onRestoreAntennaArray(antennaArray)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Restore
          </button>
        )}
        <button onClick={() => onDeleteAntennaArray(antennaArray)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
          Delete
        </button>
      </div>
    ),
  }))

  const nodeTypes: Components.EnumPropertyItem[] = [
    {
      value: StudyNodeType.UpstreamPathway,
      label: 'Pathway (upstream)',
    },
    {
      value: StudyNodeType.DownstreamPathway,
      label: 'Pathway (downstream)',
    },
  ]

  const availableResults: Components.EnumPropertyItem[] = [
    {
      value: 'redetection',
      label: 'Re-detection',
    },
    {
      value: 'entry',
      label: 'Entry efficiency',
    },
    {
      value: 'exit',
      label: 'Exit efficiency',
    },
    {
      value: 'passage',
      label: 'Passage efficiency',
    },
  ]
  const enabledResults: string[] = []

  if (studyNode?.enableRedetectionRate) {
    enabledResults.push('redetection')
  }
  if (studyNode?.enableEntryEfficiency) {
    enabledResults.push('entry')
  }
  if (studyNode?.enableExitEfficiency) {
    enabledResults.push('exit')
  }
  if (studyNode?.enablePassageEfficiency) {
    enabledResults.push('passage')
  }

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../nodes" className="hover:text-wa21-100">
                Nodes
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>{studyNode?.name}</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {(studyNode?.nodeType === StudyNodeType.UpstreamPathway || studyNode?.nodeType === StudyNodeType.DownstreamPathway) &&
                pathwayTopology?.name === 'custom' && (
                  <Components.HeaderPrimaryButton onClick={onInsertAntennaArray} disabled={busy}>
                    Add antenna array
                  </Components.HeaderPrimaryButton>
                )}
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Pathway details</h2>
        </div>
        {!!studyNode && (
          <dl className="divide-y divide-gray-200">
            <Components.TextProperty
              label="Seq #"
              name="sequence"
              currentValue={studyNode?.sequence.toString()}
              required
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('sequence', parseInt(value || '0'))}
            />
            {studyNode?.nodeType === StudyNodeType.PointOfInterest && (
              <Components.ReadonlyProperty label="Node type" name="nodeType" currentValue={studyNode?.nodeType} />
            )}
            {studyNode?.nodeType !== StudyNodeType.PointOfInterest && (
              <Components.EnumProperty
                label="Node type"
                name="nodeType"
                items={nodeTypes}
                currentValue={studyNode?.nodeType}
                required
                disabled={study?.state !== StudyState.Active || study?.deletedAt}
                onUpdate={(value) => onUpdate('nodeType', value as StudyNodeType)}
              />
            )}
            <Components.TextProperty
              label="Name"
              name="name"
              currentValue={studyNode?.name}
              required
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('name', value || '')}
            />
            {studyNode?.nodeType !== StudyNodeType.PointOfInterest && (
              <Components.EnumProperty
                label="Topology"
                name="template"
                items={pathwayTopologies}
                currentValue={studyNode?.pathwayTopologyId}
                required
                disabled={study?.state !== StudyState.Active || study?.deletedAt}
                onUpdate={(value) => onUpdate('pathwayTopologyId', value)}
              />
            )}
            {studyNode?.nodeType !== StudyNodeType.PointOfInterest && (
              <Components.MultiEnumProperty
                label="Enabled results"
                name="results"
                items={availableResults}
                disabled={study?.state !== StudyState.Active || study?.deletedAt}
                currentValue={enabledResults}
                onUpdate={onUpdateResults}
              />
            )}
            {studyNode?.nodeType !== StudyNodeType.PointOfInterest && (
              <Components.TextProperty
                type="number"
                step="0.01"
                label="Denivelation (m)"
                name="denivelation"
                numberFormat="0.00"
                disabled={study?.state !== StudyState.Active || study?.deletedAt}
                currentValue={studyNode?.denivelation}
                onUpdate={(value) => onUpdate('denivelation', value || '')}
              />
            )}
            <Components.TextAreaProperty
              label="Description / Notes"
              name="description"
              currentValue={studyNode?.description}
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('description', value)}
            />
            <Components.CoordinatesProperty
              label="Coordinates"
              name="coordinates"
              currentValue={studyNode?.location}
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('location', value)}
            />
            {studyNode?.location && studyNode.location.primary !== PrimaryCoordinate.None && (
              <Components.MapProperty label="Map" name="map" currentValue={studyNode.location} />
            )}
          </dl>
        )}
        {(studyNode?.nodeType === StudyNodeType.UpstreamPathway || studyNode?.nodeType === StudyNodeType.DownstreamPathway) && (
          <>
            <div className="pt-5 border-b border-gray-200 pb-5">
              <h2 className="text-base font-semibold leading-6 text-gray-900">Antenna arrays</h2>
            </div>
            {(listAntennaArrays.data?.antennaArrays.total || 0) > 0 && (
              <div className="h-80 mb-5 border-b">
                {!!getAntennaArrayGraph.data && (
                  <Components.Graph model={getAntennaArrayGraph.data.antennaArrayGraph} onClick={(node) => onUpdateAntennaArray({ id: node.nodeId })} />
                )}
              </div>
            )}
            <Components.Table
              columns={antennaArrayColumns}
              rows={antennaArrayRows}
              stickyHeader
              page={page}
              pages={listAntennaArrays.data?.antennaArrays.pages}
              total={listAntennaArrays.data?.antennaArrays.total}
              loading={listAntennaArrays.loading}
              onClick={onUpdateAntennaArray}
              onPage={onPage}
            />
          </>
        )}
      </main>

      <Outlet />
    </>
  )
}
