import { useQuery } from '@apollo/client'

import _ from 'lodash'

import * as api from '~/api'
import { WellKnownCategories } from '~/graphql-codegen/graphql'

import { localizedText } from './localized'

type CategoryValueAttributes = {
  category: WellKnownCategories
  value?: string | null
}

export function CategoryValueDisplay({ category, value, ...props }: React.HTMLAttributes<HTMLElement> & CategoryValueAttributes) {
  const findCategoryValue = useQuery(api.FIND_CATEGORY_VALUE, { variables: { name: category, id: value || '' }, skip: !value || value.toString().length === 0 })
  const categoryValue = findCategoryValue.data?.findCategoryValue

  return <span {...props}>{categoryValue ? localizedText(categoryValue.name, categoryValue.localizedDisplay ? categoryValue.localizedName : []) : '-'}</span>
}

type OrganismTagAttributes = {
  organismId?: string | null
  displayLabel?: boolean
}

export function OrganismTagDisplay({ organismId, displayLabel, ...props }: React.HTMLAttributes<HTMLElement> & OrganismTagAttributes) {
  const getOrganism = useQuery(api.GET_ORGANISM, { variables: { id: organismId || '' }, skip: !organismId })
  const organism = getOrganism.data?.organism

  return (
    <span {...props}>
      {organism ? organism.tag : '-'}
      {organism && displayLabel && organism.label && (
        <span className="ml-2 p-1 rounded bg-gray-50 border border-gray-200 text-xs text-gray-500">{organism.label}</span>
      )}
    </span>
  )
}

type StudyNodeAttributes = {
  studyNodeId?: string | null
  antennaArrayId?: string | null
}

export function StudyNodeDisplay({ studyNodeId, antennaArrayId, ...props }: React.HTMLAttributes<HTMLElement> & StudyNodeAttributes) {
  const getAntennaArray = useQuery(api.GET_ANTENNA_ARRAY, { variables: { id: antennaArrayId || '' }, skip: !antennaArrayId })
  const antennaArray = getAntennaArray.data?.antennaArray
  const studyNodeIdInternal = studyNodeId || antennaArray?.studyNodeId
  const getStudyNode = useQuery(api.GET_STUDY_NODE, { variables: { id: studyNodeIdInternal || '' }, skip: !studyNodeIdInternal })
  const studyNode = getStudyNode.data?.studyNode

  return <span {...props}>{studyNode ? studyNode.name : '-'}</span>
}

type AntennaNameAttributes = {
  antennaArrayId?: string | null
  antenna?: string | null
}

export function AntennaNameDisplay({ antennaArrayId, antenna, ...props }: React.HTMLAttributes<HTMLElement> & AntennaNameAttributes) {
  const getAntennaArray = useQuery(api.GET_ANTENNA_ARRAY, { variables: { id: antennaArrayId || '' }, skip: !antennaArrayId })
  const antennaArray = getAntennaArray.data?.antennaArray
  const antennaName = (antennaArray?.antennaNames || []).find((name) => name.toLowerCase() === antenna?.toLowerCase())

  if (!antennaArray) {
    return <span {...props}>-</span>
  }
  return (
    <span {...props}>
      {antennaArray.name}
      {antennaName ? ` (${antennaName})` : ''}
    </span>
  )
}
