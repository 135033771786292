import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import { PrimaryCoordinate, Study, StudyState, UpdateStudyMutationVariables, UpsertStudyFields, WellKnownCategories } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyDashboard() {
  const dispatch = useAppDispatch()
  const params = useParams()
  const studyId = params.studyId || '0'

  const apollo = useApolloClient()
  const [updateStudy] = useMutation(api.UPDATE_STUDY)
  const [busy, setBusy] = useState(false)

  async function onUpdate<K extends keyof Study>(key: K, value: Study[K]) {
    setBusy(true)
    try {
      const variables: UpdateStudyMutationVariables = {
        id: studyId,
        fields: {},
      }

      if (key === 'name') {
        variables.name = value
      } else {
        if (key === 'validFrom' || key === 'validUntil') {
          variables.fields[key as keyof UpsertStudyFields] = value ? parseInt(value) : null
        } else {
          variables.fields[key as keyof UpsertStudyFields] = value
        }
      }
      await updateStudy({ variables })
      if (key === 'state' || key === 'validFrom' || key === 'validUntil') {
        try {
          await apollo.refetchQueries({ include: api.STUDY_QUERIES })
        } catch (e) {
          console.warn(e)
        }
      }
      if (key === 'excludeBottomSwimmers') {
        try {
          await apollo.refetchQueries({ include: api.EVALUATION_QUERIES })
        } catch (e) {
          console.warn(e)
        }
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onActivate() {
    setBusy(true)
    try {
      await onUpdate('state', StudyState.Active)
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to activate study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onArchive() {
    setBusy(true)
    try {
      await onUpdate('state', StudyState.Archived)
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to archive study.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getWaterBodyType = useQuery(api.FIND_CATEGORY, { variables: { name: WellKnownCategories.StudyWaterBodyType } })
  const waterBodyTypes = (getWaterBodyType.data?.findCategory.categoryValues.items || []).map((value) => ({
    value: value.id,
    label: Components.localizedText(value.name, value.localizedName),
  }))

  const statistics: Components.CardProps[] = [
    {
      title: 'Pathways/POIs',
      value: <p className="text-2xl font-semibold text-gray-900">{study?.studyNodeCount}</p>,
      footer: (
        <div className="text-sm">
          <Link className="font-medium text-wa21-600 hover:text-wa21-500" to="../nodes">
            Manage pathways/POIs
          </Link>
        </div>
      ),
    },
    {
      title: 'Individuals',
      value: <p className="text-2xl font-semibold text-gray-900">{numeral(study?.validOrganismCount).format('0,0')}</p>,
      footer: (
        <div className="text-sm">
          <Link className="font-medium text-wa21-600 hover:text-wa21-500" to="../datasets/organisms">
            Manage individuals
          </Link>
        </div>
      ),
    },
    {
      title: 'Detection events',
      value: <p className="text-2xl font-semibold text-gray-900">{numeral(study?.validDetectionCount).format('0,0')}</p>,
      footer: (
        <div className="text-sm">
          <Link className="font-medium text-wa21-600 hover:text-wa21-500" to="../datasets/detections">
            Manage detection events
          </Link>
        </div>
      ),
    },
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="text-3xl font-bold tracking-tight text-white">Study</h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {!study?.deletedAt && study?.state === StudyState.Active && (
              <Components.HeaderSecondaryButton onClick={onArchive} disabled={busy}>
                Archive study
              </Components.HeaderSecondaryButton>
              // <Components.HeaderPrimaryButton onClick={onPublish} disabled={busy}>
              //   Publish
              // </Components.HeaderPrimaryButton>
            )}
            {!study?.deletedAt && study?.state !== StudyState.Active && (
              <Components.HeaderPrimaryButton onClick={onActivate} disabled={busy}>
                Re-activate study
              </Components.HeaderPrimaryButton>
            )}
          </div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Study details</h2>
        </div>
        {!!study && (
          <dl className="divide-y divide-gray-200">
            <Components.TextProperty
              label="Name"
              name="name"
              currentValue={study?.name}
              required
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('name', value || '')}
            />
            <Components.TextAreaProperty
              label="Description"
              name="description"
              currentValue={study?.description}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('description', value)}
            />
            <Components.TextProperty
              label="Water body"
              name="waterBody"
              currentValue={study?.waterBody}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('waterBody', value)}
            />
            <Components.EnumProperty
              label="Water body type"
              name="waterBodyTypeId"
              items={waterBodyTypes}
              currentValue={study?.waterBodyTypeId}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('waterBodyTypeId', value)}
            />
            <Components.TextProperty
              type="date"
              label="Valid from"
              name="validFrom"
              currentValue={study?.validFrom}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('validFrom', value)}
            />
            <Components.TextProperty
              type="date"
              label="Valid until"
              name="validUntil"
              currentValue={study?.validUntil}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('validUntil', value)}
            />
            <Components.ToggleProperty
              label="Exclude bottom swimmer"
              name="excludeBottomSwimmers"
              currentValue={study?.excludeBottomSwimmers}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('excludeBottomSwimmers', !!value)}
            />
            <Components.CoordinatesProperty
              label="Coordinates"
              name="coordinates"
              currentValue={study?.location}
              disabled={study.state !== StudyState.Active || study.deletedAt}
              onUpdate={(value) => onUpdate('location', value)}
            />
            {study?.location && study.location.primary !== PrimaryCoordinate.None && (
              <Components.MapProperty label="Map" name="map" currentValue={study?.location} />
            )}
          </dl>
        )}
        {/*<div className="pt-10 border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Key metrics</h2>
        </div>
        <p className="mt-5">TBD...</p>*/}
        <div className="pt-10 border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Statistics</h2>
        </div>
        <Components.Cards items={statistics} columns={3} />
      </main>
    </>
  )
}
