import { Decimal } from 'decimal.js'

import { PrimaryCoordinate, Coordinates, Lv03Coordinates, Lv95Coordinates, Wgs84Coordinates } from '~/graphql-codegen/graphql'

type CoordinatesAttributes = {
  value: Coordinates | null | undefined
}

export function CoordinatesDisplay({ value, ...props }: React.HTMLAttributes<HTMLSpanElement> & CoordinatesAttributes) {
  const { primary, lv03, lv95, wgs84 } = value || {}

  function formatLV03(value: Lv03Coordinates) {
    try {
      const x = new Decimal(value.x).toDP(0)
      const y = new Decimal(value.y).toDP(0)
      const altitude = value.altitude ? new Decimal(value.altitude) : undefined

      return (
        <span className="space-x-3" {...props}>
          <span>
            {x.toString()}
            <span className="text-gray-400 select-none"> X </span>
          </span>
          <span>
            {y.toString()}
            <span className="text-gray-400 select-none"> Y </span>
          </span>
          {altitude && (
            <span>
              {altitude.toFixed(2)}
              <span className="text-gray-400 select-none"> m </span>
            </span>
          )}
          <span className="inline-flex items-center bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> LV03 </span>
        </span>
      )
    } catch (e) {
      return '-'
    }
  }

  function formatLV95(value: Lv95Coordinates) {
    try {
      const east = new Decimal(value.east).toDP(0)
      const north = new Decimal(value.north).toDP(0)
      const altitude = value.altitude ? new Decimal(value.altitude) : undefined

      return (
        <span className="space-x-3" {...props}>
          <span>
            {east.toString()}
            <span className="text-gray-400 select-none"> E </span>
          </span>
          <span>
            {north.toString()}
            <span className="text-gray-400 select-none"> N </span>
          </span>
          {altitude && (
            <span>
              {altitude.toFixed(2)}
              <span className="text-gray-400 select-none"> m </span>
            </span>
          )}
          <span className="inline-flex items-center bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> LV95 </span>
        </span>
      )
    } catch (e) {
      return '-'
    }
  }

  function ddToDms(value: string) {
    const dd = new Decimal(value)
    const d = dd.floor()
    const m = dd.sub(d).mul(60).floor()
    const s = dd.sub(d).mul(60).sub(m).mul(60)

    return {
      d,
      m,
      s,
    }
  }

  function formatWGS84(value: Wgs84Coordinates) {
    try {
      const latitude = ddToDms(value.latitude)
      const longitude = ddToDms(value.longitude)
      const altitude = value.altitude ? new Decimal(value.altitude) : undefined

      return (
        <span className="space-x-3" {...props}>
          <span>
            {latitude.d.toString()}
            <span className="text-gray-400">°</span> {latitude.m.toString()}
            <span className="text-gray-400"> '</span> {latitude.s.toFixed(2)}
            <span className="text-gray-400"> "</span>
            <span className="text-gray-400 select-none"> E </span>
          </span>
          <span>
            {longitude.d.toString()}
            <span className="text-gray-400">°</span> {longitude.m.toString()}
            <span className="text-gray-400"> '</span> {longitude.s.toFixed(2)}
            <span className="text-gray-400"> "</span>
            <span className="text-gray-400 select-none"> N </span>
          </span>
          {altitude && (
            <span>
              {altitude.toFixed(2)}
              <span className="text-gray-400 select-none"> m </span>
            </span>
          )}
          <span className="inline-flex items-center bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"> WGS84 </span>
        </span>
      )
    } catch (e) {
      return '-'
    }
  }

  return (
    <>
      {(primary === undefined || primary === PrimaryCoordinate.None) && '-'}
      {primary === PrimaryCoordinate.Lv03 && lv03 && formatLV03(lv03)}
      {primary === PrimaryCoordinate.Lv95 && lv95 && formatLV95(lv95)}
      {primary === PrimaryCoordinate.Wgs84 && wgs84 && formatWGS84(wgs84)}
    </>
  )
}
