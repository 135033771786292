import _ from 'lodash'

import { LocalizedValue, LocalizedValueInput } from '~/graphql-codegen/graphql'

type LocalizedValueAttributes = {
  value: LocalizedValue[] | null | undefined
}

export function localizedText(name?: string, value?: LocalizedValueInput[] | null): string | undefined {
  // TODO: active language
  return _.first((value || []).filter((item) => item.lang === 'en'))?.value || _.first(value || [])?.value || name
}

export function localizedMapToArray(value?: { [k: string]: string }): LocalizedValue[] {
  return _.toPairs(value || {})
    .filter(([lang, value]) => lang.trim().length > 0 && value.trim().length > 0)
    .map(([lang, value]) => ({ lang: lang.trim(), value: value.trim() }))
}

export function localizedTextToInput(value?: FormDataEntryValue | null): LocalizedValueInput[] {
  let items: { [k: string]: string } = JSON.parse(value?.toString() || '{}') || {}

  return localizedMapToArray(items)
}

export function LocalizedValueDisplay({ value, ...props }: React.HTMLAttributes<HTMLDListElement> & LocalizedValueAttributes) {
  return (
    <dl {...props} className="inline-flex flex-wrap gap-1">
      {(value || []).map((value, i) => (
        <div key={i} className="inline-flex items-center gap-x-1 rounded-md px-2 py-1 text-xs ring-1 ring-inset ring-gray-200">
          <dt className="pr-1 text-gray-400 border-r border-gray-200">{value.lang}</dt>
          <dd>{value.value}</dd>
        </div>
      ))}
    </dl>
  )
}
