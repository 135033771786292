import { Fragment, useState, useEffect, TargetedEvent } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'

export type SlideoverProps = {
  title: React.ReactNode
  children: React.ReactNode
  busy?: boolean
  disabled?: boolean
  primaryAction?: React.ReactNode
  onPrimaryAction?: (data: FormData) => void
  secondaryAction?: React.ReactNode
  onSecondaryAction?: () => void
  extraActions?: React.ReactNode
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl'
}

export function SlideOver({
  title,
  children,
  busy,
  disabled,
  primaryAction,
  onPrimaryAction,
  secondaryAction,
  onSecondaryAction,
  extraActions,
  width = 'md',
}: Partial<SlideoverProps>) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (show) {
      return
    }
    const task = setTimeout(() => setShow(true), 10)
    return () => clearTimeout(task)
  }, [show])

  useEffect(() => {
    if (!hide) {
      return
    }
    const task = setTimeout(() => navigate({ pathname: '..', search: searchParams.toString() }), 250)
    return () => clearTimeout(task)
  }, [navigate, searchParams, hide])

  function onClose() {
    if (!busy) {
      setHide(true)
    }
  }

  function onSubmit(ev: TargetedEvent<HTMLFormElement>) {
    const data = new FormData(ev.currentTarget)

    ev.preventDefault()
    if (!busy && onPrimaryAction) {
      onPrimaryAction(data)
    }
  }

  return (
    <Transition.Root show={show && !hide} as={Fragment}>
      <Dialog onClose={onClose}>
        <div className="relative z-20">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-250"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-wa21-100 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-400 sm:duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-250 sm:duration-250"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className={classNames('pointer-events-auto w-screen', {
                      'max-w-xs': width === 'xs',
                      'max-w-sm': width === 'sm',
                      'max-w-md': width === 'md',
                      'max-w-lg': width === 'lg',
                      'max-w-xl': width === 'xl',
                      'max-w-2xl': width === '2xl',
                      'max-w-3xl': width === '3xl',
                      'max-w-4xl': width === '4xl',
                      'max-w-5xl': width === '5xl',
                      'max-w-6xl': width === '6xl',
                      'max-w-7xl': width === '7xl',
                    })}>
                    <form className="flex h-full flex-col justify-start divide-y divide-gray-200 bg-white shadow-xl" onSubmit={onSubmit}>
                      <div className="overflow-y-auto">
                        <div className="bg-wa21-header px-4 py-6 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-base font-semibold leading-6 text-white">{title}</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              {!busy && (
                                <button type="button" className="rounded-full text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={onClose}>
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">{children}</div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        {extraActions}
                        {secondaryAction && (
                          <button
                            type="button"
                            className="rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-gray-100"
                            onClick={onSecondaryAction || onClose}
                            disabled={busy || disabled}>
                            {secondaryAction}
                          </button>
                        )}
                        {primaryAction && (
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md bg-wa21-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-wa21-500 disabled:bg-wa21-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-600"
                            disabled={busy || disabled}>
                            {primaryAction}
                          </button>
                        )}
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
