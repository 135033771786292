import { useMemo } from 'react'
import { Outlet, useSearchParams, useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'

export function StudyLayout() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const queryParams = Components.encodeStudyFilters(filters, false, { eva: true, ova: true })

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const resultItems = useMemo(() => {
    let items = []

    if (study && !study.dirty) {
      if (study.enableRedetectionRate) {
        items.push({ to: `./analysis/redetection?${queryParams}`, label: 'Re-detection rate (RR)' })
      }
      if (study.enableEntryEfficiency) {
        items.push({ to: `./analysis/entry?${queryParams}`, label: 'Entry efficiency (EE)' })
      }
      if (study.enablePassageEfficiency) {
        items.push({ to: `./analysis/passage?${queryParams}`, label: 'Passage efficiency (PE)' })
      }
      if (study.enableExitEfficiency) {
        items.push({ to: `./analysis/exit?${queryParams}`, label: 'Exit efficiency (AE)' })
      }
      if (study.enablePassageEfficiency) {
        items.push(
          { to: `./analysis/delay?${queryParams}`, label: 'Passage delay (ZV)' },
          { to: `./analysis/duration?${queryParams}`, label: 'Passage duration (PD)' }
        )
      }
    }
    return items
  }, [study, queryParams])

  const evaluationItems = useMemo(() => {
    let items = []

    if (study && !study.dirty) {
      if (study.enableRedetectionRate) {
        items.push({ to: `./evaluation/discoverability?${queryParams}`, label: 'Discoverability' })
      }
      if (study.enableEntryEfficiency || study.enablePassageEfficiency) {
        items.push({ to: `./evaluation/passability?${queryParams}`, label: 'Passability' })
      }
      if (study.enablePassageEfficiency) {
        items.push({ to: `./evaluation/global-passability?${queryParams}`, label: 'Global passability' })
      }
    }
    return items
  }, [study, queryParams])

  const items = useMemo(() => {
    return [
      { to: './dashboard', label: 'Summary' },
      { to: './nodes', label: 'Topology' },
      {
        to: './datasets/organisms',
        label: 'Individuals',
        children: [
          { to: `./datasets/organisms/statistics?${queryParams}`, label: 'Demographic analysis' },
          { to: `./datasets/organisms/all?${queryParams}`, label: 'Browse' },
        ],
      },
      {
        to: './datasets/detections',
        label: 'Detection events',
        children: [
          { to: `./datasets/detections/statistics?${queryParams}`, label: 'Event analysis' },
          { to: `./datasets/detections/all?${queryParams}`, label: 'Browse' },
        ],
      },
      {
        to: `./analysis/summary?${queryParams}`,
        label: 'Results',
        children: resultItems,
      },
      {
        to: `./evaluation/summary?${queryParams}`,
        label: 'Evaluation',
        children: evaluationItems,
      },
    ]
  }, [resultItems, queryParams])

  return (
    <>
      <Components.SubMenu items={items} />
      <div className="max-w-7xl px-4 pb-12 ml-0 lg:ml-64 xl:ml-64 3xl:mx-auto sm:px-6 lg:px-8">
        <Outlet />
      </div>
    </>
  )
}
