import { useState, TargetedEvent } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useMutation } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'

import * as _ from 'lodash'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import { AppAction, useAppDispatch } from '~/state'

function filePath(file: File) {
  return file.webkitRelativePath + file.name
}

export function StudyOrganismDatasetImport() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()

  const [abort] = useState(new AbortController())
  const [files, setFiles] = useState<File[]>([])
  type FileStatuses = { [k: string]: 'queued' | 'uploading' | 'parsing' | 'parsed' | 'error' }
  const [status, setStatus] = useState<FileStatuses>({})

  const apollo = useApolloClient()
  const [insertDataset] = useMutation(api.INSERT_ORGANISM_DATASET)
  const [busy, setBusy] = useState(false)

  async function onImport() {
    setBusy(true)
    try {
      let datasets: string[] = []
      const currentStatus = { ...status }

      for (let file of files) {
        try {
          if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
            throw new Error(`invalid file type (${file.type})`)
          }
          if (file.size > 100 * 1024 * 1024) {
            throw new Error('file too large (max 100mb)')
          }

          currentStatus[filePath(file)] = 'uploading'
          setStatus({ ...currentStatus })

          const uploadResponse = await fetch('/api/store/', {
            method: 'PUT',
            headers: new Headers({ 'Content-Type': file.type, 'X-Upload-Filename': file.name }),
            body: file,
            credentials: 'same-origin',
            cache: 'no-cache',
            signal: abort.signal,
          })

          if (!uploadResponse.ok) {
            throw new Error('file transfer error')
          }

          currentStatus[filePath(file)] = 'parsing'
          setStatus({ ...currentStatus })

          const store = (await uploadResponse.json()) as api.StoreResponse
          const response = await insertDataset({
            variables: {
              studyId,
              name: store.filename || 'file.csv',
              fields: { validated: true, storeId: store.id },
            },
          })

          if (response.data) {
            datasets.push(response.data.insertOrganismDataset.id)
          }
          currentStatus[filePath(file)] = 'parsed'
        } catch (e) {
          currentStatus[filePath(file)] = 'error'
          dispatch({
            action: AppAction.PublishNotification,
            notification: { level: 'error', title: filePath(file), message: `${e}`, exception: e },
          })
        } finally {
          setStatus({ ...currentStatus })
        }
      }
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      if (_.values(currentStatus).every((status) => status === 'parsed')) {
        if (datasets.length === 1) {
          navigate({ pathname: `../${datasets[0]}` })
        } else {
          navigate({ pathname: '..', search: searchParams.toString() })
        }
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to process dataset(s).', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onAdd(ev: TargetedEvent<HTMLInputElement>) {
    setBusy(true)
    try {
      const currentFiles = [...files]

      for (let i = 0; i < (ev.currentTarget.files?.length || 0); i++) {
        const file = ev.currentTarget.files?.item(i)

        if (!file) {
          continue
        }
        currentFiles.push(file)
      }
      setFiles(currentFiles)
    } catch (e) {
      console.warn(e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Components.Modal
      title="Import dataset from file"
      busy={busy}
      primaryAction="Process CSV"
      primaryActionDisabled={files.length === 0}
      onPrimaryAction={onImport}
      secondaryAction="Cancel">
      <ul className="w-full divide-y divide-gray-200">
        {files.map((file, i) => (
          <li key={i} className="flex px-4 py-4 sm:px-0">
            <span className="flex-1">
              {filePath(file)}
              {status[filePath(file)] === 'uploading' && <CloudArrowUpIcon className="inline w-5 h-5 ml-2 text-wa21-300" />}
              {status[filePath(file)] === 'parsing' && <Components.Spinner className="inline w-5 h-5 ml-2 text-wa21-400" />}
              {status[filePath(file)] === 'parsed' && <CheckIcon className="inline w-5 h-5 ml-2 text-wa21-500" />}
              {status[filePath(file)] === 'error' && <ExclamationTriangleIcon className="inline w-5 h-5 ml-2 text-wa21-danger-500" />}
            </span>
            <span className="flex-0 text-gray-500">{numeral(file.size).format('0.0b')}</span>
          </li>
        ))}
      </ul>
      {!busy && (
        <div className="mt-4 flex justify-center rounded-lg border border-dashed border-gray-900/25 p-6">
          <div className="text-center">
            <label htmlFor="import-csv-file" className="relative cursor-pointer bg-white font-semibold text-wa21-600 hover:text-wa21-500">
              <span>Add CSV file(s)</span>
              <input id="import-csv-file" name="import-csv-file" type="file" className="sr-only" multiple accept=".csv,text/csv" onChange={onAdd} />
            </label>
          </div>
        </div>
      )}
    </Components.Modal>
  )
}
