import { Fragment, useEffect } from 'react'
import { NavLink, To } from 'react-router-dom'

import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'

import { useAppState, useAppDispatch, AppAction } from '~/state'

export type MenuItem = {
  to: To
  label: React.ReactNode
}

export type RootMenuItem = {
  to: To
  label: React.ReactNode
  children?: MenuItem[]
}

export function SubMenu({ items = [], ...props }: { items?: RootMenuItem[] } & React.HTMLAttributes<HTMLDivElement>) {
  const { subMenuOpen } = useAppState()
  const appDispatch = useAppDispatch()

  useEffect(() => {
    appDispatch({ action: AppAction.EnableSubMenu })
    return () => {
      appDispatch({ action: AppAction.DisableSubMenu })
    }
  }, [appDispatch])

  return (
    <>
      <Transition show={subMenuOpen} as={Fragment}>
        <Dialog onClose={() => appDispatch({ action: AppAction.HideSubMenu })}>
          <div className="relative z-40 lg:hidden">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-wa21-submenu/95 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => appDispatch({ action: AppAction.HideSubMenu })}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="px-2">
                      <ul className="space-y-1">
                        {items.map((item, i) => (
                          <li key={i}>
                            <NavLink
                              className={({ isActive }) =>
                                classNames(
                                  isActive ? 'bg-wa21-200 text-wa21-800 font-semibold' : 'text-wa21-700 hover:bg-wa21-400',
                                  'group flex items-center rounded-md px-2 py-2 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-200'
                                )
                              }
                              to={item.to}>
                              {item.label}
                            </NavLink>
                            {(item.children || []).map((child, j) => (
                              <NavLink
                                key={j}
                                className={({ isActive }) =>
                                  classNames(
                                    isActive ? 'bg-wa21-200 text-wa21-800 font-semibold' : 'text-wa21-700 hover:bg-wa21-400',
                                    'ml-4 group flex items-center rounded-md px-2 py-2 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-200'
                                  )
                                }
                                to={child.to}>
                                {child.label}
                              </NavLink>
                            ))}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div {...props} className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto bg-wa21-submenu/95 pt-16 pb-64">
          <div className="mt-5 flex flex-1 flex-col">
            <nav className="flex-1 px-2 pb-4">
              <ul className="space-y-1">
                {items.map((item, i) => (
                  <li key={i}>
                    <NavLink
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-wa21-200 text-wa21-900' : 'text-wa21-700 hover:bg-wa21-400',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-200'
                        )
                      }
                      to={item.to}>
                      {item.label}
                    </NavLink>
                    {(item.children?.length || 0) > 0 && (
                      <ul className="space-y-1">
                        {(item.children || []).map((child, j) => (
                          <li key={j} className="pl-6 pr-2 py-2">
                            <NavLink
                              className={({ isActive }) =>
                                classNames(
                                  isActive ? 'border-wa21-900 text-wa21-900 font-semibold' : 'border-transparent text-wa21-700 hover:border-wa21-400',
                                  'group inline-flex items-center border-b-2 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-200'
                                )
                              }
                              to={child.to}>
                              {child.label}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
