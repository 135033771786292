import { TargetedEvent, useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { ExclamationTriangleIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import classNames from 'classnames'
import { DateTime } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import {
  DataSourceFilterOperator,
  Organism,
  OrganismDataset,
  OrganismSort,
  StudyState,
  UpdateOrganismScope,
  UpdateOrganismsMutationVariables,
  UpsertOrganismFields,
  WellKnownCategories,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    taggedOn: true,
    tagQuery: true,
    validity: true,
    weight: true,
  },
  event: {},
}

function warnings(organism: Partial<Organism>): string | undefined {
  return organism.warning ? `Invalid fields: ${organism.warning.join(', ')}` : undefined
}

export function StudyOrganismDatasetUpdate() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const datasetId = params.datasetId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const page = parseInt(searchParams.get('page') || '0')
  const sort = searchParams.get('sort') || 'dataset'
  const sortBy = (sort.startsWith('-') ? sort.substring(1) : sort).toUpperCase() as OrganismSort
  const sortByReverse = sort.startsWith('-')

  const apollo = useApolloClient()
  const [updateDataset] = useMutation(api.UPDATE_ORGANISM_DATASET)
  const [parseDataset] = useMutation(api.PARSE_ORGANISM_DATASET)
  const [updateOrganism] = useMutation(api.UPDATE_ORGANISM)
  const [updateOrganisms] = useMutation(api.UPDATE_ORGANISMS)
  const [busy, setBusy] = useState(false)
  const [abort] = useState(new AbortController())

  async function onUpdate<K extends keyof OrganismDataset>(key: K, value: OrganismDataset[K]) {
    setBusy(true)
    try {
      const variables: any = {
        id: datasetId,
        fields: {},
      }

      if (key === 'name') {
        variables[key] = value
      } else {
        variables.fields[key] = value
      }
      await updateDataset({ variables })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update dataset.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onParse() {
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyOrganismDatasetUpdate.onParse',
        title: 'Processing dataset',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await parseDataset({ variables: { id: datasetId } })
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to parse dataset.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyOrganismDatasetUpdate.onParse' } })
      setBusy(false)
    }
  }

  async function onUpdateCSV(ev: TargetedEvent<HTMLInputElement>) {
    const file = (ev.currentTarget.files?.length || 0) > 0 ? ev.currentTarget.files?.item(0) : null

    if (!file) {
      return
    }

    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyOrganismDatasetUpdate.onImport',
        title: 'Processing dataset',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      if (file.type !== 'text/csv') {
        throw new Error(`invalid file type (${file.type})`)
      }
      if (file.size > 100 * 1024 * 1024) {
        throw new Error('file too large (max 100mb)')
      }

      const uploadResponse = await fetch('/api/store/', {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': file.type, 'X-Upload-Filename': file.name }),
        body: file,
        credentials: 'same-origin',
        cache: 'no-cache',
        signal: abort.signal,
      })

      if (!uploadResponse.ok) {
        throw new Error('file transfer error')
      }

      const store = (await uploadResponse.json()) as api.StoreResponse

      await updateDataset({
        variables: {
          id: datasetId,
          name: store.filename || 'file.csv',
          fields: { storeId: store.id },
        },
      })
      try {
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: file.name, message: `${e}`, exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyOrganismDatasetUpdate.onImport' } })
      setBusy(false)
    }
  }

  async function onUpdateOrganism<K extends keyof Organism>(id: string, key: K, value: Organism[K]) {
    setBusy(true)
    try {
      const variables: any = {
        id,
        fields: {},
      }

      if (key === 'tag') {
        variables[key] = value
      } else {
        variables.fields[key] = value
      }
      await updateOrganism({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individual.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateOrganisms<K extends keyof UpsertOrganismFields>(key: K, value: UpsertOrganismFields[K]) {
    setBusy(true)
    try {
      const mergedFilters = [
        { key: 'organism.datasetId', operator: DataSourceFilterOperator.Equal, params: [datasetId] },
        ...(Components.buildDataSourceQuery(filters).filters || []),
      ]

      if (searchParams.has('warn')) {
        mergedFilters.push({
          key: 'organism.warning',
          operator: searchParams.get('warn') === 'true' ? DataSourceFilterOperator.IsNotNull : DataSourceFilterOperator.IsNull,
          params: [],
        })
      }

      const variables: UpdateOrganismsMutationVariables = {
        studyId,
        filters: mergedFilters,
        fields: {},
        scope: UpdateOrganismScope.Organisms,
      }

      variables.fields[key] = value
      await updateOrganisms({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individuals.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateLabels() {
    const label = await new Promise<string | number | null>((resolve) => {
      dispatch({
        action: AppAction.RequestPrompt,
        prompt: {
          title: 'New label',
          placeholder: 'Enter label...',
          resolve,
        },
      })
    })

    if (label !== null) {
      await onUpdateOrganisms('label', label as string)
    }
  }

  async function onClearLabels() {
    await onUpdateOrganisms('label', null)
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onSort(sort: string) {
    setSearchParams((prev) => {
      prev.delete('page')
      if (sort !== 'dataset') {
        prev.set('sort', sort)
      } else {
        prev.delete('sort')
      }
      return prev
    })
  }

  function onInsertOrganism() {
    navigate({ pathname: 'new', search: searchParams.toString() })
  }

  function onEditOrganism(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getDataset = useQuery(api.GET_ORGANISM_DATASET, { variables: { id: datasetId } })
  const dataset = getDataset.data?.organismDataset

  const listOrganismsFilters = Components.parseStudyFilters(searchParams)
  const listOrganisms = useQuery(api.LIST_ORGANISMS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        sortBy,
        sortByReverse,
        datasetIds: [datasetId],
        valid: listOrganismsFilters.organism.validity,
        tags: listOrganismsFilters.organism.tags,
        tagQuery: Components.encodeLikeQuery(listOrganismsFilters.organism.tagQuery),
        labels: listOrganismsFilters.organism.labels,
        speciesIds: listOrganismsFilters.organism.species,
        length: listOrganismsFilters.organism.length,
        width: listOrganismsFilters.organism.width,
        height: listOrganismsFilters.organism.height,
        weight: listOrganismsFilters.organism.weight,
        taggedOn: listOrganismsFilters.organism.taggedOn,
        capturedAt: listOrganismsFilters.organism.capturedAt,
        releasedAt: listOrganismsFilters.organism.releasedAt,
        warning: searchParams.has('warn') ? searchParams.get('warn') === 'true' : undefined,
      },
    },
  })
  const organismColumns: Components.TableColumn[] = [
    {
      name: 'dataset',
      label: '#',
      align: 'center',
      className: 'w-20 relative',
      sortable: true,
    },
    {
      name: 'tag',
      label: 'Tag',
      sortable: true,
    },
    {
      name: 'label',
      label: 'Label',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'species',
      label: 'Species',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'size',
      label: 'Size (mm)',
      align: 'right',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'weight',
      label: 'Weight (g)',
      align: 'right',
      className: 'w-24',
      sortable: true,
    },
    {
      name: 'tagged_on',
      label: 'Tagged on',
      align: 'center',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'valid',
      label: 'Included',
      align: 'center',
      className: 'w-16',
    },
    {
      name: 'actions',
      label: 'Actions',
      align: 'right',
      className: 'w-24',
      interactive: true,
    },
  ]
  const organismRows: Components.TableRow[] = (listOrganisms.data?.organisms.items || []).map((organism) => ({
    id: organism.id,
    dataset: (
      <>
        {organism.warning && (
          <div className="absolute flex items-center inset-y-0 left-0" title={warnings(organism as Organism)}>
            <ExclamationTriangleIcon className="w-3 h-3 text-wa21-warning-500" />
          </div>
        )}
        {organism.line || organism.id}
      </>
    ),
    tag: (
      <>
        {organism.tag}
        {organism.description && <span className="ml-2 italic text-gray-600">({organism.description})</span>}
      </>
    ),
    label: organism.label,
    species: <Components.CategoryValueDisplay category={WellKnownCategories.OrganismSpecies} value={organism.speciesId as any} />,
    size: Components.formatOrganismSize(organism as Organism),
    weight: organism.weight && numeral(organism.weight).format('0,0'),
    tagged_on: organism.taggedOn && (
      <span title={DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATETIME_SHORT)}>
        {DateTime.fromSeconds(organism.taggedOn).toLocaleString(DateTime.DATE_SHORT)}
      </span>
    ),
    valid: organism.valid ? <CheckCircleIcon className="inline w-5 h-5 text-wa21-500" /> : <XCircleIcon className="inline w-5 h-5 text-wa21-danger-500" />,
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {!organism.valid && (
          <button
            onClick={() => onUpdateOrganism(organism.id, 'valid', true)}
            className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900"
            disabled={busy}>
            Include
          </button>
        )}
        {organism.valid && (
          <button
            onClick={() => onUpdateOrganism(organism.id, 'valid', false)}
            className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900"
            disabled={busy}>
            Exclude
          </button>
        )}
        {/*<button onClick={() => onDeleteOrganism(organism as Organism)} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900" disabled={busy}>
          Delete
        </button>*/}
      </div>
    ),
  }))

  const exportUrlParams = Components.encodeStudyFilters(filters, true, { 'ods[]': datasetId })
  const exportUrl = `/api/csv/organisms/${studyId}?${exportUrlParams}`
  const actionGroups: Components.StudyFilterAction[][] = [
    [
      {
        name: 'Include all',
        onClick: () => onUpdateOrganisms('valid', true),
        disabled: study?.state !== StudyState.Active || study?.deletedAt || (dataset?.validOrganismCount || 0) >= (dataset?.organismCount || 0),
      },
      {
        name: 'Exclude all',
        onClick: () => onUpdateOrganisms('valid', false),
        disabled: study?.state !== StudyState.Active || study?.deletedAt || (dataset?.validOrganismCount || 0) === 0,
      },
    ],
    [
      { name: 'Set labels', onClick: () => onUpdateLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Clear labels', onClick: () => onClearLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [{ name: 'Export CSV', url: exportUrl }],
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../datasets/organisms" className="hover:text-wa21-100">
                Individuals
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>{dataset?.name}</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {!dataset?.store && !dataset?.validated && (
                <Components.HeaderPrimaryButton onClick={onInsertOrganism} disabled={busy}>
                  Add individual
                </Components.HeaderPrimaryButton>
              )}
              {dataset?.store && dataset?.dirty && (
                <Components.HeaderPrimaryButton onClick={onParse} disabled={busy}>
                  Parse dataset
                </Components.HeaderPrimaryButton>
              )}
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="border-b border-gray-200 pb-5">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Dataset overview</h2>
        </div>
        {!!dataset && (
          <dl className="divide-y divide-gray-200">
            {dataset?.store && (
              <Components.ReadonlyProperty
                label="CSV source file"
                name="upload"
                currentValue={
                  <>
                    <a href={`/api/store/${dataset.store.id}`} target="_blank" rel="noreferrer" className="text-wa21-600 hover:text-wa21-500">
                      {dataset.store.filename}
                    </a>{' '}
                    <span className="text-gray-500 text-xs">({numeral(dataset.store.size).format('0.0b')})</span> by{' '}
                    {dataset.store.uploadedBy?.fullname || dataset.store.uploadedBy?.email}
                    {study?.state === StudyState.Active && !study?.deletedAt && (
                      <label
                        htmlFor="import-csv-file"
                        className="ml-4 relative rounded bg-white px-2 py-1 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer ">
                        <span>Update from CSV</span>
                        <input id="import-csv-file" name="import-csv-file" type="file" className="sr-only" accept=".csv,text/csv" onChange={onUpdateCSV} />
                      </label>
                    )}
                  </>
                }
              />
            )}
            <Components.TextProperty
              label="Name"
              name="name"
              currentValue={dataset?.name}
              disabled={study?.state !== StudyState.Active || study?.deletedAt}
              onUpdate={(value) => onUpdate('name', value || '')}
            />
            <Components.ReadonlyProperty label="Included rows" name="valid" currentValue={`${dataset?.validOrganismCount} / ${dataset?.organismCount}`} />
            {dataset?.store && (dataset?.errors?.length || 0) > 0 && (
              <Components.ReadonlyProperty
                label="Unparsable rows"
                name="errors"
                currentValue={dataset?.errors?.length || 0}
                className={classNames({ 'text-wa21-danger-500': (dataset.errors?.length || 0) > 0 })}
              />
            )}
            {(dataset?.warningCount || 0) > 0 && (
              <Components.ReadonlyProperty
                label="Warnings"
                name="warnings"
                currentValue={
                  <Link to={{ search: 'warn=true' }} className="text-wa21-600">
                    {dataset?.warningCount}
                  </Link>
                }
              />
            )}
            {(dataset?.undefinedSpecies?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined species"
                name="undefinedSpecies"
                currentValue={dataset?.undefinedSpecies}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-species`}
              />
            )}
            {(dataset?.undefinedCaptureMethods?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined capture methods"
                name="undefinedCaptureMethods"
                currentValue={dataset?.undefinedCaptureMethods}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-capture-methods`}
              />
            )}
            {(dataset?.undefinedLocations?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined locations"
                name="undefinedLocations"
                currentValue={dataset?.undefinedLocations}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-locations`}
              />
            )}
            {(dataset?.undefinedUsers?.length || 0) > 0 && (
              <Components.ReadonlyDownloadableListProperty
                label="Undefined users"
                name="undefinedUsers"
                currentValue={dataset?.undefinedUsers}
                filename={`${Components.stripFilenameExtension(dataset?.name || 'dataset')}-undefined-users`}
              />
            )}
          </dl>
        )}
        <div className="pt-10 pb-5 mb-2 border-b border-gray-200">
          <h2 className="text-base font-semibold leading-6 text-gray-900">Dataset rows</h2>
        </div>
        <div className="relative z-20 pb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actionGroups={actionGroups} pageKey="page" />
        </div>
        <Components.Table
          columns={organismColumns}
          rows={organismRows}
          condensed
          stickyHeader
          page={page}
          pages={listOrganisms.data?.organisms.pages}
          total={listOrganisms.data?.organisms.total}
          loading={listOrganisms.loading}
          onClick={onEditOrganism}
          onPage={onPage}
          sort={sort}
          onSort={onSort}
        />
      </main>

      <Outlet />
    </>
  )
}
