import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import * as api from '~/api'
import * as Components from '~/components'
import { StudyNodeType, WellKnownCategories } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyNodeInsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'

  const apollo = useApolloClient()
  const [insertStudyNode] = useMutation(api.INSERT_STUDY_NODE)
  const [busy, setBusy] = useState(false)

  async function onInsert(data: FormData) {
    setBusy(true)
    try {
      const response = await insertStudyNode({
        variables: {
          studyId,
          nodeType: data.get('nodeType')?.toString() as StudyNodeType,
          sequence: parseInt(data.get('sequence')?.toString() || '0'),
          name: data.get('name')?.toString() || '',
          denivelation: data.get('denivelation')?.toString() || '0',
          fields: {
            pathwayTopologyId: data.get('pathwayTopologyId')?.toString(),
          },
        },
      })

      try {
        await apollo.refetchQueries({ include: api.ALL_STUDY_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: `../${response.data?.insertStudyNode.id}` })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to create study node.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  const getPathwayTopology = useQuery(api.FIND_CATEGORY, { variables: { name: WellKnownCategories.StudyNodePathwayTopology } })
  const pathwayTopologies: Components.EnumFieldItem[] = (getPathwayTopology.data?.findCategory.categoryValues.items || []).map((value) => ({
    value: value.id,
    label: Components.localizedText(value.name, value.localizedName),
  }))

  const [nodeType, setNodeType] = useState<string>(StudyNodeType.UpstreamPathway)
  const nodeTypes: Components.EnumFieldItem[] = [
    {
      value: StudyNodeType.UpstreamPathway,
      label: 'Pathway (upstream)',
      description: 'A pathway with detection antenna to track individuals moving upstream.',
    },
    {
      value: StudyNodeType.DownstreamPathway,
      label: 'Pathway (downstream)',
      description: 'A pathway with detection antenna to track individuals moving downstream.',
    },
    {
      value: StudyNodeType.PointOfInterest,
      label: 'Point-of-Interest',
      description: 'A generic location to match against dataset imports.',
    },
  ]

  return (
    <Components.SlideOver title="Add node" busy={busy} primaryAction="Add" onPrimaryAction={onInsert}>
      <div className="divide-y divide-gray-200 px-4 sm:px-6">
        <div className="space-y-6 pb-5 pt-6">
          <Components.TextField required type="text" name="name" label="Name" placeholder="Node name" disabled={busy} />
          <Components.TextField required type="number" name="sequence" label="Seq #" placeholder="Sequence number" disabled={busy} />
          <Components.EnumField
            required
            name="nodeType"
            label="Node type"
            items={nodeTypes}
            defaultValue={StudyNodeType.UpstreamPathway}
            disabled={busy}
            onUpdate={(value) => value && setNodeType(value)}
          />
          {(nodeType === StudyNodeType.UpstreamPathway || nodeType === StudyNodeType.DownstreamPathway) && (
            <>
              <Components.EnumField required name="pathwayTopologyId" label="Pathway topology" items={pathwayTopologies} disabled={busy} />
              <Components.TextField
                required
                type="number"
                step="0.01"
                numberFormat="0.00"
                name="denivelation"
                label="Denivelation [m]"
                placeholder="Denivelation"
                disabled={busy}
              />
            </>
          )}
        </div>
      </div>
    </Components.SlideOver>
  )
}
