import { TargetedEvent, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { Spin } from '~/assets'
import { useAuth } from '~/session'

export function ChangePassword() {
  const auth = useAuth()
  const { token } = useParams()
  const [query, setQuery] = useSearchParams()
  const email = query.get('email') || ''
  const status = parseInt(query.get('status') || '0')
  const [password, setPassword] = useState<string>()
  const [busy, setBusy] = useState(false)

  async function onSubmit(ev: TargetedEvent<HTMLFormElement>) {
    ev.preventDefault()

    setQuery({ email })
    setBusy(true)
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({ email, password, token }),
      })

      if (response.ok) {
        auth.update(await response.json())
      }
      setQuery({ status: response.status.toString() })
    } catch (e) {
      console.warn(e)
    } finally {
      setBusy(false)
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link to="/">
          <h1 className="mt-6 text-center text-6xl font-title font-bold tracking-tight text-wa21-600">PIT WebTool</h1>
        </Link>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-600">Set your account password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {status === 0 && (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmit}>
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="off"
                    readOnly
                    tabIndex={-1}
                    className="form-input relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                    value={query.get('email') || 'N/A'}
                    disabled={busy}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    New password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    minLength={6}
                    tabIndex={0}
                    className="form-input relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-wa21-600 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                    placeholder="New password"
                    value={password}
                    onChange={(ev) => setPassword(ev.currentTarget.value)}
                    disabled={busy}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-wa21-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-wa21-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-600 disabled:bg-gray-400"
                  disabled={busy}>
                  Change password
                </button>
              </div>
            </form>

            {!busy && (
              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">or</span>
                  </div>
                </div>

                <div className="mt-6">
                  <Link
                    className="flex w-full justify-center rounded-md bg-wa21-secondary-400 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-wa21-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-secondary-300"
                    to="/sign-in">
                    Cancel
                  </Link>
                </div>
              </div>
            )}
            {busy && (
              <div className="flex justify-center mt-6">
                <Spin className="h-10 w-10 text-gray-300" />
              </div>
            )}
          </div>
        )}
        {status === 200 && (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <p>Your password was changed successfully!</p>
            <div className="mt-6">
              <Link
                className="flex w-full justify-center rounded-md bg-wa21-secondary-400 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-wa21-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-secondary-300"
                to="/">
                Continue
              </Link>
            </div>
          </div>
        )}
        {status !== 0 && status !== 200 && (
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <p className="text-red-500 text-justify">Failed to change your password!</p>
            <p className="mt-4 text-justify">The recovery link was either invalid or expired.</p>
            <div className="mt-6">
              <Link
                className="flex w-full justify-center rounded-md bg-wa21-secondary-400 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-wa21-secondary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-wa21-secondary-300"
                to="/recover">
                Retry
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
