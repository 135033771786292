import { useEffect, useReducer } from 'react'
import { Outlet } from 'react-router-dom'

import { FooterBafu, FooterWa21 } from '~/assets'
import * as Components from '~/components'
import { AppStateProvider, AppDispatchProvider, appStateReducer, appStateInitial, AppAction, AppSettings } from '~/state'

const settingsLoader = (async () => {
  const response = await fetch('/api/settings')

  if (response.ok) {
    return (await response.json()) as AppSettings
  }
})()

export function Root() {
  const [state, dispatch] = useReducer(appStateReducer, appStateInitial)

  useEffect(() => {
    settingsLoader.then((settings) => dispatch({ action: AppAction.UpdateSettings, settings }))
  }, [])

  return (
    <AppStateProvider value={state}>
      <AppDispatchProvider value={dispatch}>
        <div className="flex min-h-full flex-col bg-wa21-page">
          <div className="flex-auto grow shrink-0 w-full">
            <Outlet />
          </div>
          <div className="text-white bg-wa21-footer z-10">
            <div className="flex justify-between mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
              <div className="flex space-x-12">
                <div>
                  <a href="https://wa21.ch" target="_blank" rel="noreferrer">
                    <img className="mx-auto h-28 w-auto" src={FooterWa21} alt="WASSER-AGENDA 21" />
                  </a>
                </div>
                <div>
                  <a href="https://www.bafu.admin.ch/" target="_blank" rel="noreferrer">
                    <img className="mx-auto h-32 w-auto" src={FooterBafu} alt="Bundesamt für Umwelt BAFU" />
                  </a>
                </div>
              </div>
              <div className="max-w-xs text-sm font-medium">
                <p>Die Plattform Renaturierung ist eine Website von Wasser-Agenda 21 und wird vom BAFU finanziell unterstützt.</p>
              </div>
            </div>
          </div>
        </div>
        <Components.NotificationContainer />
        <Components.ConfirmationContainer />
        <Components.PromptContainer />
      </AppDispatchProvider>
    </AppStateProvider>
  )
}
