import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'

import _ from 'lodash'

import * as api from '~/api'
import * as Components from '~/components'
import { StudyState, WellKnownCategories } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyDetectionEventUpsert() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const datasetId = params.datasetId || '0'
  const eventId = params.eventId || 'new'
  const [searchParams] = useSearchParams()

  const apollo = useApolloClient()
  const [insertEvent] = useMutation(api.INSERT_DETECTION_EVENT)
  const [updateEvent] = useMutation(api.UPDATE_DETECTION_EVENT)
  const [busy, setBusy] = useState(false)

  async function onUpsert(data: FormData) {
    setBusy(true)
    try {
      const triggeredByTag = Components.maybeString(data, 'triggeredBy')
      let triggeredBy: string | undefined = undefined

      if (triggeredByTag) {
        const { data } = await apollo.query({ query: api.FIND_ORGANISM, variables: { studyId, tag: triggeredByTag } })

        triggeredBy = data.findOrganism.id
      }

      const detectedByArray = Components.maybeId(data, 'detectedBy')
      const detectedOn = Components.maybeDateTime(data, 'detectedOn')
      const fields = {
        valid: Components.maybeBool(data, 'valid'),
        detectedByAntenna: Components.maybeId(data, 'detectedByAntenna'),
        detectedFor: Components.maybeDecimal(data, 'detectedFor'),
      }

      if (eventId === 'new') {
        await insertEvent({
          variables: {
            datasetId,
            triggeredBy: Components.requireData(triggeredBy),
            detectedOn: Components.requireData(detectedOn),
            detectedByArray: Components.requireData(detectedByArray),
            fields,
          },
        })
      } else {
        await updateEvent({
          variables: {
            id: eventId,
            triggeredBy: triggeredBy,
            detectedOn: detectedOn,
            detectedByArray: detectedByArray,
            fields,
          },
        })
      }
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
      navigate({ pathname: '..', search: searchParams.toString() })
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: {
          level: 'error',
          title: 'Action failed',
          message: eventId === 'new' ? 'Failed to create event.' : 'Failed to update event.',
          exception: e,
        },
      })
    } finally {
      setBusy(false)
    }
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getDetectionEvent = useQuery(api.GET_DETECTION_EVENT, { variables: { id: eventId }, skip: eventId === 'new' })
  const detectionEvent = getDetectionEvent.data?.detectionEvent

  const displayUndefined = true // !detectionDataset?.validated

  const getOrganism = useQuery(api.GET_ORGANISM, {
    variables: { id: detectionEvent?.triggeredById || '' },
    skip: !detectionEvent?.triggeredById,
  })
  const organism = getOrganism.data?.organism

  const getSpecies = useQuery(api.FIND_CATEGORY_VALUE, {
    variables: { name: WellKnownCategories.OrganismSpecies, id: organism?.speciesId || '' },
    skip: !organism?.speciesId,
  })
  const species = getSpecies.data?.findCategoryValue

  const listStudyNodes = useQuery(api.LIST_STUDY_NODES, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false } },
  })
  const studyNodes = _.fromPairs((listStudyNodes.data?.studyNodes.items || []).map((item) => [item.id, item]))

  const listAntennaArrays = useQuery(api.LIST_ANTENNA_ARRAYS, {
    variables: { studyId, request: { page: 0, limit: 0, deleted: false } },
    skip: !detectionEvent?.detectedByArrayId,
  })
  const antennaArrays = _.sortBy(listAntennaArrays.data?.antennaArrays.items || [], (item) => `${studyNodes[item.studyNodeId]?.name}-${item.name}`).map(
    (item) => ({
      value: item.id,
      label: item.name,
    })
  )
  const [currentArray, setCurrentArray] = useState(detectionEvent?.detectedByArrayId)
  const antennaArrayNames = ((listAntennaArrays.data?.antennaArrays.items || []).find((item) => item.id === currentArray)?.antennaNames || []).map((name) => ({
    value: name.toLowerCase(),
    label: name,
  }))

  useEffect(() => setCurrentArray(detectionEvent?.detectedByArrayId), [detectionEvent])

  return (
    <Components.SlideOver
      title={eventId !== 'new' ? 'Edit event' : 'Add event'}
      busy={busy}
      disabled={study?.state !== StudyState.Active || study?.deletedAt}
      primaryAction={eventId !== 'new' ? 'Update' : 'Create'}
      onPrimaryAction={onUpsert}>
      {(eventId === 'new' || detectionEvent) && listStudyNodes.data && listAntennaArrays.data && (
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div className="space-y-6 pb-5 pt-6">
            <Components.CheckboxField
              name="valid"
              label="Valid"
              defaultChecked={detectionEvent ? detectionEvent.valid : true}
              description="Mark this event as valid in the dataset."
              disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
            />
          </div>
          <div className="space-y-6 pb-5 pt-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Detection details</h2>
            {(displayUndefined || detectionEvent?.detectedOn) && (
              <Components.TextField
                type="datetime-local"
                name="detectedOn"
                label="Detected on"
                defaultValue={detectionEvent?.detectedOn}
                placeholder="Event date/time"
                required
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                step="1"
              />
            )}
            {(displayUndefined || detectionEvent?.detectedFor) && (
              <Components.TextField
                type="number"
                name="detectedFor"
                label="Duration (s)"
                defaultValue={detectionEvent?.detectedFor?.toString()}
                placeholder="Event duration"
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                step="any"
              />
            )}
            {(displayUndefined || detectionEvent?.detectedByArrayId) && (
              <Components.EnumField
                type="text"
                name="detectedBy"
                label="Detected by array"
                items={antennaArrays}
                defaultValue={detectionEvent?.detectedByArrayId}
                placeholder="Antenna array"
                required
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                onUpdate={(value) => setCurrentArray(value || '')}
              />
            )}
            {(displayUndefined || detectionEvent?.detectedByAntenna) && (
              <Components.EnumField
                type="text"
                name="detectedByAntenna"
                label="Antenna name"
                items={antennaArrayNames}
                defaultValue={detectionEvent?.detectedByAntenna || ''}
                placeholder="Antenna"
                disabled={busy || study?.state !== StudyState.Active || study?.deletedAt}
                allowEmpty
              />
            )}
          </div>
          {organism && (
            <div className="space-y-6 pb-5 pt-6">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Individual description</h2>
              <Components.TextField
                type="text"
                name="triggeredBy"
                label="Triggered by"
                defaultValue={organism.tag}
                placeholder="Individual"
                required
                disabled
              />
              {species && (
                <Components.TextField
                  type="text"
                  name="species"
                  label="Species"
                  defaultValue={Components.localizedText(species?.name, species?.localizedName)}
                  disabled
                />
              )}
              {organism.length && (
                <Components.TextField
                  type="number"
                  name="length"
                  label="Length (mm)"
                  defaultValue={organism.length?.toString()}
                  placeholder="Length (mm)"
                  disabled
                />
              )}
              {organism.width && (
                <Components.TextField
                  type="number"
                  name="width"
                  label="Width (mm)"
                  defaultValue={organism?.width?.toString()}
                  placeholder="Width (mm)"
                  disabled
                />
              )}
              {organism.height && (
                <Components.TextField
                  type="number"
                  name="height"
                  label="Height (mm)"
                  defaultValue={organism?.height?.toString()}
                  placeholder="Height (mm)"
                  disabled
                />
              )}
              {organism.weight && (
                <Components.TextField
                  type="number"
                  name="weight"
                  label="Weight (g)"
                  defaultValue={organism.weight?.toString()}
                  placeholder="Weight (g)"
                  disabled
                />
              )}
              {organism.age && (
                <Components.TextField type="number" name="age" label="Age (y)" defaultValue={organism?.age?.toString()} placeholder="Age (year)" disabled />
              )}
            </div>
          )}
        </div>
      )}
    </Components.SlideOver>
  )
}
