import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure, Transition } from '@headlessui/react'
import { BlockMath } from 'react-katex'

import classNames from 'classnames'

import * as api from '~/api'
import * as Components from '~/components'
import { EvaluationIndicatorsDataSource, EvaluationTableDataSource } from '~/graphql-codegen/graphql'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
  },
}

export function StudyEvaluationGlobalPassability() {
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getPassabilityPEG = useQuery(api.EVALUATION_TABLE, {
    variables: {
      studyId,
      datasource: EvaluationTableDataSource.PassabilityPg,
      query,
    },
  })
  const getIndicatorsPEG = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.ClassPassabilityPg,
      query,
    },
  })

  const equation1a = `
    y = \\rho_c(\\frac{n_{det}}{n_{tot}})
  `
  const equation1b = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\rho_c(x) & \\text{species grading function (class specific)} \\\\
    n_{det} & \\text{all detected individuals} \\\\
    n_{tot} & \\text{all individuals} \\\\
    \\end{array}
  `
  const equation1c = `
    \\text{with constraint } 0 \\le \\rho_c(x) \\le 1
  `
  const equation2a = `
    Y_{\\Set{\\text{strong}, \\text{weak}, \\text{bottom}}} = \\frac{\\sum_i \\gamma(n_i) y_i}{\\sum_i \\gamma(n_i)}
  `
  const equation2b = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\gamma(n) & \\text{species weighting function} \\\\
    n_i & \\text{number of individuals of species } i \\\\
    y_i & \\text{grading of species } i \\\\
    \\end{array}
  `

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <Link to="../evaluation/summary" className="hover:text-wa21-100">
                Evaluation
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Global passability</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="relative z-20 pb-5 mb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actions={[]} />
        </div>

        <Disclosure>
          {({ open }) => (
            <div className="mt-10 border-b border-gray-200">
              <div className="border-b border-gray-200 pb-5">
                <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                  Global passability by class
                  <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                    tell me more
                    <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                  </Disclosure.Button>
                </h2>
              </div>
              <Components.IndicatorCards
                indicators={getIndicatorsPEG.data?.evaluationIndicators}
                columns={getIndicatorsPEG.data?.evaluationIndicators.items.length as any}
              />
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel>
                  <article className="px-5 pt-5 prose prose-sm max-w-none border-t border-gray-200">
                    <p>Species are grouped in classes and graded using this formula:</p>
                    <BlockMath>{equation2a}</BlockMath>
                    <BlockMath>{equation2b}</BlockMath>
                  </article>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="mt-10">
              <div className="border-b border-gray-200 pb-5 mb-5">
                <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                  Global passability by species
                  <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                    tell me more
                    <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                  </Disclosure.Button>
                </h2>
              </div>
              <div className="px-5">
                <Components.DataTable table={getPassabilityPEG.data?.evaluationTable} condensed collapsed filename={`passability-pg-${study?.name}`} />
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel>
                  <article className="px-5 pt-5 prose prose-sm max-w-none">
                    <p>Each species is graded for each pathway/criteria using this formula:</p>
                    <BlockMath>{equation1a}</BlockMath>
                    <BlockMath>{equation1b}</BlockMath>
                    <BlockMath>{equation1c}</BlockMath>
                  </article>
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </main>
    </>
  )
}
