import React from 'react'

import classNames from 'classnames'

import { DataFormat, Indicators } from '~/graphql-codegen/graphql'

import { formatData, gradeCategory } from './helpers'

export type CardProps = {
  title: string
  value: React.ReactNode
  placeholder?: React.ReactNode
  footer?: React.ReactNode
}

export function Card({ title, value, placeholder = '-', footer }: Partial<CardProps>) {
  const isUndefined = value === undefined || value === null

  return (
    <div>
      <dt className="pt-5 px-4 sm:px-6 sm:pt-6">
        <p className="truncate text-sm font-medium text-gray-500">{title}</p>
      </dt>
      <dd className="mt-1 flex flex-col md:block lg:flex">
        <div className="pb-4 px-4 sm:px-6">{isUndefined ? placeholder : value}</div>
        {footer && <div className="px-4 py-4 sm:px-6 bg-gray-50">{footer}</div>}
      </dd>
    </div>
  )
}

export type CardsProps = {
  items: CardProps[]
  columns: 1 | 2 | 3 | 4 | 5
  placeholder: React.ReactNode
}

export function Cards({ items = [], columns, placeholder = '-' }: Partial<CardsProps>) {
  return (
    <dl
      className={classNames('grid grid-cols-1 divide-y divide-gray-200 overflow-hidden bg-white border-gray-100 border-gray-200 md:divide-x md:divide-y-0', {
        'md:grid-cols-2': columns === 2,
        'md:grid-cols-3': columns === 3,
        'md:grid-cols-4': columns === 4,
        'md:grid-cols-5': columns === 5,
      })}>
      {items.map((item, i) => (
        <Card key={i} title={item.title} value={item.value} placeholder={placeholder} footer={item.footer} />
      ))}
    </dl>
  )
}

export type IndicatorsProps = {
  indicators: Indicators
  condensed: boolean
  loading: boolean
}

export function IndicatorCards({ indicators, condensed, columns, ...props }: Partial<IndicatorsProps> & Partial<CardsProps>) {
  let items = (indicators?.items || []).map((indicator) => {
    return {
      title: indicator.title,
      value: (
        <div className="mt-2 divide-y divide-gray-100">
          {indicator.values.map((value, i) => {
            if (indicator.format === DataFormat.Grading || indicator.format === DataFormat.GradingWithColor) {
              let grade = parseFloat(value.value)

              return (
                <div
                  key={i}
                  className={classNames('flex overflow-hidden py-2 text-2xl font-semibold text-gray-900 truncate', { 'justify-between': !!value.label })}>
                  {value.label && (
                    <span className="w-28 py-1 leading-7 text-xs font-medium text-gray-700 truncate" title={value.label}>
                      {value.label}
                    </span>
                  )}
                  {formatData(indicator.format, value.value !== '' ? value.value : null, indicator.precision, '-')}
                  {(condensed || indicator.format !== DataFormat.GradingWithColor) && (
                    <span className={classNames('w-20 py-1 leading-7 text-xs font-normal text-gray-700 truncate', { 'ml-2': !value.label })}></span>
                  )}
                  {!condensed && indicator.format === DataFormat.GradingWithColor && (
                    <span
                      className={classNames('w-20 py-1 leading-7 text-xs font-normal text-gray-700 truncate', { 'ml-2': !value.label })}
                      title={value.value !== '' ? gradeCategory(grade) : 'N/A'}>
                      {value.value !== '' ? gradeCategory(grade) : ''}
                    </span>
                  )}
                </div>
              )
            }
            return (
              <div key={i} className="flex justify-between py-2 text-2xl font-semibold text-gray-900">
                {value.label && (
                  <span className="flex grow-0 shrink-0 w-10 py-1 text-md font-normal text-gray-900 truncate" title={value.label}>
                    {value.label}
                  </span>
                )}
                {formatData(indicator.format, value.value !== '' ? value.value : null, indicator.precision, '-')}
              </div>
            )
          })}
        </div>
      ),
    }
  })

  return <Cards items={items} columns={columns ? (Math.max(3, columns) as any) : 3} {...props} />
}
