import { useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure, Transition } from '@headlessui/react'
import { BlockMath } from 'react-katex'

import classNames from 'classnames'

import * as api from '~/api'
import * as Components from '~/components'
import { EvaluationIndicatorsDataSource, StudyState } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch, useAppState } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    length: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    weight: true,
  },
  event: {
    detectedBy: true,
  },
}

export function StudyEvaluationSummary() {
  const { settings } = useAppState()
  const dispatch = useAppDispatch()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const query = Components.buildDataSourceQuery(filters)

  const apollo = useApolloClient()
  const [aggregateStudy] = useMutation(api.AGGREGATE_STUDY)
  const [busy, setBusy] = useState(false)

  async function onAggregate() {
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyEvaluationSummary.onAggregate',
        title: 'Processing study datasets',
        message: 'This can take up to a few minutes, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await aggregateStudy({ variables: { id: studyId } })
      try {
        await apollo.refetchQueries({ include: api.ALL_RESULT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Processing failed', message: 'Check your study setup and retry later.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyEvaluationSummary.onAggregate' } })
      setBusy(false)
    }
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const getStudyIndicators = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.Study,
      query,
    },
  })
  const getPathwayIndicators = useQuery(api.EVALUATION_INDICATORS, {
    variables: {
      studyId,
      datasource: EvaluationIndicatorsDataSource.Pathway,
      query,
    },
  })

  const equationPathwayEfficiency1 = `
    Y_{\\Set{\\text{RR}, \\text{EE}, \\text{PE,PG}, \\text{PD}}} = \\frac{\\sum_i \\gamma(n_i) y_i}{\\sum_i \\gamma(n_i)}
  `
  const equationPathwayEfficiency2 = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\gamma(n) & \\text{species weighting function} \\\\
    n_i & \\text{number of individuals of species } i \\\\
    y_i & \\text{grading of species } i \\\\
    \\end{array}
  `
  const equationPathwayPassability1 = `
    Y_{\\text{passability}} = \\alpha Y_k + (1 - \\alpha) \\frac{\\sum_j Y_j}{n}
  `
  const equationPathwayPassability2 = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\alpha & \\text{aggregation coefficient where } 0 \\le \\alpha \\le 1 \\\\
    n & \\begin{cases}
      1 & \\text{if single-antenna} \\\\
      3 & \\text{otherwise} \\\\
    \\end{cases} \\\\
    k,j & \\begin{cases}
      \\in \\Set{\\text{PG}} & \\text{if single-antenna} \\\\
      \\in \\Set{\\text{EE}, \\text{PE}, \\text{PD}} & \\text{otherwise} \\\\
    \\end{cases} \\\\
    Y_k & \\text{lesser grading value} \\\\
    \\end{array}
  `
  const equationStudyDiscoverability1 = `
    Y = \\frac{\\sum_i \\gamma(n_i) y_i}{\\sum_i \\gamma(n_i)}
  `
  const equationStudyDiscoverability2 = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\gamma(n) & \\text{species weighting function} \\\\
    n_i & \\text{number of individuals of species } i \\\\
    y_i & \\text{grading of species } i \\\\
    \\end{array}
  `
  const equationStudyPassability1 = `
    Y = \\frac{\\sum_i R_i P_i}{\\sum_i R_i}
  `
  const equationStudyPassability2 = `
    \\text{where} \\quad
    \\begin{array}{cl}
    R_i & \\text{discoverability score of pathway } i \\\\
    P_i & \\text{passability score of pathway } i \\\\
    \\end{array}
  `
  const equationStudyScore1 = `
    Y = \\alpha Y_k + (1 - \\alpha) \\frac{\\sum_j Y_j}{2}
  `
  const equationStudyScore2 = `
    \\text{where} \\quad
    \\begin{array}{cl}
    \\alpha & \\text{aggregation coefficient where } 0 \\le \\alpha \\le 1 \\\\
    k,j & \\in \\Set{\\text{discoverability}, \\text{passability}} \\\\
    Y_j & \\text{grading of criteria } j \\\\
    Y_k & \\text{lesser grading value} \\\\
    \\end{array}
  `

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Evaluation</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {(study?.dirty || settings.allowForcedAggregation) && (
                <Components.HeaderPrimaryButton onClick={onAggregate} disabled={busy}>
                  Aggregate datasets
                </Components.HeaderPrimaryButton>
              )}
            </div>
          )}
        </div>
      </header>

      {study?.dirty && (
        <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {(study?.state !== StudyState.Active || study?.deletedAt) && <p>Note: this study is archived and no evaluation was computed prior to archival.</p>}
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <p>
              Note: study parameters, topology and/or datasets changed since last aggregation. Please click 'aggregate' button to compute latest statistics.
            </p>
          )}
        </main>
      )}
      {study && !study.dirty && (
        <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <div className="relative z-20 pb-5 mb-5 border-b border-gray-200">
            <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actions={[]} />
          </div>

          <Disclosure>
            {({ open }) => (
              <div className="mt-10 border-b border-gray-200">
                <div className="border-b border-gray-200 pb-5">
                  <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                    Grading of entire study
                    <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                      tell me more
                      <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                    </Disclosure.Button>
                  </h2>
                </div>
                <Components.IndicatorCards
                  indicators={getStudyIndicators.data?.evaluationIndicators}
                  columns={getStudyIndicators.data?.evaluationIndicators.items.length as any}
                />
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel>
                    <article className="px-5 pt-5 prose prose-sm max-w-none border-t border-gray-200">
                      <p>Study score formula:</p>
                      <BlockMath>{equationStudyScore1}</BlockMath>
                      <BlockMath>{equationStudyScore2}</BlockMath>
                      <p>Study passability formula:</p>
                      <BlockMath>{equationStudyPassability1}</BlockMath>
                      <BlockMath>{equationStudyPassability2}</BlockMath>
                      <p>Study discoverability formula:</p>
                      <BlockMath>{equationStudyDiscoverability1}</BlockMath>
                      <BlockMath>{equationStudyDiscoverability2}</BlockMath>
                    </article>
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <div className="mt-10 border-b border-gray-200">
                <div className="border-b border-gray-200 pb-5">
                  <h2 className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                    Detailed evaluation by pathway
                    <Disclosure.Button className="ml-4 inline-flex items-center font-normal text-xs text-wa21-500">
                      tell me more
                      <ChevronRightIcon className={classNames('w-4 h-4', open && 'rotate-90 transform')} />
                    </Disclosure.Button>
                  </h2>
                </div>
                <Components.IndicatorCards
                  indicators={getPathwayIndicators.data?.evaluationIndicators}
                  columns={getPathwayIndicators.data?.evaluationIndicators.items.length as any}
                  condensed
                />
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0">
                  <Disclosure.Panel>
                    <article className="px-5 pt-5 prose prose-sm max-w-none border-t border-gray-200">
                      <p>Pathway passability formula:</p>
                      <BlockMath>{equationPathwayPassability1}</BlockMath>
                      <BlockMath>{equationPathwayPassability2}</BlockMath>
                      <p>Pathway efficiency formula (for RR, EE, PE/PG, PD):</p>
                      <BlockMath>{equationPathwayEfficiency1}</BlockMath>
                      <BlockMath>{equationPathwayEfficiency2}</BlockMath>
                    </article>
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
        </main>
      )}
    </>
  )
}
