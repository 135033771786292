import { Spin } from '~/assets'

export function SignOut() {
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h1 className="mt-6 text-center text-6xl font-title font-bold tracking-tight text-wa21-600">PIT WebTool</h1>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-600">Sign out of your account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center mt-6">
          <Spin className="h-10 w-10 text-gray-300" />
        </div>
      </div>
    </div>
  )
}
