import { Ref, useCallback, useMemo, useRef, TargetedEvent } from 'react'

import { Bar, Radar, Scatter } from 'react-chartjs-2'
import { Chart as ChartRenderer, ScaleOptions, ChartOptions, TooltipItem, Tick } from 'chart.js'
import autocolors from 'chartjs-plugin-autocolors-typescript'

import Decimal from 'decimal.js'
import { DateTime } from 'luxon'
import _ from 'lodash'

import { Chart, ChartAxis, DataFormat } from '~/graphql-codegen/graphql'

import { formatDelayMillis, dataUrlToClipboard, downloadDataUrl, BINARY_CLIPBOARD_AVAILABLE } from './helpers'

type ChartProps = {
  chart: Chart
  title: string
  filename: string
  xAxisOptions?: ScaleOptions
  yAxisOptions?: ScaleOptions
  y1AxisOptions?: ScaleOptions
}

function ChartExports({ renderer, filename, ...props }: { renderer: () => string | undefined; filename?: string } & React.HTMLAttributes<HTMLDivElement>) {
  const copyImage = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()

    const href = renderer()

    if (href) {
      dataUrlToClipboard(href)
    }
  }
  const downloadImage = (ev: TargetedEvent<HTMLAnchorElement>) => {
    ev.preventDefault()

    const href = renderer()

    if (href) {
      downloadDataUrl(href, `${filename || 'chart'}.png`)
    }
  }

  return filename ? (
    <div className="space-x-4 text-right" {...props}>
      {BINARY_CLIPBOARD_AVAILABLE && (
        <a className="text-xs text-wa21-500" href="about:blank" onClick={copyImage}>
          Copy PNG
        </a>
      )}
      <a className="text-xs text-wa21-500" href="about:blank" onClick={downloadImage}>
        Export PNG
      </a>
    </div>
  ) : null
}

const EMPTY_X_AXIS: ChartAxis = {
  name: '',
  unit: '',
  format: DataFormat.Text,
  stacked: false,
}

const EMPTY_Y_AXIS: ChartAxis = {
  name: '',
  unit: '',
  format: DataFormat.Integer,
  stacked: false,
}

function axisName(axis: ChartAxis): string {
  if (axis.unit.length > 0) {
    return `${axis.name} [${axis.unit}]`
  }
  return axis.name
}

function axisOptions(axis: ChartAxis, labels: string[]): ScaleOptions {
  let name = axisName(axis)
  let axisType: 'category' | 'linear' | 'time' = 'category'
  let ticks: any =
    axis.format === DataFormat.Text
      ? {
          align: 'center' as const,
          maxTicksLimit: labels.length + 1,
          autoSkip: false,
          major: {
            enabled: true,
          },
        }
      : {}
  let options: any = {}

  switch (axis.format) {
    case DataFormat.Integer:
    case DataFormat.Decimal:
      axisType = 'linear'
      options.beginAtZero = true
      options.suggestedMin = 0
      break
    case DataFormat.Percentage:
      axisType = 'linear'
      ticks.count = 11
      ticks.format = {
        style: 'percent',
      }
      options.beginAtZero = true
      options.suggestedMin = 0
      options.suggestedMax = 1
      break
    case DataFormat.Date:
    case DataFormat.DateTime:
      axisType = 'time'
      break
    case DataFormat.Duration:
      axisType = 'category'
      ticks.callback = (_value: number | string, index: number, _ticks: Tick[]) => formatDelayMillis(labels[index])
      break
  }
  return {
    display: name.length > 0,
    type: axisType,
    labels: labels,
    stacked: axis.stacked,
    title: {
      display: name.length > 0,
      text: name,
    },
    ticks,
    ...options,
  }
}

function axisValue(axis: ChartAxis, serie: string, value: string): string {
  let text = value

  switch (axis.format) {
    case DataFormat.Integer:
      text = new Decimal(value).toFixed(0)
      break
    case DataFormat.Decimal:
      text = new Decimal(value).toFixed(2)
      break
    case DataFormat.Percentage:
      text = new Decimal(value).mul(100).toFixed(2)
      break
    case DataFormat.Date:
      text = DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)
      break
    case DataFormat.DateTime:
      text = DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      break
    case DataFormat.Duration:
      text = formatDelayMillis(value)
      break
  }
  if (serie.length > 0) {
    if (axis.unit.length > 0) {
      return `${text} ${axis.unit}: ${serie}`
    }
    return `${text}: ${serie}`
  }
  if (axis.unit.length > 0) {
    return `${text} ${axis.unit}`
  }
  return `${text}`
}

export function BarChart({ chart, title, filename, ...props }: Partial<ChartProps>) {
  const chartRef = useRef<ChartRenderer>(null)
  let labels = useMemo(() => chart?.labels || (chart?.series || []).find((serie) => serie.x !== undefined)?.x || [], [chart])
  let xAxis = useMemo(() => chart?.xAxis || EMPTY_X_AXIS, [chart])
  let xScale = useMemo(() => ({ ...axisOptions(xAxis, labels), ...props.xAxisOptions }), [xAxis, labels])
  let yAxis = useMemo(() => chart?.yAxes[0] || EMPTY_Y_AXIS, [chart])
  let yScale = useMemo(() => ({ ...axisOptions(yAxis, []), position: 'left', ...props.yAxisOptions }) as ScaleOptions, [yAxis])
  let y1Axis = useMemo(() => chart?.yAxes[1] || EMPTY_Y_AXIS, [chart])
  let y1Scale = useMemo(() => ({ ...axisOptions(y1Axis, []), position: 'right', ...props.y1AxisOptions }) as ScaleOptions, [y1Axis])
  let tooltipTitleCallback = useCallback((items: TooltipItem<any>[]) => axisValue(xAxis, '', (items[0].raw as any).x), [xAxis])
  let tooltipLabelCallback = useCallback(
    (item: TooltipItem<any>) => axisValue(item.dataset.yAxisID === 'y' ? yAxis : y1Axis, item.dataset.label, (item.raw as any).y),
    [yAxis, y1Axis]
  )
  let series = useMemo(() => chart?.series || [], [chart])
  let ySeries = useMemo(() => series.filter((serie) => serie.yAxis === 0), [series])
  let y1Series = useMemo(() => series.filter((serie) => serie.yAxis !== 0), [series])
  let data = useMemo(
    () => ({
      datasets: series.map((serie, i) => {
        let axis = serie.yAxis === 0 ? yAxis : y1Axis
        let color = ySeries.length > 1 ? undefined : serie.yAxis === 0 ? 'rgba(0, 0, 0, 0.75)' : 'rgba(249, 116, 0, 0.5)'

        return {
          order: i,
          type: serie.yAxis === 0 ? 'bar' : ('line' as any),
          stack: axis.stacked ? (serie.yAxis === 0 ? 'left' : 'right') : serie.id,
          fill: serie.yAxis === 0 ? false : 'origin',
          stepped: serie.yAxis === 0 ? false : 'middle',
          label: serie.name || 'N/A',
          backgroundColor: color,
          borderColor: color,
          barPercentage: 0.8,
          categoryPercentage: 0.67,
          minBarLength: 1,
          hoverBorderWidth: 1,
          showLine: false,
          pointRadius: 0,
          pointHoverRadius: 5,
          data: _.zip(serie.x || labels, serie.y).map(([x, y]) => ({ x, y })),
          yAxisID: serie.yAxis === 0 ? 'y' : 'y1',
        }
      }),
    }),
    [yAxis, y1Axis, series, ySeries, y1Series, labels]
  )
  let options = useMemo(
    () =>
      ({
        responsive: true,
        plugins: {
          title: {
            display: !!title,
            text: title,
          },
          legend: {
            display: series.length > 1,
          },
          tooltip: {
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback,
            },
          },
        },
        scales: {
          x: xScale,
          y: yScale,
          y1: y1Scale,
        },
      }) as ChartOptions<'bar'>,
    [title, series, tooltipTitleCallback, tooltipLabelCallback, xScale, yScale, y1Scale]
  )
  let plugins = useMemo(() => {
    let plugins = []

    if (series.length > 1) {
      plugins.push(autocolors)
    }
    return plugins
  }, [series])

  return (
    <div className="relative">
      <Bar ref={chartRef as Ref<any>} options={options} plugins={plugins} data={data} redraw />
      {series.length > 0 && (
        <ChartExports className="absolute bottom-0 right-0 space-x-4 pr-3" renderer={() => chartRef.current?.toBase64Image()} filename={filename} />
      )}
    </div>
  )
}

export function ScatterChart({ chart, title, filename, ...props }: Partial<ChartProps>) {
  const chartRef = useRef<ChartRenderer>(null)
  let xAxis = useMemo(() => chart?.xAxis || EMPTY_X_AXIS, [chart])
  let xScale = useMemo(() => ({ ...axisOptions(xAxis, []), beginAtZero: false, suggestedMin: undefined, ...props.xAxisOptions }), [xAxis])
  let yAxis = useMemo(() => chart?.yAxes[0] || EMPTY_Y_AXIS, [chart])
  let yScale = useMemo(
    () => ({ ...axisOptions(yAxis, []), position: 'left', beginAtZero: false, suggestedMin: undefined, ...props.yAxisOptions }) as ScaleOptions,
    [yAxis]
  )
  let y1Axis = useMemo(() => chart?.yAxes[1] || EMPTY_Y_AXIS, [chart])
  let y1Scale = useMemo(
    () => ({ ...axisOptions(y1Axis, []), position: 'right', beginAtZero: false, suggestedMin: undefined, ...props.y1AxisOptions }) as ScaleOptions,
    [y1Axis]
  )
  let tooltipTitleCallback = useCallback((items: TooltipItem<any>[]) => axisValue(xAxis, '', (items[0].raw as any).x), [xAxis])
  let tooltipLabelCallback = useCallback((item: TooltipItem<any>) => axisValue(yAxis, item.dataset.label, (item.raw as any).y), [yAxis])
  let series = useMemo(() => chart?.series || [], [chart])
  let data = useMemo(
    () => ({
      datasets: series.map((serie, i) => ({
        order: i,
        label: serie.name || 'N/A',
        backgroundColor: series.length > 1 ? undefined : 'black',
        data: _.zip(serie.x || [], serie.y).map(([x, y]) => ({ x, y })),
        yAxisID: serie.yAxis === 0 ? 'y' : 'y1',
      })),
    }),
    [series]
  )
  let options = useMemo(
    () =>
      ({
        responsive: true,
        plugins: {
          title: {
            display: !!title,
            text: title,
          },
          legend: {
            display: series.length > 1,
          },
          tooltip: {
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback,
            },
          },
        },
        scales: {
          x: xScale,
          y: yScale,
          y1: y1Scale,
        },
      }) as ChartOptions<'scatter'>,
    [title, series, tooltipTitleCallback, tooltipLabelCallback, xScale, yScale, y1Scale]
  )
  let plugins = useMemo(() => {
    let plugins = []

    if (series.length > 1) {
      plugins.push(autocolors)
    }
    return plugins
  }, [series])

  return (
    <div className="relative">
      <Scatter ref={chartRef as Ref<any>} options={options} plugins={plugins} data={data} redraw />
      {series.length > 0 && (
        <ChartExports className="absolute bottom-0 right-0 space-x-4 pr-3" renderer={() => chartRef.current?.toBase64Image()} filename={filename} />
      )}
    </div>
  )
}

export function RadarChart({ chart, title, filename }: Partial<ChartProps>) {
  const chartRef = useRef<ChartRenderer>(null)
  let labels = useMemo(() => chart?.labels || ((chart?.series.length || 0) > 0 ? chart?.series[0].x : []) || [], [chart])
  let series = useMemo(() => chart?.series || [], [chart])
  let data = useMemo(
    () => ({
      labels,
      datasets: series.map((serie, i) => ({
        order: i,
        stack: undefined,
        fill: false,
        label: serie.name || 'N/A',
        borderColor: series.length > 1 ? undefined : 'black',
        pointRadius: 0,
        pointHoverRadius: 5,
        data: serie.x ? _.zip(serie.x, serie.y).map(([x, y]) => ({ x, y })) : serie.y,
      })),
    }),
    [labels, series]
  )
  let options = useMemo(
    () =>
      ({
        responsive: true,
        aspectRatio: 1.5,
        plugins: {
          title: {
            display: !!title,
            text: title,
          },
          legend: {
            display: series.length > 1,
            position: 'left' as any,
          },
        },
        scales: {
          r: {
            beginAtZero: true,
            ticks: {
              count: 11,
              format: { style: 'percent' },
            },
          },
        },
      }) as ChartOptions<'radar'>,
    [title, series]
  )
  let plugins = useMemo(() => {
    let plugins = []

    if (series.length > 1) {
      plugins.push(autocolors)
    }
    return plugins
  }, [series])

  return (
    <div className="relative">
      <Radar ref={chartRef as Ref<any>} options={options} plugins={plugins} data={data} redraw />
      {series.length > 0 && (
        <ChartExports className="absolute bottom-0 right-0 space-x-4 pr-3" renderer={() => chartRef.current?.toBase64Image()} filename={filename} />
      )}
    </div>
  )
}
