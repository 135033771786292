import { ChangeEvent, useEffect, useState } from 'react'

import _ from 'lodash'

import { AppAction, AppPrompt, useAppDispatch, useAppState } from '~/state'

import { Modal } from './modal'

export function PromptContainer() {
  const dispatch = useAppDispatch()
  const [expiry] = useState<{ [k: string]: number }>({})
  const [value, setValue] = useState<{ [k: string]: string }>({})
  const [visibility, setVisibility] = useState<{ [k: string]: boolean }>({})
  const { prompts } = useAppState()
  const items = prompts || []

  function onUpdate(id: string, ev: ChangeEvent<HTMLInputElement>) {
    const newValue = { ...value }

    newValue[id] = ev.currentTarget.value
    setValue(newValue)
  }

  useEffect(() => {
    let newVisibility = { ..._.pickBy(visibility, (_, id) => prompts.find((item) => item.id === id)) }
    let visibilityChanged = _.size(newVisibility) < _.size(visibility)

    for (let item of items) {
      if (expiry[item.id] === undefined) {
        newVisibility[item.id] = true
        visibilityChanged = true
      }
    }
    if (visibilityChanged) {
      setVisibility(newVisibility)
    }
  }, [prompts])

  function onPrimaryAction(item: AppPrompt) {
    item.resolve(value[item.id])
    dispatch({ action: AppAction.DismissPrompt, prompt: item })
  }

  function onSecondaryAction(item: AppPrompt) {
    item.resolve(null)
    dispatch({ action: AppAction.DismissPrompt, prompt: item })
  }

  return (
    <>
      {items.map((item) => {
        return (
          <Modal
            key={item.id}
            title={item.title}
            manual
            primaryAction={item.choices === 'okcancel' ? 'OK' : 'Yes'}
            onPrimaryAction={() => onPrimaryAction(item)}
            secondaryAction={item.choices === 'okcancel' ? 'Cancel' : 'No'}
            onSecondaryAction={() => onSecondaryAction(item)}>
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
                onPrimaryAction(item)
              }}>
              <div>
                {item.label && (
                  <label htmlFor={`prompt-${item.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                    {item.label}
                  </label>
                )}
                <div className="mt-2">
                  <input
                    id={`prompt-${item.id}`}
                    type={item.type}
                    name={item.name}
                    defaultValue={item.defaultValue?.toString() || ''}
                    placeholder={item.placeholder}
                    className="form-input block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-wa21-300 sm:text-sm sm:leading-6 disabled:bg-gray-100"
                    onChange={(ev) => onUpdate(item.id, ev)}
                  />
                </div>
                {item.description && <p className="mt-2 text-sm text-gray-500">{item.description}</p>}
              </div>
            </form>
          </Modal>
        )
      })}
    </>
  )
}
