import { createContext, useContext } from 'react'

import apolloClient from './apollo'

export type AuthSession = {
  userId: string
  userRole: 'standard' | 'admin'
  organizationId: string
  organizationRole: 'standard' | 'super_admin'
}

export class AuthController {
  public static readonly instance = new AuthController()

  private cache?: AuthSession | null

  private constructor() {}

  get current() {
    return this.cache || null
  }

  update(session: AuthSession) {
    this.cache = session
    apolloClient.clearStore()
  }

  clear() {
    this.cache = null
    apolloClient.clearStore()
  }

  async load(): Promise<AuthSession | null> {
    if (this.cache === undefined) {
      return await this.refresh()
    }
    return this.cache || null
  }

  async refresh(): Promise<AuthSession | null> {
    try {
      const response = await fetch('/api/session')

      if (response.ok) {
        this.cache = await response.json()
      }
    } catch (e) {
      console.debug(e)
      this.cache = null
    }
    apolloClient.clearStore()
    return this.cache || null
  }
}

const context = createContext<AuthController>(AuthController.instance)

export const AuthProvider = context.Provider

export function useAuth() {
  return useContext(context)
}
