import { useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { DateTime } from 'luxon'

import * as api from '~/api'
import * as Components from '~/components'
import { DetectionDataset, StudyState } from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

export function StudyDetectionDatasetsListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') || '0')

  const apollo = useApolloClient()
  // const [updateDataset] = useMutation(api.UPDATE_DETECTION_DATASET)
  const [parseDataset] = useMutation(api.PARSE_DETECTION_DATASET)
  const [deleteDataset] = useMutation(api.DELETE_DETECTION_DATASET)
  const [busy, setBusy] = useState(false)

  async function onParse(dataset: Partial<DetectionDataset>) {
    const id = dataset.id

    if (!id) {
      return
    }
    setBusy(true)
    dispatch({
      action: AppAction.PublishNotification,
      notification: {
        id: 'StudyDetectionDatasetsListing.onParse',
        title: 'Processing dataset',
        message: 'This can take a few seconds, please wait...',
        forced: true,
        spinner: true,
      },
    })
    try {
      await parseDataset({ variables: { id } })
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to validate dataset.', exception: e },
      })
    } finally {
      dispatch({ action: AppAction.DismissNotification, notification: { id: 'StudyDetectionDatasetsListing.onParse' } })
      setBusy(false)
    }
  }

  async function onDelete(dataset: Partial<DetectionDataset>) {
    const id = dataset.id

    if (!id) {
      return
    }

    const confirm = await new Promise<boolean>((resolve) => {
      dispatch({
        action: AppAction.RequestConfirmation,
        confirmation: {
          title: 'Confirmation',
          message: (
            <span>
              Are you sure you want to delete <span className="font-medium">{dataset.name}</span>?
            </span>
          ),
          resolve,
        },
      })
    })

    if (!confirm) {
      return
    }
    setBusy(true)
    try {
      await deleteDataset({ variables: { id, delete: true } })
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to delete dataset.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onRestore(dataset: Partial<DetectionDataset>) {
    const id = dataset.id

    if (!id) {
      return
    }

    setBusy(true)
    try {
      await deleteDataset({ variables: { id, delete: false } })
      try {
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Action failed', message: 'Failed to restore dataset.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onImport() {
    navigate({ pathname: 'import', search: searchParams.toString() })
  }

  // function onInsert() {
  //   navigate({ pathname: 'new', search: searchParams.toString() })
  // }

  function onUpdate(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const listDatasets = useQuery(api.LIST_DETECTION_DATASETS, { variables: { studyId: studyId, request: { page, limit: 25, deleted: false } } })
  const study = listDatasets.data?.study
  const datasetColumns: Components.TableColumn[] = [
    // {
    //   name: 'id',
    //   label: 'ID',
    //   className: 'w-16 text-center',
    // },
    {
      name: 'name',
      label: 'Name',
    },
    {
      name: 'state',
      label: 'State',
      className: 'w-32',
    },
    {
      name: 'events',
      label: 'Rows',
      className: 'w-24 text-right',
    },
    {
      name: 'errors',
      label: 'Errors',
      className: 'w-16 text-right',
    },
    {
      name: 'createdAt',
      label: 'Uploaded at',
      className: 'w-32',
    },
    // {
    //   name: 'validated',
    //   label: 'Valid',
    //   className: 'w-16 text-center',
    // },
    {
      name: 'actions',
      label: 'Actions',
      className: 'w-32 text-right',
      interactive: true,
    },
  ]
  const datasetRows: Components.TableRow[] = (listDatasets.data?.study.detectionDatasets.items || []).map((dataset) => ({
    id: dataset.id,
    name: dataset.name,
    state: dataset.state,
    events: `${dataset.validEventCount} / ${dataset.eventCount}`,
    errors: dataset.errors && dataset.errors.length > 0 ? <span className="text-wa21-danger-500">{dataset.errors.length}</span> : 0,
    validated: dataset.validated ? (
      <CheckCircleIcon className="inline w-5 h-5 text-wa21-500" title={`Validated by ${dataset.validatedBy?.fullname || dataset.validatedBy?.email}`} />
    ) : (
      <XCircleIcon className="inline w-5 h-5 text-wa21-danger-500" />
    ),
    createdAt: DateTime.fromSeconds(dataset.createdAt).toLocaleString(DateTime.DATE_FULL),
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {!dataset.deletedAt && dataset.dirty && (
          <button onClick={() => onParse(dataset as DetectionDataset)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Parse
          </button>
        )}
        {/*!dataset.deletedAt && !dataset.validated && (
          <button onClick={() => onValidate(dataset as DetectionDataset)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Validate
          </button>
        )}
        {!dataset.deletedAt && dataset.validated && (
          <button
            onClick={() => onInvalidate(dataset as DetectionDataset)}
            disabled={busy}
            className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Invalidate
          </button>
        )*/}
        {dataset.deletedAt && (
          <button onClick={() => onRestore(dataset as DetectionDataset)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Restore
          </button>
        )}
        {!dataset.deletedAt && (
          <button onClick={() => onDelete(dataset as DetectionDataset)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Delete
          </button>
        )}
      </div>
    ),
  }))

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Detection events</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {/*<Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>Add custom dataset</Components.HeaderPrimaryButton>*/}
              <Components.HeaderPrimaryButton onClick={onImport} disabled={busy}>
                Import from CSVs
              </Components.HeaderPrimaryButton>
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <Components.Table
          columns={datasetColumns}
          rows={datasetRows}
          stickyHeader
          page={page}
          pages={listDatasets.data?.study.detectionDatasets.pages}
          total={listDatasets.data?.study.detectionDatasets.total}
          loading={listDatasets.loading}
          onClick={onUpdate}
          onPage={onPage}
        />
      </main>

      <Outlet />
    </>
  )
}
