import { useState } from 'react'
import { Link, Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { DateTime } from 'luxon'
import numeral from 'numeral'

import * as api from '~/api'
import * as Components from '~/components'
import {
  DetectionEvent,
  DetectionEventSort,
  UpsertDetectionEventFields,
  StudyState,
  UpdateDetectionEventMutationVariables,
  UpdateDetectionEventsMutationVariables,
  UpdateOrganismScope,
  UpdateOrganismsMutationVariables,
  UpsertOrganismFields,
} from '~/graphql-codegen/graphql'
import { AppAction, useAppDispatch } from '~/state'

const ACTIVE_FILTERS = {
  organism: {
    capturedAt: true,
    datasets: true,
    labels: true,
    releasedAt: true,
    species: true,
    tagQuery: true,
    taggedOn: true,
    validity: true,
  },
  event: {
    datasets: true,
    detectedBy: true,
    detectedByArray: true,
    detectedOn: true,
    validity: true,
  },
}

export function StudyDetectionEventsListing() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const studyId = params.studyId || '0'
  const [searchParams, setSearchParams] = useSearchParams()
  const filters = Components.parseStudyFilters(searchParams)
  const page = parseInt(searchParams.get('page') || '0')
  const sort = searchParams.get('sort') || 'detected_on'
  const sortBy = (sort.startsWith('-') ? sort.substring(1) : sort).toUpperCase() as DetectionEventSort
  const sortByReverse = sort.startsWith('-')

  const apollo = useApolloClient()
  const [updateEvent] = useMutation(api.UPDATE_DETECTION_EVENT)
  const [updateEvents] = useMutation(api.UPDATE_DETECTION_EVENTS)
  const [updateOrganisms] = useMutation(api.UPDATE_ORGANISMS)
  const [busy, setBusy] = useState(false)

  async function onUpdateEvent<K extends keyof DetectionEvent>(id: string, key: K, value: DetectionEvent[K]) {
    setBusy(true)
    try {
      const variables: UpdateDetectionEventMutationVariables = {
        id,
        fields: {},
      }

      if (key === 'triggeredBy') {
        variables.triggeredBy = value
      } else if (key === 'detectedOn') {
        variables.detectedOn = value
      } else if (key === 'detectedByArray') {
        variables.detectedByArray = value
      } else {
        variables.fields[key as keyof UpsertDetectionEventFields] = value
      }
      await updateEvent({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individual.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateEvents<K extends keyof UpsertDetectionEventFields>(key: K, value: UpsertDetectionEventFields[K]) {
    setBusy(true)
    try {
      const variables: UpdateDetectionEventsMutationVariables = {
        studyId,
        filters: Components.buildDataSourceQuery(filters).filters,
        fields: {},
      }

      variables.fields[key] = value
      await updateEvents({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_EVENT_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update events.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateOrganisms<K extends keyof UpsertOrganismFields>(key: K, value: UpsertOrganismFields[K]) {
    setBusy(true)
    try {
      const variables: UpdateOrganismsMutationVariables = {
        studyId,
        filters: Components.buildDataSourceQuery(filters).filters,
        fields: {},
        scope: UpdateOrganismScope.DetectionEvents,
      }

      variables.fields[key] = value
      await updateOrganisms({ variables })
      try {
        await apollo.clearStore()
        await apollo.refetchQueries({ include: api.ALL_ORGANISM_QUERIES })
      } catch (e) {
        console.warn(e)
      }
    } catch (e) {
      dispatch({
        action: AppAction.PublishNotification,
        notification: { level: 'error', title: 'Update failed', message: 'Failed to update individuals.', exception: e },
      })
    } finally {
      setBusy(false)
    }
  }

  async function onUpdateLabels() {
    const label = await new Promise<string | number | null>((resolve) => {
      dispatch({
        action: AppAction.RequestPrompt,
        prompt: {
          title: 'New label',
          placeholder: 'Enter label...',
          resolve,
        },
      })
    })

    if (label !== null) {
      await onUpdateOrganisms('label', label as string)
    }
  }

  async function onClearLabels() {
    await onUpdateOrganisms('label', null)
  }

  function onPage(page: number) {
    setSearchParams((prev) => {
      prev.set('page', page.toString())
      return prev
    })
  }

  function onSort(sort: string) {
    setSearchParams((prev) => {
      prev.delete('page')
      if (sort !== 'detected_on') {
        prev.set('sort', sort)
      } else {
        prev.delete('sort')
      }
      return prev
    })
  }

  function onView(row: Components.TableRow) {
    navigate({ pathname: row.id, search: searchParams.toString() })
  }

  const getStudy = useQuery(api.GET_STUDY, { variables: { id: studyId } })
  const study = getStudy.data?.study

  const listDetectionEventsFilters = Components.parseStudyFilters(searchParams)
  const listDetectionEvents = useQuery(api.LIST_DETECTION_EVENTS, {
    variables: {
      studyId,
      request: {
        page,
        limit: 25,
        sortBy,
        sortByReverse,
        datasetIds: listDetectionEventsFilters.organism.datasets,
        valid: listDetectionEventsFilters.event.validity,
        speciesIds: listDetectionEventsFilters.organism.species,
        tags: listDetectionEventsFilters.organism.tags,
        tagQuery: Components.encodeLikeQuery(listDetectionEventsFilters.organism.tagQuery),
        labels: listDetectionEventsFilters.organism.labels,
        capturedAt: listDetectionEventsFilters.organism.capturedAt,
        releasedAt: listDetectionEventsFilters.organism.releasedAt,
        detectionDatasetIds: listDetectionEventsFilters.event.datasets,
        detectedOn: listDetectionEventsFilters.event.detectedOn,
        detectedBy: listDetectionEventsFilters.event.detectedBy,
        detectedByArray: listDetectionEventsFilters.event.detectedByArray,
      },
    },
  })
  const eventColumns: Components.TableColumn[] = [
    {
      name: 'detected_on',
      label: 'Time',
      className: 'w-48',
      sortable: true,
    },
    {
      name: 'detected_for',
      label: 'Duration (s)',
      align: 'right',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'tag',
      label: 'Individual',
      className: '',
      sortable: true,
    },
    {
      name: 'detected_by',
      label: 'Antenna',
      className: 'w-32',
      sortable: true,
    },
    {
      name: 'valid',
      label: 'Included',
      align: 'center',
      className: 'w-16',
    },
    {
      name: 'actions',
      label: 'Actions',
      align: 'right',
      className: 'w-24',
      interactive: true,
    },
  ]
  const eventRows: Components.TableRow[] = (listDetectionEvents.data?.detectionEvents.items || []).map((event) => ({
    id: event.id,
    detected_on: DateTime.fromSeconds(event.detectedOn).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
    detected_for: event.detectedFor ? numeral(event.detectedFor).format('0,0.0') : undefined,
    tag: <Components.OrganismTagDisplay organismId={event.triggeredById} />,
    detected_by: <Components.AntennaNameDisplay antennaArrayId={event.detectedByArrayId} antenna={event.detectedByAntenna} />,
    valid: event.valid ? <CheckCircleIcon className="inline w-5 h-5 text-wa21-500" /> : <XCircleIcon className="inline w-5 h-5 text-wa21-danger-500" />,
    actions: study?.state === StudyState.Active && !study?.deletedAt && (
      <div className="space-x-2">
        {!event.valid && (
          <button onClick={() => onUpdateEvent(event.id, 'valid', true)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Include
          </button>
        )}
        {event.valid && (
          <button onClick={() => onUpdateEvent(event.id, 'valid', false)} disabled={busy} className="text-wa21-600 disabled:text-gray-400 hover:text-wa21-900">
            Exclude
          </button>
        )}
      </div>
    ),
  }))

  const exportUrlParams = Components.encodeStudyFilters(filters, true)
  const exportUrl = `/api/csv/events/${studyId}?${exportUrlParams}`
  const actionGroups: Components.StudyFilterAction[][] = [
    [
      { name: 'Include all', onClick: () => onUpdateEvents('valid', true), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Exclude all', onClick: () => onUpdateEvents('valid', false), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [
      { name: 'Set labels', onClick: () => onUpdateLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
      { name: 'Clear labels', onClick: () => onClearLabels(), disabled: study?.state !== StudyState.Active || study?.deletedAt },
    ],
    [{ name: 'Export CSV', url: exportUrl }],
  ]

  return (
    <>
      <header className="pt-10 pb-6">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto mx-auto max-w-7xl">
            <h1 className="flex items-end space-x-1 text-3xl font-bold tracking-tight text-white">
              <Link to=".." className="hover:text-wa21-100">
                Study
              </Link>
              <ChevronRightIcon className="h-8 w-8 flex-shrink-0 text-white opacity-50" aria-hidden="true" />
              <span>Validated detection events</span>
            </h1>
            <p className="mt-1 truncate text-sm text-white">{study?.name}</p>
          </div>
          {study?.state === StudyState.Active && !study?.deletedAt && (
            <div className="flex space-x-2 mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {/*<Components.HeaderPrimaryButton onClick={onInsert} disabled={busy}>Add event</Components.HeaderPrimaryButton>*/}
            </div>
          )}
        </div>
      </header>

      <main className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        <div className="relative z-20 pb-5 border-b border-gray-200">
          <Components.StudyFilters studyId={studyId} flags={ACTIVE_FILTERS} actionGroups={actionGroups} pageKey="page" />
        </div>
        <Components.Table
          columns={eventColumns}
          rows={eventRows}
          condensed
          stickyHeader
          page={page}
          pages={listDetectionEvents.data?.detectionEvents.pages}
          total={listDetectionEvents.data?.detectionEvents.total}
          loading={listDetectionEvents.loading}
          onClick={onView}
          onPage={onPage}
          sort={sort}
          onSort={onSort}
        />
      </main>

      <Outlet />
    </>
  )
}
